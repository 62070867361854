#AddQuestionMenu {
  text-align: center;

  button {
    font-size: 12px;
    padding: 6px 5px 6px 10px !important;
  }

  .MuiButton-label {
    color: #fff;
  }

  /* Moving this into ID wrapper. Wrap Mui overrides or they becomes global overrides. */
  .MuiPopover-paper {
    box-shadow: 5px 5px 5px rgb(219, 218, 218) !important;
  }
}

#footContainer {
  max-width: 50% !important;
  margin-left: 56px;

  .addFooter {
    margin-left: 56px;
    width: 35%;
    background-color: none;
    font-size: 12px;
    padding: 0.5em 1em 0.5em 1em !important;
    position: absolute;
    bottom: 0;
    left: 0;

    button {
      font-size: 12px;
      padding: 6px 5px 6px 10px !important;
      margin-bottom: 25px;
    }
  }
}

.material-icons {
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
  display: inline-block;
  line-height: 22px !important;
  border-radius: 4px !important;
  width: 22px !important;
  height: 22px !important;
  color: white;
  text-align: center;
  vertical-align: bottom;
  margin-right: 5px;
  font-size: 22px !important;
}

.catalog {
  border-top: 1px solid rgb(209, 203, 203) !important;
}

.add-question-button {
  span {
    color: white;
  }
}

.icon {
  margin-right: 5px;
  // min-width: 30px !important;
}

.blue {
  div > span {
    background-color: #5a87db;
  }
}

.brown {
  div > span {
    background-color: #ad6f3c;
  }
}

.green {
  div > span {
    background-color: #75b975;
  }
}

.yellow {
  div > span {
    background-color: #f0e054;
  }
}

.red {
  div > span {
    background-color: #da4949;
  }
}

.purple {
  div > span {
    background-color: #c557cf;
  }
}

.orange {
  div > span {
    background-color: orange;
  }
}

.pink {
  div > span {
    background-color: pink;
  }
}
