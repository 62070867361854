@import '../../css/index';

#ContentLayout {
  overflow: hidden;

  .content-layout-container {
    height: 100%;
    width: 100%;

    #DashboardToolbar {
      max-height: 60px;
    }

    .MuiDialogActions-root {
      padding: 10px !important;
      background-color: white !important;
    }

    .MuiPaper-root {
      background-color: white !important;
    }
  }
}

.end-content {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.toggle-filter {
  height: 34px;
  width: 34px;
  max-height: 34px;
  max-width: 34px;
  margin-left: 10px !important;
}

#ToggleFilter .MuiToggleButton-root {
  background-color: rgba(255, 255, 255, 0.7) !important;
  @include themify($themes) {
    color: rgba(0, 0, 0, 0.38) !important;
  }
}

#ToggleFilter .MuiToggleButton-root.Mui-selected {
  @include themify($themes) {
    background-color: themed('buttonOtlBgColor') !important;
    color: themed('buttonOtlTextColorAlt') !important;
  }
}