@import '../../../css/index';

@include themify($themes) {
  --accentColor: themed('accentColor');
}

.question-draggable-item {
  cursor: pointer !important;
}

body > .question-draggable-item {
  border-radius: 5px;
  padding: 5px 10px;
}

body.theme-ripple > .question-draggable-item {
  background-color: $darkTurquoise;
}

body.theme-warnerMedia > .question-draggable-item {
  background-color: #e5bc73;
}

#PulseQuestionRow {
  display: flex;
  flex-direction: row;
  margin-left: 18px;

  .media-wrapper {
    margin-bottom: 4px;
  }

  .media-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 400px;
    border-radius: 4px;
    margin: 0 0 8px 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .media-placeholder-icon {
    font-size: 50px !important;
    color: #777481;
  }

  .media-error-msg {
    margin: 0 0 4px 10px;
    font-size: 14px;
    color: #e85c5c;
  }

  .MuiPaper-rounded {
    height: 100%;
    width: 100%;
    border: none !important;
  }

  .ordered-list-container {
    float: left;
    width: fit-content;

    .question-draggable-item {
      width: 100%;
      background-color: #efeff4;
      border-radius: 5px;
      margin-bottom: 10px;
      .order-option-title {
        color: #50515f;
      }
    }

    .ordered-option-row {
      cursor: pointer;
      // @include themify($themes) {
      //   border: 1px solid themed('accentColor') !important;
      // }
      padding: 5px 10px;
      margin: 10px 0;
      width: 100%;
      display: inline-block;
    }
  }

  .sentiment-button {
    float: right;
  }

  .number-text {
    color: $red;
  }

  .number-field {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .image-slider {
    display: flex;
    height: 100%;
    align-items: center !important;
    padding-left: 10px;
  }

  .video-slider {
    padding-left: 20px;
  }
  textarea {
    height: initial;
  }

  .MuiFormControlLabel-root {
    margin-left: -1px !important;
  }

  .MuiGrid-container {
    display: block;
  }

  .prefer-not-to-answer {
    margin-top: 25px;
    margin-left: 0 !important;
  }

  .prefer-not-to-answer-active {
    margin-top: 10px;
    margin-left: 0 !important;
    @include themify($themes) {
      border-color: themed('accentColor20') !important;
    }
  }

  .video-area {
    position: relative;
  }
  .video-controls {
    justify-content: center;
    button {
      color: white;
    }
    position: absolute;
    bottom: 6%;
  }

  .volume {
    margin-left: 110px;
  }

  .flex-row {
    width: fit-content;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .star-icon {
    width: calc(40px + 0.45vw);
    height: calc(40px + 0.45vw);

    @media (min-width: 200px) {
      height: calc(100% + 3 * (100vw - 600px) / 400);
      max-height: calc(40px + 0.45vw);
      width: calc(100% + 3 * (100vw - 600px) / 400);
      max-width: calc(40px + 0.45vw);
    }
  }

  .star-text {
    height: 100%;
    width: 100%;
    display: flex;
    font-size: calc(12px + 0.45vw);
    font-size: clamp(12px, 1.3vw, calc(12px + 0.45vw));
    justify-content: center;
    align-self: center;
    margin-bottom: 5px;
  }

  .media-image {
    cursor: pointer;
    float: left;
    height: auto;
    max-width: 240px;
    max-height: 240px;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
    border-radius: 6px;
    border: solid #efefef 1px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .media-video {
    max-width: 700px;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
    border-radius: 6px;
    border: solid #efefef 1px;
    background-color: #000;
    margin-left: 0px !important;
    padding: 10px !important;
    border-radius: 10px !important;
    border: 5px solid $spanishGray !important;
  }

  .media-youTube {
    max-width: 700px;
    min-height: 400px;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
    border-radius: 6px;
    border: solid #efefef 1px;
    background-color: #000;
    margin-left: 0px !important;
    padding: 10px !important;
    border-radius: 10px !important;
    border: 5px solid $spanishGray !important;
  }

  .media-vimeo {
    max-width: 700px;
    min-height: 400px;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
    border-radius: 6px;
    border: solid #efefef 1px;
    background-color: #000;
    margin-left: 0px !important;
    padding: 10px !important;
    border-radius: 10px !important;
    border: 5px solid $spanishGray !important;
  }

  .mux-player-container {
    display: flex;
    width: 100%;
    justify-content: center;
    min-width: 250px;
    max-width: 700px;
    min-height: 140px;
    max-height: 400px;
  }

  .mux-player {
    width: 100%;
    min-width: 250px;
    max-width: 700px;
    min-height: 140px;
    max-height: 400px;
  }

  &.blurred {
    opacity: 0.5;
    display: none;
  }

  .indicator {
    color: #fff;
    font-weight: bold;
    margin-left: -62px;

    &.active {
      @include themify($themes) {
        background-color: themed('accentColor') !important;
      }
    }

    &.error {
      @include themify($themes) {
        background-color: red !important;
        color: white !important;
      }
    }
  }

  .spacer {
    display: flex;
    height: 40px;
  }

  .MuiCard-root {
    background-color: $surface;
    flex: 1;

    .question-row-title {
      font-weight: 500;
      font-size: calc(12px + 0.45vw);
      font-size: clamp(12px, 1.3vw, calc(12px + 0.45vw));
      color: black;

      .required-indicator {
        color: $red;
        padding-left: 5px;
        font-size: 1.6rem;
      }

      video,
      iframe {
        min-width: 200px;
        max-width: 700px;
        min-height: 112px;
        max-height: 400px;
        background-color: black;
      }
    }
  }

  .MuiRating-iconFilled {
    color: #ffb400;
  }

  .MuiRating-root.Mui-disabled {
    opacity: 1 !important;
    pointer-events: none !important;
  }

  .MuiSlider-root {
    display: block;
    left: 70px;
    width: calc(100% - 140px);

    &.is-mobile {
      margin-top: 15px;

      .MuiSlider-track {
        padding-left: 6px;
      }

      .MuiSlider-thumb {
        left: -46%;
      }
    }

    .MuiSlider-track {
      left: -20px !important;
      @include themify($themes) {
        color: themed('buttonHoverColor') !important;
      }
    }

    .MuiSlider-thumb {
      @include themify($themes) {
        border: 2px solid themed('primaryBgColor') !important;
      }
      color: $white !important;
    }

    &.has-answer {
      .MuiSlider-track {
        left: -20px !important;
        padding-left: 20px;
        @include themify($themes) {
          color: themed('activeTabBgColor') !important;
        }
      }

      .MuiSlider-thumb {
        @include themify($themes) {
          color: themed('activeTabBgColor') !important;
          border: 2px solid themed('activeTabBgColor') !important;
        }
      }

      .MuiSlider-thumb.Mui-disabled {
        margin-top: -6px !important;
        margin-left: -8px !important;
      }
    }

    .MuiSlider-rail {
      height: 6px;
      left: -20px;
      width: calc(100% + 40px);
      border-radius: 6px;
    }

    .MuiSlider-track {
      height: 6px;
      border-radius: 6px;
    }

    .MuiSlider-thumb {
      width: 18px;
      height: 18px;
    }

    .MuiSlider-mark {
      height: 14px;
      margin-top: 20px;
      background-color: #dcdcdc;
    }

    .MuiSlider-markLabel {
      margin-top: 28px;
      white-space: pre;
      text-align: center;
      font-size: 16px;
      font-size: clamp(12px, 0.9vw, 16px);
    }
  }

  .ReactModal__Overlay--after-open {
    div {
      z-index: 10000 !important;
    }
  }

  .MuiSlider-vertical {
    display: block;
    left: 20px;
    width: 6px;

    &.has-answer {
      .MuiSlider-track {
        left: -21px !important;
        padding-left: 6px;
      }

      .MuiSlider-thumb.Mui-disabled {
        margin-left: -5px !important;
      }
    }

    .MuiSlider-rail {
      width: 6px;
      left: -20px;
      height: 100%;
      border-radius: 6px;
    }

    .MuiSlider-track {
      border-radius: 6px;
    }

    .MuiSlider-thumb {
      width: 18px;
      height: 18px;
      left: -65%;
    }

    .MuiSlider-mark {
      width: 14px;
      height: 1px !important;
      left: -20%;
      float: left;
      background-color: #dcdcdc;
      white-space: normal;
      text-align: left;
    }

    .MuiSlider-markLabel {
      margin: 0px;
      transform: translateY(41%) !important;
      white-space: nowrap;
      text-align: left;
    }
  }

  .slider-container {
    margin: 25px 0 15px 0;

    &.mui-slider-container-vertical {
      margin-left: 30px;

      .MuiSlider-markLabel {
        width: calc(100vw - 265px);
        min-width: 600px;
        white-space: pre-wrap;
        max-height: 2 * 19px;
        overflow: hidden;
      }
    }

    &.mui-slider-container-mobile {
      margin-bottom: 25px;

      .MuiSlider-markLabel {
        width: calc(100vw - 260px);
      }
    }
  }
}
