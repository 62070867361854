@import './index';

#WidgetTable,
.widget-table {
  flex: 1;
  flex-basis: auto;

  .MuiTableRow-root {
    .MuiTableCell-head {
      font-weight: 700 !important;
      background-color: $whisper !important;
      @include themify($themes) {
        background-color: themed('secondarySurfaceColor') !important;
      }
    }

    &.MuiTableRow-hover {
      cursor: pointer;
    }
  }

  .MuiTableCell-root {
    font-size: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 120px;
    overflow-x: hidden;
    padding: 5px 4px 5px 16px;
  }

  // .MuiTableCell-sizeSmall:last-child {
  //   padding-right: 12px !important;
  // }

  // .survey-name {
  //   text-overflow: ellipsis;
  //   white-space: nowrap;
  //   max-width: 120px;
  //   overflow-x: hidden;
  // }

  .user-name {
    white-space: nowrap;
    padding: 0;

    div {
      display: inline-flex;
      align-items: center;
      flex: 1;
      height: 100%;

      .MuiAvatar-root {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      .MuiSvgIcon-root {
        font-size: 24px;
        margin-right: 9px;
        margin-left: -1px;
      }
    }
  }
}
