@import '../../css/index';

#GroupsSnackbar {
  .snackbarText {
    display: flex;
    font-weight: 500 !important;
    justify-content: center;
    align-items: center;
  }

  .custom-icon {
    margin-top: 4px;
    margin-right: 15px;
    height: 18px;
  }
}

.snackbar-save-error {
  .MuiSnackbarContent-root {
    background-color: #f4bfbb !important;
    color: #ef636b;
  }
}

.snackbar-save-info {
  .MuiSnackbarContent-root {
    background-color: #f4e8bb !important;
    color: black;
  }
}

.snackbar-save-success {
  .MuiSnackbarContent-root {
    background-color: #cde0e6 !important;
    color: #52b3b6;
    flex-wrap: nowrap;
  }
}