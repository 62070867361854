@import '../../../css/index';

#TaskHeader {
  @include themify($themes) {
    background-color: themed('primarySurfaceColor');
  }
  margin: 15px;
  margin-top: 0;

  .taskHeaderSelector {
    font-weight: 700;
  }

  .MuiCardContent-root {
    padding: 16px !important;
  }

  #TaskCardHeader {
    color: #000;
    border-bottom: 1px solid #d7d7e2;
    padding: 10px 16px;
    display: flex;
    flex-wrap: wrap;
  }
}
