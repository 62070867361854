@import '../../css/colors';

#GroupEditDrawer {
  padding: 20px;

  .MuiDrawer-paper {
    header {
      height: 60px;
    }
    .drawer-card {
      height: calc(100vh - 64px);
    }
  }

  .spinner-container {
    width: 100%;
    height: calc(100vh - 120px);
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1002;
  }

  .tab-error {
    .MuiTab-wrapper {
      color: $indianRed;
    }
  }

  .defaultDash-select {
    width: 275px;

    div {
      padding: 0px !important;
    }
  }

  .title {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }

  .alwaysLandToggle {
    margin-top: -10px;
  }

  .privileges-container {
    margin-top: 16px;
    padding-bottom: 100px;
  }

  .group-name-helper-text {
    color: $indianRed;
    font-size: 1rem;
  }

  .privileges-table {
    border: 1px solid $whiteSmoke !important;
    border-radius: 5px !important;
  }

  .privilege-checkbox-cell-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .privilege-row {
    cursor: pointer;
  }

  .temp-table .MuiTableCell-stickyHeader {
    background-color: $ghostWhite !important;
  }

  .privilege-name {
    margin: auto 0;
  }

  .description-container {
    margin-top: 10px;
  }
}

#GroupsTab {
  height: 100%;
  .paging-row {
    margin-top: auto;
  }
  .table-header {
    width: 50% !important;
  }
}

#participantsToolbar {
  .toolbar-header {
    display: flex;
    justify-content: space-between !important;
  }
}

#GroupMembersList {
  height: 80vh;

  #ParticipantsSelect {
    .ag-theme-alpine {
      height: 68vh !important;
    }
  }

  .group-members-title-bar {
    margin-bottom: 10px;
  }

  .members-title {
    margin-right: 5px;
  }

  .avatar {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }

  .avatar-cell {
    display: flex;
    align-items: center;
  }

  .delete-icon {
    float: right;
  }

  .add-member-button {
    margin-top: 20px;
  }

  .members-table {
    border: 1px solid $whiteSmoke !important;
    border-radius: 5px !important;
  }

  .temp-table .MuiTableCell-head {
    background-color: $ghostWhite !important;
  }
}

#GroupAddMembersList {
  height: calc(100% - 65px - 80px);

  .add-member-header {
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .add-member-title {
      margin-left: 120px !important;
    }
  }

  .body {
    height: 100%;
  }

  .back {
    cursor: pointer;
  }

  .search {
    width: 100%;
    margin-bottom: 10px;
  }

  .select-column {
    background-color: $whiteSmoke;
    height: 100%;
  }

  .member-search {
    width: 100% !important;
    padding: 0 10px;
    float: right;
    transition: width 0.5s;
    width: 100px;
    border-radius: 5px;
    border: 1px solid $lavenderGray;
  }
}
