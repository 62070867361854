#ScheduleTabPanel {
  padding: 60px;
  padding-top: 40px;
  padding-bottom: 60px;
  background: #fff;
  height: calc(100vh - 158px);
  align-content: flex-start;
  overflow-y: auto;

  .more-top-margin {
    margin-top: 12px;
  }

  .invisible {
    visibility: hidden;
  }

  .when-due {
    padding: 12px;
  }

  .reminder-input {
    padding-left: 12px;
  }

  .MuiSelect-root {
    padding-right: 30px !important;
  }

  .MuiFormControl-root {
    margin-right: 10px;
  }

  .small-number-input {
    margin-right: 10px;

    input {
      width: 32px;
    }
  }

  .before-due-text {
    margin-left: 10px;
  }

  .date-input {
    width: 180px;
    display: inline-flex;

    .MuiFormControl-root {
      margin-top: 0px !important;
    }
  }

  .MuiToggleButton-root {
    height: 40px;
  }

  .MuiAutocomplete-root {
    display: inline-flex;
  }

  .MuiAutocomplete-inputRoot {
    padding: inherit;
    padding-right: 30px !important;
    width: 360px;
  }
}

.MuiAutocomplete-popup li {
  font-size: 1.2rem;
}
