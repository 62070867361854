@import '../../../css/index';

#CompletedUserTasksWidget {
  width: 100%;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .MuiTableBody-root {
    .MuiTableCell-root {
      &:first-child {
        border-left: solid #ffcd6e 3px;
      }
    }
  }

  .MuiCardHeader-root {
    height: 52px !important;
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
  }

  .MuiCardContent-root {
    padding: 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    justify-content: flex-start;
  }

  .no-rows {
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }

  .paging-row {
    margin-top: auto;
  }

  .table-row {
    cursor: pointer;

    .name-cell {
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  table th {
    @include themify($themes) {
      background-color: themed('secondarySurfaceColor') !important;
    }
  }

  #CompletedUserTasksWidgetTable {
    .MuiTableRow-root .MuiTableCell-head {
      font-weight: 700 !important;
    }

    .avatar-cell {
      .widgetAvatar {
        height: 20px !important;
        width: 20px !important;
      }
    }
  }

  // ag-grid stuff:
  .creator-cell-avatar {
    height: 20px !important;
    width: 20px !important;
  }

  @import '../../../css/ag-grid-tables';
}
