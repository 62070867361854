@import '../../css/colors';

#PasswordStrengthChecklist {
  height: 100%;
  padding-top: 5px;

  .checklist {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: left;
  }

  .checklist-item {
    display: flex;
    flex-direction: row;
    margin: 0;
  }

  .checklist-text {
    font-size: 14px;
  }

  .MuiSvgIcon-root {
    font-size: 1.3rem;
    margin-right: 5px;

    &.green {
      color: green;
    }

    &.red {
      color: red;
    }
  }
}