@import '../../../css/index';

#CampaignAnalysisEditScreen {
  height: calc(100vh - #{$topNavHeight});
  overflow-x: hidden;
  overflow-y: hidden;

  .campaign-worksheet {
    height: 100%;
    background: #fff;
  }
}
