@import '../../../css/index';

#CompletionToolbar {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: white;
  padding: 24px 35px 20px;
  border-bottom: 1px solid $whiteLilac;

  .flex-align {
    display: flex;
    align-items: center;
  }

  .input-label {
    display: flex;
    align-items: center;
    color: $spanishGray;
    font-size: 18px !important;
    
    svg {
      margin-left: 5px;
      color: $lavenderGray;
    }
  }

  .MuiFormControlLabel-root {
    margin: 0 0 0 16px;

    .MuiCheckbox-root.Mui-disabled {
      opacity: 0.4 !important;
    }
    
  }
}
