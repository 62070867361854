@import '../../css/index';

#UsersListCol {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding-bottom: 1em;

  .col-actions {
    display: flex;
    justify-content: space-between;
  }

  .search {
    display: inline-block;
  }

  .hidden-menu {
    display: inline-block;
    position: relative;
  }

  .hidden {
    color: #474852 !important;
    background-color: #C1C1D3 !important;
  }

}

#UsersListGridContainer {
  max-height: 100%;
  width: 100%;
  padding-bottom: 1em;
  overflow-y: auto;
  overflow: hidden;
  flex-grow: 1;
}

#UsersListGrid {
  max-height: 100%;
  padding-bottom: 1em;
  width: 100%;
  overflow-y: hidden;
}

#UsersListAGGrid {
  height: 53vh;
  max-height: 53vh;
  padding-bottom: 1.2em;
  width: 100%;
  overflow-y: auto;
}
