@import '../../css/colors';

#ConsentWidget {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .card-content {
    padding: 0 16px 16px;
    flex: 1;

    .consent-content-container {
      height: 100%;
      width: 100%;
      display: flex;

      > div {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .consent-version-text {
        font-size: 2.25rem;
        margin-top: 10px;
      }

      .consent-required-status {
        .required,
        .not-required {
          display: block;
          margin-top: 10px;
          margin-bottom: 10px;
          font-weight: 700;
          text-transform: capitalize;
        }

        .required {
          color: $indianRed;
        }

        .not-required {
          color: $airforceBlue;
        }
      }
    }
  }
  .consent-row {
    margin: 15px 0;
  }
}

#ConsentDrawer {
  // Fix some messed up global styles...
  .MuiCardHeader-action {
    align-self: unset !important;
    margin-top: 0 !important;
  }

  .language-select {
    margin-bottom: 20px;
  }

  .MuiPaper-root.MuiCard-root {
    height: 100%;
  }
  .MuiCardContent-root {
    height: calc(100vh - 50px - 50px - 64px) !important;
  }
  .consent-drawer-language-select {
    overflow: hidden;
  }

  .consent-drawer-header {
    display: flex;
    align-items: center;
  }
  .consent-drawer-toolbar {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
  .consent-drawer-textarea-container {
    margin-bottom: 10px;
    max-height: calc(100vh - 64px - 100px - 190px);
    overflow: auto;
  }
  .consent-drawer-textarea {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    height: 500px;
    max-height: 500px;
  }
  .consent-required-change-button {
    margin-top: 10px;
    margin-left: 0px !important;
  }
  .consent-row {
    margin-bottom: 5px;
  }
  .edit-consent-footer {
    min-height: fit-content;
  }

  .attachments-section {
    margin: 14px 0px;
    margin-left: 6px;

    .attachments-header {
      display: flex;
      align-items: center;

      .MuiInputLabel-root {
        margin-bottom: 0px !important;
      }
    }

    .attachments-list {
      margin-top: 10px;

      .attachment-container {
        flex-basis: 48%;
        margin-bottom: 3px !important;
        margin-right: 5px !important;
      }
    }
  }
}
