.cantDeleteDialog {
  padding: 20px;

  .bold {
    font-weight: bold;
  }

  .tiedRole {
    margin: 5px;
  }
}
