@import '../../../../css/colors';

#LocationsBasicInfo {
  .addressContainer {
    margin-top: 16px;

    .coordinates-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .descriptionTextField {
      height: 100px;
    }
  }

  .google-map-container {
    margin-top: 12px;
  }

  .google-map {
    height: 200px;
    box-shadow: -2px 3px 7px 0px #c0c0c0;
    padding: 0;
    margin: 0 12px;
  }

  .marker {
    background: orange;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0 0 10px 3px #c0c0c0;
  }
}
