@import '../../../css/index';

$bgColor: #f1f2f6;

#CompletedContentScreenDesigner {
  .add-button{
    width: fit-content;
    margin: 0 0 15px 0 !important;
  }

  .intro-text {
    color: #6c6c7f;
    font-weight: 900;
  }

  .pulse-campaign-header-title {
    display: flex;
    justify-content: center;
  }

  .pulse-campaign-header-close {
    display: flex;
    justify-content: flex-end;
  }

  .intro-input {
    background-color: white;
    border-radius: 10px;
    padding-left: 10px;
    font-weight: 700;
    color: #6c6c7f;
  }

  .body {
    display: flex;
    flex-direction: row;
  }

  .editor-section {
    .body-invalid {
      padding: 1px;
      border: 1px solid #f44336;
    }
  }

  .intro-attach-image {
    height: 306px;
    width: 306px;
    border-radius: 10%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    .add-photo-icon {
      height: 100px !important;
      width: 100px !important;
      color: #6c6c7f;
    }
  }

  .intro-attached-image {
    height: 306px;
    width: 306px;
    border-radius: 10%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .intro-image {
    overflow: hidden;
    border-radius: 1%;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    object-fit: contain;
    object-position: center;
  }

  .intro-attach-image:hover {
    background-color: rgb(219, 219, 219);
  }

  .MuiDialog-paperWidthSm {
    min-width: 775px;
    max-width: 80vw;
    min-height: 85vh;
    max-height: 85vh;
    overflow: hidden;
    background-color: $bgColor;
  }

  .MuiDialogContent-root {
    overflow-y: hidden;
  }

  .intro-buttons {
    margin: 10px;
    display: flex;
    justify-content: flex-end;

    .MuiButton-contained.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.26) !important;
    }
  }

  .intro-editor-label {
    margin: 10px 0;
  }

  .intro-section {
    margin-bottom: 0 !important;
  }

  .intro-left {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }

  .intro-right {
    display: flex;
    width: 100%;
    margin-top: 20px;
    height: calc(100vh - 205px) !important;
    max-height: calc(100vh - 205px) !important;
  }

  .survey-completed-content-card{
    background-color: white;
    border-radius: 10px;
    height: calc(100vh - 205px) !important;
    max-height: calc(100vh - 205px) !important;
    padding: 20px;
  }

  .survey-completed-content-text {
    font-weight: 500;
    font-size: calc(12px + 0.35vw);
    color: black;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .tox {
    height: calc(100vh - 305px) !important;
    max-height: calc(100vh - 305px) !important;
  }
}

.intro-preview-root {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

