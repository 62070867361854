@import '../../../css/colors';

#QuestionDivider {
  display: flex;
  flex-direction: row;
  border-left: solid rgba($lavenderGray, 0.5) 2px;
  padding-left: 40px;
  margin-left: 18px;
  height: 16px;

  &.blurred {
    filter: blur(4px);
  }

  .indicator {
    margin-right: 12px;
    color: #fff;
    font-weight: bold;
    margin-left: -62px;
  }
}
