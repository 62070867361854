@import "../../../css/colors";

.sentiment-dialog{
  text-transform: capitalize;
  .MuiPaper-root {
    width: 100%;
  }
  .dialog-title{
    background-color:#f0eff4;
  }

  .dialog-text {
    text-transform: none;
  }

  .positive-sentiment {
    color: $green;
  }

  .negative-sentiment {
    color: $red
  }

  .sentiment-actions{
    margin-right: 16px;
    margin-bottom: 8px;
  }
}
