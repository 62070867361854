@import '../../../css/index';

#WorksheetEditScreen {
  overflow-y: hidden;
  margin-bottom: -120px;
  height: calc(100% - 64px);
  background: #fff;

  .spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1002;
  }

  input,
  .MuiInputBase-input {
    font-size: 1.3rem;
  }

  .MuiFormLabel-root {
    font-weight: bold;
  }

  .MuiSelect-outlined {
    padding: 14px 14px;
  }

  .MuiTab-wrapper {
    font-size: $fs_default !important;
  }

  .viz-title-input input {
    font-size: 17px !important;
  }
}
