#QuizStats {
  .quiz-stats-container {
    padding: 15px;
    border-radius: 20px;
    background-color: #F4F5FA;

    .quiz-stats-title {
      padding-bottom: 15px;

      .quiz-stats-title-text {
        font-weight: bold;
        color: #73679D;
        font-size: large;
      }
    }

    .quiz-stats-col-text {
      font-weight: bold;
      letter-spacing: 0px;
      color: #73679d;
      text-transform: capitalize;
    }

    .quiz-stats-col {
      padding-left: 20px;
      border-left: 2px solid lightgray;

      .stat-text {
        margin-top: 5px;
        font-weight: bold;
        color: #9696A5
      }

      .score-view {
        flex-direction: row;
      }
    }

    .your-score-col {
      flex-direction: row;

      .your-score-view {
        padding-left: 10px
      }

      .your-score-text {
        font-size: large;
        font-weight: bold;
        color: #009F9E
      }
    }
  }
}
