@import '../../../css/index';

$bgColor: #f1f2f6;
$panelColor: white;
$fgColor: #6f8096;

#DetailsStep {
  height: 100%;
  width: 100%;
  padding: 5px 15px 5px;
  background-color: $bgColor !important;

  .pulse-campaign-details {
    height: 100%;
    width: 100%;
  }

  .pulse-campaign-details-panel {
    display: flex;
    position: relative;
    width: 100%;
    height: calc(100vh - 175px);
    background: $panelColor;
    padding: 15px 15px 0 15px;
    border-radius: 10px;
    overflow-y: auto;
  }
}
