@import '../../../css/colors';

#AnnouncementParticipantsListTable #AnnouncementDetailsHeader {
  .summary-info-bar{
    height:120px;
    display:flex;
    flex-direction: row;

    .responses-header{
      margin-top:10px;
      color:#000;
      font-weight: 800 !important;
      font-size: 16pt ;
    }

    .responses-text{
      color:#68686a;
      font-size: 14pt ;
    }
  }

  .announcement-body-trunc {
    width: 100%;
  }
  
  .announcement-body-html {
    width: 100%;
  }

  .announcement-body-html p {
    margin-bottom: 0;
  }
}

#AnnouncementParticipantsListTable #TableHeader {
  color: #000;
  border-bottom: 1px solid #e3e3e8 !important;
  padding: 9px 12px 9px 16px !important;
  height: 48px !important;

  .MuiTypography-root {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #000000 !important;
  }

  .MuiCardHeader-content {
    align-self: center;
  }

  .MuiCardHeader-action {
    align-self: center;
    vertical-align: middle;
    margin-top: 0;
    margin-right: 0;

    .MuiTypography-root {
      font-size: 1.2rem;
      color: #888;
    }
  }

  .MuiIconButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.18) !important;
    background-color: #EBEBEB !important;
  }

  .widget-cta {
    border-radius: 64% !important;
  }

  .search-icon {
    align-content: center;
    margin-left: 5px;
    height: 100%;
    position: absolute;
  }

  .search-textbox {
    padding-left: 30px;
    float: right;
    transition: width 0.5s;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }

  .search-textbox:focus-within {
    transition: width 0.5s;
    width: 200px;
  }
}
