@import '../../../css/index';

#PulseQuestionMediaDrawer {
  height: 100%;
  overflow-y: hidden;
  position: absolute;
  right: 0;
  margin-top: 0;
  background: #fff;
  z-index: 3;
  width: 45%;
  min-width: 400px;

  .media-view {
    height: calc(100vh - #{$topNavHeight});
    padding-bottom: 0.75rem;
    overflow-y: auto;
  }
}