@import '../../../css/index';

#ContentPreviewDialog,
#ContentUploadDialog {
  .MuiDialog-paperWidthSm {
    overflow: hidden;
    max-width: 650px;
    max-height: 820px;
    min-height: 400px;
    height: 90%;
    background-color: white;
  }

  .content-upload-dialog-content {

    .dropzoneText {
      color: #91919a;
      margin-bottom: 5px;
    }
    
    .iconContainer {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      .noteAddIcon {
        font-size: 5em;
        color: #05d1cf;
        margin-bottom: 15px;
      }

      .thumbUpIcon {
        font-size: 5em;
        color: #6aca9a;
        margin-bottom: 15px;
      }

      .thumbDownIcon {
        font-size: 5em;
        color: #e85c5c;
        margin-bottom: 15px;
      }
    }
 
    .dropzone {
      background-color: #f4f4f7;
      min-height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px dashed #788998;
      border-radius: 4px;
      padding: 1em;
      cursor: pointer;
  
      .iconContainer {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
  
        .noteAddIcon {
          font-size: 5em;
          color: #05d1cf;
          margin-bottom: 15px;
        }
  
        .thumbUpIcon {
          font-size: 5em;
          color: #6aca9a;
          margin-bottom: 15px;
        }
  
        .thumbDownIcon {
          font-size: 5em;
          color: #e85c5c;
          margin-bottom: 15px;
        }
      }
  
      .dropzoneText {
        color: #91919a;
        margin-bottom: 5px;
      }
  
      .uploadProgress {
        width: 60px !important;
        height: 60px !important;
        margin-top: 5px;
      }
  
      .dropLink {
        color: #05d1cf;
        text-decoration: underline #05d1cf;
      }
    }
  
  }
  
  .content-upload-dialog-form {
    width: 100%;
    margin-top: 15px;
  
    .dialog-form {
      margin: 0 !important;
    }
  
    .MuiFormControl-root {
      width: 100%;
    }
  
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      padding: 0 4px 0 6px;
      flex: 1;
    }
  
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
      padding: 2px 0;
    }

    .MuiGrid-spacing-xs-2 > .MuiGrid-item {
      padding: 4px;
    }
  
  }

  .attachment-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3px;
    background-color: #f4f4f7;
    min-height: 100px;
    border: 2px dashed #788998;
    border-radius: 4px;
    padding: 1em;
    max-height: 295px;
    cursor: pointer;

    iframe {
      border: none;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 100%;
    }

    .media-off {
      display: none;
    }

    .media-preview {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
      object-fit: contain;
      object-position: center;

      &.image {
        height: 280px !important;
        max-width: 500px !important;
      }

      &.file {
        width: 100%;
        max-height: 166px;
        max-width: 300px;
        justify-content: center;
        align-items: center;
        color: #788998 !important;
      }
    }

    .attachment-clear-root {
      display: flex;

      .attachment-clear-btn {
        right: 28px;
        top: 70px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;
        z-index: 1002;
      }
    }

  }

  .file-details {
    font-size: 12px;
    margin-top: 5px !important;
  }

  .dialogText {
    flex-direction: column;
    word-wrap: break-word;

    .file-details-title {
      font-size: 12px;
      font-weight: 700;
    }

    .file-details-title-sub {
      font-size: 10px;
      font-weight: 700;
    }

    .MuiDialogContentText-root {
      margin-bottom: 0px;
    }
  }

  .dialog-content-grid {

    .dialog-content {
      padding: 0 !important;
    }

  }

  .errorContainer {
    padding-left: 22px;
    padding-right: 22px;
  }

  .filesContainer {
    max-height: 300px;
    overflow-y: auto;

    padding-left: 0;
    padding-right: 0;
  }

  .errorContainer {
    flex-direction: column;
  }

  .errorDialog {
    padding-left: 22px;
  }

  .errorText {
    color: #e85c5c;
    margin-bottom: 5px;
  }

  .dialog-actions {
    padding: 0px 18px 12px 18px;
    justify-content: flex-end;

    .downloadButton {
      color: #009f9e;
      border-color: #009f9e;
    }

    .cancelButton {
      color: #009f9e;
    }

    .saveButton {
      color: #ffffff;
      background-color: #05d1cf;
    }
    .saveButton:hover {
      background-color: #05d1cf;
    }
  }

  .intro-text {
    color: #6c6c7f;
    font-weight: 900;
  }

  .spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    position: absolute;
    z-index: 1002;
  }

  .dialog-action-buttons {
    margin: 10px 16px 10px 20px;
    display: flex;
    justify-content: space-between;

    .MuiButton-contained.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.26) !important;
    }
  }

  .content-info {
    display: flex;
    flex-direction: column;
  }
  
  .switch-label-group {
    display: flex;
    align-items: center;
  }

  .switch-label {
    color: rgba(0, 0, 0, 0.54);
    font-weight: 400 !important;
    font-size: 14px !important;
  }

  .MuiSwitch-root .MuiSwitch-track {
    background-color: teal !important;
  }

  .MuiSwitch-root .Mui-checked + .MuiSwitch-track {
    background-color: blue !important;
  }

  .mux-player {
    height: 100%;
    min-height: 280px !important;
    max-height: 280px !important;
    max-width: 300px !important;
    justify-content: center;
  }
  
}