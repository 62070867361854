@import '../css/index';

#MuiConfirmPrompt {
  .dialog-title {
    background-color: $antiflashWhite;
    padding: 16px;

    .MuiTypography-root {
      letter-spacing: 0 !important;
      font-size: $fs_secondary !important;
      font-weight: 700 !important;
    }
  }

  .strong {
    background-color: rgb(192, 0, 0) !important;
    padding: 16px;
    color: white !important;

    .MuiTypography-root {
      letter-spacing: 0 !important;
      font-size: $fs_secondary !important;
      font-weight: 700 !important;
      color: white !important;
    }
  }

  .MuiDialogContent-root {
    padding: 20px 30px;

    .send-image {
      height: 100%;
      width: 100%;
    }

    .warning-icon {
      height: 100%;
      width: 100%;
      color: $indianRed;
    }

    .confirm-message-view {
      padding-left: 12px;
      padding-top: 6px;

      .confirm-message {
        font-size: $fs_secondary;
        font-weight: bold;
        margin-bottom: 0px;
      }

      .confirm-message-strong {
        font-size: $fs_secondary;
        font-weight: bold;
        margin-bottom: 0px;
        color: rgb(192, 0, 0) !important;
      }

      .note-span {
        font-weight: 700;
      }

      .cannot-undo-text {
        font-size: $fs_secondary;
        color: $spanishGray;
      }
    }
  }

  .MuiDialogActions-root {
    padding: 0 16px 16px;

    .send-icon-btn {
      height: 15px;
      width: 15px;
      margin-right: 5px;
    }

    .btn-cancel {
      color: $black50 !important;
    }
  }
}
