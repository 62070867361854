@import '../../../css/index';

#SubmitRow {
  display: flex;
  flex-direction: row;
  border-left: solid rgba($lavenderGray, 0.5) 2px;
  padding-left: 40px;
  margin-left: 18px;

  .indicator {
    color: #fff;
    font-weight: bold;
    margin-left: -62px;

    &.active {
      @include themify($themes) {
        background-color: themed('accentColor') !important;
      }
    }
  }

  .hr {
    height: 2px;
    width: 20px;
    background: transparent;
    margin-top: 21px;
  }

  .spinner {
    @include themify($themes) {
      color: themed('accentColor') !important;
    }
  }
}
