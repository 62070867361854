#SurveyStreak {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .streak-title {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    color: #c8c9e3;
  }

  .streak-badge {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    width: 130px;
  }

  .streak-text-container {
    display: flex;
    flex-direction: column;
    margin: 15px 30px 7px 30px;
    padding: 5px;
    background-color: #24233b;
    border-radius: 20px;
  }

  .streak-text {
    display: flex;
    justify-content: center;
    color: #a4a5cd;
  }

  .streak-text-container-2 {
    display: flex;
    flex-direction: column;
    margin: 5px 30px 15px 30px;
  }

  .streak-text-2 {
    display: flex;
    justify-content: center;
    color: #a4a5cd;
    font-size: 14px;
  }

  .drop-logo {
    height: 20px;
  }

  .drop-logo2 {
    height: 23px;
  }

  .streak-drops-container {
    display: flex;
    justify-content: space-between;
    margin: 15px 39px 0px 87px;
  }

  .streak-checks-container {
    display: flex;
    justify-content: space-between;
    margin: 5px 35px 0px 35px;
  }

  .progress-icon {
    color: #737293;
    height: 13px;
  }

  .progress-icon-check {
    color: #737293;
    height: 13px;
  }

  .streak-number-container {
    display: flex;
    justify-content: space-between;
    margin: 5px 39px 0px 87px;
  }

  .progress-text {
    display: flex;
    justify-content: center;
    color: #737293;
    font-size: 13px;
  }

  .progress-circle {
    margin: 2px 8px 0px 8px;
    width: 7px;
    height: 7px;
    background: #737293;
    border-radius: 50%;
  }

  .progress-circle-active {
    margin: 2px 8px 0px 8px;
    width: 7px;
    height: 7px;
    background: #53b2b0;
    border-radius: 50%;
  }
}
