@import '../../../css/index';

#PulseSurveyCard{
  margin: 3px 0px;
  background-color: white;
  display: flex;
  flex-direction: row;
  border: 2px solid $whiteLilac;
  height: 50px;
  width: 100%;
  border-radius: 10px;

  .disabled{
    opacity: 0.4;
  }

.drag-icon{
  margin-top: 3px;
  height: 18px;
}

.pulse-name{
  margin-left: 10px;
  display:flex;
  align-items: center;
  font-weight: 600 !important;
  color:#6c6c7f !important;
  width: 100%;
} 
}

.pu-error{
  border: 1px solid red !important;
}

.pu-grab{
  cursor: grab;
}

.MuiPaper-elevation1{
  box-shadow: none !important;
}
