@import '../../css/index';

$bgColor: white;
$bgDarkColor: rgba(128, 128, 128, 0.3);
$fgColor: #6f8096;

#PulseCategoryTree {
  padding: 20px 100px 0 100px;
  width: calc(100vw - 210) !important;
  background-color: #f1f2f6;
  height: calc(100vh - #{$topNavHeight});
  overflow-x: hidden;
  overflow-y: auto;

  .grand-container {
    width: 100%;
    margin-bottom: 20px;
    .grand-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      justify-content: space-between;

      .add-icon-container {
        padding: 0px !important;
        margin-left: 5px;
        background-color: rgba(0, 159, 158, 0.6) !important;
        color: #ffffff !important;
      }
    }

    .grand-header {
      color: $fgColor;
      font-size: $fs_header;
    }
  }

  .icon-container {
    padding: 0px;
    margin: 0px 10px 0px 10px;
    background-color: $bgDarkColor !important;

    .expand-icon {
      color: #6b6985;
      height: 18px;
      width: 18px;
    }
  }

  .parent-card {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 5px;
    justify-content: space-between;
    height: 40px;
    max-height: 40px;
    cursor: pointer;

    &.border-cat-1 {
      border-left: 6px solid #A5AACA;
      color: $fgColor !important;
    }

    &.border-cat-2 {
      border-left: 6px solid #D7ABA0;
      color: $fgColor !important;
    }

    &.border-cat-3 {
      border-left: 6px solid #99CAD1;
      color: $fgColor !important;
    }

    &.border-cat-4 {
      border-left: 6px solid #4dabf5;
      color: $fgColor !important;
    }

    &.border-cat-5 {
      border-left: 6px solid #ffc107;
      color: $fgColor !important;
    }

    &.border-cat-6 {
      border-left: 6px solid #dd33fa;
      color: $fgColor !important;
    }

    &.border-cat-7 {
      border-left: 6px solid #d1ff33;
      color: $fgColor !important;
    }

    &.border-cat-8 {
      border-left: 6px solid #4aedc4;
      color: $fgColor !important;
    }

    &.border-cat-9 {
      border-left: 6px solid #3d5afe;
      color: $fgColor !important;
    }

    &.border-cat-10 {
      border-left: 6px solid #834bff;
      color: $fgColor !important;
    }

    .parent-card-title {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .parent-header {
      color: $fgColor;
      font-size: $fs_secondary;
      font-weight: bolder;
    }
  }

  .spinner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  .no-child-container {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    padding: 5px 5px 10px 5px;
    color: #6f8096;
    font-size: 14px;
    
    .add-question-button {
      width: 150px;
      margin-left: 0px !important;
    }

    .add-category-button {
      width: 150px;
      margin-left: 0px !important;
    }
  }

  .child-container {
    padding-left: 40px;
    cursor: pointer;

    .child-sub-container {
      background-color: #ffffff;
      padding: 3px 5px 3px 5px;
      border-radius: 4px;
      margin-bottom: 5px;

      &.border-cat-1 {
        border-left: 6px solid #A5AACA;
        color: $fgColor !important;
      }

      &.border-cat-2 {
        border-left: 6px solid #D7ABA0;
        color: $fgColor !important;
      }

      &.border-cat-3 {
        border-left: 6px solid #99CAD1;
        color: $fgColor !important;
      }

      &.border-cat-4 {
        border-left: 6px solid #4dabf5;
        color: $fgColor !important;
      }

      &.border-cat-5 {
        border-left: 6px solid #ffc107;
        color: $fgColor !important;
      }

      &.border-cat-6 {
        border-left: 6px solid #dd33fa;
        color: $fgColor !important;
      }

      &.border-cat-7 {
        border-left: 6px solid #d1ff33;
        color: $fgColor !important;
      }

      &.border-cat-8 {
        border-left: 6px solid #4aedc4;
        color: $fgColor !important;
      }

      &.border-cat-9 {
        border-left: 6px solid #3d5afe;
        color: $fgColor !important;
      }

      &.border-cat-10 {
        border-left: 6px solid #834bff;
        color: $fgColor !important;
      }

      .child-card {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .child-card-title {
          display: flex;
          flex-direction: row;
          align-items: center;

          .child-header {
            color: $fgColor;
            font-size: $fs_secondary;
          }
        }
      }

      .questions-container {
        padding-left: 40px;
        width: 90%;

        .question-card {
          margin: 5px;
          background-color: #ecedf2;
          padding: 10px 20px 10px 20px;
          border-radius: 4px;
          cursor: pointer;

          .prompts {
            font-size: 12px;
            margin-bottom: 5px;
            color: $fgColor;
          }

          .question-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .question-pulse-sets {
              color: #6f8096;
              font-size: 14px;
            }
          }

          .question-type {
            color: #6f8096;
            font-size: 14px;
          }

          .question-title {
            font-weight: bold;
            color: #6f8096;
            font-size: 14px;
          }
        }
      }

      .add-buttons {
        margin-top: 5px;
        margin-bottom: 5px;
        padding-left: 45px;
      }
    }
  }
}
