@import '../../css/index';

#PulseCampaignTypesManage {
  height: calc(100vh - #{$topNavHeight});
  overflow-x: hidden;
  overflow-y: auto;

  #DashboardToolbar {
    height: $topNavHeight;
  }
}
