@import '../../../../css/index';

#GoalPreview {
  margin-top: 16px;

  #PreviewHeader {
    .back-button {
      margin-top: 5px;
      margin-left: 10px;
    }
    .title {
      font-weight: 700;
      text-transform: uppercase;
      color: $spanishGray;
      letter-spacing: 0.1em;
    }
    .preview-thumbnail-container {
      width: 100%;
      height: 100%;
      padding: 10px 10px 10px 0;

      .preview-thumbnail {
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        min-height: 220px;
        background-color: $lightGray;

        svg {
          height: 30px;
          width: 30px;
        }
      }
    }

    .preview-details {
      padding: 10px;
      display: flex;
      flex-direction: column;

      .preview-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-weight: 500;
        }
      }

      .preview-category {
        border-radius: 16px;
        padding: 6px 12px;
        background-color: $lightGray;
        width: fit-content;
        margin-top: 6px;
      }
    }
  }

  #PreviewBody {
    height: 200px;
    width: 100%;
    padding: 0px 10px 10px;

    .subtitle {
      display: flex;
      align-items: center;
    }

    .preview-plan-content {
      padding-top: 10px;
      border-top: 1px solid $lavenderGray;
      height: 100%;
      width: 100%;
      overflow-y: scroll;

      .preview-item-container {
        height: 30px !important;
        min-height: 30px !important;

        .item-subtitle {
          margin-left: 5px;
          color: $gunPowder;
        }
      }
      .content-items-container {
        padding: 0px 30px !important;

        .type-and-icon {
          display: flex;
          align-items: center;
          svg {
            height: 15px;
            width: 15px;
            color: $gunPowder;
            margin: 0 2.5px 0 5px;
          }
        }
      }
    }
  }
}
