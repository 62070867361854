@import '../../css/index';

#EmailTemplatesManage {
  display: flex;
  height: calc(100vh - #{$topNavHeight});
  overflow-x: hidden;
  overflow-y: auto;

  .email-templates-manage-container {
    height: 100%;
    width: 100%;
  }

  .email-templates-card {
    height: 100%;
  }

  .email-templates-grid {
    display: flex;
    max-height: calc(100vh - #{$topNavHeight} - #{$topNavHeight});
  }

  .email-templates-grid-item {
    flex: 1;
    height: 100%;
    margin: 0 15px 0 15px;
  }

  .spinner-container {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    position: absolute;
    z-index: 1002;
  }
}

#EmailTemplatesManage #NotificationTemplatesCard .ag-theme-alpine {
  height: 380px !important;
}

#EmailTemplatesManage #EmailTemplatesTable .ag-theme-alpine {
  height: 75px !important;
}