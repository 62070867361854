#AllCampaignsCard {
  height: 100%;
  width: 100%;

  .all-campaigns-card {
    height: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    background-color: #FFFFFF;

    .header-text {
      .MuiCardHeader-title{
        color:#6b6985;
        font-weight: 700 !important;
        &.MuiTypography-h5{
          font-size: 16px !important;
        }
      }
    }
  }

}

#CreateCampaignDialog {
  .MuiDialog-paperWidthSm {
    max-width: 900px !important;
  }
}