@import '../../../css/index';

.searchbar {
  margin-left: 1rem;
  height: 2.3rem;
  padding-bottom: 2.2rem;
}

#UserOrganizationTab {
    .paper {
        .list {
            > .collapse-item {
                height: 100%;
            }
        }
    }

}