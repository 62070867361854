@import '../../../css/colors';

#OrganizationUsersWidget {
  .org-users-aggrid-name-cell {
    display: flex;
    align-items: center;

    > :first-child {
      margin-right: 10px;
    }
  }

  @import '../../../css/ag-grid-tables';
}

#OrganizationUsersWidget .paging-row {
  margin-top: auto;
  padding: 0 6px;
  width: 100%;
  button {
    padding: 8px !important;
  }
}

#OrganizationUsersWidget .no-rows {
  padding: 14px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

#OrganizationUsersWidget #WidgetTable {
  flex: 1;

  th {
    font-weight: 700;
    background-color: $whisper;
  }

  tr {
    cursor: pointer;
  }

  .MuiTableCell-root {
    font-size: inherit;
  }

  .MuiTableBody-root {
    .MuiTableCell-root {
      &:first-child {
        border-left: solid #ffcd6e 3px;
      }
    }
  }

  .table-cell {
    width: 90px;
    max-width: 90px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
  }

  .name-cell {
    min-width: 130px;
    width: 130px;
    max-width: 130px;
    text-transform: capitalize;
  }

  .email-cell {
    min-width: 100px;
    width: 100px;
    max-width: 100px;
    text-transform: lowercase;
  }

  .title-cell {
    min-width: 140px;
    width: 140px;
    max-width: 140px;
    text-transform: capitalize;
  }

  .username {
    margin-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
  }

  .avatar-name {
    flex-wrap: nowrap;
  }
}
