@import '../../css/index';

#TaskCommentCard {
  width: 100%;
  display: flex;
  padding: 5px;
  margin-bottom: 5px;

  .task-comment-card-left {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px 5px 0px;
  }

  .task-comment-card-right {
    flex: 1;

    .task-comment-card-name-date-status {
      display: flex;
      margin-bottom: 5px;

      > span {
        margin-right: 10px;
      }
    }
  }

  .comment-action,
  .editable {
    cursor: pointer;
  }

  .comment-button {
    margin-top: 5px;
    margin-left: 5px;
    background-color: white;
  }

  .comment-text {
    width: 100% !important;
    margin-bottom: 5px;
  }

  .comment-text-wrapper {
    white-space: pre-line;
  }

  .comment-text-field > div {
    align-items: flex-end !important;
  }

  .edit-icon {
    color: lightgray;
    margin-left: 5px;
  }

  .editable {
    display: flex !important;
    align-items: center !important;
    span {
      height: 17px;
    }
  }
}

.attachment-file:hover {
  color: $darkTurquoise;
  cursor: pointer;
}

.comment-edit-inputs {
  .MuiInputBase-root {
    background-color: white;
  }
}

.comment-body-editable:hover {
  cursor: pointer;
  background-color: $darkTurquoise10;
  border-radius: 5px;
}

.thumbnail-file-field {
  display: flex;
  align-items: center;
  height: 20px !important;
  width: calc(100% - 22px);
  img,
  video {
    width: 20px;
  }
  .attachment-file {
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 20px);
    // text-overflow: ellipsis;
  }
}

.attachment-image {
  height: 20px;
  width: auto;
  margin-right: 5px;
}

.task-comment-attachment-row {
  // margin-left: 50px !important;
  margin: 5px 0;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $whiteSmoke;
  padding: 2.5px 2.5px;
  padding-left: 0px !important;
  border-radius: 5px;

  .img-container {
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 20px;
    margin-right: 2.5px;

    video {
      width: 20px;
      max-width: 20px;
      height: 20px;
      object-fit: cover;
    }
  }

  svg {
    color: $spanishGray;
  }

  img,
  video {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .delete {
    height: 18px;
    cursor: pointer;
  }
}
