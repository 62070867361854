@import '../../../css/index';

#ParticipantsList {
  .delete-participant {
    cursor: pointer;
  }
  .participants-section-header-row,
  .participant-card-name {
    h6 {
      margin-bottom: 0px;
    }
  }
  .participant-card-name {
    margin-left: 10px;
  }
}
