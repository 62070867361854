@import '../../../../css/index';

$paginSelectedBgColor: rgba(33, 150, 243, 0.3);
$paginSelectedFgColor: #6b6985;
$paginBgColor: rgb(232, 232, 232);
$bgColor: #f2f3f6;
$fgColor: #6b6985;
$bgDarkColor: rgba(128, 128, 128, 0.3);
$summaryDrawerWidth: 460px;

#CampaignsList {
  height: calc(100vh - 320px);

  ::-webkit-scrollbar {
    display: none;
  }

  .campaigns-list-header {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .blank-span {
      width: 22px;
    }

    .column-header {
      text-transform: uppercase;
      color: #b6bdc7;
      font-size: $fs_tertiary;
      font-weight: 700;
    }

    .name-column {
      width: 11%;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      .MuiSvgIcon-root {
        padding-left: 5px;
        color: #6f8096 !important;
      }
    }

    .progress-column {
      width: 18%;
      text-align: center;
    }

    .start-column {
      width: 10%;
      text-align: center;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-left: 40px;
      .MuiSvgIcon-root {
        padding-left: 5px;
        color: #6f8096 !important;
      }
    }

    .end-column {
      width: 10%;
      text-align: center;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-left: 40px;
      .MuiSvgIcon-root {
        padding-left: 5px;
        color: #6f8096 !important;
      }
    }

    .polarity-column {
      width: 9%;
      text-align: center;
    }

    .responded-column {
      width: 8%;
      text-align: center;
    }

    .actions-column {
      width: 10%;
      text-align: center;
    }

    .type-column {
      width: 9%;
      text-align: center;
    }

    .owner-column {
      width: 10%;
      text-align: center;
    }
    
    @media (max-width: 1250px) {
      .hide-on-small-screen {
        display: none;
      }

      .name-column {
        width: 20%;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        .MuiSvgIcon-root {
          padding-left: 5px;
          color: #6f8096 !important;
        }
      }
  
      .progress-column {
        width: 20%;
        text-align: center;
      }

      .polarity-column {
        width: 12%;
        text-align: center;
      }
  
      .responded-column {
        width: 12%;
        text-align: center;
      }
  
      .actions-column {
        width: 14%;
        text-align: center;
      }
  
      .type-column {
        width: 12%;
        text-align: center;
      }
  
    }

  }

  .campaigns-list {
    height: calc(100vh - 370px);
    overflow-y: scroll;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  #EmptyState {
    height: calc(100vh - 370px);

    .empty-state-container {
      .empty-state-header {
        font-weight: bold;
        color: #6f8096 !important;
      }
      #EmptyStateText {
        .empty-state-text {
          color: #6f8096 !important;
        }
      }
    }
  }
}

#CampaignsPagination {
  .campaigns-list-pagination {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 10px;

    .MuiPaginationItem-outlinedPrimary.Mui-selected {
      color: $paginSelectedFgColor !important;
      background-color: $paginSelectedBgColor !important;
      border: 1px $paginBgColor !important;
    }

    .MuiPaginationItem-outlined {
      background-color: $paginBgColor !important;
      border: 1px $paginBgColor !important;
      color: $paginSelectedFgColor !important;
    }
  }
}
