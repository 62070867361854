#customized-menu {
  .MuiListItemIcon-root {
    margin-right: 5px;
  }

  .positive {
    color: #6aca9a;
  }

  .neutral {
    color: grey;
  }

  .negative {
    color: #e85c5c;
  }

  .selected {
    box-shadow: 0 0 0 3px #05d1cf;
    border-radius: 90px;
  }
}
