@import '../../css/index';

@include themify($themes) {
  --accentColor: themed('accentColor');
}

#SurveyResults {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;

  .results-container {
    margin: 0;
    width: 100%;
  }

  .question-results {
    margin: 5px 0 60px 0;
    min-width: 600px;
    width: 650px;
    flex-grow: 2;
  }

  .question-results-mobile {
    position: relative;
    margin: 5px 0 60px 0;
    width: 80vw;
    height: 100%;
    flex-grow: 2;
  }

  .back-button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 10px;
    justify-content: flex-end;
  }

  .back-to-surveys {
    color: #8694f0 !important;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    padding: 0 28%;
  }

  .star-icon {
    width: calc(40px + 0.45vw);
    height: calc(40px + 0.45vw);
  }

  .survey-question {
    margin: 2px 0 20px 0;
    display: flex;
    justify-content: center;
    min-width: 100%;
    font-weight: 700;
    font-size: 22px !important;
  }
  .MuiRating-iconFilled {
    color: #05d1cf;
  }

  .label {
    flex-grow: 1;
    margin-top: 5px;
    text-align: center;
    align-items: flex-start;
  }

  .rating-stars {
    display: flex;
    height: 100%;
    justify-content: center;
    margin-bottom: 25px;
  }

  .rating-slider {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 25px;
  }

  .my-result-percent {
    margin-left: 5px;
  }

  .my-answer-number {
    font-size: 20px !important;
    display: flex;
    justify-content: center;
  }

  .my-result-number {
    font-size: 20px !important;
    font-weight: 700 !important;
    display: flex;
    justify-content: center;
  }

  .my-answer-text {
    font-size: 20px !important;
    display: flex;
    justify-content: center;
  }

  .my-result-text {
    font-size: 20px !important;
    font-weight: 700 !important;
    display: flex;
    justify-content: center;
  }

  .my-text-container {
    width: 100%;
    justify-content: center;
  }

  .rating-answer {
    font-size: 20px !important;
    display: flex;
    justify-content: center;
  }

  .rating-result {
    font-size: 20px !important;
    font-weight: 700 !important;
    display: flex;
    justify-content: center;
  }

  .yesNo {
    min-width: 30px;
  }

  .results-nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-top: 1px solid #d7d7e2;
    height: 50px;
    width: 80%;
  }

  .results-nav-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .arrow-icon {
    color: #8694f0;
  }

  .progress-bar-container {
    width: 500px;
  }

  .MuiLinearProgress-root {
    height: 20px;
    border-radius: 50px;
  }

  .option-container-item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .option-container {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .option-container-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75vw;
  }

  .emoji-container {
    height: 20px;
    margin-right: 5px;
  }

  .checks-icon {
    background-color: #8af593;
    border-radius: 50px;
    margin-top: 2px;
    margin-left: 4px;
  }

  .MuiSvgIcon-root.checks-icon {
    font-size: 12pt !important;
  }

  .result-option-container {
    margin-left: 20px;
    display: flex;
  }

  .result-option {
    display: flex;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .my-result-order {
    align-items: center !important;
    justify-content: flex-start;
    display: flex;
    color: #50515f;
    min-height: 45px;
    min-width: 200px;
    margin-bottom: 10px;
    padding-left: 10px;
    background-color: #efeff4;
    border-radius: 5px;
    max-width: 98%;

    .order-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      background-color: #8694f0;
      margin-right: 15px;
      width: 30px;
      height: 30px;
      color: white;
    }
    .order-text {
      max-width: 98%;
    }

    .order-icon-container {
      width: 40px;
    }
  }

  .results-toggle {
    margin-top: 5px;
    margin-right: 10px;

    .MuiToggleButton-label {
      text-transform: capitalize !important;
    }

    .icon {
      margin-right: 5px;
    }

    .Mui-selected {
      background: #d1cff8 !important;
      box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.2);

      .icon {
        color: #7976ee;
      }

      .MuiToggleButton-label {
        font-weight: 700;
        color: #7976ee;
      }
    }

    .MuiToggleButton-root {
      height: 30px;
      background: #ffffff;
      border: 1px solid #d1cff8;
    }
  }

  .MuiLinearProgress-colorPrimary {
    background-color: #c5c5c53f;
  }

  .MuiLinearProgress-barColorPrimary {
    border-radius: 50px;
    background-image: linear-gradient(to right, #7a66ed, #75a7f5, #71e4fc);
    // color: repeating-linear-gradient(45deg, transparent 10px, none 10px);
  }

  .MuiRating-root.Mui-disabled {
    opacity: 1 !important;
    pointer-events: none !important;
  }

  .MuiSlider-thumb.Mui-disabled {
    margin-top: -6px !important;
    margin-left: -8px !important;
  }

  .MuiSlider-root {
    display: block;
    width: 70vw;
    align-self: center;

    &.is-mobile {
      .MuiSlider-track {
        padding-left: 6px;
      }

      .MuiSlider-thumb {
        left: -46%;
      }

      .MuiSlider-markLabel {
        margin-top: 20px;
        white-space: pre;
        text-align: center;
        font-size: calc(9px + 0.2vh);
      }
    }

    .MuiSlider-track {
      left: -20px !important;
      @include themify($themes) {
        color: themed('buttonHoverColor') !important;
      }
    }

    .MuiSlider-thumb {
      @include themify($themes) {
        border: 2px solid themed('primaryBgColor') !important;
      }
      color: $white !important;
    }

    &.has-answer {
      .MuiSlider-track {
        left: -20px !important;
        padding-left: 20px;
        @include themify($themes) {
          color: #05d1cf !important;
        }
      }

      .MuiSlider-thumb {
        @include themify($themes) {
          color: #05d1cf !important;
          border: 2px solid #05d1cf !important;
        }
      }

      .MuiSlider-thumb.Mui-disabled {
        margin-top: -6px !important;
        margin-left: -8px !important;
      }
    }

    .MuiSlider-rail {
      height: 6px;
      left: -20px;
      width: calc(100% + 40px);
      border-radius: 6px;
    }

    .MuiSlider-track {
      height: 6px;
      border-radius: 6px;
    }

    .MuiSlider-thumb {
      width: 18px;
      height: 18px;
    }

    .MuiSlider-mark {
      height: 14px;
      margin-top: 20px;
      background-color: #dcdcdc;
    }

    .MuiSlider-markLabel {
      margin-top: 28px;
      white-space: pre;
      text-align: center;
    }
  }

  .ReactModal__Overlay--after-open {
    div {
      z-index: 10000 !important;
    }
  }

  .MuiSlider-vertical {
    display: block;
    left: -20px;
    width: 6px;

    &.has-answer {
      .MuiSlider-track {
        left: -21px !important;
        padding-left: 6px;
      }

      .MuiSlider-thumb.Mui-disabled {
        margin-left: -5px !important;
      }
    }

    .MuiSlider-rail {
      width: 6px;
      left: -20px;
      height: 100%;
      border-radius: 6px;
    }

    .MuiSlider-track {
      border-radius: 6px;
    }

    .MuiSlider-thumb {
      width: 18px;
      height: 18px;
      left: -65%;
    }

    .MuiSlider-mark {
      width: 14px;
      height: 1px !important;
      left: -20%;
      float: left;
      background-color: #dcdcdc;
      white-space: normal;
      text-align: left;
    }

    .MuiSlider-markLabel {
      margin: 0px;
      transform: translateY(41%) !important;
      white-space: nowrap;
      text-align: left;
    }
  }

  .slider-container {
    margin-top: 30px;

    &.mui-slider-container-vertical {
      margin-left: 30px;

      .MuiSlider-markLabel {
        width: calc(100vw - 265px);
        min-width: 600px;
        white-space: pre-wrap;
        max-height: 2 * 19px;
        overflow: hidden;
      }
    }

    &.mui-slider-container-mobile {
      margin-bottom: 25px;

      .MuiSlider-markLabel {
        width: calc(100vw - 260px);
      }
    }
  }
}