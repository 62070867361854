@import '../../../css/index';

#MediaItemDrawer {
  .add-icon-container {
    background-color: $platinumGray;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add-participant-text {
    margin: 0 -4px;
    font-weight: 500 !important;
  }

  .media-item-detail-header {
    display: block;
    color: #91919a !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    margin-bottom: 8px !important;
  }

  .media-item-detail-field {
    display: block;
    margin-bottom: 6px;
  }

  .media-upload-file-error {
    color: $indianRed;
  }

  .preview-image {
    display: block;
    max-height: 350px;
    max-width: 92%;
    z-index: 1;
  }

  .preview-video {
    max-width: 92%;
    position: absolute;
    z-index: 1;
  }

  .autocompleteInput {
    padding: 0px;
    padding-right: 14px;

    .MuiAutocomplete-endAdornment {
      top: unset;
      padding-right: 8px;
    }
  }

  .participants-container {
    align-items: center;
    padding-left: 6px;
  }

  .audience-member {
    padding: 10px 12px;
    cursor: pointer;

    &:hover {
      background-color: $black10 !important;
    }
  }

  .participantsToolbar {
    .root {
      display: flex;
    }

    .box {
      display: flex;
      justify-content: center;
      flex: 1;

      &:first-child > span {
        margin-right: auto;
      }
      &:last-child > span {
        margin-left: auto;
      }
    }
    .button {
      background-color: #05d1cf;
      color: white;
      :hover {
        background-color: #05d1cf;
      }
    }
  }

  .participantsSearchBar {
    padding-top: 0px;
    width: 100%;

    .search-textbox {
      width: 100%;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 5px;
    }
  }

  #participantsPickerTabsBar {
    padding: 0px;
    height: 40px;
    min-height: unset;

    span {
      font-size: 9px;
    }
  }

  .participantPickerContainer {
    height: 100%;
    overflow-y: auto;
  }

  .participant-picker-card {
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: $black10 !important;
    }
  }

  .avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
  }

  .avatar {
    display: inline-block;
    height: 100%;
    width: auto;
  }

  .defaultAvatarIcon {
    font-size: 4.166rem;
  }

  .participantCardInfo {
    padding-left: 10px;

    span {
      display: block;
    }

    .participantName {
      font-size: $fs_secondary;
      font-weight: bold;
    }
  }

  .selectedParticipantChip {
    max-width: 150px;
    margin: 0.25em;
  }

  .participants-section-header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
  }

  .media-button {
    margin-left: 8px;
  }
}
