@import '../../../css/index';

#StepperBar {
  .stepper-root {
    width: 100%;
  }
  .MuiStepper-root {
    padding: 15px !important;
  }

  .completed {
    @include themify($themes) {
      background-color: themed('TPcompletedStepBG') !important;
    }
  }

  .error {
    background-color: #ffd9e0;
    border: none;
    .MuiSvgIcon-root {
      color: #ef666c;
    }
  }

  .active.error {
    background-color: #ffd9e0 !important;
    border: none;
    .MuiSvgIcon-root {
      color: #ef666c !important;
    }
  }

  .active.completed {
    @include themify($themes) {
      background-color: themed('activeTabBgColor') !important;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
    }
    .MuiSvgIcon-root {
      color: #fff !important;
    }
  }

  .MuiStepLabel-iconContainer {
    .active {
      @include themify($themes) {
        background-color: themed('activeTabBgColor');
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
      }
      .MuiSvgIcon-root {
        color: #fff;
      }
    }

    .completed {
      @include themify($themes) {
        color: themed('TPcompletedStepIcon');
      }
    }
  }

  .MuiStepLabel-label.MuiStepLabel-active {
    font-weight: 700 !important;
  }

  .MuiStepLabel-label {
    cursor: pointer;
  }

  .disabled {
    opacity: 0.3 !important;
  }
}

#ToolbarMenu {
  background: $whiteSmoke;
  .MuiButton-contained {
    margin-right: 10px;
  }
}
