
#SurveyTemplateFolder {

  .tree-item-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .item-right {
      display: flex;
      flex-direction: row;
      align-items: center;

      .folder-actions {
        display: flex;
        flex-direction: row;
        align-items: center;

        .action-icon {
          cursor: pointer;
          margin-right: 2px;
        }
      }
    }
  }

}