@import '../../../../css/colors';

#CategoriesListWidget {
  width: 100%;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .card-content {
    overflow-y: none;
  }

  .MuiCardContent-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: calc(100% - 48px);
  }

  .user-avatar {
    height: 18px;
    width: 18px;
  }

  .userName {
    padding-left: 12px;
  }

  @import '../../../../css/ag-grid-tables.scss';
}
