@import '../../../../css/index';

$drawerHeight: calc(100vh - 64px);
#QuestionsCatalogDrawer {
  #QuestionRow {
    margin: 4px;
    margin-right: 12px;
    padding: 10px;
    background-color: white;
    cursor: pointer;
    height: 112px;
    // min-height: 120px;
    // max-height: 120px;
    overflow-y: hidden;
    overflow-x: hidden;
    // border: solid $ghostWhite 1px;
    // border-radius: 4px;

    .rating-options {
      width: 510px;
      white-space: nowrap;
      overflow-x: hidden;
    }

    .title {
      width: 87%;
      white-space: nowrap;
      overflow-x: hidden;
    }

    .category {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .option {
      display: inline-flex;
      // float: left;
      margin-top: -12px;
      margin-right: 4px;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      align-items: center;
      // margin-bottom: 2px;
    }

    .question-card.selected {
      background-color: $darkTurquoise20 !important;
    }

    .option-icon {
      margin-right: 5px;
      color: $gunPowder;
      width: 20px;
      height: 20px;
    }

    .question-card-header {
      display: flex;
      align-items: center;
      margin-top: -5px;
      margin-bottom: -5px;

      .MuiIcon-root {
        margin-left: auto;
      }

      .question-card-header-checkbox {
        margin-left: -5px;
      }
    }

    .question-card-content {
      margin-left: 6px;
    }

    .icon-color-brown {
      background-color: #ad6f3c;
    }

    .icon-color-blue {
      background-color: #5a87db;
    }

    .icon-color-green {
      background-color: #75b975;
    }

    .icon-color-yellow {
      background-color: #f0e054;
    }

    .icon-color-red {
      background-color: #da4949;
    }

    .icon-color-purple {
      background-color: #c557cf;
    }

    .icon-color-orange {
      background-color: orange;
    }

    .icon-color-pink {
      background-color: pink;
    }
  }
}
