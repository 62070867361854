@import '../../../../css/index';

#PulseCampaignTemplatesDashboard {
  height: calc(100vh - #{$topNavHeight});
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #e1e4ef;

  .pulse-campaignTemplates-dashboard {
    height: 100%;

    .campaignTemplates-dashboard-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 15px 15px 0;

      .dashboard-title {
        font-size: $fs_header;
        font-weight: 600;
        color: #6f8096;
      }

      .template-buttons-right {
        display: flex;
        flex-direction: row;
        align-items: center;

        .row-layout-button {
          margin-right: 20px;
          background-color: #c0cae2;
          border-radius: 5px;
          cursor: pointer;

          &.selected {
            box-shadow: inset rgba(2, 2, 2, 0.4) 0px 2px 10px 0px;
          }
          
          .row-icon {
            color: #6b6985;
            margin: 7px;
            vertical-align: middle;
          }
        }

        .tile-layout-button {
          margin-right: 20px;
          background-color: #c0cae2;
          border-radius: 5px;
          cursor: pointer;

          &.selected {
            box-shadow: inset rgba(2, 2, 2, 0.4) 0px 2px 10px 0px;
          }

          .dash-icon {
            color: #6b6985;
            margin: 7px;
            vertical-align: middle;
          }
        }
      }
    }

    .pulse-campaignTemplates-dashboard-content {
      padding: 20px;
      display: flex;
      flex-direction: row;
      height: calc(100vh - 128px - 64px);

    }
  }
}
