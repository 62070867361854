@import '../../../../css/index';

#TenantLinkEdit {
  .link-edit-header {
    .link-edit-title {
      padding-top: 10px;
    }

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

  }

  .btn-row {
    display: flex;
    justify-content: flex-end;
  }

  .media-select {
    .MuiOutlinedInput-adornedStart {
      padding-left: 0px !important;
    }

    .thumbnail-container {
      width: 50px;
      height: 38px;
      border-right: 1px solid $lavenderGray;
      margin-right: 5px;
      img,
      video {
        width: 50px;
        position: relative;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        height: 100%;
      }
    }

  

    .MuiDialog-paper {
      padding: 20px;
    }

    .browse-button {
      height: calc(#{$fs_default} * 2.35) !important;
      margin-top: 0;
      margin-right: -14px;
      min-width: 100%;
      border-top-left-radius: inherit;
      font-size: 1.2rem;
      font-weight: bold;
    }

    .MuiDialog-paperWidthSm {
      width: 400px;
    }
  }

  .error-text {
    color: #e85c5c;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}