@import '../../../../css/index';

$currentSurveyMaxHeight: calc(100vh - 270px);
$currentSurveyMinHeight: 300px;

#PulseCampaignQuestionEdit {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 98%;
  flex-grow: 1;
  flex-shrink: 1;
  border-radius: 10px;
  margin: 0 15px 0 15px;
  background-color: white;
  overflow-y: auto;
  min-height: $currentSurveyMinHeight;
  max-height: $currentSurveyMaxHeight;
  height: 100%;

  .question-edit-header {
    .back-to-survey-btn {
      display: flex;
      flex-direction: row;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }

  #PulseQuestionTitle {
    .question-title {
      width: 100%
    }
  }
 
}
