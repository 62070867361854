@import '../../../css/index';

$bgColor: #F1F2F6;
$bgDarkColor: rgb(213, 213, 222);
$bgDisabled: #EBEBEB;
$fgColor: #78737E;

#PulseCampaignSurveyQuestionPreviewCard {
  width: 100%;

  .MuiCardHeader-root {
    padding: 0 0 10px 0 !important;
  }

  .MuiCardHeader-avatar {
    height: 100%;
    margin: 0 !important;
    align-self: center;
  }

  .MuiCardHeader-action {
    height: 100%;
    margin-top: 0 !important;
    margin-right: 0 !important;
    align-self: center;
  }

  .MuiCardContent-root {
    padding: 0 0 5px 0 !important;
  }

  .MuiPaper-rounded {
    height: 100%;
    width: 100%;
    background-color: white !important;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
  }

  .question-card-header-drag {
    min-width: 26px;
    min-height: 46px;

    .MuiIconButton-root.Mui-disabled {
      color: rgba(0, 0, 0, 0.54) !important;
    }
  }

  .question-card-header-actions {
    display: flex;
    flex-direction: row;
  }

  .question-card-content-body {
    margin: 0;
  }

  .MuiCard-root {
    background-color: $surface;
    flex: 1;
    margin-bottom: 5px;

    .question-card-header-title {
      font-weight: bolder;
      font-size: calc(12px + 0.25vw) !important;
      font-size: clamp(12px, 0.9vw, calc(12px + 0.25vw)) !important;
      color: $fgColor;
      padding-top: 5px;

      .required-indicator {
        color: $red;
        padding-left: 5px;
        font-size: 1.6rem;
      }
    }
  }

  .question-card-edit {
    position: absolute;
    display: flex;
    left: 45%;
    align-items: center;
    justify-content: center;
    transform: translate(0%, -65%);
  }

  .question-card-btn {
    padding: 5px !important;
  }

  .question-card-btn-avatar {
    max-height: 32px;
    max-width: 32px;
    color: rgba(0, 0, 0, 0.58);
    background-color: $bgDarkColor !important;

    &.disabled {
      color: rgba(0, 0, 0, 0.18) !important;
      background-color: $bgDisabled !important;
    }
  }
}
