@import '../../css/colors';

#PasswordStrengthMeter {
    .password-strength-meter {
        height: 0.3rem;
        background-color: lightgrey;
        border-radius: 3px;
        margin: .6rem 0;
    }

    .password-strength-text {
        margin: 0.5rem 0 !important;
        font-size: 13px !important;
    }

    .password-strength-meter::before {
        content: "";
        height: 100%;
        display: block;
        border-radius: 3px;
        transition: width 0.2s;
    }
}