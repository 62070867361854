@import '../../../../css/index';

#SurveyOptions {
  height: 100%;
  display: flex;
  background: white;

  .left {
    width: 25%;
    border-right: 3px solid #f0f1f4;
    padding: 15px 0 15px 15px;

    .MuiTabs-root.survey-options-tabs {
      height: auto !important;
      margin-right: -3px;

      .MuiTab-root.survey-options-tab {
        height: 75px !important;
        width: auto !important;
        max-width: unset !important;
        margin: 0 !important;
        margin-bottom: 15px !important;
        background-color: white !important;
        border-right: 3px solid #f0f1f4;
        border-radius: 5px 0 0 5px !important;
        text-transform: unset;
        padding: 15px !important;
        opacity: 1;
        color: black;

        &.Mui-selected {
          @include themify($themes) {
            background-color: themed('accentColorLight') !important;
          }
          color: black !important;
        }

        .MuiTab-wrapper {
          justify-content: flex-start;
          align-items: flex-start;

          .custom-tab-label {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .tab-primary-text {
              font-size: $fs_default;
              font-weight: 700;
              text-align: left;
            }

            .tab-secondary-text {
              font-size: $fs_secondary;
              text-align: left;
            }
          }
        }
      }

      .MuiTabs-indicator {
        width: 3px;
      }
    }

    .invalid {
      color: $red;
    }
  }

  .right {
    width: 75%;
    padding: 15px;
    overflow-y: auto;

    .section {
      padding-bottom: 15px;
      border-bottom: 1px solid #f0f1f4;
      margin-bottom: 15px;
    }

    .primary-text {
      font-size: $fs_default;
      font-weight: 700;
      margin-bottom: 5px;
    }

    .secondary-text {
      font-size: $fs_secondary;
      color: rgba(0, 0, 0, 0.6);
    }

    .custom-publish-section {
      margin-top: 15px;

      .section-content {
        .custom-publish-group-items {
          margin-top: 15px;

          .custom-publish-group-item {
            margin-bottom: 10px;

            .custom-publish-group-item-grid {
              width: 100%;
            }

            .custom-publish-group-item-grid-paper {
              width: 100%;
              flex-grow: 1;

              .custom-publish-group-item-grid-sub {
                padding-right: 15px;
                padding-left: 15px;
                flex-grow: 1;

                .custom-publish-group-item-grid-buffer {
                  padding-right: 5px;
                }
              }
            }

            .MuiPaper-root {
              display: flex;
              min-height: 80px;
              max-height: 100px;
              padding-top: 5px;
              justify-content: center;
              background-color: white;
              border: 1px solid #e8e8e8;
            }

            .custom-publish-label {
              color: rgb(132, 141, 162);
            }
          }
        }

        .custom-publish-group-paper {
          .MuiPaper-root {
            display: flex;
            height: 50px;
            justify-content: center;
            background-color: white;
          }
        }

        .custom-publish-rest {
          margin-top: 15px;
        }
      }

      .custom-publish-btn {
        display: flex;
        justify-content: flex-end;

        min-height: 100%;
        height: 100%;
        color: rgb(172, 175, 187);

        .custom-publish-label {
          color: rgb(132, 141, 162);
        }
      }

      .custom-publish-create-btn {
        display: flex;
        border: 1px solid #e8e8e8;
        min-height: 100%;
        height: 100%;
        justify-content: left;

        .custom-publish-label {
          color: rgb(132, 141, 162);
        }
      }

      .custom-publish-avatar {
        height: 40px;
        width: 40px;
        margin-right: 15px;
        color: rgb(172, 175, 187);
        background-color: rgb(237, 241, 248);
      }
    }

    .question-flow {
      .section-content {
        display: flex;

        .question-flow-card {
          height: 120px;
          width: 150px;
          padding: 10px;
          margin-right: 15px;
          margin-top: 15px;

          .MuiFormControlLabel-root {
            margin-left: 0;
          }

          .MuiRadio-colorSecondary {
            color: $nightRider;
            padding: 0;
            padding-right: 5px;
          }

          &.active {
            border: 1px solid #333;
          }
        }
      }
    }

    .survey-owner {
      .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
        padding: 0;
      }

      .owner-container {
        display: flex;
        height: fit-content;
        width: 100%;
        max-width: 100%;
        background-color: white;
        flex-wrap: wrap;
        min-width: 0;

        .owner-name {
          max-width: 325px;
          justify-content: flex-start;
        }
      }
    }

    .deliver-on-specific-date-picker {
      width: 180px;
    }

    .publish-at-specific-date-time-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 15px;

      > div {
        margin-right: 15px;
      }

      .custom-time-picker {
        margin-top: 0 !important;
      }

      .time-zone-autocomplete {
        width: 325px;

        .MuiFormControl-root {
          width: 100%;
        }

        .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
          padding: 0;
        }

        .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
          padding: 0px;
          padding-right: 65px;
          padding-left: 10px;

          .MuiAutocomplete-input {
            padding-top: 0px !important;
          }
        }
      }
    }

    .recurring-options-content {
      .repeat-mode-container {
        margin-top: 10px;

        .MuiSelect-select {
          min-width: 100px;
        }

        .weekly-container {
          margin-top: 10px;
        }
      }
    }

    .due-date-content {
      .due-after-row {
        margin-top: 10px;

        .MuiTextField-root {
          width: 60px;
          margin-right: 10px;
        }

        .MuiSelect-select {
          min-width: 70px;
        }
      }
    }

    .due-at-specific-date-time-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 15px;

      > div {
        margin-right: 15px;
      }

      .custom-time-picker {
        margin-top: 0 !important;
      }

      .time-zone-autocomplete {
        width: 325px;

        .MuiFormControl-root {
          width: 100%;
        }

        .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
          padding: 0;
        }

        .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
          padding: 0px;
          padding-right: 65px;
          padding-left: 10px;

          .MuiAutocomplete-input {
            padding-top: 0px !important;
          }
        }
      }
    }

    .due-date-picker-row {
      max-width: 30%;
    }

    .due-time-picker-row {
      max-width: 30%;
    }

    .MuiToggleButtonGroup-root {
      background: white;

      .MuiToggleButton-root {
        height: 35px;
        font-size: $fs_secondary;
      }

      .MuiToggleButton-root.Mui-selected {
        @include themify($themes) {
          background-color: themed('accentColorLight') !important;
        }
      }
    }

    .survey-published-notification-type-tabs {
      margin-bottom: 10px;

      button.survey-published-notification-type-tab {
        background-color: white !important;
        margin-right: 0 !important;

        &.error {
          color: red;
        }

        span.MuiTab-wrapper {
          font-weight: 700;
        }
      }
    }
  }

  .textfield-label {
    font-weight: 600;
    margin-bottom: 5px;
  }

  .MuiDivider-root {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .reminder-row {
    .reminder-input-row {
      display: flex;
      align-items: center;

      .MuiTextField-root {
        width: 5rem;
      }

      .reminder-value-select {
        width: 5rem;
      }

      .reminder-period-select {
        margin-left: 0.75rem;
        margin-right: 0.75rem;
        width: 7.5rem;

        .MuiSvgIcon-root {
          margin-top: 0.2rem;
          margin-bottom: 0.2rem;
        }
      }

      .MuiIconButton-root {
        padding: 0.75rem;
        margin-left: 0.5rem;
      }
    }

    .MuiDivider-root {
      margin-top: 0.5rem;
    }
  }

  .visibility-participants-container {
    max-width: 60vw;
    flex-wrap: wrap;
    display: flex;
    margin-top: 10px;

    .visibility-participant-card.select-people {
      cursor: pointer;
    }

    .visibility-participant-card {
      display: flex;
      align-items: center;
      min-height: 52px;
      width: 250px;
      padding: 4px;
      border-radius: 2px;
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: $ghostWhite;

      .visibility-participant-card-left {
        .fake-avatar-gray {
          background-color: $lightGray;
          height: 35px;
          width: 35px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          .visibility-add-participant-button-icon {
            color: $spanishGray;
          }
        }
      }

      .visibility-participant-card-right {
        margin-left: 10px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .type {
          text-transform: capitalize;
        }
      }
    }
  }

  .reno-participants-container {
    max-width: 60vw;
    flex-wrap: wrap;
    display: flex;
    margin-top: 10px;

    .reno-participant-card.select-people {
      cursor: pointer;
    }

    .reno-participant-card {
      display: flex;
      align-items: center;
      min-height: 52px;
      width: 250px;
      padding: 4px;
      border-radius: 2px;
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: $ghostWhite;

      .reno-participant-card-left {
        .fake-avatar-gray {
          background-color: $lightGray;
          height: 35px;
          width: 35px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          .reno-add-participant-button-icon {
            color: $spanishGray;
          }
        }
      }

      .reno-participant-card-right {
        margin-left: 10px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .type {
          text-transform: capitalize;
        }
      }
    }
  }


  .right
    .survey-owner
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input {
    padding-left: 10px;
  }

  .reminder-container {
    padding-left: 20px;
  }

  .reminder-checkbox-container {
    display: flex;
    flex-direction: column;
  }

  .reminder-nodue-text {
    margin-top: 20px;
  }
}

.specific-date-time-reminder-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // margin-top: 15px;

  > div {
    margin-right: 15px;
  }

  .custom-time-picker {
    margin-top: 0 !important;
    width: 160px !important;
  }

  .time-zone-autocomplete {
    width: 325px;

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
      padding: 0;
    }

    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      padding: 0px;
      padding-right: 65px;
      padding-left: 10px;

      .MuiAutocomplete-input {
        padding-top: 0px !important;
      }
    }
  }
}

.owner-dialog {
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0;
    padding-right: 62px !important;
    padding-left: 10px;

    .MuiAutocomplete-input {
      margin-top: -3px;
    }
  }

  .owner-dialog-content {
    height: 135px;
  }

  .owner-dialog-content-sm {
    height: 64px;
  }

  .owner-warning {
    color: $red;
    padding-bottom: 10px;
  }

  .publisher-select {
    width: 430px;

    div {
      padding: 0px !important;
    }
  }
}
