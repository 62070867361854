@import '../../../css/index';

#EmailTemplateSettingsForm {
    padding: 0 10px 0 5px;

    .button-space {
        margin-right: 10px;
    }

    .MuiGrid-container {
        padding: 0 10px !important;
    }

    #templateGeneral,
    #templateAdvanced {
        margin: 10px 0 20px 0;

        .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
            padding: 0 9px;
        }
    }


    .settings-group-label {
        margin: 10px 0;
    }

    .send-test-email-button {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}