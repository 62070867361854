@import '../../../../css/index';

#PreviewTabPanel {
  height: calc(100vh - 162px);
  width: 100%;
  background-color: $ghostWhite;
  padding: 20px;

  .fields {
    margin: 20px;
  }

  .MuiTableCell-stickyHeader {
    background-color: $ghostWhite;
  }

  .no-participants-selected {
    padding: 30px 20px;
  }

  .details {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 20px;
  }

  .participants-summary,
  .measurable-summary {
    padding: 20px 0px;
    padding-left: 10px;
  }

  .participant-table {
    max-height: 220px;
    overflow-y: scroll;
  }

  .table-header-cell {
    font-weight: 700;
    border-bottom: 0px !important;
  }

  .MuiTableCell-root {
    padding: 8px !important;
  }

  .user-cell {
    display: flex;
    align-items: center;

    .MuiAvatar-root {
      margin-right: 8px;
    }
  }

  .details-section,
  .participants-summary {
    padding-bottom: 20px;
    border-bottom: 1px solid lightgray;
  }

  .MuiTableRow-root:last-child > .MuiTableCell-root {
    border-bottom: 0px !important;
  }

  .measurable-title {
    display: flex;
    align-items: center;
  }

  .preview-details-row {
    width: 100px;
  }
  .measurable-statement {
    padding-top: 5px;
    padding-left: 10px;
    .detail {
      font-weight: 600;
      text-decoration: underline;
    }
  }
}
