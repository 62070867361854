@import '../../css/_index.scss';

$bgColor: white;
$bgDarkColor: rgba(128, 128, 128, 0.3);
$fgColor: #6f8096;
$inactiveColor: #f7f7f7;

#PulseCampaignTypesList {
  padding: 20px 100px 0 100px;
  width: calc(100vw - 210) !important;
  background-color: #f1f2f6;
  height: calc(100vh - #{$topNavHeight});
  overflow-x: hidden;
  overflow-y: auto;

  .spinner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  #EmptyState {
    .empty-state-container {
      .empty-state-header {
        font-weight: bold;
        color: #6f8096 !important;
      }
      #EmptyStateText {
        .empty-state-text {
          color: #6f8096 !important;
        }
      }
    }
  }
}

#PulseTypeRow {
  .pulse-type-row {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    padding: 5px 15px;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 5px;
    justify-content: space-between;
    height: 40px;
    max-height: 40px;
    cursor: pointer;

    &--inactive {
      background-color: $inactiveColor;
    }

    .pulse-type-title {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .pulse-type-name {
      color: $fgColor;
      font-size: $fs_default;
      font-weight: bolder;

      &--inactive {
        opacity: 0.5;
      }
    }

    .pulse-type-controls {
      display: flex;
      align-items: center;
      gap: 25px;
    }

    .pulse-type-active {
      &--inactive {
        opacity: 0.5;
      }
    }
  }
}
