#EmailTemplatesEmptyState {
  height: 100%;
  overflow: hidden;
  background-color: #F1F2F6;

  .spinner-container {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    position: absolute;
    z-index: 1002;
  }

  .empty-state-container {
    .empty-state-header {
      font-weight: bold;
      color: #6F8096 !important;
    }

    #EmptyStateText {
      .empty-state-text {
        color: #6F8096 !important;
      }
    }
  }

}