#WidgetContainer {
  display: flex;
  flex: 1;
  height: 100%;
  overflow-y: hidden;

  .deleteIcon {
    position: absolute;
    right: 0;
    margin: 12px 12px 0 0;
  }
}
