#SurveyTagsDrawer {
  .MuiSelect-select:focus {
    background-color: inherit;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0 9px;
  }

  .MuiCardHeader-action {
    margin-top: 0px !important;
    .MuiButtonBase-root {
      margin-top: 0px !important;
    }
  }
}
