@import '../../../css/index';

#GoalsTable {
  height: 100%;
  display: flex;
  flex-direction: column;

  th {
    font-weight: 700;
  }

  tr {
    cursor: pointer;
  }

  .MuiTableCell-root {
    font-size: inherit;
    background-color: white;
  }

  .MuiTableBody-root {
    .MuiTableCell-root {
      &:first-child {
        border-left: solid #ffcd6e 3px;
      }
    }
  }

  .paging-row {
    margin-top: auto;
    padding: 0 6px;
    width: 100%;
    button {
      padding: 10px !important;
    }
  }
  .category-cell {
    .category-text {
      background-color: lightgray;
      padding: 3px 10px;
      border-radius: 16px;
    }
  }
  .participant-cell {
    padding-top: 0px;
    // padding-bottom: 0px;
  }
}
