@import '../../../css/index';

.active-content-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  box-shadow: 5px 5px 5px $lightGray !important;
  border: 1.5px solid $jordyBlue !important;
}

.content-card-error {
  border-color: $errorRed !important;
}

#ContentCard {
  margin-bottom: 20px;
  display: flex;
  border: 1px solid $lavenderGray;
  min-height: 60px;
  border-radius: 4px;
  cursor: pointer;

  .title-placeholder {
    color: $spanishGray;
  }

  .close-icon {
    color: $spanishGray;
  }

  .content-type {
    color: $spanishGray;
    font-weight: 600 !important;
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }

  .drag-indicator {
    display: flex;
    width: 100%;
    align-items: flex-start;

    svg {
      height: 20px;
    }
  }

  .active {
    background-color: $jordyBlue !important;
    border-right: 1.5px solid $jordyBlue !important;
  }

  .content-number {
    position: relative;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $whisper;
  }

  .add-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5px 0;
    height: 100%;
  }

  .content-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 20px;
  }
}
