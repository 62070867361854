@import '../../../../css/index';

#UserTrainingPlanTakeScreen {
  align-content: flex-start;
  height: calc(100vh - #{$topNavHeight});
  overflow-x: hidden;
  overflow-y: hidden;

  @include themify($themes) {
    background-color: themed('secondaryBgColor') !important;
  }
}
