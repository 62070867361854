@import '../../css/index';

#QuizResultQuestion {
  .quiz-question-options {
    flex-direction: column;
  }

  .quiz-result-question {
    padding: 15px;

    .question-text {
      color: #333333
    }
  }
  
  .isEven {
    background-color: #F4F5FA
  }
}
