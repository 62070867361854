@import '../../css/index';

#QuestionsList,
.question-draggable-item {
  .question-card.active {
    @include themify($themes) {
      border: 1px solid themed('lightBlueAccent') !important;
    }
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    //transistion: all 0.3s cubic-bezier(.25,.8,.25,1);
  }

  .question-card {
    margin: 15px 0;
    border-radius: 6px;
    border: solid $lavenderGray 1px;
    background: #fff;
    cursor: grab !important;

    .question-required {
      color: $indianRed;
    }

    .MuiListItemIcon-root {
      min-width: 28px !important;
    }

    .check-marker {
      list-style-type: upper-alpha;
    }

    .options-marker {
      height: 100%;
      border-right: 1px solid rgb(209, 203, 203);
      vertical-align: top;
      margin-right: 5px;
    }

    .material-icons {
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 900;
      display: inline-block;
      line-height: 26px !important;
      border-radius: 4px !important;
      width: 26px !important;
      height: 26px !important;
      text-align: center;
      vertical-align: center;
      color: white;
      justify-content: center;
    }

    .card-index {
      vertical-align: center;
      display: inline-block;
    }

    .header {
      cursor: grab;
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-basis: auto;
      background-color: #f0f1f4;
      height: 30px;
      line-height: 30px;
      vertical-align: middle;
      padding: 0 2px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      &:active:hover {
        cursor: grabbing;
      }

      &.active-header {
        @include themify($themes) {
          background-color: themed('lightBlueAccent') !important;
        }
      }

      .question-card-header-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p {
          margin-left: 5px;
          font-size: $fs_secondary;
          font-weight: 700 !important;
        }

        .MuiListItemIcon-root div {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .question-card-header-icons {
        height: 100%;
        margin-left: -5px;
        margin-top: -5px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .MuiListItemIcon-root {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .media-icon,
        .copy-icon,
        .delete-icon {
          color: $gunPowder;
          cursor: pointer;
        }

        .media-icon {
          margin-right: 7.5px;
          height: 14.5px;
          width: auto;
        }

        .copy-icon {
          margin-top: 0.75px;
          margin-right: 3px;

          .MuiSvgIcon-root {
            height: 13.5px;
            width: auto;
          }
        }

        .delete-icon {
          .MuiSvgIcon-root {
            height: 16.75px;
            width: auto;
          }
        }
      }
    }

    .title {
      padding: 5px;
      font-size: $fs_secondary;
      color: gray;
      overflow-wrap: anywhere;
      // border-bottom: 1px solid #f0f1f4;
    }

    .rating-option {
      vertical-align: inherit;
      padding-left: 5px;
    }

    .emoji-options {
      line-height: 30px;
      padding: 0 10px 0 0;
      font-size: 12px;
      border-top: 1px solid #f0f1f4;

      div img {
        height: 20px;
        width: 20px;
        margin: auto;
      }

      .option-menu {
        float: right;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
      }
    }

    .title:empty {
      display: none;
    }

    .options {
      padding: 6px;
      font-size: 12px;
      border-top: 1px solid #f0f1f4;

      span {
        margin: 0 6px 0 2px;
      }

      p {
        display: inline;
      }

      .fa-check-square {
        margin-left: 2px;
      }

      div {
        vertical-align: middle;
        min-width: 22px !important;
        height: 20px;
        width: 20px;
        margin: auto;
      }

      .option-menu {
        float: right;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
      }
    }

    .error {
      border: 1px solid $indianRed;
    }

    .title-container {
      min-height: 35px;

      p {
        font-size: $fs_default;
      }
    }

    .blue {
      div > span {
        background-color: #5a87db;
      }
    }

    .brown {
      div > span {
        background-color: #ad6f3c;
      }
    }

    .green {
      div > span {
        background-color: #75b975;
      }
    }

    .yellow {
      div > span {
        background-color: #f0e054;
      }
    }

    .red {
      div > span {
        background-color: #da4949;
      }
    }

    .purple {
      div > span {
        background-color: #c557cf;
      }
    }
  }
}
