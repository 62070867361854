#SurveyTemplatesFoldersTable {
  @import '../../../css/ag-grid-tables.scss';

.survey-templates-manage {
  padding: 20px;
  display: flex;
  flex-direction: row;

  .dashboard-left {
    height: 100%;
    width: 25%;

    .folders-card {
      height: calc(100vh - 220px) !important;
      overflow-y: auto;
      margin-right: 10px;
    }
  }

  .dashboard-right {
    height: 100%;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
}