@import '../../../css/index';

#MediaFileUploader {
  width: 100%;
  .error-message {
    color: $errorRed !important;
  }

  #mediaUploader {
    padding: 10px;
  }

  .media-item-preview {
    display: block;
    height: 350px;
    max-width: 92%;
  }

  .noteAddIcon {
    font-size: 5em !important;
    color: #05d1cf;
    margin-bottom: 15px;
  }

  .dropzoneText {
    color: #91919a;
  }

  .dropLink {
    color: #05d1cf;
    text-decoration: underline #05d1cf;
  }

  .thumbUpIcon {
    font-size: 5em !important;
    color: #6aca9a;
    margin-bottom: 15px;
  }

  .thumbDownIcon {
    font-size: 5em !important;
    color: #e85c5c;
    margin-bottom: 15px;
  }

  .dropzoneImage {
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }

  .dropzoneVideo {
    display: inline-block;
    max-width: 100%;
    max-width: 100%;
    height: auto;
    text-align: center;
  }

  .buttonDiv {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    color: #009f9e;
  }

  .revertButton {
    font-size: inherit;
    color: inherit;
    &:hover {
      background-color: #009f9e0d;
    }
    &:focused {
      background-color: #009f9e33;
    }
  }

  .imageButton {
    font-size: inherit;
    border-color: #ceced5;
    color: inherit;
    &:hover {
      background-color: #009f9e0d;
    }
    &:focused {
      background-color: #009f9e33;
    }
  }
}
