@import '../../css/colors';

#RoleEditDrawer {
  padding: 20px;

  .MuiDrawer-paper {
    header {
      height: 60px;
    }
    .drawer-card {
      height: calc(100vh - 64px);
    }
  }

  .spinner-container {
    width: 100%;
    height: calc(100vh - 120px);
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1002;
  }

  .tab-error {
    .MuiTab-wrapper {
      color: $indianRed;
    }
  }

  .defaultDash-select {
    width: 275px;

    div {
      padding: 0px !important;
    }
  }
  .title {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }

  .alwaysLandToggle {
    margin-top: -10px;
  }

  .privileges-container {
    margin-top: 16px;
    padding-bottom: 100px;
  }

  .privileges-table {
    border: 1px solid $lavenderGray !important;
    border-radius: 5px !important;
  }

  .temp-table .MuiTableCell-stickyHeader {
    background-color: $ghostWhite !important;
  }

  .privilege-row {
    cursor: pointer;
  }

  .privilege-checkbox-cell-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .privilege-name {
    margin: auto 0;
  }

  .description-container {
    margin-top: 10px;
  }

  .role-name-helper-text {
    color: $indianRed;
    font-size: 1rem;
  }
}

#participantsToolbar {
  .toolbar-header {
    display: flex;
    justify-content: space-between !important;
  }
}

#RoleMemberList {
  height: 80vh;

  #ParticipantsSelect {
    .ag-theme-alpine {
      height: 68vh !important;
    }
  }

  .role-members-title-bar {
    margin-bottom: 10px;
  }

  .members-title {
    margin-right: 5px;
  }

  .avatar {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }

  .members-table {
    border: 1px solid $lavenderGray !important;
    border-radius: 5px !important;
  }

  .avatar-cell {
    display: flex;
    align-items: center;
  }

  .delete-icon {
    float: right;
  }

  .add-member-button {
    margin-top: 20px;
  }
}

#RoleAddMembersList {
  height: calc(100% - 65px - 50px);

  .add-member-header {
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .add-member-title {
      margin-left: 120px !important;
    }
  }

  .body {
    height: 100%;
  }

  .back {
    cursor: pointer;
  }

  .search {
    width: 100%;
    margin-bottom: 10px;
  }

  .select-column {
    background-color: $whiteSmoke;
    height: 100%;
  }

  .member-search {
    width: 100% !important;
    padding: 0 10px;
    float: right;
    transition: width 0.5s;
    width: 100px;
    border-radius: 5px;
    border: 1px solid $lavenderGray;
  }
}

#RoleCategoryManager {
  .category-select {
    width: 100%;
  }

  .weights {
    display: flex;
    align-items: center;
  }

  .delete-category {
    margin-left: 5px;
    color: grey;
    cursor: pointer;
  }

  .chart-area {
    width: 100%;
    height: 300px;
  }

  .weight-label {
    padding-left: 7px;
  }

  .add-member-button {
    margin-top: 10px;
  }
}

#RoleCategoryTree {
  .MuiTypography-root {
    font-size: 1.2rem;
  }

  .MuiTreeItem-label {
    width: initial !important;

    svg {
      color: green;
      margin-right: 12px;
    }
  }

  .MuiTreeItem-content {
    height: 30px;
  }
}

#CategorySelectInline {
  margin-top: 16px;

  .MuiDialog-paper {
    padding: 20px;
  }

  .MuiDialog-paperWidthSm {
    width: 400px;
  }

  .MuiTreeItem-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }

  .dialog-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .select-button {
    height: 38px;
    margin-top: 0;
    margin-right: -14px;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    color: $darkTurquoise;
    font-size: 1.2rem;
    font-weight: bold;
  }
}
