@import '../../../../css/index';

#RolesSideBar {
  height: 100vh;
  background-color: #ede8e8;
  padding: 0 10px;

  .roles-header {
    display: flex;
    justify-content: space-between;
    .greeting {
      font-size: $fs_header;
    }
    padding: 20px 0px 21px 8px;

    .sidebar-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .icon {
      margin-left: 3px;
      padding: 7px;
    }
  }

  .role-picker-card {
    padding: 30px;
    height: 90px;
    margin: 8px;
  }

  .Mui-selected {
    background-color: rgba(0, 159, 158, 0.6);
  }

  .role-picker-card:hover:not(.selected) {
    background-color: rgba(0, 159, 158, 0.2);
    cursor: pointer;
  }
}
