@import '../../../../css/index';

#PulseCampaignParticipantsList{

.ag-header-cell{
  background-color: rgba(237, 232, 232, 0.01) !important;
  font-size: 12px !important;
  height: 25px !important;
  min-height: 25px !important;
}

.ag-header{
  height: 25px !important;
  min-height: 25px !important;
  background-color: none !important;
  display:flex;
  justify-content: center;
  align-items: center;
}

.MuiIconButton-sizeSmall{
  font-size: .5rem !important;
  padding: 1px !important;
}

.ag-body-viewport{
    height:205px !important;
    overflow:scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
.ag-theme-alpine {
  /* use theme parameters where possible */
  font-family: 'Nunito', 'sans-serif';
  background-color: #f7f5f6 !important;
  
  &.ag-root-wrapper{
    font-size: 12px !important;
  }

  &.ag-grid-no-invite-rows {
    height: 36px !important;
  }

  .ag-header-container {
    background-color: #f7f5f6;
  }

  .ag-row {
    background-color: rgba(237, 232, 232, 0.01) !important;
    font-size: 12px !important;
    height: 25px !important;
    .ag-cell{
      border-left: none !important
    }
  }

  .ag-row-selected {
    @include themify($themes) {
      background-color: themed('buttonHoverColor') !important;
    }
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    @include themify($themes) {
      color: themed('accentColor') !important;
    }
  }
}
.ag-tooltip {
  background-color: rgb(46, 48, 63) !important;
  color: white !important;
  border: none !important;
}

.invitationHistory {
  text-transform: capitalize;
  .MuiPaper-root {
    width: 100%;
  }
}

.progressCircle {
  width: 1.5em !important;
  height: 1.5em !important;
}

.surveyParticipantsList {
  text-transform: capitalize;
}

.user-card {
  margin: 5px;

  .type-email-title-text {
    color: #aeb8d3;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2.2px;
  }
  .type-text {
    font-size: 12px;
    font-style: italic;
    padding-left: 10px;
  }
  .email-text {
    font-size: 12px;
    font-style: italic;
    padding-left: 10px;
  }
}}
