@import '../../../../css/index';

#CatalogPlansWidget,
#CatalogItemsWidget {
  overflow-y: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  // cursor: pointer;
  .filter {
    display: flex;
    align-items: center;
    padding-right: 20px;
    .MuiInput-underline:after {
      border-bottom: 0px !important;
    }
    .MuiFormLabel-root {
      margin-bottom: 0px !important;
      margin-right: 5px;
    }
    .Muifocused {
      &:before {
        border-bottom: 0px;
      }
    }
    .MuiInputBase-root {
      &:before {
        border-bottom: 0px;
      }
    }
  }
  #Content {
    height: calc(100% - 48px - 34px);
    overflow-y: scroll;
  }
}
