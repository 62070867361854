@import '../../../css/index';

$bgColor: #f1f2f6;

#PulseCampaignEditScreen {
  height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);
  overflow-y: hidden;
  margin-bottom: -100px;
  background-color: $bgColor;

  input,
  .MuiInputBase-input {
    font-size: 1.3rem;
  }

  .MuiFormLabel-root {
    font-weight: bold;
  }

  .MuiSelect-outlined {
    padding: 14px 14px;
  }

  .survey-edit-tab-panel-container {
    height: calc(100vh - 164px);
    max-height: calc(100vh - 164px);
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0;
    padding-right: 62px !important;
    padding-left: 10px;

    .MuiAutocomplete-input {
      margin-top: -3px;
    }
  }
}
