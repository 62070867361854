@import '../../../css/index';
@import '../../../css/colors';

#CreateSurveyScreen {
  height: calc(100vh - #{$topNavHeight});
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #e1e4ef;

  #DashboardToolbar {
    height: $topNavHeight;
  }

  .dashboard-title.spaced {
    padding-right: 8px;
  }

  .MuiLink-underlineAlways {
    cursor: pointer;
  }

  .MuiTypography-colorPrimary {
    color: white;
  }

  .actions-container {
    margin-top: 10px;
  }

  .create-campaign-grid {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0 10px 10px 10px;
  }
  
  .create-campaign-dialog-title {
    display: flex;
    padding: 0 100px 50px 100px;
    justify-content: center;
  }

  .create-campaign-grid-item {
    display: flex;
    justify-content: center;
    align-items: center !important;
    height: 100%;
    width: 100%;
  }

  .create-campaign-btn-card {
    display: flex;
    flex-direction: column;
    height: 50vh;
    width: 50vw;
    margin: 10px;
    align-items: center;
    border-radius: 10px;
    background-color: #ffffff;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);

    &:hover {
      cursor: pointer;
      -webkit-filter: none;
      filter: none;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;

      .create-campaign-btn-card-container {
        .create-campaign-btn-card-frame {
          .create-campaign-btn-img {
            height: 100px;
            width: 100px;
          }
        }
      }

      .create-campaign-btn-card-text {
        color: black;
      }
    }

    .create-campaign-btn-card-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;

      .create-campaign-btn-card-frame {
        display: flex;
        flex-direction: column;
        height: 110px;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin-bottom: 20px;

        .create-campaign-btn-img {
          height: 90px;
          width: 90px;
          transition: width 0.5s, height 0.5s;
        }
      }
    }

    .create-campaign-btn-card-text {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      text-align: center;
      padding: 0px 20px 0px 20px;
      color: gray;

      .create-campaign-card-label-main {
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 18px;
      }

      .create-campaign-card-label-sub {
        font-weight: 400;
      }
    }
  }
}