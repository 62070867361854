@import '../../../css/index';

#OptionRow {
  .option-text {
    color: grey
  }
  .MuiButton-root {
    display: flex;
    justify-content: flex-start;

    .MuiCheckbox-root {
      padding: 0;
    }

    .MuiRadio-root {
      padding: 0;
    }

    .MuiButton-label {
      text-transform: initial;
      color: $gunPowder;
      font-weight: 500;
      font-size: calc(12px + 0.25vw) !important;
    }

    img {
      width: 22px;
      height: 22px;
      border: solid transparent 2px;
      border-radius: 50%;
    }

    &.correct-answer {
      background-color: $viridianGreen40 !important;
      .MuiButton-label {
        font-weight: 600;
        // color: $green;
      }
    }

    &.incorrect-answer {
      background-color: $indianRed40 !important;
      .MuiButton-label {
        font-weight: 600;
        // color: $red;
      }
    }

    &.active {
      background-color: #CCF6F4;
      // @include themify($themes) {
      //   background-color: themed('accentColor20');
      // }

      .MuiButton-label {
        font-weight: 600;

        @include themify($themes) {
          color: themed('primaryBgColor');
        }
      }

      .MuiCheckbox-root {
        .MuiIconButton-label {
          @include themify($themes) {
            color: themed('accentColor') !important;
          }
        }
      }

      .MuiRadio-root {
        .MuiIconButton-label {
          @include themify($themes) {
            color: themed('accentColor') !important;
          }
        }
      }

      img {
        @include themify($themes) {
          border: 2px solid themed('accentColor') !important;
        }
        border-radius: 50%;
        width: 22px;
        height: 22px;
      }
    }
  }
}
