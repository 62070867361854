#Builder {
  overflow-y: auto;
  height: calc(100vh - 162px);
  background: #fff;
  align-content: flex-start;

  #FlowDiagram {
    position: relative;
    max-width: 70%;
    padding-left: 30px;

    .canvas-container {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }

    .elements-container {
      z-index: 1;
      margin-bottom: 85px;
    }

    .start-end-badge {
      width: 60px;
      padding: 5px;
      border-radius: 20px !important;
      color: #cfd4da !important;
      background-color: #f0f1f4 !important;
      text-align: center;
      margin: 15px auto;
      cursor: default;

      p {
        line-height: 20px;
        vertical-align: middle;
        text-transform: uppercase;
        font-size: 13px;
      }
    }
  }
}
