@import '../../../css/index';

#ManageSurveysWidget {
  width: 100%;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .MuiCardContent-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .filter-is-active {
    @include themify($themes) {
      background-color: themed('accentColor') !important;
    }
  }
}
