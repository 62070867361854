@import '../../css/index';

.ag-theme-alpine {
  /* use theme parameters where possible */
  font-size: 13px;
  font-family: 'Nunito', 'sans-serif';

  .ag-header-container {
    background-color: #f8f5f5;
  }

  .ag-row {
    background-color: #fff;
  }

  .ag-row-selected {
    @include themify($themes) {
      background-color: themed('buttonHoverColor') !important;
    }
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    @include themify($themes) {
      color: themed('accentColor') !important;
    }
  }
}
.ag-tooltip {
  background-color: rgb(46, 48, 63) !important;
  color: white !important;
  border: none !important;
}

.surveyEventSummary {
  text-transform: capitalize;
  .MuiPaper-root {
    width: 100%;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .buttons-item {
    display: flex;
  }

  .tab-buttons {
    font-size: $fs_default;
  }

  .progressCircle {
    width: 1.5em !important;
    height: 1.5em !important;
  }
}
