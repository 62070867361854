@import '../../css/colors';

#PrivilegeSetDrawer {
  .MuiCardContent-root {
    height: calc(100vh - 64px - 50px - 50px) !important;
  }
}

#privilegeSetBasicInfo {
  .descriptionContainer {
    margin-top: 16px;
  }

  .privilegesTableContainer {
    margin-top: 16px;
  }
}

#PrivilegesTable {
  border: 1px solid $whiteSmoke !important;
  border-radius: 5px !important;

  .checkbox-container {
    padding: 4px;
  }
}
