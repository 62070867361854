#GoalEditScreen {
  height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);
  overflow-y: hidden;
  margin-bottom: -100px;

  input,
  .MuiInputBase-input {
    font-size: 1.3rem;
  }

  .MuiFormLabel-root {
    font-weight: bold;
  }

  .MuiSelect-outlined {
    padding: 14px 14px;
  }
}
