#MuiDrawerToolbar {
  .tool-bar {
    justify-content: space-between;
  }

  .more-menu {
    display: flex;
    align-items: center;
  }
}
