@import '../../css/index';

#UsersListModal {
  height: 70vh;
  width: 35vw;
  min-width: 425px;
  // min-height: 500px;
  overflow: hidden;
  margin: auto;
  // padding: 5px;
  // margin-bottom: 80vh;

  #UsersList,
  #ContactsList {
    height: 58vh;

    .ag-theme-alpine {
      height: 52vh;
      overflow-x: hidden;

      .ag-root-wrapper {
        border: none;
      }
      .ag-row:not(.ag-row-first) {
        border-top: none;
      }
      .ag-header {
        border-bottom: none;
      }
      .ag-cell {
        padding-left: 0;
        padding-right: 0;
      }
      .ag-center-cols-viewport {
        overflow-x: hidden;
      }
    }
  }

  .modal-list-container > div {
    padding: 0px 10px 10px;
  }

  .modal-toolbar {
    display: flex;
    justify-content: space-between;
    height: 34px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding: auto 0px !important;
    border-bottom: 1px solid lightgray;

    .left-toolbar {
      padding-left: 8px;
      display: flex;
      align-items: center;
    }
  }

  .search-bar {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .MuiFormControlLabel-root {
      margin: 0px;
    }
    .search {
      height: 32px;
    }
  }

  .MuiToolbar-regular,
  .MuiToolbar-gutters {
    padding: auto 0px !important;
  }

  #UserRow {
    border-radius: 5px;
  }

  #UserRow.Mui-selected {
    background-color: $darkTurquoise30;
  }

  .ag-theme-alpine {
    height: 100%;

    .ag-body-horizontal-scroll-viewport {
      overflow-x: hidden;
    }
    .ag-root-wrapper {
      border: none;
    }

    .ag-body-viewport {
      overflow: hidden;
    }

    .ag-body-viewport,
    .ag-body-horizontal-scroll-viewport,
    .ag-body-horizontal-scroll {
      background-color: #fff;
    }
    .ag-row {
      background-color: #fff;
      border-bottom: none;
      border-top: none;
      font-size: inherit;

      .ag-cell {
        &[aria-colindex='1'] {
          border-left: none;
        }
      }
    }
    .ag-react-container {
      width: 100%;
    }
    .ag-row:not(.ag-row-first) {
      border-top: none;
    }
    .ag-header {
      border-bottom: none;
    }
    .ag-cell {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
