#PulseQuestionDropdownInfo {
  .dropdown-info {
    margin: 0;
  }

  .dropdown-toggle,
  .dropdown-label {
    width: 50%;
    margin: 0 0 10px 0;

    .dropdown-label-header {
      margin: 0 0 8px 0;
      font-weight: bold;
    }
  }
}
