#DrawerLinks {
  .links-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .header-text {
      font-size: 19px;
      font-weight: bold;
    }
  }

  .add-btn {
    margin-left: 10px;
    padding: 0px !important;
    margin-left: 5px;
    background-color: rgba(0, 159, 158, 0.6) !important;
    color: #ffffff !important;
  }

 

  .link-row {
    padding: 8px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ecedf2;
    border-radius: 5px;
    margin-top: 8px;
    cursor: pointer;

    .link-header {
      font-weight: 700;
    }
  }
}