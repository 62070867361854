@import '../css/index';

.avatar-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  font-size: 8px;
  border-radius: 50%;
  color: white;
}

.MuiBadge-anchorOriginBottomRightRectangle {
  transform: scale(1) translate(38%, 38%) !important;
}

.completedBadge {
  background-color: $confirmGreen;
}

.incompleteBadge {
  background-color: $topazYellow;
}
