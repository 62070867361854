@import '../../../../css/colors';

#CategoriesScreenBody {
  .cat-layout {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20vw, 20vw));
    grid-auto-flow: column;
  }

  .cat-container,
  .subcat-container {
    display: flex;
    align-items: center;
    max-height: 100%;
    overflow: hidden;
  }

  .cat-picker-card {
    padding: 16px;
    height: 90px;
    margin: 8px 8px 0px 8px;
  }

  .cat-card-error {
    border: 1px solid $indianRed;
  }

  .check-card {
    padding-left: 7px;
    margin: 8px 8px 0px 8px;
  }

  .checkbox-selected {
    svg {
      fill: white;
    }
  }

  .selected {
    background-color: rgba(0, 159, 158, 0.6);
  }

  .MuiOutlinedInput-root.Mui-disabled {
    background-color: $gainsboroGray;

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }

  .MuiOutlinedInput-root {
    background-color: $white;
  }

  .cat-click {
    #cat-desc {
      max-width: 70%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .error {
    padding-left: 10px;
    color: red;
  }
}
