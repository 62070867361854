@import '../../../../css/index';

#CategoriesScreenHeader {
  padding: 20px;

  .header {
    display: flex;
  }

  .header-buttons {
    display: flex;
  }

  .greeting {
    font-size: $fs_header;
  }

  .btn-outlined-white {
    margin-left: 10px;
  }

  .options-collapse {
    position: absolute;
    z-index: 1;
    border: 1px solid $lightGray;
    border-radius: 4px;
    padding: 10px;
    background-color: #f8f5f5;
  }
}
