#CompletedPlan {
  text-align: center;
  padding: 50px;
  background-color: white;
  height: 100%;
  width: 100%;

  .success-msg {
    font-size: 36px;
    font-weight: 700;
  }
  
  .success-caption {
    font-size: 18px !important;
    font-weight: 500 !important;

    &.mtb {
      margin: 8px 0 30px;
    }
  }

  img {
    opacity: 0.65;
    width: 34%;
    margin-top: 30px;
    min-width: 300px;
  }

  .btn-row {
    display: flex;
    justify-content: center;
  }
}
