@import '../../../../css/index';

#GoalsModal {
  .close-modal-button {
    cursor: pointer;
  }

  .MuiDialog-paper {
    max-width: 800px !important;
  }
  #Header {
    padding: 0px !important;
    .title {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      background-color: $lightGray;
      height: 40px;
      padding: 8px 14px;

      .MuiDialogTitle-root {
        padding: 0;
        .MuiTypography-root {
          font-weight: 700;
        }
      }
    }

    .body-toolbar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: $platinumGray;
      height: 40px;
      padding: 0 10px 0 20px;

      .MuiTypography-root {
        font-weight: 400 !important;
      }

      .MuiButton-outlined {
        background-color: white;
      }
    }
  }

  .MuiDialogContent-root {
    padding: 0px !important;

    .body {
      width: 100%;
      height: 350px;
    }

    .left-body {
      padding: 20px 25px;
      height: 100%;

      .select-label {
        margin-bottom: 4px;
      }

      .MuiAutocomplete-endAdornment {
        top: unset;
      }

      .MuiAutocomplete-inputRoot {
        padding: 0 12px;
      }

      .select-goal-input {
        width: 100%;
      }
    }
    .right-body {
      padding: 20px 25px;
      height: 100%;
      .plan-pill {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 14px;
        margin-bottom: 5px;
        .icons {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
