@import '../../css/colors';

#NotificationsWidget {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .MuiTableBody-root {
    .MuiTableCell-root {
      &:first-child {
        border-left: solid #ffcd6e 3px !important;
      }
    }
  }

  .MuiCardContent-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

#NotificationsWidgetDrawer {
  #NotificationsWidgetDrawerCard {
    height: 100%;

    > .MuiCardContent-root {
      height: calc(100% - 50px - 60px) !important;
    }

    .tox-statusbar__branding {
      display: none;
    }
  }

  .editor-container {
    padding: 18.5px 14px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    transition: border-color 200ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);

    &:hover {
      border-color: #333333ff;
    }

    &:focus-within {
      border-color: $darkTurquoise;
      border-width: 2px;
    }
  }

  .drawer-info {
    text-align: center;
  }
}
