#DraftCampaignsCard {
  height: 50%;
  width: 100%;
  padding: 0px 0px 0px 20px;
  min-width: 250px;

  .draft-campaigns-card {
    height: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    background: url('../../../../../assets/img/campaign/Drafts.png');
    background-size: 100% 100%;
   
  }

  .icon-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;

    .draft-campaigns-icon {
    background-color: #edeef7;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height:30px;
    width: 30px;
    color:#d8704d;

    .edit-icon{
      height:16px;
      width: 16px;
    }
  }
}

.MuiCardHeader-root{
  padding: 16px 16px 8px 16px;
}

  .header-text {
    .MuiCardHeader-title{
      color:#6b6985;
      font-weight: 700 !important;
      
      &.MuiTypography-h5{
        font-size: 16px !important;
      }
    }
  
    .MuiCardHeader-subheader{
      color:#9796a9;
      font-size: 8pt;
    }
  }

  .no-drafts{
    font-size: 12pt;
    display: flex;
    justify-content: center;
  }

  .draft-container{
    width:100%;
    height:100%;
    padding: 0px 20px 80px 20px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }

  .draft-container::-webkit-scrollbar {
    display: none;
  }

  .draft-container {
    scrollbar-width: none;
  }

  .MuiGrid-item{
    width:100%;
  }

  .MuiGrid-container{
    flex-wrap: nowrap !important;
  }

  .draft-row{
    width:100%;
    margin: 4px 0px;
    border: 1px solid rgb(210, 210, 210);
    padding: 5px 10px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.70);

    &:hover {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
      cursor: pointer;
    }

    .draft-text{

    .name-text{
      color:#6b6985;
      font-size: 10pt;
      font-weight: 700 !important;
    }

    .date-text{
      color:#9796a9;
      font-size: 9pt;
    }
  }
  }

  .arrow-cont{
    display:flex;
    justify-content: center;
    align-items: center;

  .arrow {
    width: 24px;
  }
  
  .line {
    margin-top: 3px;
    width: 18px;
    background: #d8704d;
    height: 2px;
    float: left;
  }
  
  .point {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 6px solid #d8704d;
    float: right;
  }
}
}