@import '../../../css/index';

#UserImportRow {
  margin-bottom: 10px;
  padding: 10px !important;
  border-bottom: 1px solid lightgray;

  .MuiSvgIcon-root {
    font-size: 1.3rem;
    margin-left: 5px;

    &.good {
      color: green;
    }
    &.bad {
      color: $topazYellow;
    }
  }

  .user-icon {
    margin-left: 6px;
  }

  .user-found {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  .row-error-text {
    color: $indianRed;
  }

  // .result-error {
  //   color: $indianRed;
  // }

  // .row-index {
  //   margin-right: 5px;
  // }

  // .row {
  //   display: flex !important;
  //   flex-direction: row;
  // }

  // .user-info {
  //   display: flex;
  //   align-items: center;
  //   margin-bottom: 5px;
  // }

  // .user-row {
  //   margin-left: 5px;
  // }

  // .title {
  //   margin-right: 5px;
  //   margin-bottom: 0px !important;
  // }

  // .info-section {
  //   padding-bottom: 5px;

  //   .MuiGrid-root {
  //     margin-right: 5px;
  //   }
  // }
}
