@import '../../../css/colors';

#SystemAdminsListWidget #Content {
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  justify-content: flex-start;

  .paging-row {
    margin-top: auto;
    padding: 0 6px;

    button {
      padding: 10px !important;
    }
  }

  .delete-cell {
    padding: 0px !important;
    .delete-button {
      height: 30px !important;
    }
  }

  .no-rows {
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }

  #WidgetTable {
    flex: 1;

    th {
      font-weight: 700;
      background-color: $whisper;
    }

    tr {
      cursor: pointer;
    }

    .MuiTableCell-root {
      font-size: inherit;
    }

    .MuiTableBody-root {
      .MuiTableCell-root {
        &:first-child {
          border-left: solid #ffcd6e 3px;
        }
      }
    }

    .survey-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 120px;
      overflow-x: hidden;
    }

    .user-name {
      white-space: nowrap;
      padding: 0;

      div {
        display: inline-flex;
        align-items: center;
        flex: 1;
        height: 100%;

        .MuiAvatar-root {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        .MuiSvgIcon-root {
          font-size: 24px;
          margin-right: 9px;
          margin-left: -1px;
        }
      }
    }
  }
}
