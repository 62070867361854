#OrganizationGeneralWidget {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  section {
    margin-bottom: 10px !important;
  }

  .MuiDivider-root {
    margin-top: 10px;
  }

  .email {
    margin-top: 14px !important;
    margin-bottom: 8px !important;
  }
}

#OrganizationGeneralWidget::-webkit-scrollbar {
  display: none;
}
