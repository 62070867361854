@import '../../../../css/index';

.hide-category-descriptions {
  .rst__rowSubtitle {
    display: none;
  }
  .rst__rowTitle {
    height: 100%;
    font-size: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.rst__rowSearchMatch {
  outline: none;
  border-radius: 6px;
  box-shadow: 2.5px 5px 5px 0px rgba(0, 0, 0, 0.2);
  .rst__rowContents {
    @include themify($themes) {
      background-color: themed('accentColorLight') !important;
    }
  }
}

#CategoriesTree {
  height: 100%;
  width: 100%;

  .primary-border-1 {
    .rst__rowContents {
      @include themify($themes) {
        border-left: 5px solid themed('primaryBorderAccent') !important;
      }
    }
  }

  .primary-border-2 {
    .rst__rowContents {
      @include themify($themes) {
        border-left: 5px solid themed('secondaryBorderAccent') !important;
      }
    }
  }

  .primary-border-3 {
    .rst__rowContents {
      @include themify($themes) {
        border-left: 5px solid themed('tertiaryBorderAccent') !important;
      }
    }
  }

  .tree-item {
    display: flex;
    align-items: center;

    .rst__rowContents {
      border-radius: 5px;
    }
  }

  .rst__collapseButton,
  .rst__expandButton {
    position: absolute;
    &:active {
      left: -2.5px;
    }
  }
  .rst__collapseButton:hover:not(:active),
  .rst__expandButton:hover:not(:active) {
    width: 16px;
    height: 16px;
  }
  .rst__collapseButton:before,
  .rst__expandButton:before {
    font-size: 18px;
    border-radius: 50%;
    color: white;
    font-style: normal !important;
    font-family: 'Material Icons';
    left: -1px;
    top: -2.5px;
    position: relative;
  }
  .rst__collapseButton:before {
    content: 'expand_more' !important;
  }
  .rst__expandButton:before {
    content: 'expand_less' !important;
  }

  .rst__collapseButton:focus,
  .rst__expandButton:focus {
    box-shadow: none;
  }

  .rst__node {
    @for $i from 1 through 6 {
      div.rst__lineFullVertical:nth-child(#{$i}):after {
        @include get-color-for-list($i);
        width: 2px !important;
      }
      div.rst__lineHalfHorizontalRight:nth-child(#{$i}):after {
        @include get-color-for-list($i);
        width: 2px !important;
      }
      div.rst__lineHalfHorizontalRight:nth-child(#{$i}):before {
        @include get-color-for-list($i);
        height: 2px !important;
      }
      div:nth-child(#{$i + 1}) > div > div > div.rst__lineChildren:after {
        @include get-color-for-list($i + 1);
        width: 2px;
      }
      div:nth-child(#{$i + 1}) > div > div > button:before {
        @include get-color-for-list($i);
      }

      div:first-child:nth-last-child(#{$i}):not(.rst__rowLabel),
      div:first-child:nth-last-child(#{$i}):not(.rst__rowLabel) ~ div {
        $offset: #{$i - 1} * 30 * 1px;
        width: calc(100% - #{$offset});
      }
      $color: null;
      $color2: null;
    }
  }
}

.rst__rowWrapper {
  width: 100% !important;
  cursor: pointer;
  padding: 5px 10px 5px 0 !important;
}
.rst__nodeContent {
  width: 100%;
  .rst__rowContents {
    width: 100%;
  }
}
.rst__rowLabel {
  width: 100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  .rst__rowTitle {
    // overflow-x: hidden;
    // overflow-y: none;
    text-overflow: ellipsis;
  }
  .rst__rowSubtitle {
    // max-width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

#CategoriesWidget {
  .card-content {
    height: calc(96% - 52px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .no-rows {
    width: 100%;
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }
}
