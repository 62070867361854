#AnalysisTabPanel {
  // padding: 10px;
  background: #fff;
  height: calc(100vh - 120px);
  align-content: flex-start;
  overflow-y: auto;
  // border-top: solid #f5f5f5 10px;

  #TableauReportContainer {
    background: #fff;
    height: calc(100vh - 135px);
    min-height: 500px;
    width: 100%;
  }

  .no-worksheet, .no-responses {
    margin: 36px;
    margin-left: 57px;
  }
}
