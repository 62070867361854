@import '../../../css/colors';

#EmailTemplatesTable {
  @import '../../../css/ag-grid-tables.scss';

  height: 100%;
  flex-grow: 1;
  margin-top: 15px;

  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .templates-card {
    height: 100%;
  }

  .popover {
    pointer-events: none;
    padding: 8px;
  }

  .email-template-preview-cell {
    max-height: 34px;
  }

  .email-template-preview-popover {
    max-height: 80px;
  }
  
  .email-template-preview-img {
    max-width: 75px;
    object-fit: cover !important;
    object-position: 100% 0% !important;
    transform: translate(0%, 0%);
  }

  .email-template-preview-popover-text {
    padding: 5px;
  }

  .email-template-actions-cell {
    display: flex;
    flex-direction: row;

    .MuiIconButton-root.Mui-disabled {
      background-color: rgba(168, 168, 168, 0.18) !important;
    }
  }

  .email-template-action-btn-edit {
    margin: 0 3px 0 3px;
    padding: 3px; 
    color: #60CAAA;
  }

  .email-template-action-btn-revert {
    margin: 0 3px 0 3px;
    padding: 3px;
    color: #008eff;
  }

  .email-template-action-btn-delete {
    margin: 0 3px 0 3px;
    padding: 3px;
    color: $indianRed;
  }
}

.email-template-preview-popover-img {
  max-height: 340px;
}

#EmailTemplatesTable .paging-row {
  margin-top: auto;
  padding: 0 6px;

  button {
    padding: 10px !important;
  }
}

#EmailTemplatesTable .ag-theme-alpine {
  overflow-x: hidden !important;
}

#EmailTemplatesTable #WidgetTable {
  flex: 1;

  th {
    width: 25%;
    font-weight: 700;
    background-color: $whisper;
  }

  tr {
    cursor: pointer;
  }

  .MuiTableCell-root {
    font-size: inherit;
  }
}
