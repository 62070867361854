/* Material-UI Widgets */
@import './index';

.widget-container {
  .MuiPaper-root {
    @include themify($themes) {
      background-color: themed('primarySurfaceColor') !important;
    }

    .MuiCardHeader-root {
      border-bottom: 1px solid $platinumGray !important;
      @include themify($themes) {
        background-color: themed('primarySurfaceColor') !important;
      }
      padding: 9px 12px 9px 16px !important;
      height: 48px !important;

      .MuiCardHeader-title {
        font-size: $fs_default !important;
        font-weight: 700 !important;
        color: $black !important;

        @include xl-desktop-up {
          font-size: $fs_default !important;
        }
      }

      .MuiIconButton-root {
        color: $gunPowder !important;

        &.widget-cta {
          @include themify($themes) {
            background-color: themed('buttonIconCTAColor') !important;
            color: themed('buttonTextColor') !important;
          }
          // color: $white !important;

          &:hover {
            @include themify($themes) {
              background-color: themed('buttonBgColor') !important;
            }
          }
        }
      }

      .MuiCardHeader-action {
        display: flex;
        align-self: center;
        margin-top: 0;
        margin-right: 0;

        .MuiIconButton-sizeSmall {
          padding: 5px !important;
          min-width: 28px !important;
          min-height: 28px !important;
        }

        div:not(:last-child) {
          margin-right: 6px;
        }
      }
    }
  }
}

.widget-title-tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    display: block;
    min-width: fit-content;
  }

  .tab-btns {
    margin-left: 20px;

    .tab-button {
      cursor: pointer;
      display: inline-block;
      color: $black50 !important;
      font-weight: 600 !important;
      font-size: $fs_secondary !important;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      margin-right: 16px;
      @include themify($themes) {
        border-bottom: 2px solid themed('primarySurfaceColor') !important;
      }

      &.active {
        font-weight: 700 !important;
        border-bottom: 2px solid !important;
        @include themify($themes) {
          color: themed('activeTabColor') !important;
          border-bottom-color: themed('activeTabBgColor') !important;
        }
      }

      @include xl-desktop-up {
        font-size: 12.5px !important;
      }
    }
  }
}
