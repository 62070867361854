@import '../../../css/index';

$bgColor: #F2F3F6;
$fgColor: #6B6985;

#PulseCampaignCategoriesTree {
    .category-row-left {
        display: flex !important;
        flex-direction: row !important;
    }

    .category-build-header-element {
        margin-left: 0 !important;
    }

    .category-focus-header-element {
        margin-left: 15px !important;
    }

    .category-header-element-text {
        font-size: 12px !important;
        text-align: center;
    }

    .sub-category-header-element-text {
        color: $fgColor;
        font-size: 12px;
        width: 100%;
        text-align: left;
    }

    .sub-category-header-element-check {
        height: 30px;
        width: 30px;
    }

    .category-header-element-questions-number {
        width: 24px;
        font-weight: bold;
        margin: 0 0 0 10px;
        font-size: $fs_secondary;
        background-color: $bgColor;
        border-radius: 5px;
        color: $fgColor !important;
        text-align: center;
    }
}
