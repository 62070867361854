@import '../../../css/colors';

#AnnouncementListTable {
  @import '../../../css/ag-grid-tables.scss';
  text-transform: capitalize;
  margin: 15px;

  .ag-body-viewport{
    height: calc(100vh - 170px);
  }
  .ag-body-horizontal-scroll{
    height:0px !important;
    min-height: 0px !important;
  }

  .no-participants-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

.ag-theme-alpine {
  height: 80vh !important;
}
}
