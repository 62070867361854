@import '../../css/index';

.MuiDialog-paperFullScreen {
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: 100%;
    max-height: none;
    border-radius: 0;
    overflow-y: hidden !important;
}

.notification-template-dialog-grid {
    display: flex;
    height: 100%;
    width: 100% !important;
    justify-content: left !important;
    flex-direction: row;

    .spinner-container {
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        position: absolute;
        z-index: 1002;
    }

    .notification-template-dialog-grid-item {
        width: 100%;
        height: 100%;
        flex: 1;

        .grid-item-content {
            height: 100%;
            padding: 25px;
            border-right: 1px solid #d7d7e2;
        }
    
        .grid-item-previewer {
            padding: 0 0 50px 0;
            overflow-y: auto;
            height: 100%;
        }
    }

}

.notification-template-dialog-editor {
    display: flex;
    height: 100%;
    width: 100%;
}

.notification-template-dialog-preview {
    display: flex;
    height: 100%;
    width: 100%;
}

#NotificationTemplateDialogAppBar {
    .MuiAppBar-colorPrimary {
        @include themify($themes) {
            background-color: themed('primaryBgColor');
        }
    }
}