#SurveyEditScreen {
  height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);
  overflow-y: hidden;
  margin-bottom: -100px;

  .spinner-container {
    text-align: center;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -30px;
    margin-top: -30px;
  }

  input,
  .MuiInputBase-input {
    font-size: 1.3rem;
  }

  .MuiFormLabel-root {
    font-weight: bold;
  }

  .MuiSelect-outlined {
    padding: 14px 14px;
  }

  .survey-edit-tab-panel-container {
    height: calc(100vh - 64px - 50px - 46px);
    max-height: calc(100vh - 64px - 50px - 46px);
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0;
    padding-right: 62px !important;
    padding-left: 10px;

    .MuiAutocomplete-input {
      margin-top: -3px;
    }
  }
}
