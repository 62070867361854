@import '../../../css/index';

#UserSurveyTakeScreen {
  height: calc(100vh - #{$topNavHeight});
  overflow-x: hidden;
  overflow-y: auto;

  .is-mobile {
    padding: 0px !important;
    margin-left: 0px !important;
    border-left: none !important;
  }

  @include themify($themes) {
    background-color: themed('secondaryBgColor') !important;
  }
}
