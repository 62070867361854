@import '../../../css/colors';

#OrgUsersImport{
  background-color: #f4f4f7;
  height:100vh;
}

#OrgUsersContent{
  height:100%
}

.user-import-list {
  .MuiListItem-root:last-child {
    border-bottom: 0px !important;
  }
}

