@import '../../../css/index';

#CalendarLayout {
  display: flex;
  height: calc(100vh - #{$topNavHeight});

  .side-container {
    display: flex;
    flex: none;
    flex-direction: column;
    flex-basis: auto;

    @include themify($themes) {
      background-color: themed('tertiarySurfaceColor') !important;
    }

    // Mini Calendar
    .MuiPickersStaticWrapper-staticWrapperRoot {
      min-width: unset;
    }
    .MuiPickersBasePicker-pickerView {
      width: 100%;
      max-width: 100%;
      min-width: unset;
      min-height: unset;
      max-height: unset;
      @include themify($themes) {
        background-color: themed('tertiarySurfaceColor') !important;
      }

      .MuiSvgIcon-root {
        @include themify($themes) {
          color: themed('primaryBgColor') !important;
        }
      }
    }
    // Header
    .MuiPickersCalendarHeader-switchHeader {
      justify-content: center;

      .MuiPickersCalendarHeader-transitionContainer {
        width: 39%;
      }

      .MuiTypography-body1 {
        font-size: $fs_default;
        font-weight: 600;
      }
    }
    // Day Labels
    .MuiPickersCalendarHeader-dayLabel {
      flex-grow: 1;
      font-size: $fs_default !important;
      font-weight: 600;
    }
    .MuiPickersCalendarHeader-iconButton {
      @include themify($themes) {
        background-color: themed('tertiarySurfaceColor') !important;
      }
    }
    // Day Numbers
    .MuiPickersCalendar-week {
      div[role='presentation'] {
        flex-grow: 1;
        text-align: center;

        .MuiTypography-body2 {
          // font-size: 1.1rem;
          font-weight: 500;
        }
      }
    }

    .side-padding {
      padding: 0 12px;
    }

    .mini-calendar {
      display: flex;
      flex-direction: column;
      flex-basis: auto;
      flex-grow: 0;
      width: 100%;
    }

    .my-calendars {
      display: flex;
      flex-direction: column;
      flex-basis: auto;
      flex-grow: 0;
      margin-bottom: 20px;
    }

    .other-calendars {
      display: flex;
      flex-direction: column;
      flex-basis: auto;
      flex-grow: 0;

      .MuiInputLabel-shrink,
      .PrivateNotchedOutline-legendLabelled-428,
      .PrivateNotchedOutline-legendNotched-429 {
        display: none;
      }

      .MuiInputLabel-animated {
        top: -7px !important;
      }

      .MuiChip-root {
        margin: 0px !important;
      }
    }
  }

  .my-calendars-select {
    .MuiCheckbox-root {
      padding: 6px !important;
    }
  }

  .other-calendar-input {
    margin-bottom: 20px;

    .MuiInputBase-root {
      padding: 6px 28px 6px 8px !important;
      height: auto !important;
      height: fit-content !important;
      align-items: flex-start;

      .MuiChip-root {
        margin: 2px 0px !important;
      }
    }

    @for $i from 1 through length($cal_colors) {
      .MuiChip-root:nth-child(#{length($cal_colors)}n + #{$i}) {
        background-color: nth($cal_colors, $i);
      }
    }

    .MuiChip-label {
      font-size: 12.5px;
    }
  }
}

// Custom Toolbar
#CalendarTopToolbar {
  height: $calendarTopToolbarHeight;

  .MuiAppBar-root {
    height: $calendarTopToolbarHeight;
  }

  .MuiButton-outlined {
    @include themify($themes) {
      background-color: themed('buttonOtlBgColor') !important;
      color: themed('buttonOtlTextColorAlt') !important;
    }
  }

  .MuiToolbar-root {
    justify-content: space-between;

    @include themify($themes) {
      background-color: themed('tertiarySurfaceColor') !important;
    }
  }

  .MuiToolbar-gutters {
    padding: 12px 16px;
  }

  .month-name {
    color: $nightRider;
    font-size: $fs_header;

    @include xl-desktop-up {
      font-size: $fs_header !important;
    }
  }

  .start-content,
  .end-content {
    display: flex;
    align-items: center;
  }
}

// Main Calendar
.rbc-calendar {
  height: calc(100% - #{$calendarTopToolbarHeight}) !important;
  border-radius: 8px !important;
  @include themify($themes) {
    background-color: themed('tertiarySurfaceColor') !important;
  }
  .rbc-agenda-view {
    // border-radius: 8px !important;

    .rbc-agenda-table {
      border: none !important;

      tbody > tr > td + td {
        border-left: none !important;
      }

      tr {
        height: 34px !important;
      }

      .rbc-header {
        @include themify($themes) {
          background-color: themed('calAgendaHeaderBgColor') !important;
        }
        font-weight: 700 !important;
        padding-left: 10px !important;
      }

      .rbc-agenda-date-cell {
        @include themify($themes) {
          background-color: themed('primarySurfaceColor') !important;
        }
        font-size: 13px !important;
        font-weight: 600 !important;
      }

      .rbc-agenda-time-cell,
      .rbc-agenda-event-cell {
        background-color: #ffffff !important;
      }

      .rbc-agenda-time-cell {
        text-transform: capitalize !important;
      }
    }
  }
}

.rbc-month-view {
  border: none !important;

  .rbc-month-header {
    height: 34px !important;
    align-items: center !important;
    @include themify($themes) {
      background-color: themed('calAgendaHeaderBgColor') !important;
    }
  }
}

.rbc-header {
  border: none !important;
  padding: 5px 3px !important;
  text-transform: uppercase;
  font-weight: 700 !important;
  font-size: $fs_default !important;
  // height: 30px;
}

.rbc-off-range {
  font-weight: 400 !important;
}

.rbc-off-range-bg {
  @include themify($themes) {
    background-color: themed('secondarySurfaceColor') !important;
  }
}

.rbc-row {
  min-height: 30px;
}

.rbc-timeslot-group {
  min-height: 45px;
}

.rbc-month-row,
.rbc-row-bg {
  overflow: visible !important;
  // @include themify($themes) {
  //   background-color: themed('primarySurfaceColor') !important;
  // }
  background-color: $white !important;
}

.rbc-row-content {
  background-color: transparent !important;
}

.rbc-show-more {
  background-color: transparent !important;
  margin-top: 2px !important;
  font-size: $fs_default !important;
}

.rbc-time-gutter,
.rbc-time-header-gutter {
  @include themify($themes) {
    background-color: themed('secondarySurfaceColor') !important;
  }
}

.rbc-today {
  @include themify($themes) {
    background-color: themed('calTodayBgColor') !important;
  }
}

.rbc-day-slot {
  @include themify($themes) {
    background-color: themed('primarySurfaceColor') !important;
  }
  // background-color: $white !important;
}

.rbc-date-cell {
  a {
    padding: 1px;
  }
}

.rbc-current {
  position: relative;

  a {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white !important;
    font-weight: bold;
    width: 24px;
    height: 24px;
    border-radius: 50%;

    @include themify($themes) {
      background-color: themed('primaryBgColor') !important;
    }
  }
}

.MuiPickersDay-daySelected {
  @include themify($themes) {
    background-color: themed('primaryBgColor') !important;
  }
}

.past-day,
.past-time {
  color: $black30 !important;
  @include themify($themes) {
    background-color: themed('primarySurfaceColor') !important;
  }
}

.my-calendars-items {
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
  font-weight: 600;

  .my-calendar-event {
    padding: 2px;
  }

  .my-calendar-task {
    padding: 2px;
  }

  .my-calendar-survey {
    padding: 2px;
  }

  .my-calendar-trainings {
    padding: 2px;
  }
}

.myCalendarSelect-icon {
  margin-right: 5px;

  &.icon-event {
    @include themify($themes) {
      color: themed('calEventIconColor') !important;
    }
  }

  &.icon-task {
    @include themify($themes) {
      color: themed('calTaskIconColor') !important;
    }
  }

  &.icon-survey {
    @include themify($themes) {
      color: themed('calSurveyIconColor') !important;
    }
  }

  &.icon-trainings {
    @include themify($themes) {
      color: themed('calTrainingsIconColor') !important;
    }
  }
}

// .rbc-selected {
//   background-color: $lightGray !important;
// }

.rbc-event {
  position: relative;
  padding: 0px !important;
}

.declined {
  background-color: $spanishGray !important;
}

.declined,
.going {
  padding: 2px 5px !important;
  border-radius: 5px !important;
}

.rbc-event-content {
  @include themify($themes) {
    color: themed('calEventTextColor') !important;
  }
}

.event-pill-event {
  @include themify($themes) {
    background-color: themed('calEventBgColor') !important;
    border: 1px solid themed('calPillBorderColor') !important;
  }
}

.event-pill-survey {
  @include themify($themes) {
    background-color: themed('calSurveyBgColor') !important;
    border: 1px solid themed('calPillBorderColor') !important;
  }
}

.event-pill-task {
  @include themify($themes) {
    background-color: themed('calTaskBgColor') !important;
    border: 1px solid themed('calPillBorderColor') !important;
  }
}

.event-pill-task-due {
  @include themify($themes) {
    background-color: themed('calTaskDueBgColor') !important;
    border: 1px solid themed('calPillBorderColor') !important;
  }
}

.event-pill-trainings {
  @include themify($themes) {
    background-color: themed('calTrainingsBgColor') !important;
    border: 1px solid themed('calPillBorderColor') !important;
  }
}

.calendar-pill {
  max-width: 175px;
  display: flex;
  align-items: center;
}

.pill-icon {
  display: flex;
  align-items: center;
  margin-right: 4px;

  .icon {
    display: flex;
    align-items: center;
    height: 14px;
  }

  .MuiSvgIcon-root.icon-event {
    @include themify($themes) {
      color: themed('calEventIconColor') !important;
    }
  }

  .MuiSvgIcon-root.icon-task {
    @include themify($themes) {
      color: themed('calTaskIconColor') !important;
    }
  }

  .MuiSvgIcon-root.icon-survey {
    @include themify($themes) {
      color: themed('calSurveyIconColor') !important;
    }
  }
}

@for $i from 1 through length($cal_colors) {
  .event-pill-container-#{$i} {
    z-index: 2;
    position: relative;
    border-left: 2px solid nth($cal_colors_big, $i) !important;
    background-color: $white !important;
  }
  .EventPill-#{$i} {
    border-left: 2px solid nth($cal_colors, $i) !important;
  }
}

.selected-event {
  border: 1px solid green;
}
