@import '../../../css/index';

#PendingUserTasksWidget {
  width: 100%;
  overflow-y: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .MuiTableBody-root {
    .MuiTableCell-root {
      &:first-child {
        border-left: solid #ffcd6e 3px;
      }
    }
  }

  .MuiCardHeader-root {
    height: 52px !important;
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
  }

  .pending-tasks-widget-card-content {
    height: calc(100% - 52px);
    max-height: calc(100% - 52px);
    padding: 0px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  #PendingUserTasksWidgetTable {
    .temp-table .MuiTableRow-root .MuiTableCell-head {
      font-weight: 700 !important;
    }

    .action-cell {
      padding: 2px 16px !important;

      .MuiButton-outlined {
        @include themify($themes) {
          background-color: themed('buttonOtlBgColor') !important;
          color: themed('buttonOtlTextColor') !important;
        }
      }
    }
  }

  .no-rows {
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }

  .paging-row {
    margin-top: auto;
  }

  .headerRow {
    @include themify($themes) {
      background-color: themed('secondarySurfaceColor') !important;
    }
  }

  .date-column {
    text-align: center;
  }

  .timelineHeader {
    display: inline-block;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 0em;
    color: $bastille;
    font-weight: 700 !important;

    &.details-column {
      padding-left: 1.5em;

      .italicized {
        font-style: italic;
        font-weight: 500 !important;
      }
    }
  }

  .dateContainer {
    position: relative;
    border-right: 1px $gainsboroGray solid;
  }

  .paddedContainer {
    padding: 1em;
  }

  .upcoming-header-container {
    padding: 0 1em;

    .no-tasks-due {
      margin-bottom: 15px;
    }

    .upcoming-header {
      @include themify($themes) {
        background-color: themed('secondarySurfaceColor') !important;
      }
      border-radius: 8px;
      padding: 10px;
      font-weight: 600;
      font-size: 12.5px;
    }
  }
  .over-due-days {
    color: $indianRed;
    font-style: italic;
  }

  .task-item {
    margin: 2.5px 0;
    background-color: $white;
    border-left: 3px solid $topazYellow;
    cursor: pointer;

    &.over-due {
      border-left: 3px solid $indianRed;
    }

    &.none {
      border-left: none;
      cursor: unset;
      background-color: $whiteSmoke;

      .MuiCardContent-root {
        padding: 16px 12px;

        .task-name {
          font-weight: 500;
        }
      }
    }

    &:hover:not(.none) {
      background-color: rgba(0, 0, 0, 0.01);
    }

    .MuiCardContent-root {
      padding: 12px;
    }

    .task-name {
      font-weight: 600;
      font-size: $fs_default;
    }

    .task-desc {
      font-style: italic;
    }

    .discipline-chip {
      background-color: $platinumGray;
      border-radius: 16px;
      padding: 6px 12px;
    }

    .card-actions {
      justify-content: space-between;
      padding: 0 8px 8px;

      .MuiAvatar-root {
        width: 26px;
        height: 26px;
      }
    }

    .action-col {
      text-align: end;
    }
  }

  .circle {
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(50%, -50%);
    height: 12px;
    width: 12px;
    background-color: #97cccb;
    border-radius: 50%;
    // border: 2px solid $darkTurquoise;
    display: inline-block;
  }

  .upcomingMonth {
    color: $bastille;
    font-weight: 700;
    opacity: 0.5;
  }

  .upcomingDay {
    font-weight: 700;
    color: $bastille;
    font-size: $fs_secondary;
    opacity: 0.5;
  }

  .upcomingCircle {
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(50%, -50%);
    height: 12px;
    width: 12px;
    background-color: $gainsboroGray;
    border: 2px solid $gainsboroGray;
    border-radius: 50%;
    display: inline-block;
  }

  .month {
    color: $viridianGreen;
    font-size: 12px;
    font-weight: 700;
  }

  .day {
    color: $viridianGreen;
    font-weight: 700;
    font-size: $fs_secondary;
  }

  .table-row {
    cursor: pointer;
  }

  .widgetAvatar {
    width: 20px;
    height: 20px;
    margin: 0 auto;
  }

  // ag-grid stuff:
  .creator-cell-avatar {
    height: 20px !important;
    width: 20px !important;
  }

  @import '../../../css/ag-grid-tables';
}
