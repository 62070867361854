@import '../../../../css/index';

#modalHeader,
#modalBody,
#masterModalBody {
  background-color: white;
}

#masterModalBody {
  padding: 25px 50px !important;
  .master-event-text {
    margin-top: -20px !important;
    margin-bottom: 20px;
  }
}

#modalHeader {
  width: 100%;
  margin: 0;
  overflow: hidden;

  .MuiGrid-item {
    padding: 0px;

    .MuiDialogTitle-root {
      padding: 10px 15px;
      font-weight: 700;
    }
  }

  .MuiIconButton-root {
    margin-right: 3px;
  }
}

#modalBody {
  padding: 8px;

  .tab-bar {
    border-bottom: 1px solid lightgray;
  }
}

#subscribeTab,
#downloadTab {
  padding: 12px 24px;
}

#downloadTab {
  .download-description {
    margin-bottom: 20px;
  }

  .download-form {
    justify-content: space-around;
    margin: 20px 0;
  }

  .file-type-label {
    margin-left: -8px;
    border-bottom: none;
    margin-bottom: 8px;
    color: rgb(145, 145, 154);
    font-weight: 700;
    font-size: $fs_default;
  }

  .radio {
    padding: 4px;
  }

  .radio-label {
    margin-bottom: 0px;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: rgba(0, 0, 0, 0.54);
  }
  .download-button-container {
    margin-top: 20px;

    .download-button {
      text-align: end;
    }
  }
}

#subscribeTab {
  display: flex;
  flex-direction: column;
  text-align: -webkit-center;

  .row {
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .instructions {
    margin-bottom: 10px;
  }

  .row-input {
    text-align: center;
    padding: 0 40px;
  }

  .copied {
    color: $confirmGreen !important;
  }

  .icon {
    cursor: pointer;
    color: $spanishGray;
  }

  .row-number {
    margin-right: 10px;
    border-radius: 50%;
    padding: 5px 10px;
    border: 1px solid black;
  }
}
