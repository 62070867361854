@import '../../../css/index';

$bgColor: #F1F2F6;

#TemplateStep {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 0 15px 5px;
  background-color: $bgColor  !important;

  .pulse-campaign-build {
    height: 100%;
    width: 100%;
  }

  .pulse-campaign-build-questions-panel {
    flex-grow: 1 !important;
  }

  .pulse-campaign-build-survey-selector {
    height: calc(100vh - 210px) !important;
    flex-grow: 1 !important;
    flex-shrink: 1 !important;

    #EmptyState {
      height: 70%;

      .empty-state-container {
        .empty-state-header {
          font-weight: bold;
          color: #6F8096 !important;
        }

        #EmptyStateText {
          .empty-state-text {
            color: #6F8096 !important;
          }
        }
      }
    }
  }

  .spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px
  }
}