#TrainingItemEditScreen2 {
  height: calc(100% - 64px);
  max-height: calc(100% - 64px);
  overflow: hidden;

  #TabsBar {
    height: calc(100% - 64px);
    max-height: calc(100% - 64px);
  }

  .MuiChip-label {
    max-width: 300px;
  }
}

.TrainingItemFromModal {
  height: calc(100% - 50px) !important;
  max-height: calc(100% - 50px) !important;
  #TabsBar > div {
    height: 100%;
  }

  #BuilderTabPanel {
    height: 100% !important;
  }

  #BuilderDrawer {
    height: calc(100% - 64px - 50px - 46px);
    max-height: calc(100% - 64px - 50px - 46px);
    .drawer-body {
      height: calc(100% - 100px) !important;
    }
  }

  #CompletionTabPanel {
    height: 100%;
    overflow: hidden;
    #Builder {
      height: 100%;
    }
  }
}
