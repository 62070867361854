@import '../../../css/index';

.pulse-positive {
  color: #6aca9a !important;
}

.pulse-neutral {
  color: grey !important;
}

.pulse-negative {
  color: #e85c5c !important;
}

#PulseQuestionOptionRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  width: 100%;

  .drag-handle {
    padding: 0px !important;
    min-width: 0px !important;
    margin: 0 5px 0 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    span svg {
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .follow-up-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 160px;
    .prompt-switch {
      margin-left: 10px;
    }
  }

  .icon-container {
    .delete-icon {
      color: $gunPowder;
    }

    .prompt-icon {
      margin-top: 4px;
      color: $gunPowder;
    }
  }

  .response-type-select {
    max-width: 200px;
    margin-right: 5px;

    .MuiSelect-select {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.prompt-option-row {
  display: flex;
  flex-direction: row;
  margin: 0 0 20px 20px;
  align-items: center;
}
