@import '../../../css/index';

$stepCircleSize: 35px;
$bgColor: #f1f2f6;

#PulseCampaignSteppers {
  background: $bgColor;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 140px 0;
  height: 56px;
  max-height: 56px;

  .left-line {
    position: absolute;
    width: 100%;
    height: 0px;
    border-bottom: 2px dashed #ccccd3;
    z-index: 1;
    margin-right: 20px;
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    position: relative;
    margin-right: 20px;

    .step {
      display: flex;
      align-items: center;
      cursor: pointer;
      background: $bgColor;
      z-index: 2;
      padding: 0 10px;

      .step-left {
        height: $stepCircleSize;
        width: $stepCircleSize;
        // background-color: $lavenderGray;
        // @include themify($themes) {
        //   background: themed('accentColor');
        // }
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2px;

        .MuiSvgIcon-root,
        .fa {
          color: #68686a;
        }

        .check-icon {
          @include themify($themes) {
            color: themed('buttonOtlTextColor');
          }
        }

        .custom-icon {
          height: 32px;
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
          opacity: 0.5;
        }

        .warning-icon {
          font-size: 1.3rem;
          color: #ef666c !important;
        }
      }

      .step-right {
        font-size: 14px;
        color: #afafb5;
        text-transform: uppercase;
      }

      &.published-step {
        cursor: default;
      }

      &.disabled-stepper {
        cursor: default;
      }

      &.visited {
        .MuiSvgIcon-root {
          @include themify($themes) {
            color: themed('buttonOtlTextColor');
          }
        }
        .step-left {
          transition: background 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
      }

      &.active {
        .MuiSvgIcon-root {
          color: #fff !important;
        }
        .step-left {
          @include themify($themes) {
            background-color: #dce1ed;
            transition: background 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          }
          .custom-icon {
            filter: none;
            opacity: 1;
          }
        }

        &.active.invalid {
          .MuiSvgIcon-root {
            color: #ef666c !important;
          }
          .step-left {
            @include themify($themes) {
              background-color: #ffd9e0 !important;
              transition: background 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
              box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
            }
          }
        }

        .step-right {
          font-weight: 700;
          text-decoration: underline;
          color: #333333;
        }
      }

      &.invalid {
        .step-right {
          color: #f44336;
        }
        .step-left {
          background-color: #ffd9e0 !important;
          transition: background 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
      }
    }
  }
}
