@import '../../css/colors';

#OIDCAuthCallback {
  text-align: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(#5dcecd, #5bc5d1 90%, #5cc2d7);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;

  .center-body {
    flex: 0 0 30%;
  }

  .logo {
    max-width: 90%;
    max-height: 90%;
  }

  .error-msg {
    color: white;
  }
}
