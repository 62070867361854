@import '../../css/index';

#NotificationTemplatePreview {
    display: flex;
    height: 100%;
    width: 100%;
    overflow-y: auto !important;

    &.preview-desktop {
        padding: 0;
        margin: 0;
        font: inherit;
        font-size: 100%;
        vertical-align: baseline;
        border: 0;
        width: 100%;
        height: 100%;
    }

    &.preview-mobile {
        border-style: solid;
        border-width: 40px 10px;
        border-radius: 15px;
        border-color: hsla(0, 0%, 62%, .22);
        margin: 10px 60px;
        overflow-y: scroll;
        width: 348px;
        height: 100%;
        min-height: 528px;
        max-height: 578px;
        word-break: break-word;
    }
}