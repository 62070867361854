@import '../../../css/index';

$bgColor: #f1f2f6;
$panelColor: white;

#BuildStep {
  height: 100%;
  width: 100%;
  padding: 0 15px 5px;
  background-color: $bgColor !important;

  .pulse-campaign-build {
    height: 100%;
    width: 100%;
  }

  .pulse-campaign-build-questions-panel {
    flex-grow: 1 !important;
  }

  .pulse-campaign-build-survey-selector {
    height: calc(100vh - 210px) !important;
    flex-grow: 1 !important;
    flex-shrink: 1 !important;
  }

  .pulse-campaign-build-full-panel {
    display: flex;
    position: relative;
    width: 100%;
    height: calc(100vh - 175px);
    background: $panelColor;
    padding: 15px 15px 0 15px;
    border-radius: 10px;
  }

  .surveys-wrapper {
    margin-bottom: 5px;
  }
}
