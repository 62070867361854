@import '../../../../css/index';

.userSurveyPopupCard {
  width: 500px;

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0 9px !important;
  }

  .survey-link {
    padding: 4px 8px;
    font-weight: 700;
    float: right;
    cursor: pointer;
  }

  .user-survey-popup-title-grid,
  .user-survey-popup-created-at-grid,
  .user-survey-popup-questions-grid,
  .user-survey-popup-from-grid {
    margin-bottom: 10px;
  }

  .popup-due {
    font-weight: 600 !important;
    margin-bottom: 10px;
  }

  .maybe-going {
    font-weight: 9rem;
  }

  .icon {
    color: $spanishGray;
  }

  .MuiCardHeader-root {
    padding: 2px;
    background-color: #ede8e8;

    .MuiCardHeader-action {
      margin: 0 0;
    }
  }

  .MuiAvatar-root {
    height: 20px !important;
    width: 20px !important;
  }

  .MuiCardContent-root {
    padding-bottom: 0;
  }

  h5 {
    font-size: $fs_default !important;
  }
}

.participants-container {
  margin-top: 5px;
}

.location-container {
  flex-wrap: nowrap !important;
}

.popup-participant-row {
  display: flex;
  align-items: center;
}

.participant-name {
  margin-left: 5px !important;
}

.calendarEventModal {
  z-index: 100;
}
