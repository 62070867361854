@import '../../../../css/colors';

#SidePanel {
  height: calc(100vh - 64px - 50px - 51px);
  max-height: calc(100% - 64px - 50px - 51px);
  overflow-y: hidden;
  position: absolute;
  right: 0;
  margin-top: 0;
  background: #fff;
  z-index: 1;
  width: 45%;
  min-width: 400px;

  .disabled {
    pointer-events: none;
  }

  .MuiToolbar-root {
    background-color: $solitudeGray !important;
    align-items: center !important;
    padding: 0 16px !important;

    .MuiButton-root {
      min-width: unset !important;
      margin: 5px 0;
    }
  }

  .question-toolbar-media-options {
    margin-right: 0.5rem;
  }

  .media-upload-button {
    margin-right: 10px;
  }

  .content-container {
    height: calc(100vh - 64px - 50px - (2 * 46px));
    max-height: calc(100vh - 64px - 50px - (2 * 46px));
    overflow-y: auto;
    overflow-x: hidden;
  }
}
