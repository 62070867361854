#SurveyTagsWidget {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .MuiTableBody-root {
    .MuiTableCell-root {
      &:first-child {
        border-left: solid #ffcd6e 3px !important;
      }
    }
  }

  .MuiCardContent-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  @import '../../../css/ag-grid-tables.scss';

  .ag-theme-alpine {
    height: calc(100% - 34px - 48px) !important;
  }

  .survey-types-widget-is-default-cell {
    display: flex;
    align-items: center;
  }
}

#SurveyTagsWidget .paging-row {
  margin-top: auto;
  padding: 0 6px;

  button {
    padding: 10px !important;
  }
}
