@import '../../../css/index';

#MuiConfirmDeleteDialog {
  .dialog-title {
    background-color: $antiflashWhite;
    padding: 16px;

    .MuiTypography-root {
      letter-spacing: 0 !important;
      font-size: $fs_secondary !important;
      font-weight: 700 !important;
    }
  }

  .MuiDialogContent-root {
    padding: 12px 18px;

    .warning-icon {
      height: 100%;
      width: 100%;
      color: $indianRed;
    }

    .confirm-message {
      font-size: $fs_secondary;
      padding-top: 16px;
      padding-left: 12px;

      .item-name-text {
        font-weight: 700;
      }

      .cannot-undo-text {
        font-size: 12.5px;
      }
    }
  }

  .MuiDialogActions-root {
    padding: 0 16px 16px;

    .btn-cancel {
      color: $black50 !important;
    }
  }
}
