@import '../../../css/colors';

#UserAudienceTab {
  .audience-paper {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
  }

  .actions-container {
    margin-bottom: 5px;
  }

  .save-message {
    margin-top: 10px;
    margin-left: 5px;
    color: $red;
  }
}

#AudienceTable {
  height: 62vh;
  margin-top: 10px;

  .ag-theme-alpine .ag-row:not(.ag-row-first) {
    // border-top: none;
  }

  .user-cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .avatar {
      height: 20px;
      width: 20px;
    }

    .name {
      margin-left: 5px;
    }
  }
  //   margin-top: 5px;
  //   display: flex;
  //   flex-direction: column;
  //   // border: 1px solid $whiteSmoke !important;
  //   border-radius: 5px !important;

  //   .MuiTableHead-root {
  //     background-color: $ghostWhite !important;

  //     .MuiTableCell-head {
  //       font-weight: 700;
  //     }
  //   }

  //   .audience-row {
  //     display: flex;
  //     justify-content: space-between;
  //     .MuiGrid-root {
  //       display: flex;
  //       align-items: center;
  //     }
  //   }

  //   .remove-button {
  //     float: right;
  //   }

  //   .name-cell {
  //     width: 300px !important;
  //   }
}
