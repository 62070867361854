@import '../../../css/index';
.user-import-list {
    .MuiListItem-root:last-child {
      border-bottom: 0px !important;
    }
  }
  .field-error {
    margin-top: 5px;
    color: $errorRed;
  }

  .input-error {
    color: $errorRed
  }
  
  .errors {
    overflow-y: scroll;
    max-height: 20vh
  }

  .each-input {
      padding: 10px 0 10px 0;
      width: 100%;
  }
  
  .dialog-body {
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
  }
  
  .dialog-actions {
    padding: 0px 18px 12px 18px;
  
    .downloadButton {
      color: #009f9e;
      border-color: #009f9e;
    }
  
    .cancelButton {
      color: #009f9e;
    }
  
    .saveButton {
      color: #ffffff;
      background-color: #05d1cf;
    }
    .saveButton:hover {
      background-color: #05d1cf;
    }
  }
  