
#CampaignAnalysisEmptyState {
  height: 100%;
  overflow: hidden;
  background-color: #F1F2F6;
  .empty-state-container {
    .empty-state-header {
      font-weight: bold;
      color: #6F8096 !important;
    }
    #EmptyStateText {
      .empty-state-text {
        color: #6F8096 !important;
      }
    }
  }

}