@import '../../css/index';

#MediaSelect {
  .MuiDialog-paper {
    padding: 20px;
  }

  .browse-button {
    height: calc(#{$fs_default} * 2.35) !important;
    margin-top: 0;
    margin-right: -14px;
    min-width: 100%;
    border-top-left-radius: inherit;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .MuiDialog-paperWidthSm {
    width: 400px;
  }
}
