@import '../../../css/index';

#WorksheetsSnackbar {
  .snackbarText {
    display: flex;
    font-weight: 500 !important;
  }

  .custom-icon {
    margin-top: 4px;
    margin-right: 10px;
    height: 18px;
  }
}

.snackbar-error {
  .MuiSnackbarContent-root {
    background-color: #f4bfbb !important;
    color: #ef636b;
  }
}

.snackbar-info {
  .MuiSnackbarContent-root {
    background-color: #f4e8bb !important;
    color: black;
  }
}

.snackbar-success {
  .MuiSnackbarContent-root {
    background-color: #cde0e6 !important;
    color: #52b3b6;
  }
}