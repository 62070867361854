@import '../../../css/index';

#PulseSetEditModal {
  .MuiDialog-paperWidthSm {
    min-width: 60vw;
    min-height: 60vh;
  }

  .dialog-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px 10px 40px;
    border-bottom: 1px solid gray;

    .dialog-title {
      font-size: $fs_subheader;
      margin-right: 20px;
    }

    .dialog-left {
      display: flex;
      flex-direction: row;
    }

    .dialog-right {
      .delete-btn {
        color: $errorRed !important;
      }
    }
  }

  .dialog-content {
    padding: 20px 40px 20px 40px;

    .set-name {
      .set-header {
        margin-bottom: 8px;
        font-weight: bold;
      }
      .name-input {
        width: 300px;
        margin-bottom: 30px;
      }
    }

    .set-orgs {
      margin-bottom: 30px;

      .set-header {
        margin-bottom: 8px;
        font-weight: bold;
      }

      .orgs-input {

        .MuiInputBase-root {
          height: auto !important;
          height: fit-content !important;    
        }
  
        .toolbar-input {
          height: fit-content;
          width: 100%;
          background-color: white;
  
          div input {
            border: none !important;
          }
        }

        .org-input-textfield {
          width: 100%;

          div {
            padding: 0px !important;
            padding-right: 5px;
            height: fit-content;
  
            input {
              padding: 0px 0px 0px 10px !important;
              height: fit-content;
            }
          }
        }
    
          .MuiChip-root {
            border-radius: 4px;
            margin-left: 10px;
          }
        
  
      }
    }

    .set-templates {
      .set-header {
        margin-bottom: 16px;
        font-weight: bold;
      }

      .template-add-button {
        width: 50px;
        margin-top: 10px;
        margin-left: 0px !important;
      }

      .template-container {
        padding: 5px 10px;
        border-radius: 4px;
        background-color: #ecedf2;
        margin-bottom: 5px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .template-name {
          font-weight: bold;
          color: #6f8096;
          font-size: $fs_secondary;
        }

        .surveys-container {
          display: flex;
          flex-direction: row;

          .survey-name {
            color: #6f8096;
            font-size: $fs_secondary;
          }
        }
      }

      .pulse-set-template-select {
        display: flex;
        flex-direction: row;

        .templates-input {
          margin-top: 10px;
          width: 100%;

          .toolbar-input {
            height: fit-content;
            width: 100%;
            background-color: white;
    
            div input {
              border: none !important;
            }
          }
  
          .template-input-textfield {
            width: 100%;
  
            div {
              padding: 0px !important;
              padding-right: 5px;
              height: fit-content;
    
              input {
                padding: 0px 0px 0px 10px !important;
                height: fit-content;
              }
            }
  
            .MuiChip-root {
              border-radius: 4px;
              margin-left: 10px;
            }
          
          }
        }

        .pulse-set-template-cancel-button {
          margin-top: 10px;
          color: $errorRed;
        }
  
        .pulse-set-template-save-button {
          margin-top: 10px;
          color: $confirmGreen;
        }
      }
   
    }
  }
}
