@import '../../css/index';

$modalHeight: 75vh;
$toolbarHeight: 46px;
$selectedColor: #d3f7f7;

#SurveyTemplateFoldersPicker {

  .folder-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .folder-spinner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
  }
}