/* TEMPORARY STYLING FOR WIDGETS THAT NEED REFACTORING:
*
*  src/app/system-admin/widgets/OrganizationsListWidget.tsx
*  src/app/system-admin/widgets/SystemAdminsListWidget.tsx
*
**********************************************************/

@import './index';

.temp-widget {
  height: 100%;

  .temp-widget-header {
    min-height: 52px !important;
    padding: 9px 9px 9px 16px !important;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $platinumGray;

    .MuiTypography-root {
      font-size: $fs_secondary !important;
      font-weight: 600 !important;
      color: $black !important;
    }

    .temp-toolbar-right {
      display: flex;

      .MuiIconButton-sizeSmall {
        padding: 5px !important;
        min-width: 34px !important;
        min-height: 34px !important;
      }
    }
  }

  .hide {
    display: none;
  }

  .search {
    width: 100px;
    position: relative;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-left: 16px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.05);

    &:hover {
      background-color: $black10;
    }

    &.search-focused {
      width: 200px;
      transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .search-icon {
      width: 40px;
      height: 100%;
      display: flex;
      position: absolute;
      align-items: center;
      pointer-events: none;
      justify-content: center;
    }

    .MuiInputBase-input {
      width: 100%;
      padding: 8px 8px 8px 40px;
      transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  .MuiTable-root {
    min-width: 350px;
  }

  .page2-container {
    padding: 8px;

    .page2-toolbar {
      display: flex;
      padding-bottom: 8px;
    }

    .temp-org-detail-table {
      margin: 0 8px;
      width: fit-content;
    }
  }
}

.table-wrapper {
  height: fit-content;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.temp-table {
  .MuiTableRow-root {
    height: 32px !important;

    &.MuiTableRow-hover {
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(5, 209, 207, 0.2);
    }

    .MuiTableCell-head {
      font-weight: 700 !important;
      // background-color: $whisper !important;
      @include themify($themes) {
        background-color: themed('secondarySurfaceColor') !important;
      }
    }
  }

  .MuiTableCell-head {
    font-weight: 700 !important;
    background-color: $ghostWhite !important;
  }

  .MuiTableCell-root {
    font-size: inherit;
  }
}

.pagination {
  margin-top: auto;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1px;
}

.temp-paper-root {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}
