#SurveyRowActionsCell {
    .custom-popover {
      padding: 10px 0;
      background: white;
      width: 100px;
    }
  
    .MuiIconButton-root {
      width: 26px;
      height: 26px;
    }
  
    .MuiSvgIcon-root {
      font-size: 18px !important;
    }
  }
  