@import '../../../css/index';

$bgColor: #f1f2f6;

#EmailTemplateCreateDialog {
  .intro-text {
    color: #6c6c7f;
    font-weight: 900;
  }

  .spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    position: absolute;
    z-index: 1002;
  }

  .pulse-campaign-header-title {
    display: flex;
    justify-content: center;
  }

  .pulse-campaign-header-close {
    display: flex;
    justify-content: flex-end;
  }

  .intro-input {
    background-color: white;
    border-radius: 10px;
    padding-left: 10px;
    font-weight: 700;
    color: #6c6c7f;
  }

  .MuiDialog-paperWidthSm {
    overflow: hidden;
    background-color: $bgColor;
  }

  .MuiDialogContent-root {
    overflow-y: hidden;
  }

  .intro-buttons {
    margin: 10px;
    display: flex;
    justify-content: flex-end;

    .MuiButton-contained.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.26) !important;
    }
  }

  .email-info {
    display: flex;
  }
}

#EmailTemplateCreateDialogName {
  width: 100%;

  .container {
    display: flex;
    flex-wrap: wrap;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .formControl {
    // margin: 10px;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0 9px;
  }
}