#QuizResponses {
  border-radius: 20px;
  border: 2px solid #E3E6EF;
  margin-top: 15px;
  overflow: hidden;

  .quiz-responses-title {
    padding: 15px;
    border-bottom: 2px solid #E3E6EF;

    .quiz-responses-title-text {
      font-weight: bold;
      color: #73679D;
      font-size: large;
    }
  }

  .quiz-responses-header {
    padding: 15px 15px 5px 15px;

    .header-text {
      font-weight: bold;
      color: #9696A5
    }

    .your-answer-text {
      margin-left: 20px
    }
  }

  .quiz-responses-container {
    flex-direction: column;
  }
}
