@import '../css/index';

.MuiDialogMessage {
  .okButton {
    font-size: 12px;
    color: #009f9e;
  }
}

.MuiDialogMessageTitle {
  .MuiTypography-root {
    font-size: $fs_default;
  }
}
