#CustomPublishGroupDialog {

    .custom-publish-dialog-content {
      display: flex;
      flex-direction: column;
      overflow-y: hidden;

      .custom-publish-time-picker {
        margin-bottom: 15px;
      }

      .custom-publish-participant-select {
        height: 625px;
        overflow-y: auto;
      }
  
      .publish-at-specific-date-time-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 15px;
  
        > div {
          margin-right: 15px;
        }
  
        .custom-time-picker {
          margin-top: 0 !important;
        }
  
        .time-zone-autocomplete {
          width: 325px;
  
          .MuiFormControl-root {
            width: 100%;
          }
  
          .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
            padding: 0;
          }
  
          .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
            padding: 0px;
            padding-right: 65px;
            padding-left: 10px;
  
            .MuiAutocomplete-input {
              padding-top: 0px !important;
            }
          }
        }
      }
    }
  
  }