@import '../../css/colors';

#PlacesSearchBarV2 {
  // margin-bottom: 17px;

  .autocomplete-container {
    position: absolute;
    z-index: 9999;
    width: 56%;
    margin-top: 2px;
    padding: 8px 0;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    background-color: $white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .suggestion-item {
    padding: 8px;
    margin: 0 10px;
    // margin-left: 12px;
    // padding: 3px;
    font-size: 13px;
    cursor: pointer;
  }

  .suggestion-item--active {
    background: $lightGray;
    border-radius: 5px;
  }

  .search-input-container {
    position: relative;
  }

  input {
    min-width: 300px;
    padding-right: 25px !important;
  }

  .clear-button,
  .clear-button:active,
  .clear-button:focus {
    position: absolute;
    right: 30px;
    top: 91%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    font-weight: 600;
    color: $red;
    cursor: pointer;
    height: 40px;
    width: 20px;
    font-size: 1.25rem;
  }

  .save-button,
  .save-button:active,
  .save-button:focus {
    position: absolute;
    right: 8px;
    top: 91%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    font-weight: 600;
    color: $green;
    cursor: pointer;
    height: 40px;
    width: 20px;
    font-size: 1.25rem;
  }
}
