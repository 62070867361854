@import '../css/index';

#DashboardToolbar {
  .dashboard-appbar {
    margin-left: 0px;
    transition: all $leftNavTransitionDuration cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;

    &.no-transition {
      transition: none !important;
    }

    top: $topNavHeight;
    left: $leftNavClosedWidth;
    width: calc(100% - #{$leftNavClosedWidth});
    background-color: $gunPowder;
    @include themify($themes) {
      background-color: themed('secondaryBgColor');
    }

    .dashboard-toolbar {
      .dashboard-toolbar-right {
        margin-left: auto;

        .dashboard-action {
          margin-left: 10px !important;
        }

        .btn-icon {
          &.btn-icon-white {
            @include themify($themes) {
              background-color: themed('buttonOtlBgColor') !important;
            }
            color: $white !important;

            &:hover {
              background-color: $viridianGreen10 !important;
            }
          }

          .MuiSvgIcon-root {
            margin-right: 10px;
          }
        }

        .edit-memo {
          border-bottom: 1px solid $white90;
          margin-right: 10px;
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
          color: $white90;
          letter-spacing: 2px;
        }
      }
    }

    .dashboard-toolbar {
      display: flex;
      padding: 0 12px 0 16px;

      &.space-between {
        justify-content: space-between;
      }
      .dashboard-title {
        font-size: $fs_header;
        font-weight: 600;
        @include themify($themes) {
          color: themed('dashboardHeaderText') !important;
        }
      }

      .sys-admin {
        width: 12%;
      }

      .tab-btns {
        margin-left: 20px;

        .tab-button {
          cursor: pointer;
          display: inline-block;
          text-transform: uppercase;
          margin-left: 20px;
          margin-right: 4px;
          font-size: $fs_secondary;
          // color: $black50;
          font-weight: 600;
          letter-spacing: 0.1em;
          @include themify($themes) {
            color: themed('dashboardInactiveTab') !important;
          }
        }

        .tab-button.active {
          font-weight: 700;
          @include themify($themes) {
            color: themed('activeTabColor') !important;
            border-bottom-color: themed('activeTabBgColor') !important;
          }
          border-bottom: 2px solid;
        }
      }

      .dashboard-action {
        margin-left: auto !important;
      }
    }
  }
  .btn-action {
    min-width: 150px !important;
  }
}

.drawerOpen {
  .dashboard-appbar {
    left: $leftNavOpenWidth !important;
    // margin-left: $leftNavOpenWidth !important;
    width: calc(100% - #{$leftNavOpenWidth}) !important;
    transition: all $leftNavTransitionDuration cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;

    &.no-transition {
      transition: none !important;
    }
  }
}
