@import '../../css/index';

#FileUploadModal {
  .MuiDialog-paperWidthSm {
    max-width: 650px;
  }

  .attachment-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid $lavenderGray;
    margin-bottom: 5px;

    .attachment-content {
      height: 95%;
      max-height: 95%;

      display: flex;
      align-items: center;
    }

    .file-container {
      border-top-left-radius: 10px;
      background-color: $whiteSmoke;
      border-bottom-left-radius: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 40px;
      height: 100%;
      border-right: 1px solid $lavenderGray;
      margin-right: 5px;
      svg {
        color: $lavenderGray;
      }
    }

    .thumbnail-container {
      width: 40px;
      height: 100%;
      border-right: 1px solid $lavenderGray;
      margin-right: 5px;
      img,
      video {
        height: 40px;
        width: 40px;
        position: relative;
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
        height: 100%;
      }
    }
  }

  .dialog-content {
    .dropzone {
      background-color: #f4f4f7;
      min-height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px dashed #d7d7e2;
      border-radius: 4px;
      padding: 1em;
      cursor: pointer;

      .iconContainer {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;

        .noteAddIcon {
          font-size: 5em;
          color: #05d1cf;
          margin-bottom: 15px;
        }

        .thumbUpIcon {
          font-size: 5em;
          color: #6aca9a;
          margin-bottom: 15px;
        }

        .thumbDownIcon {
          font-size: 5em;
          color: #e85c5c;
          margin-bottom: 15px;
        }
      }

      .dropzoneText {
        color: #91919a;
        margin-bottom: 5px;
      }

      .uploadProgress {
        width: 60px !important;
        height: 60px !important;
        margin-top: 5px;
      }

      .dropLink {
        color: #05d1cf;
        text-decoration: underline #05d1cf;
      }
    }

    .dialogText {
      flex-direction: column;
      margin-top: 7px;
      word-wrap: break-word;

      .file-details {
        margin-top: 10px !important;
      }

      .file-details-title {
        font-weight: 700;
      }

      .MuiDialogContentText-root {
        margin-bottom: 0px;
      }
    }
  }

  .errorContainer {
    padding-left: 22px;
    padding-right: 22px;
  }

  .filesContainer {
    max-height: 300px;
    overflow-y: auto;

    padding-left: 22px;
    padding-right: 22px;
  }

  .errorContainer {
    flex-direction: column;
  }

  .errorDialog {
    padding-left: 22px;
  }

  .errorText {
    color: #e85c5c;
    margin-bottom: 5px;
  }

  .dialog-actions {
    padding: 0px 18px 12px 18px;
    justify-content: flex-end;

    .downloadButton {
      color: #009f9e;
      border-color: #009f9e;
    }

    .cancelButton {
      color: #009f9e;
    }

    .saveButton {
      color: #ffffff;
      background-color: #05d1cf;
    }
    .saveButton:hover {
      background-color: #05d1cf;
    }
  }
}
