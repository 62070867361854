@import '../../../../css/index';

.training-item-card-error {
  border-color: $errorRed !important;
}

#TrainingItemCard {
  background-color: $white;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  border: 1px solid $whiteLilac;
  min-height: 60px;
  border-radius: 4px;
  cursor: grab;

  .title-placeholder {
    color: $spanishGray;
  }

  .close-icon {
    color: $spanishGray;
  }

  .drag-indicator {
    display: flex;
    width: 100%;
    align-items: flex-start;

    svg {
      height: 20px;
    }
  }

  .active {
    background-color: $jordyBlue !important;
    border-right: 1.5px solid $jordyBlue !important;
  }

  .training-item-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

  .training-item-number {
    display: flex;
    flex-direction: row;
    width: 40px;
    background-color: $whiteLilac;
    justify-content: center;
    align-items: center;
  }

  .add-training-item {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5px 0;
    height: 100%;
  }

  .training-item-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 16px 8px 20px;

    .preview {
      display: flex;
      flex-direction: row;
      color: #91919a;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    .block{
      font-weight: 500;
      color:  #727070
    }
    
    .preview-menu {}

    .preview-button {
      margin-left: 5px;
    }
  }
}

.content-items-popup {
  margin-top: 6px;

  .content-popup-menu-item-icon-container {
    width: 15%;
  }

  .content-popup-menu-item-icon {
    margin-right: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $gunPowder;

    .item-icon-container {
      display: flex;
      min-width: 24px;
      margin-left: 5px;
      opacity: 0.7;
    }

    span {
      font-weight: 400 !important;
      text-transform: capitalize;
      font-size: $fs_default !important;
    }

    svg {
      height: 16px;
      width: 16px;
      margin-right: 5px;
    }
  }

  .popup-body {
    width: 100%;
    background-color: $jordyBlue20;
    padding: 18px 24px 18px 24px;
    cursor: pointer;
    max-width: 500px;

    .block-name{
      font-weight: 700;
      font-size: 16px;
      opacity: .9;
        color: $gunPowder;
    }

    .content-popup-menu {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 12px;
      min-width: 200px;
    
      .content-popup-menu-item {
        display: flex;
        flex-direction: column;
        color: $gunPowder;
        padding: 5px 0;

        .item-details {
          display: flex;

          span {
            font-weight: 700 !important;
            font-size: $fs_default !important;
            opacity: 0.9;
          }

          span:first-child {
            margin-right: 6px;
          }
        }
      }

      .content-popup-menu-edit {
        display: flex;
        flex: 1;
        padding: 5px 0;
        justify-content: flex-end;

        .content-popup-menu-edit-icon {
          display: flex;
          color: $viridianGreen;
          align-items: center;
          margin-left: 15px;
        
          .edit-icon-container {
            display: flex;
            min-width: 24px;
            margin-left: 5px;
            opacity: 0.7;

            .MuiIconButton-root {
              padding: 6px !important;
            }
          }
      
          span {
            font-weight: 400 !important;
            text-transform: capitalize;
            font-size: $fs_default !important;
          }
      
          svg {
            height: 24px;
            width: 24px;
          }

          .edit-icon-btn {
            max-height: 26px;
            max-width: 26px;
            color: $viridianGreen !important;
            background-color: transparent !important;
          }
        }
      }
    }
  }
}
