#PrivilegeSetsWidget {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .MuiTableBody-root {
    .MuiTableCell-root {
      &:first-child {
        border-left: solid #ffcd6e 3px !important;
      }
    }
  }

  .eventType-description {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .MuiTableRow-head th {
    cursor: pointer;
    width: 50% !important;
  }

  .MuiTableCell-root {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 120px;
    overflow-x: hidden;
  }

  .privilege-set-name-cell {
    text-transform: capitalize;
  }

  .paging-row {
    margin-top: auto;
    padding: 0 6px;

    button {
      padding: 10px !important;
    }
  }

  .MuiCardContent-root {
    height: calc(100% - 48px);
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  @import '../../css/ag-grid-tables.scss';
}
