@import '../../../css/colors';

#CustomCollectionsListWidget {
  width: 100%;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .MuiTableBody-root {
    .MuiTableCell-root {
      &:first-child {
        border-left: solid #ffcd6e 3px !important;
      }
    }
  }

  .no-rows {
    width: 100%;
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }

  #WidgetHeader {
    color: #000;
    border-bottom: 1px solid #d7d7e2;

    .MuiCardContent-root {
      padding: 0;
    }

    .MuiCardHeader-content {
      align-self: center;
    }

    .MuiCardHeader-action {
      align-self: center;
      align-items: center;
      margin-top: 0;
      margin-right: 0;

      .MuiTypography-root {
        font-size: 1.2rem;
        color: #888;
      }
    }

    .search {
      position: relative;
      float: right;
    }

    .search-icon {
      align-content: center;
      margin-left: 5px;
      height: 100%;
      position: absolute;
    }

    .search-textbox {
      padding-left: 30px;
      float: right;
      transition: width 0.5s;
      width: 100px;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 5px;
    }

    .search-textbox:focus-within {
      transition: width 0.5s;
      width: 200px;
    }
  }

  .paging-row {
    margin-top: auto;
  }

  @import '../../../css/ag-grid-tables.scss';

  .ag-theme-alpine {
    height: calc(100% - 34px - 48px) !important;
  }
}
