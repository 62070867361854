@import '../../../css/index';

#ContentPickerDialogHeader {

    .dialog-header-grid {
        padding: 5px;
    }

    .dialog-header-title {
        font-size: $fs_subheader;
        color: #ABB0B8;
        font-weight: bolder;
    }

    .dialog-header-close {
        margin-right: 10px;
        padding: 5px;
        color: #ABB0B8;
    }

    .dialog-header-delete {
        margin-right: 15px;
        padding: 5px;
        color: $indianRed;
    }
}