@import './index';

#app {
  // height: calc(100% - 131px);
  height: 100% !important;
  z-index: 0;
}

// body {
//   @include themify($themes) {
//     background-color: themed('backgroundColor') !important;
//   }
// }

body,
html {
  height: 100% !important;
  font-size: $fs_default !important;
  font-family: 'Nunito', 'sans-serif' !important;

  @include xl-desktop-up {
    font-size: $fs_default !important;
  }
}

html {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  position: relative;
  background-color: white;
}

h6 {
  font-size: $fs_subheader !important;
  font-weight: 700 !important;
  margin-top: 0 !important;
  margin-bottom: 10px;
}

.MuiTypography-caption {
  font-size: $fs_secondary !important;
}

section {
  margin-bottom: 26px;

  .section-indent {
    width: 90%;
    align-self: center;
    display: flex;
    margin-left: 10px;
  }

  .left-col {
    flex: 0 0 40%;
  }

  .right-col {
    flex: 0 0 60%;
    word-break: break-word;

    p:not(:last-child) {
      margin-bottom: 0.2em;
    }
  }
}
