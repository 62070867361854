#TrainerSelectModal,
#TraineeSelectModal {
  height: 60vh;
  width: 40vw;
  min-height: 500px;
  min-width: 400px;
  overflow: hidden;
  margin: 12.5vh auto;
  padding: 5px;
  border-radius: 10px;

  .MuiDialogContainer,
  .MuiDialog-paper {
    height: 100%;
    width: 100% !important;
  }

  .MuiDialogTitle-root {
    width: 100% !important;

    padding: 0px;
  }

  .MuiDialogContent-root {
    width: 100% !important;

    padding: 0px !important;
  }
  .modal-title {
    padding: 0 16px;
    font-weight: 700;
    letter-spacing: .025px;
  }
}
