#TreeContainer {
  height: calc(96% - 16px);
}

#CategoriesWidget {
  #CategoriesTree {
    overflow-y: auto;

    .MuiTreeItem-content {
      height: 30px;

      .MuiTreeItem-label {
        display: flex;
        flex: 1;

        svg {
          color: green;
          margin-right: 12px;
          margin-left: auto;
        }
      }
    }
  }

  .card-content {
    height: calc(96% - 52px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .no-rows {
    width: 100%;
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }
}
