@import '../../css/index';

#ScreenHeader {
  @include themify($themes) {
    background-color: themed('secondaryBgColor') !important;
    color: themed('dashboardHeaderText') !important;
  }
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  justify-items: center;

  .MuiToolbar-regular {
    padding-left: 15px;
    padding-right: 15px;
  }

  .survey-and-link {
    display: flex;
    align-items: center;
    .survey-text {
      margin-bottom: 4px;
    }
  }

  .task-link {
    margin-left: 20px;
    color: $airforceBlue;
  }

  .MuiLinearProgress-root {
    height: 20px;
    margin-top: 6px;
    border-radius: 10px;
    @include themify($themes) {
      border: 1px solid themed('accentColor') !important;
    }

    .MuiLinearProgress-bar {
      @include themify($themes) {
        background-color: themed('accentColor') !important;
      }
    }
  }

  .survey-text {
    font-size: 22px;

    @include tablet-landscape-down {
      display: none;
    }
  }

  .right-buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      margin-left: 15px;
      border: 1px solid $light50;
      border-radius: 4px;
      padding: 8px;
      cursor: pointer;
    }

    div.add-widget-btn {
      background-color: $accent;
      border: none;
    }
  }
}
