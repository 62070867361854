@import '../../../css/colors';

#OrganizationsSAListWidget {
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  .MuiCardContent-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

#Header {
  color: #000;
  border-bottom: 1px solid #d7d7e2;
  padding: 9px 12px 9px 16px !important;

  .MuiCardHeader-content {
    align-self: center;
  }

  .MuiCardHeader-action {
    align-items: center;
    vertical-align: middle;
    margin-top: 0;
    margin-right: 0;

    .MuiTypography-root {
      font-size: 1.2rem;
      color: #888;
    }

    div:not(:last-child) {
      margin-right: 6px;
    }
  }
}

#Content {
  display: flex;
  flex-direction: column;
  flex: 1;

  .no-rows {
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }

  #OrgsTable {
    height: 100%;
    display: flex;
    flex-direction: column;

    .paging-row {
      margin-top: auto;
      padding: 0 6px;
      width: 100%;
      button {
        padding: 10px !important;
      }
    }

    #WidgetTable {
      flex: 1;

      th {
        font-weight: 700;
        background-color: $whisper;
      }

      tr {
        cursor: pointer;
      }

      .MuiTableCell-root {
        font-size: inherit;
      }

      .MuiTableBody-root {
        .MuiTableCell-root {
          &:first-child {
            border-left: solid #ffcd6e 3px;
          }
        }
      }
      .delete-button {
        height: 30px !important;
      }

      // .phone-cell {
      //   min-width: 130px;
      //   width: 130px;
      //   max-width: 130px;
      //   text-transform: lowercase;
      // }

      // .name-cell {
      //   min-width: 130px;
      //   width: 130px;
      //   max-width: 130px;
      //   text-transform: capitalize;
      // }

      // .email-cell {
      //   min-width: 150px;
      //   width: 120px;
      //   max-width: 120px;
      //   text-transform: lowercase;
      // }

      // .zone-cell {
      //   min-width: 120px;
      //   width: 120px;
      //   max-width: 120px;
      //   text-transform: capitalize;
      // }

      .username {
        margin-left: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
      }

      .avatar-name {
        flex-wrap: nowrap;
      }
    }
  }
}
