@import '../../../css/index';

$bgColor: white;
$bgDarkColor: rgba(128, 128, 128, 0.3);
$fgColor: #6f8096;

#PulseSetRow {

  .icon-container {
    padding: 0px;
    margin: 0px 10px 0px 10px;
    background-color: $bgDarkColor !important;

    .expand-icon {
      color: #6b6985;
      height: 18px;
      width: 18px;
    }
  }

  .pulse-set-row {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 5px;
    justify-content: space-between;
    height: 40px;
    max-height: 40px;
    cursor: pointer;

    .pulse-set-title {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .pulse-set-name {
      color: $fgColor;
      font-size: $fs_default;
      font-weight: bolder;
    }
  }
}