@import '../../css/index';

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  justify-content: center;
  align-items: center;
  background-color: $blackRock;
  padding: 40px;

  img {
    max-width: 25%;
    min-width: 300px;
    width: 25%;
    margin-bottom: 20px;
  }

  .text {
    color: $white !important;
    text-align: center;
    letter-spacing: 1px;

    &.header {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 4px;
    }

    &.body {
      margin: 4px 0 20px;
      font-size: 17px;
      font-weight: 400;
    }
  }

  pre {
    height: auto;
    max-height: 200px;
    overflow: auto;
    background-color: #eeeeee;
    word-break: normal !important;
    word-wrap: normal !important;
    white-space: pre !important;
  }
}
