@import '../../../css/index';

$fgColor: #6F8096;

#PulseCampaignQuestion {

    .MuiPaper-root {
        margin: 5px;
        padding: 5px 5px 5px 10px;
        background-color: white !important;
        border: 1px solid #e8e8e8;
        border-radius: 4px;
    }

    cursor: pointer;

    .MuiCardHeader-root {
        padding: 0 15px 0 0 !important;
    }

    .MuiCardHeader-action {
        margin-top: 0 !important;
    }

    .question-card {
    
        &.border-cat-1 {
            border-left: 6px solid #A5AACA;
            color: $fgColor !important;
        }
    
        &.border-cat-2 {
            border-left: 6px solid #D7ABA0;
            color: $fgColor !important;
        }
    
        &.border-cat-3 {
            border-left: 6px solid #99CAD1;
            color: $fgColor !important;
        }

        &.border-cat-4 {
            border-left: 6px solid #4dabf5;
            color: $fgColor !important;
          }

          &.border-cat-5 {
            border-left: 6px solid #ffc107;
            color: $fgColor !important;
          }

        &.border-cat-6 {
            border-left: 6px solid #dd33fa;
            color: $fgColor !important;
        }

        &.border-cat-7 {
            border-left: 6px solid #d1ff33;
            color: $fgColor !important;
        }

        &.border-cat-8 {
            border-left: 6px solid #4aedc4;
            color: $fgColor !important;
        }

        &.border-cat-9 {
            border-left: 6px solid #3d5afe;
            color: $fgColor !important;
        }

        &.border-cat-10 {
            border-left: 6px solid #834bff;
            color: $fgColor !important;
        }
    }

    .question-type {
        margin: 0;
        color: $fgColor;
        font-size: $fs_secondary;
    }

    .question-title {
        margin-left: 0;
        font-weight: bold;
        color: $fgColor;
        font-size: $fs_secondary;
    }
}
#PulseChips {
    .MuiTooltip-tooltip {
        border: 1px solid lightgray;
        background-color: white !important;
    }
}