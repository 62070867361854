@import '../../css/index';

#EmptyState,
  .empty-state-container {
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: center;
  }

  .empty-state-avatar {
    margin: 0 5px 5px 5px;
    align-self: center;
    justify-content: center;
  }

  .empty-state-img {
    width: 10vw;
    height: auto;
    max-width: 140px;
    max-height: 140px;
    min-width: 60px;
    min-height: 60px;
    align-self: center;
    justify-content: center;
  }

  .empty-state-header {
    margin: 0 5px 5px 5px;
    font-size: 20px !important;
    font-weight: 700;
    line-height: 1.5;
    align-self: center;
    justify-content: center;

    @include themify($themes) {
      color: themed('dashboardHeaderText') !important;
    }
  }

#EmptyStateText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .empty-state-text {
        margin: 0 5px 0 5px;
        font-size: 16px !important;
        font-weight: 400;
        line-height: 1.5;
        align-self: center;
        justify-content: center;

        @include themify($themes) {
        color: themed('dashboardHeaderText') !important;
        }
    }

    .empty-state-action-btn {
        margin-top: 15px;

        @include themify($themes) {
        // background-color: themed('buttonOtlBgColor') !important;
        color: white !important;
        }
    }
  }