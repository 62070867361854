@import '../../../css/index';
#UserSelectModal {   

    .avatar-container {
        align-items: center;
        display: flex;
        height: 100%;
    }

    .audience-container {
        padding: 10px 0;
        border-radius: 4px;
        cursor: pointer;
        background-color: $white;

        .name-container {
        display: flex;
        align-items: center;
        }
    }

    .audience-container-disabled {
      padding: 10px 0;
      border-radius: 4px;
      background-color: transparent;

      .name-container {
      display: flex;
      align-items: center;
      }
  }

    .add-icon-container {
        display: flex;
        background-color: $whiteLilac;
        border-radius: 50px;
        height: 40px;
        width: 40px;
        border-collapse: separate;
        justify-content: space-around;
        align-items: center;
    }

    .add-participant-text {
        margin: 10px 0 10px 5px !important;
        color: $spanishGray;
        font-size: 15px !important;
        font-weight: 500 !important;

        &.filled {
        color: $nightRider !important;
        }
    } 
}

#UserSelectModalDialog {
    height: 60vh;
    min-height: 500px;
    min-width: 400px;
    overflow: hidden;
    margin: 12.5vh auto;
    padding: 5px;
    border-radius: 10px;
  
    .MuiDialogContainer,
    .MuiDialog-paper {
      height: 100%;
      width: 100% !important;
    }
  
    .MuiDialogTitle-root {
      width: 100% !important;
  
      padding: 0px;
    }
  
    .MuiDialogContent-root {
      width: 100% !important;
  
      padding: 0px !important;
    }
    .modal-title {
      padding: 0 16px;
      font-weight: 700;
      letter-spacing: .025px;
    }
}