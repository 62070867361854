@import '../../../css/index';

#PulseCampaignDetailsColRight {
  .details-grid {
    margin: 0;
  }

  .details-grid-header {
    width: 100%;
    margin: 5px 0 5px 0;
  }

  .details-grid-field-wrapper {
    margin: 0;
    height: 100%;
  }

  .details-grid-header-title {
    margin-bottom: 10px;

    @include themify($themes) {
      color: themed('calEventTextColor') !important;
    }
    font-size: $fs_default;
    font-weight: bold;
  }

  .campaign-status {
    font-style: italic;
    margin-bottom: 15px;
  }

  .details-grid-toggle-group {
    margin-bottom: 10px;
  }

  .details-grid-toggle-btn {
    padding: 5px 18px !important;
    color: black;
  }
}
