@import '../../css/index';

#DashboardEditContainer {
  height: calc(100vh - #{$topNavHeight});
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;

  &.edit-mode {
    background-color: $black40 !important;
    height: calc(100vh - #{$topNavHeight + 64}) !important;

    .MuiCardHeader-root {
      cursor: grab !important;
      &:active:hover {
        cursor: grabbing !important;
      }
    }

    #WidgetContainer {
      .MuiCardHeader-action {
        display: none;
      }
    }
  }

  .progress {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: inherit;
    border: none;
  }

  .react-grid-layout {
    .widget-container {
      border-radius: 4px;
      background-color: transparent;
      overflow: auto;
    }

    .react-grid-placeholder {
      background-color: $light;
      border-radius: 4px;
    }
  }

  .no-transition {
    transition: none !important;
  }
}
