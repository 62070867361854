@import '../../../css/index';

#PulseCampaignsDashboard {
  height: calc(100vh - 64px);
  width: calc(100vw - 56px);

  .pulse-campaigns-dashboard {
    height: 100%;
    background-color: #e1e4ef;

    .campaigns-dashboard-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 15px 15px 0;

      .dashboard-title {
        font-size: $fs_header;
        font-weight: 600;
        color: #6f8096;
      }

      .header-buttons {
        display: flex;
        flex-direction: row;
        
        .empty-state-action-btn {
          margin-left: 10px
        }
      }

      .template-buttons-right {
        display: flex;
        flex-direction: row;
        align-items: center;

        .row-layout-button {
          margin-right: 20px;
          background-color: #c0cae2;
          border-radius: 5px;
          cursor: pointer;

          &.selected {
            box-shadow: inset rgba(2, 2, 2, 0.4) 0px 2px 10px 0px;
          }
          
          .row-icon {
            color: #6b6985;
            margin: 7px;
            vertical-align: middle;
          }
        }

        .tile-layout-button {
          margin-right: 20px;
          background-color: #c0cae2;
          border-radius: 5px;
          cursor: pointer;

          &.selected {
            box-shadow: inset rgba(2, 2, 2, 0.4) 0px 2px 10px 0px;
          }

          .dash-icon {
            color: #6b6985;
            margin: 7px;
            vertical-align: middle;
          }
        }
      }
    }

    .pulse-campaigns-dashboard-content {
      padding: 20px;
      display: flex;
      flex-direction: row;
      height: calc(100vh - 128px);

      .dashboard-left {
        height: 100%;
        width: 75%;
      }

      .dashboard-right {
        height: 100%;
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}
