@import '../../../../css/colors';
@import '../../../../css/index';

#SurveyTemplatesSelectHeader {
  color: #000;
  padding: 20px !important;
  height: 50px !important;  

  .manage-templates-container{
    display:flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center;
    padding-bottom: 5px;
    
    .left{
      width: 25%;
      display:flex;
      align-items: center;
      flex-direction: row;

      .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
        padding: 0;
        padding-left: 10px;
        padding-right: 40px;
        min-width: 250px;
        margin-bottom: 10px;
      }
  
    }
   
    .right{
      width: 75%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .right-left {
        display:flex;
        align-items: center;
      }

      .right-right {
        display:flex;
        align-items: center;
      }
     
    }
    
  }

  .manage-users-button{
    @include themify($themes) {
      background-color: themed('buttonBgColor') !important;
    }
    min-height: 40px !important;
    min-width: 40px !important;
  }

  .manage-users-button-disabled{
    background-color: #cccbce !important;
    min-height: 40px !important;
    min-width: 40px !important;
  }

  .manage-users-title{
    display:flex;
  }

  .title-text{
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #000000 !important;
  }

  .selected-user-count{
    margin-left: 6px;
    font-size: 14px !important;
    display: flex;
    align-items: center;
  }

  .MuiTypography-root {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #000000 !important;
  }

  .MuiCardHeader-content {
    align-self: center;
  }

  .MuiCardHeader-action {
    align-self: center;
    vertical-align: middle;
    margin-top: 0;
    margin-right: 0;

    .MuiTypography-root {
      font-size: 1.2rem;
      color: #888;
    }
  }

  .MuiIconButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.18) !important;
    background-color: #EBEBEB !important;
  }

  .widget-cta {
    border-radius: 64% !important;
    background-color: rgba(0, 159, 158, 0.6) !important;
    color: #ffffff !important;
  }

  .widget-cta:hover{
    background-color: rgb(0, 159, 158) !important;
  }

  .search-icon {
    align-content: center;
    margin-left: 5px;
    height: 100%;
    position: absolute;
  }

  .search-textbox {
    padding-left: 30px;
    float: right;
    transition: width 0.5s;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }

  .search-textbox:focus-within {
    transition: width 0.5s;
    width: 200px;
  }

  .toggle-action-btn {
    @include themify($themes) {
      // border: 2px solid themed('buttonBorderColor') !important;
    }
    border-radius: 5px;
    margin-right: 10px;
    min-height: 40px !important;
    min-width: 40px !important;
    display:flex;
    justify-content: center;
    align-content: center;
  }

  .toggle-btn {
    height: 40px !important;
    @include themify($themes) {
      border: 2px solid themed('buttonBorderColor') !important;
    }
  }

  .Mui-selected {
    background-color: #009f9e !important;
  }

  .import-action-btn{
    @include themify($themes) {
      border: 2px solid themed('buttonBorderColor') !important;
    }
    border-radius: 5px;
    margin-right: 10px;
    min-height: 40px !important;
    min-width: 40px !important;
    display:flex;
    justify-content: center;
    align-content: center;

    &:hover {  
      background-color: rgba(0, 159, 158, 0.1) !important;
    }

    .MuiIconButton-root{
      padding: 7px;

      &:hover {  
        background-color: unset;
      }
    }

    .import-icon{
      color:#777481 !important;
      height: 16px;
  }
}

  .action-btn{
    @include themify($themes) {
      border: 2px solid themed('buttonBorderColor') !important;
    }
    border-radius: 5px;
    margin-right: 10px;
    min-height: 40px !important;
    min-width: 40px !important;
  }

  .action-btn-end{
    @include themify($themes) {
      border: 2px solid themed('buttonBorderColor') !important;
    }
    border-radius: 5px;
    min-height: 40px !important;
    min-width: 40px !important;
  }

  .MuiButton-root:not(:first-child):not(.MuiButtonGroup-grouped) {
    margin-left: 0px !important;
  }

  .column-btn{
    @include themify($themes) {
      color: themed('buttonOtlTextColor') !important;
    }
  }

  .refresh-btn{
    @include themify($themes) {
      color: themed('buttonOtlTextColor') !important;
    }
   }

  .action-btn-add{
    margin-right: 10px;
    min-height: 40px !important;
    min-width: 40px !important;
  }

  .arrow-icon{
    color:#777481 !important;
    margin-left: 40px;
  }

  .menu-item-arrow-icon{
    width: 100%;
  }

  .table-edit-cancel{
    @include themify($themes) {
      color: themed('buttonOtlTextColor') !important;
    }
  }

  .table-edit-save{
    @include themify($themes) {
      background-color: themed('buttonBgColor') !important;
    }
    margin-right: 10px;
    min-height: 38px !important;
    min-width: 38px !important;
  }
  
}

.refresh-users-dw-item{
.refresh-users-dw-icon{
  margin-right: 10px;
  margin-left:8px;
  color:  rgb(0, 159, 158);
}}

.more-menu{
  filter:drop-shadow(3px 4px 10px rgb(126, 126, 126));
}


