@import '../../css/colors';

// .add-new-menu {
//   // border: 2px solid red;
//   .MuiList-padding {
//     padding-top: 0;
//   }

//   .MuiListSubheader-root {
//     font-size: 11px;
//     text-transform: uppercase;
//     font-weight: 700;
//     background-color: $antiflashWhite;
//     color: $spanishGray;
//   }
// }

#ManageGroupsAndRolesWidget {
  width: 100%;
  // overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .MuiCardContent-root {
    height: calc(100% - 48px);
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  @import '../../css/ag-grid-tables.scss';
}
