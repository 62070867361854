@import '../../../css/index';

.MuiDialog-paperFullScreen {
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: 100%;
    max-height: none;
    border-radius: 0;
    overflow-y: hidden !important;
}

.email-template-edit-dialog-grid {
    display: flex;
    height: 100%;
    width: 100% !important;

    .spinner-container {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        position: absolute;
        z-index: 1002;
    }
}

.email-template-edit-dialog-editor {
    display: flex;
    height: 100%;
    width: 100%;
}

.email-template-edit-dialog-preview {
    display: flex;
    height: 100%;
    width: 100%;
}

#EmailTemplateEditDialogAppBar {
    .MuiAppBar-colorPrimary {
        @include themify($themes) {
            background-color: themed('primaryBgColor');
        }
    }

    .strong {
        background-color: rgb(192, 0, 0) !important;
    }
}