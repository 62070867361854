@import '../../css/index';

$modalHeight: 75vh;
$toolbarHeight: 46px;
$selectedColor: #d3f7f7;

#SurveyTemplateFolderEdit {

  .template-folder-edit {
    display: flex;
    flex-direction: row;
    align-items: center;

    .template-folder-name {
      width: 300px;
      margin-right: 5px;
    }

    .template-folder-cancel-button {
      color: $errorRed;
    }

    .template-folder-save-button {
      color: $confirmGreen;
    }
  }
}