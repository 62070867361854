@import '../../../../css/index';

.userEventPopupCard {
  width: 500px;

  .popup-participants {
    height: 110px
  }

  .event-attachment {
    margin: 5px 0;
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    border: 1px solid $lavenderGray;
    padding: 2.5px 10px;
    padding-left: 0px !important;
    border-radius: 5px;

    .event-attachment-thumbnail {
      display: flex;
      align-items: center;
      height: 20px !important;
      img,
      video {
        width: 20px;
      }
    }

    .event-img-container {
      margin-left: -1px;
      height: 100%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 20px;
      margin-right: 2.5px;

      img,
      video {
        height: 20px;
        width: 20px;
        position: relative;
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
        height: 100%;
        object-fit: cover;
      }
    }

    svg {
      color: $spanishGray;
    }

    img,
    video {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }
  }

  div.placeholder-icon {
    display: inline-block;
    margin-right: 3px;

    svg {
      font-size: 0.9rem;
      color: teal;
    }
  }

  .MuiCardHeader-action {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;

    .owner-icons {
      display: flex;
    }
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0 9px !important;
  }

  .title-item {
    padding: 0 8px !important;
  }
  
  .notes-body {
    white-space: pre-line;
  }

  .MuiCardHeader-root {
    padding: 2px;
    background-color: #ede8e8;

    .MuiCardHeader-action {
      margin: 0 0;
    }
  }

  .MuiAvatar-root {
    height: 20px !important;
    width: 20px !important;
  }

  h5 {
    font-size: $fs_default !important;
  }
}

.participants-container {
  margin-top: 10px;
  padding: 2px 0;
  display: flex;
  flex-wrap: wrap;
  height: 60px;
  overflow-y: auto;

  .popup-participant {
    height: 26px;
    margin: 1px 3px;

    .participant-avatar > div {
      cursor: default !important;
    }
  }
}

.location-container {
  flex-wrap: nowrap !important;
}

.popup-participant-row {
  margin: 3px;
  display: flex;
  align-items: center;

  .going-yes-badge {
    background-color: $confirmGreen;
    color: white;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    .badge-icon {
      width: 10px;
      height: 10px;
    }
  }

  .going-maybe-badge {
    background-color: $tuscanYellow;
    color: white;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    .badge-icon {
      width: 10px;
      height: 10px;
    }
  }

  .going-no-badge {
    background-color: $errorRed;
    color: white;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    .badge-icon {
      width: 10px;
      height: 10px;
    }
  }

  .going-new-badge {
    background-color: $spanishGray;
    color: white;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    .badge-icon {
      width: 10px;
      height: 10px;
    }
  }

  .readStatus {
    width: 5px;
    height: 5px;
    background-color: $errorRed;
    border-radius: 50%;
  }
}

.participant-name {
  margin-left: 5px !important;
}

.calendarEventModal {
  z-index: 100;
}

.popup-date {
  font-weight: 600 !important;
}

.going {
  font-weight: bold !important;
}

.maybe-going {
  font-weight: 9rem;
}

.icon {
  color: $spanishGray;
}

.going-container {
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 4px;

  .MuiButton-root:not(:first-child):not(.MuiButtonGroup-grouped) {
    margin-left: 0px !important;
  }
}

.button-active {
  background-color: rgba(0, 159, 158, 0.1) !important;
}

.going-button {
  min-width: 0px !important;
  height: 30px !important;
  margin: 0 2px !important;
}

.MuiExpansionPanel-root.Mui-expanded {
  margin-top: 0px !important;
}

.MuiExpansionPanelSummary-root {
  min-height: 0px !important;
  height: 24px !important;
  padding: 0 12px !important;
}

.MuiExpansionPanelDetails-root {
  justify-content: space-around;
  padding: 0 24px 6px !important;
}
