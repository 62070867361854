@import '../../../css/index';

$bgColor: white;
$bgDarkColor: rgba(128, 128, 128, 0.3);
$fgColor: #6F8096;
$borderColor: #F2F3F6;

#PulseCampaignCategoriesTree {
  padding: 0 0 0 20px;
  width: calc(100vw - 210) !important;

  .grand-container {
    width: 98%;
    border-radius: 4px;
    padding: 0 10px 5px 10px;

    .grand-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;

      border-radius: 4px;
      padding: 5px 10px 5px 0;
    }

    .grand-header {
      color: $fgColor;
      font-size: $fs_subheader !important;
    }
  }

  .icon-container {
    padding: 0px;
    margin: 0px 10px 0px 10px;
    background-color: $bgDarkColor !important;

    .expand-icon {
      color: #6B6985;
      height: 18px;
      width: 18px;
    }
  }

  .parent-container {
    background-color: #FFFFFF;
    border-radius: 4px;
    margin-bottom: 8px;

    &.border-cat-1 {
      border-left: 6px solid #A5AACA;
      color: $fgColor !important;
    }

    &.border-cat-2 {
      border-left: 6px solid #D7ABA0;
      color: $fgColor !important;
    }

    &.border-cat-3 {
      border-left: 6px solid #99CAD1;
      color: $fgColor !important;
    }

    &.border-cat-4 {
      border-left: 6px solid #4dabf5;
      color: $fgColor !important;
    }

    &.border-cat-5 {
      border-left: 6px solid #ffc107;
      color: $fgColor !important;
    }

    &.border-cat-6 {
      border-left: 6px solid #dd33fa;
      color: $fgColor !important;
    }

    &.border-cat-7 {
      border-left: 6px solid #d1ff33;
      color: $fgColor !important;
    }

    &.border-cat-8 {
      border-left: 6px solid #4aedc4;
      color: $fgColor !important;
    }

    &.border-cat-9 {
      border-left: 6px solid #3d5afe;
      color: $fgColor !important;
    }

    &.border-cat-10 {
      border-left: 6px solid #834bff;
      color: $fgColor !important;
    }
  }

  .parent-card-build {
    // display: none !important;
    flex-direction: row;
    background-color: $bgColor !important;
    padding: 5px;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 5px;
    justify-content: space-between;
    cursor: pointer;
  }

  .parent-card-focus {
    display: flex;
    flex-direction: row;
    background-color: $bgColor !important;
    padding: 5px;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 5px;
    justify-content: space-between;
    border-radius: 4px;
    padding: 10px 10px 5px 10px;
    height: 50px;
    max-height: 50px;
    cursor: pointer;

    .parent-category-header-element-selection-btn {
      display: none;
    }

    &:hover .parent-category-header-element-selection-btn {
      display: flex;
      margin: 0 15px 0 0;
      align-items: center;
      justify-content: center;
      max-height: 40px;
      width: 130px;
    }

    &:hover {
      border-top: 0.25px $borderColor solid !important;
      border-bottom: 0.25px $borderColor solid !important;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 8px 0 rgba(0, 0, 0, 0.1);
    }
  }

  #ParentCardTitle {
    display: flex;
    flex-direction: row;
    align-items: center;

    .parent-header {
      color: $fgColor;
      font-size: $fs_secondary;
      font-weight: bolder;
    }
  }

  .spinner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px
  }

  .no-child-container {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    padding: 5px 5px 10px 5px;
    @include themify($themes) {
      color: themed('calEventTextColor') !important;
    }
  }

  #ChildCardTitleBuild {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 34px;
    width: 100%;
    justify-content: space-between;

    .child-left {
      display: flex;
      flex-direction: row;
    }

    .child-header {
      color: $fgColor;
      font-size: $fs_secondary;
    }
  }

  #ChildCardTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 34px;
    width: 100%;
    padding-left: 40px;

    .child-header {
      color: $fgColor;
      font-size: $fs_secondary;
    }
  }

  .child-container-build {

    .child-sub-container {
      background-color: $bgColor !important;
      padding: 0 0 0 5px;
      border-radius: 4px;
      margin-bottom: 5px;

      .child-card {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }

      .questions-container {
        padding-left: 40px;
        width: 100%;
      }
    }
  }

  .child-container-focus {

    &.expanded {
      background-color: #ECEDF2;
      border-top: unset;
      border-bottom: unset;
      box-shadow: none;
    }

    &:hover {
      border-top: 0.25px $borderColor solid !important;
      border-bottom: 0.25px $borderColor solid !important;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 8px 0 rgba(0, 0, 0, 0.1);
    }

    .child-sub-container {
      padding: 5px;
      border-radius: 10px;
      margin-bottom: 5px;

      .child-category-header-element-selection-btn {
        display: none;
      }
  
      &:hover .child-category-header-element-selection-btn {
        display: flex;
        margin: 0 15px 0 0;
        align-items: center;
        justify-content: center;
        max-height: 32px;
        width: 140px;
      }

      .child-card {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }

      .questions-container {
        padding-left: 80px;
        width: 90%;
      }
    }
  }
}
