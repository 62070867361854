@import '../../../css/index';

$bgColor: white;
$fgColor: #6f8096;

#PulseCampaignQuestionsPanel {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 235px);
  background: $bgColor;
  padding: 15px 15px 60px 15px;
  border-radius: 10px;
  overflow-y: auto;

  .back-to-questions-btn {
    margin-right: 10px;
    white-space: nowrap;

    .MuiSvgIcon-root {
      margin-right: 4px;
    }
  }

  .pulse-campaign-questions-grid {
    margin: 0;
    padding-bottom: 200px;
  }

  .pulse-campaign-questions-grid-header {
    width: 100%;
    margin: 5px 0 5px 0;
  }

  .pulse-campaign-questions-grid-header-title {
    margin-bottom: 10px;

    @include themify($themes) {
      color: themed('calEventTextColor') !important;
    }
    font-size: $fs_default;
    font-weight: bold;
  }

  .pulse-campaign-questions-grid-header-grp {
    margin: 0;
    height: 100%;
  }

  .pulse-campaign-questions-grid-header-questions-selected-number {
    font-weight: bold;
    margin: 0 10px 0 0;
    font-size: 26px;
  }

  .pulse-campaign-questions-grid-header-questions-selected-text {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-weight: 400;
    font-size: 14px !important;
    justify-content: center !important;
  }

  .pulse-campaign-questions-grid-questions {
    margin: 5px 0 5px 0;
    width: 100%;
  }

  .pulse-campaign-questions-grid-questions-list {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  .pulse-campaign-questions-grid-questions-list-grp {
    margin: 0;
    width: 100%;
  }

  .pulse-campaign-questions-grid-questions-list-grp-item {
    width: 100%;
    margin: 5px 0 10px 0;
    text-align: left;

    .MuiChip-outlinedPrimary {
      border: 1px solid rgba(0, 0, 0, 0.23);
    }

    .MuiChip-sizeSmall {
      height: 18px !important;
    }

    .MuiChip-root {
      font-size: 10px !important;
      border-radius: 4px !important;
    }
  }

  .pulse-campaign-questions-grid-questions-list-grp-item-text {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    white-space: normal;
  }

  .pulse-campaign-questions-grid-questions-list-grp-item-chip {
    margin-top: 5px;
  }

  #PulseCampaignTopToolbar {
    .dashboard-appbar {
      .dashboard-toolbar {
        flex-wrap: wrap;
        .dashboard-text-wrapper {
          width: 100%;
          margin-bottom: 15px;
        }
      }
    }
  }
}
