@import '../../../css/colors';
@import '../../../css/index';

#SurveyTypesManage {
  padding: 20px;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;


#SurveyTypesTable {
  @import '../../../css/ag-grid-tables.scss';
  height: 80%;

  .ag-root,
  .ag-body-viewport,
  .ag-body-viewport-wrapper {
    overflow: visible !important;
  }

  .actions-button-cell {
    overflow: visible;
  }

  .ag-row {
    z-index: 0;
  }

  .ag-row.ag-row-focus {
    z-index: 1;
  }

  .MuiCardContent-root {
    height: 95%;
    // padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  #SurveyTypesTableHeader {
  color: #000;
  border-bottom: 1px solid #d7d7e2;
  height: 48px !important;

  .header-actions {
    display: flex;
    align-items: center;
  }

  .type-title {
    align-self: center;
    font-weight: 700 !important;
  }

  .MuiCardHeader-content {
    align-self: center;
    font-weight: 700 !important;
  }

  .MuiCardHeader-action {
    align-self: center;
    vertical-align: middle;
    margin-top: 0;
    margin-right: 0;

    .MuiTypography-root {
      font-size: 1.2rem;
      color: #888;
    }
  }

  .search {
    position: relative;
    float: right;
  }

  .search-icon {
    align-content: center;
    margin-left: 5px;
    height: 100%;
    position: absolute;
  }

  .search-textbox {
    padding-left: 30px;
    float: right;
    transition: width 0.5s;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }

  .search-textbox:focus-within {
    transition: width 0.5s;
    width: 200px;
  }
}
}


// #SurveyTypesTable {
//   @import '../../css/ag-grid-tables.scss';
//   height: 100%;

//   .spinner-container {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     height: 100%;
//   }
// }

// #SurveyTypesTable .paging-row {
//   margin-top: auto;
//   padding: 0 6px;

//   button {
//     padding: 10px !important;
//   }
// }

// #SurveyTypesTable #WidgetTable {
//   flex: 1;

//   th {
//     width: 25%;
//     font-weight: 700;
//     background-color: $whisper;
//   }

//   tr {
//     cursor: pointer;
//   }

//   .MuiTableCell-root {
//     font-size: inherit;
//   }
// }
}
