@import '../../../css/index';

$summaryDrawerWidth: 360px;
$drawerBgColor: white;
$bgDarkColor: rgba(128, 128, 128, 0.3);

#EmailTemplateSettings {
  display: flex;
  width: 100%;

  .summary-drawer-root {
    display: flex;
    width: 100%;
    background-color: transparent;
    border: none !important;
    border-radius: 5px !important;

    position: absolute;
    transition: .2s ease;
    z-index: 100;

    .MuiDrawer-paperAnchorDockedLeft {
      box-shadow: 10px 10px 20px -15px rgba(0, 0, 0, 0.75);
      border: none !important;
      border-radius: 5px !important;
      height: calc(100vh - 58px);
      max-height: calc(100vh - 58px);
      left: 0 !important;

      &:hover {
        transform: translateX(2px)
      }
    }
  }

  .summary-drawer-open {
    width: $summaryDrawerWidth;

    &:hover {
      transform: none !important;
    }
  }

  .summary-drawer {
    flex-shrink: 1;
    white-space: nowrap;
    background-color: $drawerBgColor  !important;
    top: 60px !important;
    left: 15px !important;
  }

  .email-template-btn {
    padding: 5px !important;
  }

  .email-template-btn-avatar {
    max-height: 32px;
    max-width: 32px;
    color: #6B6985 !important;
    background-color: transparent !important;
  }

  .settings-label-rotate {
    visibility: visible;
    transform: rotate(90deg);
    transform-origin: top left 0;
    float: right;
    display: flex;
    align-items: center;
    width: 85px;
    color: #708090;
    font-size: 14px;
    height: 40px;
    cursor: pointer;
  }

  .settings-label-normal {
    visibility: visible;
    transform: rotate(0deg);
    transform-origin: top left 0;
    float: right;
    display: flex;
    align-items: center;
    width: 100%;
    color: #708090;
    font-size: 14px;
    height: 40px;
    cursor: pointer;
  }
}