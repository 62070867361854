@import '../../../css/index';
$bgDarkColor: rgb(213, 213, 222);
$bgDisabled: #EBEBEB;

#PulseQuestionMedia {

  .media-display-header {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .media-tabs {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;

    .media-tab {
      cursor: pointer;
      margin-right: 20px;

      &.active {
        text-decoration: underline;
      }
    }
  }

  .media-select-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .media-select {
      width: 50%;

      .MuiOutlinedInput-adornedStart {
        padding-left: 0px !important;
      }

      .file-container {
        background-color: $whiteSmoke;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 40px;
        height: 38px;
        border-right: 1px solid $lavenderGray;
        margin-right: 5px;
        svg {
          color: $lavenderGray;
        }
      }

      .thumbnail-container {
        width: 50px;
        height: 38px;
        border-right: 1px solid $lavenderGray;
        margin-right: 5px;

        img,
        video {
          width: 50px;
          position: relative;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          height: 100%;
          object-fit: cover;
        }
      }

    

      .MuiDialog-paper {
        padding: 20px;
      }

      .browse-button {
        height: calc(#{$fs_default} * 2.35) !important;
        margin-top: 0;
        margin-right: -14px;
        min-width: 100%;
        border-top-left-radius: inherit;
        font-size: 1.2rem;
        font-weight: bold;
      }

      .MuiDialog-paperWidthSm {
        width: 400px;
      }
    }

    .media-first-toggle {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 20px;
    }
  }
  
}