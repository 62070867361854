@import '../../css/index';
@import './UsersListModal';

#ParticipantsSelect {
  background-color: $ghostWhite;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  overflow-y: auto;

  #SelectedPane_VirtualList {
    height: 100%;
  }

  .participants-select-grid-container {
    max-height: 100%;
    overflow-y: auto;
  }

  .ag-theme-alpine {
    height: 100%;

    .ag-body-horizontal-scroll-viewport {
      overflow-x: hidden;
    }
    .ag-root-wrapper {
      border: none;
    }

    .ag-body-viewport,
    .ag-body-horizontal-scroll-viewport,
    .ag-body-horizontal-scroll {
      background-color: #fff;
    }
    .ag-row {
      background-color: #fff;
      border-bottom: none;
      border-top: none;
      font-size: inherit;

      .ag-cell {
        &[aria-colindex='1'] {
          border-left: none;
        }
      }
    }
    .ag-react-container {
      width: 100%;
    }
    .ag-row:not(.ag-row-first) {
      border-top: none;
    }
    .ag-header {
      border-bottom: none;
    }
    .ag-cell {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .user-cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .avatar {
      height: 20px;
      width: 20px;
    }

    .name {
      margin-left: 5px;
    }
  }

  > .MuiGrid-container {
    height: inherit;

    .participants-list {
      max-height: 100%;
      overflow-y: hidden;
    }

    > .select-column {
      max-height: 100%;
      padding: 5px 20px 0px 20px;
      background: $white;
      overflow: hidden;

      #ParticipantsSelectVirtualListContainer {
        height: calc(100% - 62px);
        overflow-y: scroll;
      }
    }

    > .selected-column {
      max-height: 100%;
      padding: 5px 20px 0px 20px;
      background: #f9f9fc;
      overflow: hidden;

      .participants-select-selected-pane-top-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .MuiChip-root {
        margin: 7px;
      }
    }
  }

  #UsersList,
  #ParticipantsSelect_RolesList,
  #ParticipantsSelect_GroupsList,
  #ParticipantsSelect_ClientsList,
  #ParticipantsSelect_EventList {
    margin-top: 5px;
    max-height: 100%;
    overflow-y: hidden;
    width: 100%;
  }

  .search {
    position: relative;
  }

  .search-icon {
    align-content: center;
    margin-left: 5px;
    height: 100%;
    position: absolute;
  }

  .participant-search {
    padding-left: 30px;
    float: right;
    transition: width 0.5s;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }

  .participant-search:focus-within {
    transition: width 0.5s;
    width: 200px;
  }

  .tab-buttons {
    margin-top: 10px;
  }

  .tab-button {
    cursor: pointer;
    display: inline-block;
    text-transform: uppercase;
    margin-left: 20px;
    margin-right: 4px;
    font-size: 13px;
    color: $spanishGray;
    font-weight: 700;
    border-bottom: solid $white 2px;
  }

  .client {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
    height: 20px;
  }

  .tab-button.active {
    @include themify($themes) {
      color: themed('activeTabColor') !important;
      border-bottom: 2px solid themed('activeTabBgColor') !important;
    }
  }

  .MuiListSubheader-root {
    background-color: $white;
    padding-top: 10px;
  }

  .MuiChip-label {
    font-size: $fs_secondary;
  }

  .Mui-selected {
    background-color: #ccf6f4;
  }

  .MuiListItem-root {
    margin: 4px 0;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 6px;
    padding-top: 4px;
    padding-bottom: 4px;

    .MuiListItemText-primary {
      font-size: $fs_secondary;
      overflow: hidden;
      max-height: calc(#{$fs_secondary} * 1.5);
    }
  }

  .row-button {
    display: flex;
    justify-content: left;
    width: 100%;
  }

  .paging-row {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
