#OptionsHeader {
  height: 25px;
  width: 100%;
  display: flex;
  flex-direction: row;

  p {
    padding-top: 5px;
  }
  .MuiSvgIcon-root {
    width: 0.6em !important;
    font-size: 1em;
    padding: 0 !important;
    margin-left: 1px;
    margin-top: -4px;
    height: 100%;
  }
}

.optionsLabel {
  width: 8.5%;
  min-width: 64px;
  font-size: 11px !important;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
}

#AnswerText {
  width: 100%;
  min-width: 29px;
  font-size: 11px !important;
  padding-left: 8px;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}

#Handle {
  min-width: 21.5px;
}
