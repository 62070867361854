#CustomCollectionEditScreen {
  overflow-y: hidden;
  margin-bottom: -120px;
  height: calc(100% - 64px);
  background: #fff;

  input, .MuiInputBase-input {
    font-size: 1.3rem;
  }

  .MuiFormLabel-root {
    font-weight: bold;
  }

  .MuiSelect-outlined {
    padding: 14px 14px;
  }
}
