@import '../../../css/index';

#EmailTemplatesEditorHTMLTab {
  #inactive-survey {
    color: $jordyBlue;
  }

  #take-survey {
    color: $medAquamarine;
  }

  .ag-cell {
    &.is-active {
      border-left: 3px solid $topazYellow;
    }

    &.is-inactive {
      border-left: 3px solid $jordyBlue;
    }
  }

  #always-active {
    margin-top: 15px;
    color: black;
  }
}
