@import '../../../css/index';

#AnnouncementAnalytics {
  height: calc(100vh - 60px);
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #f7f5f6!important;
  

  .list-manage-container {
    height: 100%;
    width: 100%;
  }

  .spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    position: absolute;
    z-index: 1002;
  }

.announcement-bread-crumbs{
  height: $topNavHeight;
  display:flex;
  align-items: center;
  background-color: #4e4e5e;
  padding-left:20px;
 

  .announcements-title{
    color:white;
  }

  .back {
    cursor: pointer;
  }

  .spaced {
    padding-right: 20px;
  }
}
}