@import '../../../css/index';

#NLPBody{
  padding: 15px;

.nlp-content{
  .MuiAutocomplete-root{
    width: 100%
  }

  .MuiAutocomplete-inputRoot{ 
    flex-wrap: nowrap !important;
  }

  .nlp-error{
    display:flex;
    justify-content: center;
    align-self: center;
    text-align: center;
    padding: 3px;
    margin-top: 10px;
    border-radius: 2px;
  }
}
}

.nlp-close-button{
  margin-right: 15px !important;
}
  
.dialog-header{
  background-color:#f0eff4
}