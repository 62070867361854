@import '../../../css/index';

#GoalsCatalogWidget {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  .filter {
    display: flex;
    align-items: center;
    padding-right: 20px;
    .MuiInput-underline:after {
      border-bottom: 0px !important;
    }
    .MuiFormLabel-root {
      margin-bottom: 0px !important;
      margin-right: 5px;
    }
    .Muifocused {
      &:before {
        border-bottom: 0px;
      }
    }
    .MuiInputBase-root {
      &:before {
        border-bottom: 0px;
      }
    }
  }

  #GoalCatalogContent {
    height: calc(100% - 48px) !important;
    padding: 0;
    // display: flex;
    // flex-direction: column;
    // flex: 1;
    overflow-y: scroll;
    background-color: $whiteSmoke;
  }
}

#GoalRow {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  border-bottom: 2px solid $lavenderGray;
  cursor: default;

  .icons {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }

    .edit {
      color: $medAquamarine;
    }

    .assign {
      color: $airforceBlue;
      margin-left: 8px;
    }
  }

  .goal-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      color: $spanishGray;
    }
  }

  .thumbnail {
    height: 60px;
    width: 60px;
    margin: 0px 5px;
    border-radius: 5px;
    padding: 0px;
    margin-bottom: auto;
  }

  .goal-description {
    margin: 5px 0px 10px 0px;
  }

  .default-thumbnail {
    height: 60px;
    width: 67px !important;
    background-color: $lavenderGray;
    margin: 0px 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: auto;
    svg {
      color: $gunPowder;
    }
  }

  .source-pill {
    background-color: $lavenderGray;
    border-radius: 10px;
    padding: 1px 7.5px;
    width: fit-content;
  }
}
