@import '../../../../css/index';

#ContentItemExercise {
  width: 100%;

  .exercise-take {}

  .exercise-item {
    justify-content: center;
    align-items: center;
  }

  .exercise-items {}

  .exercise-info-card {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
  }

  .exercise-info-card-paper {
    display: flex;
    height: 100%;
    width: 85%;
    border-radius: 9px;
    padding: 10px;
    background-color: #D9D9D9;
  }

  .exercise-left {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .exercise-right {
    width: 100%;
    height: 100%;
  }

  .create-dialog-container {
    margin: 10px;
  }

  .input-label-header {
    font-weight: 400 !important;
    margin-bottom: 15px;
  }

  .input-label {
    display: flex;
    width: 100%;
    font-weight: 400 !important;
    justify-content: flex-start;
    align-items: center;
  }

  .input-value {
    display: flex;
    width: 100%;
    font-weight: 400 !important;
    justify-content: flex-start;
    align-items: center;
  }

  .input-label-center-reg {
    display: flex;
    width: 100%;
    font-weight: 400 !important;
    justify-content: center;
    align-items: center;
  }

  .input-label-center {
    display: flex;
    width: 100%;
    font-weight: 600 !important;
    justify-content: center;
    align-items: center;
  }

  .input-value-center {
    display: flex;
    width: 100%;
    font-weight: 400 !important;
    padding-top: 5px !important;
    justify-content: center;
    align-items: center;
  }

  .completed-checkbox {
    display: flex;
    width: 100%;
    height: 36px;
    justify-content: center;
    align-items: center;

    .MuiSvgIcon-root {
      width: 30px;
      height: 30px;
      stroke-width: 0.01rem !important;
    }
  }

  .MuiCheckbox-root {
    color: #c0c0c0;
    stroke-width: 0.1rem !important;
  }

  .media-background {
    margin-top: 10px;
    display: flex;
    height: 350px;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;

    .media-preview {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
      object-fit: contain;
      object-position: center;
      
      justify-content: center;
      align-items: center;
      z-index: 999999;
      pointer-events: none;
  
      &.youtube {
        iframe {
          max-width: 800px;
        }
      }
  
      &.vimeo {
        width: 100%;
      }
  
      iframe {
        width: 800px;
        height: 350px;
        pointer-events: all;
      }
  
      &.iframewrapper::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
      
      &.iframewrapper iframe {
        vertical-align: top;
      }
    
    }

    .mux-player-container {
      display: flex;
      width: 100%;
      justify-content: center;
      min-width: 800px;
      max-width: 800px;
      max-height: 350px;
    }

    .mux-player {
      width: 100%;
      min-width: 800px;
      max-width: 800px;
      min-height: 350px;
      max-height: 350px;
    }
  }
}