@import '../../../../css/colors';
@import '../../../../css/index';

#ResponsesList {
  height: 90%;
  position: relative;

  .ag-cell {
    &.is-paper-survey {
      border-left: 3px solid $jordyBlue;
    }
    &.is-selected {
      background-color: #ccf6f4
    }
  }

  // .ag-row-focus {
  //   @include themify($themes) {
  //     background-color: themed('accentColor20') !important;
  //   }
  // }

  .vlist-table {
    border-collapse: collapse;
    border-spacing: 0;
    cursor: pointer;
    position: absolute;
    width: 100%;
    &.sticky-header {
      border-collapse: separate;
    }

    .vlist-thead {
      border-color: inherit;
      position: sticky;
      top: 0;
      vertical-align: middle;
      width: 100%;

      .vlist-thead-tr {
        align-items: stretch;
        color: inherit;
        cursor: pointer;
        display: flex;
        justify-content: center;
        outline: 0;
        vertical-align: middle;
        width: 100%;

        .vlist-th {
          border-bottom: 1px solid rgba(224, 224, 224, 1);
          color: #333;
          font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
            'Helvetica Neue', Arial, 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji',
            'Segoe UI Symbol';
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.43rem;
          padding: 16px;
          text-align: left;
          vertical-align: inherit;
          width: 50% !important;
          &.small {
            font-size: 92%;
            padding: 6px 24px 6px 16px;
          }
          &.sticky-header {
            background-color: #f8f5f5;
            left: 0;
            position: sticky;
            z-index: 2;
          }
        }
      }
    }

    .vlist-tbody {
      vertical-align: middle;
      border-color: inherit;
      width: 100%;

      .vlist-tr {
        align-items: stretch;
        border-color: inherit;
        color: inherit;
        cursor: pointer;
        display: flex;
        justify-content: center;
        outline: 0;
        vertical-align: middle;
        width: 100%;
        &.selected {
          color: #05d1cf;
          font-weight: bold;

          .vlist-td {
            color: #05d1cf;
            font-weight: 700;
          }
        }

        .vlist-td {
          border-bottom: 1px solid rgba(224, 224, 224, 1);
          font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
            'Helvetica Neue', Arial, 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji',
            'Segoe UI Symbol';
          font-size: 1rem;
          font-weight: 400;
          line-height: 2;
          overflow: hidden;
          padding: 16px;
          text-align: left;
          vertical-align: inherit;
          width: 50% !important;
          &.small {
            padding: 6px 24px 6px 16px;
          }

          .paper-survey-indicator {
            color: $indianRed;
            font-size: 1.1rem;
            margin-left: 2px;
          }
        }
      }
    }
  }

  .widgetHeader {
    color: #000;
    border-bottom: 1px solid #d7d7e2;

    .MuiCardContent-root {
      padding: 0;
    }

    .MuiCardHeader-content {
      align-self: center;
    }

    .MuiCardHeader-action {
      align-self: center;
      align-items: center;
      margin-top: 0;
      margin-right: 0;

      .MuiTypography-root {
        font-size: 1.2rem;
        color: #888;
      }
    }
  }
}
