@import '../../css/colors';
@import '../../css/index';

.content-toggle {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 15px 0 0 !important;

  span {
    display: block;
    min-width: fit-content;
    height: fit-content;
  }

  .tab-btns {
    display: flex;
    width: 100%;

    .tab-button {
      display: flex !important;
      justify-content: center;
      height: 40px;
      width: 40px;
      border-radius: 5px;
      min-height: 40px !important;
      min-width: 40px !important;
      margin: 0 !important;
      cursor: pointer;
      display: inline-block;

      @include themify($themes) {
          border: 2px solid themed('buttonBorderColor') !important;
      }

      .tab-button-text {
        font-weight: 400 !important;
        font-size: $fs_secondary !important;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        padding: 0 3px 0 3px;
      }

      &.active {
        font-weight: 700 !important;
        // border-bottom: 2px solid !important;

        @include themify($themes) {
          color: themed('buttonOtlTextColorAlt') !important;
          // border-color: themed('activeTabBgColor') !important;
        }
      }

      @include xl-desktop-up {
        font-size: 12.5px !important;
      }
    }
  }

}