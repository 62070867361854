@import '../../../css/index';

#PulseCampaignDetailsColLeft {
  .details-grid {
    margin: 0;
  }

  .details-grid-header {
    width: 100%;
    margin: 5px 0 5px 0;
  }

  .details-grid-field-wrapper {
    margin: 0;
    height: 100%;
  }

  .details-grid-header-title {
    margin-bottom: 10px;

    @include themify($themes) {
      color: themed('calEventTextColor') !important;
    }
    font-size: $fs_default;
    font-weight: bold;
  }

  .input-div {
    margin-left: 10px;
    margin-bottom: 10px;
    width: calc(100% - 20px);

    .toolbar-input {
      height: fit-content;
      width: 100%;
      background-color: white;

      div input {
        border: none !important;
      }
    }

    .category-picker-container {
      width: 100%;
      max-width: 100%;
      background-color: white;

      .category-name-display {
        max-width: 100%;
        flex: 1;
        justify-content: flex-start;
      }
    }

    .survey-type-input {
      width: 100%;

      div {
        padding: 0px !important;
        padding-right: 5px;
        height: fit-content;

        input {
          height: fit-content;
          padding: 0 10px;
        }
      }
    }
  }

  .owner-input {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
}
