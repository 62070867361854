@import '../css/index';

#InfoTooltipBtn {
  .MuiIconButton-label {
    color: $lavenderGray;

    &:hover {
      cursor: help;
    }
  }
  .MuiTooltip-popperArrow {
    .MuiTooltip-tooltip {
      text-transform: unset !important;
      font-size: $fs_default !important;
      font-weight: 600 !important;
      background-color: $whisper !important;
      color: $nightRider !important;
      padding: 8px 14px !important;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  
      .MuiTooltip-arrow {
        display: none;
      }
    }
  }
}


