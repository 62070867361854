@import '../../css/index';

#QuizResultOption {
  .quiz-option {
    align-items: center;
    max-height: 40px;
    
    .correct-icon {
      margin-left: 20px;
      color: #17C09B
    }

    .incorrect-icon {
      margin-left: 20px;
      color: #EF666C
    }

    .quiz-option-text {
      border-radius: 10px;
      padding: 5px 5px 5px 10px;

      .option-text {
        color: #68686A
      }

      .text-is-checked {
        font-weight: bold;
      }
    }
    .is-checked {
      background-color: #CCF6F4
      // @include themify($themes) {
      //   background-color: themed('accentColor20') !important;
      // }
    }

    .quiz-option-percent {
      padding-left: 10px;

      .quiz-option-percent-text {
        color: #9A9AB5;
        font-weight: bold
      }
    }
  }
}
