@import 'colors.scss';

$themes: (
  ripple: (
    primaryBgColor: $blackRock,
    secondaryBgColor: $gunPowder,
    primarySurfaceColor: $whiteSmoke,
    // secondarySurfaceColor: $whisper,
    secondarySurfaceColor: rgba(237, 232, 232, 0.75),
    tertiarySurfaceColor: #e3dcdd,
    accentColor: $darkTurquoise,
    // accentColorLight: #ccf6f4,
    accentColor20: $darkTurquoise20,
    accentColor30: $darkTurquoise30,
    buttonBgColor: $viridianGreen,
    buttonTextColor: $white,
    buttonBorderColor: $lightGray,
    buttonHoverColor: $viridianGreen10,
    buttonOtlBgColor: transparent,
    buttonOtlTextColor: $viridianGreen,
    buttonOtlTextColorAlt: $viridianGreen,
    buttonOtlHoverColor: $viridianGreen10,
    buttonOtlBorderColor: $lightGray,
    buttonIconCTAColor: $viridianGreen60,
    activeTabColor: $darkTurquoise,
    activeTabBgColor: $darkTurquoise,
    dashboardHeaderText: $white,
    dashboardInactiveTab: $white60,
    lightBlueAccent: $jordyBlue60,
    calPillBorderColor: none,
    calEventBgColor: $jordyBlue60,
    calTaskBgColor: $vodka,
    calTaskDueBgColor: $indianRed,
    calSurveyBgColor: $lightGreenCyan,
    calTrainingsBgColor: #ffe6a1,
    calEventTextColor: $nightRider,
    calEventIconColor: $nightRider,
    calTaskIconColor: $nightRider,
    calSurveyIconColor: $nightRider,
    calTodayBgColor: $darkTurquoise10,
    calAgendaHeaderBgColor: $whisper,
    surveyEditorDetailsTabLeftGrid: $jordyBlue10,
    primaryBorderAccent: $gunPowder,
    secondaryBorderAccent: $darkTurquoise,
    tertiaryBorderAccent: $lightGray,
    accentColorLight: #b9d7f2,
    TPcompletedStepIcon: $viridianGreen,
    TPcompletedStepBG: $morningGlory,
    emailTemplateEditorTextColor: $white,
  ),
  warnerMedia: (
    primaryBgColor: #1e1e1e,
    secondaryBgColor: #b6b6b6,
    primarySurfaceColor: #eaeaea,
    secondarySurfaceColor: rgba(208, 208, 208, 0.8),
    tertiarySurfaceColor: #b6b6b6,
    accentColor: #e5bc73,
    // accentColorLight: #dbc39a,
    accentColor20: rgba(10, 8, 4, 0.2),
    accentColor30: rgba(214, 167, 85, 0.3),
    buttonBgColor: #d6a755,
    buttonTextColor: #5c2e00,
    buttonBorderColor: #e5bc73,
    buttonHoverColor: #c39139,
    buttonOtlBgColor: #5b5b5b,
    buttonOtlTextColor: #5c2e00,
    buttonOtlTextColorAlt: #ffcd73,
    buttonOtlHoverColor: $black10,
    buttonOtlBorderColor: #e5bc73,
    buttonIconCTAColor: #e5bc73,
    activeTabColor: $nightRider,
    activeTabBgColor: #c39139,
    dashboardHeaderText: $black,
    dashboardInactiveTab: $black50,
    lightBlueAccent: rgba(24, 155, 214, 0.5),
    calPillBorderColor: #e5bc73,
    calEventBgColor: $white,
    calTaskBgColor: $white,
    calTaskDueBgColor: $white,
    calSurveyBgColor: $white,
    calTrainingsBgColor: $white,
    calEventTextColor: #1e1e1e,
    calEventIconColor: $airforceBlue,
    calTaskIconColor: $darkTurquoiseDark,
    calSurveyIconColor: $topazYellowDark,
    calTodayBgColor: #d6a7542e,
    calAgendaHeaderBgColor: #cacaca,
    surveyEditorDetailsTabLeftGrid: $ghostWhite,
    primaryBorderAccent: #5c2e00,
    secondaryBorderAccent: #96520e,
    tertiaryBorderAccent: #d6a755,
    accentColorLight: #debb8b,
    TPcompletedStepIcon: #5c2e00,
    TPcompletedStepBG: #ffcd73,
    emailTemplateEditorTextColor: $white,
  ),
);
