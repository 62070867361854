@import '../../css/index';

#ContentLayoutGridBody {
  height: 100%;
  width: 100vw;
}

#ContentPickerGrid,
#ContentLayoutGridBody {
  .content-empty-container {
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: center;
  }

  .content-empty-avatar {
    margin: 0 5px 5px 5px;
    align-self: center;
    justify-content: center;
  }

  .content-empty-img {
    width: 10vw;
    height: auto;
    max-width: 140px;
    max-height: 140px;
    min-width: 60px;
    min-height: 60px;
    align-self: center;
    justify-content: center;
  }

  .content-empty-header {
    margin: 0 5px 5px 5px;
    font-size: 20px !important;
    font-weight: 700;
    line-height: 1.5;
    align-self: center;
    justify-content: center;
  }

  #ContentPickerEmptyText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content-empty-text {
      margin: 0 5px 0 5px;
      font-size: 16px !important;
      font-weight: 400;
      line-height: 1.5;
      align-self: center;
      justify-content: center;

    }

    .content-empty-action-btn {
      margin-top: 15px;
    }
  }
}

.MuiSkeleton-text {
  max-width: 280px !important;
  max-height: 160px !important;
  transform: none !important;
  margin-top: 0;
  border-radius: 4px;
  margin-bottom: 0;
  transform-origin: 0 60%;
}

.content-card-info-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .content-card-info-row-right-access {
    margin-right: 15px;
    color: #788998 !important;
  }

  .content-card-info-row-left {
    display: flex;

    .content-icon-tags-icon {
      display: flex;
      align-items: center;
    }

    .content-icon-tags {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      .content-type-chip {
        max-width: 100px;
        padding: 0 5px 0 0;
      }
      
      .MuiAvatar-colorDefault {
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        background-color: transparent;
      }

      .MuiAvatar-root {
        width: unset;
        height: unset;
        border-radius: unset;
        background-color: transparent;
        border: none;
        margin-right: 10px;
      }

      .MuiChip-sizeSmall {
        height: 22px;
        font-size: 12px;
        margin: 2px 2px 0 2px;
      }

    }

    .content-icon-tags-file {
      width: 26px;
      height: 26px;
      justify-content: center;
      align-items: center;
      color: #788998 !important;
    }

    .content-icon-type {
      display: flex;
      max-width: 100%;
      max-height: 100%;
      height: 26px;
      margin: 0;      
      justify-content: center;
      align-items: center;
    
      &.iframewrapper::after {
        content: "";
        position: absolute;
        z-index: 999999;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
      
      &.iframewrapper iframe {
        vertical-align: top;
      }
    
      &.image {
        max-height: 100%;
        background-color: #DCE1ED;
      }
    
      &.file {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: #788998 !important;
      }
    }
  }

}

.content-sel-item .content-item .MuiGrid-item {
  max-height: inherit !important;
  max-width: inherit !important;
}

#content-card-container {
  display: flex;
  align-items: center;
}

.content-card-container-type {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;

  .skeleton-preview {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    object-fit: contain;
    object-position: center;
    
    justify-content: center;
    align-items: center;
  
    &.file {
      width: 100%;
      max-height: 80px;
      justify-content: center;
      align-items: center;
      color: #788998 !important;
    }
  }
}

.iframewrapper iframe {
  border: none !important;
}

.content-sel-item .content-item .MuiGrid-item .media-preview {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  object-fit: contain;
  object-position: center;
  
  justify-content: center;
  align-items: center;
  position: absolute;

  &.iframewrapper::after {
    content: "";
    position: absolute;
    z-index: 999999;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  &.iframewrapper iframe {
    vertical-align: top;
  }

  &.image {
    max-height: 100%;
    background-color: #DCE1ED;
  }

  &.file {
    width: 100%;
    max-height: 80px;
    justify-content: center;
    align-items: center;
    color: #788998 !important;
  }
}

.content-sel-item {
  width: 100%;
  height: 100%;
  max-height: 280px !important;
  max-width: 280px !important;

  .content-item {
    color: #50515f;

    .MuiCardContent-root {
      padding: 0 !important;
    }
  
  }
}

.content-card-container {
  position: relative;
  z-index: inherit;

  .content-skeleton {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
  }
}

#content-skeleton,
#content-container-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  .skeleton {
    max-width: 280px !important;
    max-height: 160px !important;
  }
}

#content-container-overlay {
  z-index: 5;
}

#ContentPickerDialogAppBar {
  .MuiAppBar-colorPrimary {
    @include themify($themes) {
      background-color: themed('primaryBgColor');
    }
  }
}

#ContentPickerGrid {
  height: 100%;
  margin-right: 5px;
  margin-left: 15px;

  .root {
    padding: 0 85px;
    margin-top: 20px;
    justify-content: flex-start;
  }

  .row {
    display: flex;
    justify-content: flex-start,
  }

  .spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1002;
  }
}

#content-sel,
#ContentPickerGrid {
  height: 100%;

  .content-sel-item {
    margin: 10px;
    border-radius: 5px;
    top: 0px !important;
    border: 1px solid rgb(188, 188, 188) !important;

    .content-item {
      // max-height: 250px;
      color: #50515f;
    }
  }

  .media-box {
    display: flex;
    flex-direction: row;
    height: 180px;
    width: 280px;

    max-height: 180px !important;
    max-width: 280px !important;
    background-color: #DCE1ED;
  }

  .media {
    width: 100%;
    max-height: 160px;
  }

  .media-off {
    display: none;
  }

  .content-sortable-grid {
    margin-left: 0;
    width: 100%;
    align-self: center !important;
  }

  #content-sortable-grid-scroller {
    margin-left: 0;
    height: 100%;
    width: 100%;
    align-self: center !important;
  }

  .content-grid {
    // max-height: 250px;
  }

  .content-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 280px;
    // max-height: 280px;
    background-color: white;
  }

  //
  .content-card-header {
    width: 100%;
    height: 100%;

    //
    .content-card-header-sel {

      .content-sel-handle-disabled {
        .MuiIconButton-label {
          .MuiSvgIcon-root {
            fill: rgba(145, 145, 154, 0.5) !important;
          }
        }
      }
    }
  }

  .content-card-header-sub {
    font-size: 12px;
    width: 100%;
    margin: 5px 0 5px 0 5px;
  }

  .content-card-content {
    width: 100%;
  }

  .content-card-media {
    width: 100%;

    .MuiCardMedia-img {
      object-fit: cover;
      object-position: 100% 0%;
    }
  }

  .content-card-body {
    width: 100%;
    margin: 15px;
    max-height: 50px;
  }

  .content-type-container {
    display: flex;
  }

  .content-card-body-text {
    background: #DCE1ED;
    font-size: 12px !important;
    flex-wrap: nowrap;
    flex-shrink: 1;
    text-overflow: ellipsis;
    padding: 2px 8px;
    margin: 2px 0;
    font-weight: 600;
    text-transform: uppercase;
    color: rgb(102, 102, 135);
    border-radius: 4px;
  }

  .content-card-body-text-bold {
    width: 100%;
    max-width: 250px;
    font-size: 16px !important;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    font-weight: 700;
  }

  .card-action-container {
    display: flex;
    height: 100%;
    margin-left: 5px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;

    .content-info {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .card-actions {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 4px 8px 0 4px;
  }

  .checked-container {
    margin: 0 !important;
  }

  .MuiCheckbox-colorPrimary {
    .MuiSvgIcon-root {
      background-color: #f4f4f7;
      padding: 0 !important;
      border-radius: 4px;
      border: none;
    }

  }
}

#content-skeleton .skeleton-chart-container,
#content-container-overlay .skeleton-chart-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;

  .MuiCardHeader-root {
    padding: 0 !important;
  }

  .MuiCardHeader-avatar {
    height: 100%;
  }

}

#content-skeleton .skeleton-chart-bar,
#content-container-overlay .skeleton-chart-bar {
  margin-left: 10px;
  margin-right: 10px;
}

#content-container-overlay .skeleton-chart-container .MuiSkeleton-root {
  margin-left: 10px;
  margin-right: 10px;
}


.tag-popover {
  pointer-events: none;

  .tag-popover-header {
    display: flex;
    flex-direction: row !important;
    padding: 5px;
    margin-top: 10px;
    align-items: center;
  }

  .tag-popover-header-icon {
    width: 18px;
    height: 18px;
    color: #788998 !important;
    margin-right: 8px;
  }

  .tag-popover-header-text {
    align-items: center;
  }

}
