@import '../../../../css/index';

#DateRangeModal {
  .rdrDateRangePickerWrapper {
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
  }
  .rdrDefinedRangesWrapper {
    width: 110px !important;
  }
  .rdrMonthAndYearWrapper {
    height: 30px !important;
    padding-top: 0px !important;
  }
  .rdrMonthName {
    display: none;
  }

  .rdrMonthAndYearPickers {
    .rdrMonthPicker,
    .rdrYearPicker {
      select:hover {
        background-color: rgba(0, 0, 0, 0) !important;
      }
    }
  }

  .footer {
    padding: 10px;
    width: 100%;
    max-width: 100%;
  }

  .date-range-modal-header {
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 12px;
    color: black;
    font-weight: 700;
    font-size: $fs_default;
  }
}
