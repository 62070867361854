@import '../../css/colors';

#ContentLayoutTable {
  @import '../../css/ag-grid-tables.scss';

  height: calc(100vh - 3 * (64px)) !important;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 0;
  margin-right: 25px;
  margin-left: 25px;

  .spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1002;
  }

  .MuiCardContent-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .hidden {
    color: #474852 !important;
    background-color: #C1C1D3 !important;
  }

  .ag-body-viewport {
    height: calc(100vh - 170px);
    background-color: white !important;
  }

  .ag-header-viewport {
    background-color: white !important;
  }

  .ag-body-horizontal-scroll {
    height: 0px !important;
    min-height: 0px !important;
  }

  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .ag-theme-alpine {
    height: 100% !important;
    background-color: white !important;
  }

  .ag-header-cell {
    background-color: white !important;
  }

  .ag-row {
    background-color: white !important;
  }

  .centerCell {
    width: 100%;
    justify-content: center !important;
  }

}