@import '../../../css/index';

$gray1: #212227;
$gray2: #5a5e6c;
$gray3: #444852;
$gray4: #a3abc4;
$white: #ffffff;
$background: #edeef3;
$framingBackground: #f9faff;
$periwinkle: #afc1f6;
$yellow: #ffcc4d;
$greenSun: #00a650;
$greenArrow: #0caa73;
$redLightning: #fa230f;
$redArrow: #fa4900;
$purple: #9429ff;

#Insights {
  height: calc(100vh - 64px);
  overflow-y: auto;
  background-color: $background !important;
  padding: 24px;

  .h1-sm {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0.1px;
  }

  .h2-sm {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 29px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.1px;
  }

  .h3-sm {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0.1px;
  }

  .h6 {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 19px !important;
    font-weight: 700 !important;
    line-height: 25px;
    letter-spacing: 0.1px;
  }

  .body {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .body-sm {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  .body-italicized {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    font-style: italic;
  }

  .label {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 21px;
    letter-spacing: 0.1px;
  }

  .support-text {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  .support-text-md {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.1px;
  }

  .support-text-lg {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 19px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.1px;
  }

  .callout {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.1px;
  }

  .callout-sm {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .callout-lg {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    letter-spacing: 0.2px;
  }

  .insights-container {
    height: 100%;
    width: 100%;

    .header-container {
      flex-wrap: nowrap;
    }

    .insights-header {
      color: $gray1;
      margin-bottom: 10px;
    }

    .insights-subheader {
      color: $gray3;
    }

    .no-periods-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-top: 100px;
    }

    .no-periods-img {
      width: 140px;
      height: auto;
      align-self: center;
      justify-content: center;
    }

    .no-periods-header-text {
      color: $gray3;
      text-align: center;
    }

    .no-periods-message-text {
      font-size: 18px !important;
      color: $gray2;
      text-align: center;
      font-weight: 600 !important;
    }

    .insights-summary-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .left-container {
    background-color: $gray1;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
  }

  .group-container {
    padding: 0 8px;
  }

  .group-name {
    color: $white;
    margin-bottom: 6px;
  }

  .group-summary-text {
    color: $white;
    margin-bottom: 24px;
  }

  .sentiment-container {
    border-radius: 8px;
    background-color: $background;
    padding: 20px 24px;
    position: relative;
    margin-bottom: 24px;
  }

  .sentiment-title {
    color: $gray2;
    margin-bottom: 4px;
  }

  .sentiment-tool-tip {
    position: absolute;
    right: 0;
    top: 0;
  }

  .sentiment-tool-tip-icon {
    color: $gray2;
    width: 16px;
    height: 16px;
  }

  .sentiment-score-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .sentiment-score-inner-wrapper {
    display: flex;
    align-items: flex-end;
    gap: 4px;
  }

  .sentiment-score {
    color: $gray1;
  }

  .sentiment-score-disabled {
    color: $gray2 !important;
  }

  .sentiment-score-out-of {
    color: $gray1;
  }

  .sentiment-direction-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .sentiment-direction-icon {
    height: 20px;
    width: 20px;
  }

  .sentiment-direction-percentage {
    color: $gray3;
  }

  .sentiment-asterisk {
    color: $gray3;
    margin-bottom: 12px;
  }

  .response-container {
    padding: 0 8px;
    margin-bottom: 16px;
  }

  .response-title {
    color: $periwinkle;
    margin-bottom: 2px;
  }

  .response-score-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .response-percentage {
    color: $white;
  }

  .response-direction-percentage {
    color: $white;
  }

  .motivation-container {
    padding: 0 8px;
    margin-bottom: 16px;
  }

  .motivation-title {
    color: $periwinkle;
    margin-bottom: 2px;
  }

  .motivation-score-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .motivation-score {
    color: $white;
  }

  .motivation-score-out-of {
    color: $white;
  }

  .motivation-direction-percentage {
    color: $white;
  }

  .motivation-perception {
    color: $background;
  }

  .workload-container {
    padding: 0 8px;
  }

  .workload-title {
    color: $periwinkle;
    margin-bottom: 2px;
  }

  .workload-score-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .workload-score {
    color: $white;
  }

  .workload-score-out-of {
    color: $white;
  }

  .workload-direction-percentage {
    color: $white;
  }

  .workload-perception {
    color: $background;
  }

  .trend-percentage {
    color: $white;
  }

  .trend-direction-percentage {
    color: $white;
  }

  .trend-direction-icon {
    height: 20px;
    width: 20px;
  }

  .trend-neutral-icon {
    height: 15px;
    width: 15px;
  }

  .trend-direction-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .score-asterisk {
    color: $white;
    margin-left: 2px;
  }

  .special-note {
    color: $white;
    margin-top: 16px;
    padding: 0 8px;
  }

  .left-dropdown-wrapper {
    margin: 0 12px;
  }

  .right-dropdown-wrapper {
    margin-left: 12px;
  }

  .insights-dropdown {
    background-color: $white;
    border-radius: 24px;
    justify-content: space-between;
    text-transform: none !important;
    color: $gray2 !important;
    width: 100%;
    height: 100% !important;
    margin-left: 0 !important;
    padding: 8px 16px !important;
  }

  .insights-dropdown-disabled {
    background-color: #dadde7;
    border-radius: 24px;
    justify-content: space-between;
    text-transform: none !important;
    color: #7a798b !important;
    width: 100%;
    height: 100% !important;
    margin-left: 0 !important;
    padding: 8px 16px !important;
  }

  .insights-dropdown-label {
    font-family: 'Helvetica Neue', sans-serif;
    color: $gray1;
    display: block;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 6px;
  }

  .content-container {
    gap: 24px;
    flex-wrap: nowrap;
  }

  .insights-legend {
    padding: 16px 0 12px;

    .legend-title {
      font-family: 'Helvetica Neue', sans-serif;
      font-size: 16px;
      line-height: 21px;
      font-weight: 700;
      color: $gray2;
      letter-spacing: 0.1px;
    }

    .legend-item {
      padding-top: 2px;
    }

    .legend-item-wrapper {
      padding: 0 8px;
      display: flex;
      align-items: center;
    }

    .legend-icon {
      height: 16px;
      width: 16px;
      margin-right: 4px;
    }
  }

  .framing-container {
    background-color: $framingBackground;
    border-radius: 8px;
    padding: 24px;
    min-height: 192px;
    gap: 24px;
    flex-wrap: nowrap;
    margin-bottom: 16px;

    .framing-inner-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .category-text-container {
      max-width: 238px;
    }

    .category-name {
      color: $gray1;
      margin-bottom: 6px;
    }

    .category-description {
      color: $gray3;
    }

    .insight-data-item {
      padding-left: 24px;
      position: relative;
      margin-bottom: 14px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .insight-text {
      color: $gray1;
    }

    .category-sentiment-score-icon {
      height: 24px;
      width: 24px;
    }

    .category-sentiment-score {
      color: $gray3;
      padding-left: 12px;
    }

    .insight-text-icon {
      position: absolute;
      left: -1px;
      top: 2px;
      color: $purple;
      height: 18px;
      width: 18px;
    }
  }

  .red-arrow {
    color: $redArrow;
  }

  .red-lightning {
    color: $redLightning;
  }

  .green-arrow {
    color: $greenArrow;
  }

  .green-sun {
    color: $greenSun;
  }

  .yellow {
    color: $yellow;
  }

  .grey-neutral {
    color: $gray4;
  }

  .disabled {
    color: $gray4 !important;
  }

  .spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    position: absolute;
    z-index: 1002;
  }
}

.insight-dropdown-menu {
  .MuiList-padding {
    padding: 10px;
  }

  .MuiPaper-root {
    background-color: $white;
    border-radius: 20px;
    width: 288px;
  }
}
