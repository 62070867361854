@import '../../css/index';

#TasksDashboard {
  height: calc(100vh - #{$topNavHeight});
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;

  .react-resizable-handle {
    display: none;
  }

  .drag-handle {
    cursor: default !important;
  }

  .dashboard-header {
    color: $secondaryText;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px 0;
    font-size: 12px;

    .greeting {
      font-size: $fs_header;
    }

    .right-buttons {
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        margin-left: 15px;
        border: 1px solid $light50;
        border-radius: 4px;
        padding: 8px;
        cursor: pointer;
      }

      div.add-widget-btn {
        background-color: $accent;
        border: none;
      }
    }
  }

  .react-grid-layout {
    .widget-container {
      overflow: hidden;
      border-radius: 4px;
      position: relative;
      overflow: hidden;
    }

    .react-grid-placeholder {
      background-color: $light;
    }
  }
}
