@import '../../../css/index';

#ActionSnackbar {
  .snackbar-success {
    .MuiSnackbarContent-root {
      background-color: #cde0e6 !important;
      font-weight: 500 !important;
      color: #52b3b6;
    }
  }
}
