@import '../../../../css/index';

#BuilderTabPanel {
  overflow-y: auto;
  // height: 100%;
  background: #fff;
  align-content: flex-start;

  .input-label {
    display: flex;
    align-items: center;
    svg {
      margin-left: 5px;
      color: $lavenderGray;
    }
  }

  .tab-body {
    padding: 30px 35px;

    .tab-title {
      color: $spanishGray;
      font-size: 18px !important;
    }

    .content-order {
      margin: 10px 16px !important;

      .input-label {
        font-weight: 600 !important;
        font-size: $fs_default !important;
      }
    }
  }

  #AddTrainingItemButton {
    display: flex;
    justify-content: space-around;
  }

  .no-items {
    text-align: center;
  }

  .start-end-badge {
    width: 60px;
    padding: 5px;
    border-radius: 20px !important;
    color: #cfd4da !important;
    background-color: #f0f1f4 !important;
    text-align: center;
    margin: 15px auto;
    cursor: default;

    p {
      line-height: 20px;
      vertical-align: middle;
      text-transform: uppercase;
      font-size: 13px;
    }
  }
}

#trainingItemModal {
  .MuiDialog-paper {
    height: calc(100% - 64px);
    max-height: calc(100% - 64px);
    width: 80vw !important;
    max-width: 80vw !important;
    overflow: hidden;

    .dialog-title {
      height: 50px;
      background-color: $lavenderGray;
      .MuiDialogTitle-root {
        padding: 0px 24px !important;
      }
    }

    #TrainingItemEditScreen {
      height: calc(100% - 50px) !important;
      max-height: calc(100% - 50px) !important;
      overflow: hidden;

      input,
      .MuiInputBase-input {
        font-size: 1.3rem;
      }

      .MuiFormLabel-root {
        font-weight: bold;
      }

      .MuiSelect-outlined {
        padding: 14px 14px;
      }

      .training-item-back-to-impace-btn {
        display: none;
      }

      #BuilderDrawer {
        height: calc(100% - 64px - 50px);
        max-height: calc(100% - 64px - 50px);
      }

      #TabsBar {
        height: calc(100% - 64px);
        max-height: calc(100% - 64px);
        overflow: hidden;
      }
    }
  }
}
