@import '../../../../css/index';

#TPDetailsTabPanel {
  overflow-y: auto;
  height: calc(100vh - 158px);
  background: #fff;
  align-content: flex-start;

  .start-adornment {
    img {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      width: 36px;
      margin-left: -14px;
    }
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    min-height: 40px !important;
    padding: 6px 28px 6px 8px !important;
    height: fit-content !important;
    align-items: flex-start;
  }

  .required {
    color: $red;
  }

  .input-label {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    svg {
      margin-left: 5px;
      color: $lavenderGray;
    }
  }

  .groups-roles-error {
    fieldset {
      border: 1px solid $errorRed;
    }
  }

  .left-panel {
    padding: 30px 35px;
    background-color: #f2f6ff;

    .MuiInputBase-root,
    .MuiOutlinedInput-root,
    .MuiInputBase-fullWidth,
    .category-picker-container {
      background-color: white;
    }

    .category-name-display {
      overflow: hidden;
    }

    .details-item {
      margin-bottom: 20px;

      .roles-groups-input {
        margin-bottom: 20px;
      }
    }
  }

  .right-panel {
    padding: 30px 35px;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;

    .flex-align {
      display: flex;
      align-items: center;
      h6 {
        margin-top: 0px !important;
      }
    }

    .peer-input {
      margin-left: 5px;
    }

    .details-item {
      margin-bottom: 30px;
    }

    .add-to-catalog {
      margin-right: 30px;
    }

    .associate-goals {
      margin-top: 2.5px;
    }

    .avatar-container {
      align-items: center;
      display: flex;
      height: 100%;
    }

    .audience-member {
      margin-left: 10px;
    }
    .trainer-error {
      border: 1px solid $errorRed;
    }

    .audience-container {
      padding: 10px 0;
      border-radius: 4px;
      cursor: pointer;
      background-color: $white;

      .name-container {
        display: flex;
        align-items: center;
      }
    }

    .add-icon-container {
      display: flex;
      background-color: $whiteLilac;
      border-radius: 50px;
      height: 40px;
      width: 40px;
      border-collapse: separate;
      justify-content: space-around;
      align-items: center;
    }

    .add-participant-text {
      margin: 10px 0 10px 5px !important;
      color: $spanishGray;
      font-size: $fs_default !important;
      font-weight: 500 !important;

      &.filled {
        color: $nightRider !important;
      }
    }

    .training-items-details-svg {
      margin-left: auto;
      width: 600px;
    }
  }

  .browse-button {
    height: 37px !important;
    margin-top: 0;
    margin-right: -14px;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    font-size: 1.2rem;
    font-weight: bold;
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
  }
}
