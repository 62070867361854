#UserConsentScreen {
  height: calc(100vh - 64px);
  width: calc(100vw - 56px);
  display: flex;
  justify-content: center;
  align-items: center;

  .consent-card {
    max-height: calc(70vh - 64px);
    display: flex;
    flex-direction: column;

    .consent-card-header {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);

      .MuiTypography-root {
        font-size: 1.25rem !important;
      }
    }

    .consent-card-content {
      flex-grow: 1;
      max-height: calc(100% - 100px);
      overflow-y: auto;
      background-color: #fff;
    }

    .consent-card-actions {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid rgba(0, 0, 0, 0.125);
    }

    .link-pill {
      background-color: #e0e0e0;
      border-radius: 25px;
      margin: 5px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      white-space: nowrap;
    }

    .consent-attachment-container {
      flex-direction: column;
      align-items: flex-start;
    }

    .link-pill-text {
      padding-left: 10px;
      padding-right: 15px;
    }

    .link-pill-icon {
      color: #fff;
      background-color: darkgray;
      border-radius: 50px;
      padding: 3px;
      display: 'inline-flex';
      vertical-align: middle;
      margin-right: 7px;
    }
  }

  @media (max-width: 960px) {
    .consent-card {
      max-height: calc(80vh - 64px);
    }
  }

  @media (max-width: 600px) {
    .consent-card {
      max-height: calc(100vh - 64px);
    }
  }
}
