@import '../../css/index';

#SurveyResultsArea {
  height: calc(100vh);
  overflow-x: hidden;
  overflow-y: auto;

  @include themify($themes) {
    background-color: themed('secondaryBgColor') !important;
  }
}
