@import '../../css/index';

.weight-profiles-cell {
  .MuiButtonBase-root {
    padding: 3px !important;
  }
  .weight-profile-button {
    @include themify($themes) {
      background-color: themed('buttonIconCTAColor') !important;
      color: themed('buttonTextColor') !important;
    }
    &:hover {
      @include themify($themes) {
        background-color: themed('buttonBgColor') !important;
      }
    }
  }
}
