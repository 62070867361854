@import '../../../../css/index';

#TrainingPlansModal {
  .close-modal-button {
    cursor: pointer;
  }
  .MuiDialog-paper {
    max-width: 800px !important;
  }

  #Header {
    padding: 0px !important;
    .title {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      background-color: $lightGray;
      height: 40px;
      padding: 5px 10px;
      .MuiDialogTitle-root {
        padding: 0 10px;
      }
    }
    .body-tool-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $platinumGray;
      height: 40px;
      padding: 0 10px 0 20px;

      .MuiTypography-root {
        font-weight: 200px !important;
      }

      .MuiButton-outlined {
        background-color: white;
      }
    }
  }

  .MuiDialogContent-root {
    padding: 0px !important;

    .body {
      width: 100%;
      height: 320px;
    }

    .left-body {
      padding: 10px 20px;
      height: 100%;
    }
    .right-body {
      padding: 10px 20px;
      height: 100%;
      .plan-pill {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        margin-bottom: 5px;
        .icons {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.trainer-content {
  overflow: hidden !important;
}
