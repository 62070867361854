@import '../../../css/index';

#PulseCampaignEditStepView {
  height: calc(100% - 100px);

  > div {
    height: calc(100% - 46px);

    > div.MuiBox-root {
      height: 100%;
    }
  }

  .MuiAppBar-root {
    background: $solitudeGray !important;
  }

  .MuiToolbar-root {
    min-height: 51px;
    height: 51px;
    justify-content: space-between;
    align-items: flex-end;
    font-size: $fs_header;

    div {
      .manage-survey-tabs {
        height: 100% !important;
      }
    }

    .MuiAppBar-root {
      background: $ghostWhite;
    }

    .MuiBox-root {
      padding: 0 20px !important;
    }

    .MuiTab-labelIcon {
      min-height: inherit !important;
      height: 51px !important;

      .MuiTab-wrapper {
        flex-direction: row;
        padding: 0;
        font-size: $fs_secondary;
        text-transform: capitalize;
        font-size: $fs_default !important;

        *:first-child {
          margin-bottom: 0;
          margin-right: 10px;
          font-size: 20px;
        }
      }
    }
  }

  .MuiTabs-root {
    min-height: inherit !important;
    background-color: inherit !important;

    .MuiTab-root {
      letter-spacing: normal !important;
      font-weight: 600 !important;
      margin-right: 10px !important;
      background-color: $platinumGray !important;
      border-top-right-radius: 4px !important;
      border-top-left-radius: 4px !important;
    }

    .MuiTab-textColorPrimary.Mui-selected {
      @include themify($themes) {
        color: themed('activeTabColor') !important;
      }
      background-color: white !important;
      box-shadow: 5px 5px 10px $lightGray !important;
    }

    .MuiTabs-indicator {
      @include themify($themes) {
        background-color: themed('activeTabBgColor') !important;
      }
    }

    .MuiTab-root.error {
      color: $red !important;
    }

    .MuiTab-fullWidth {
      flex-basis: auto;
    }
  }
}
