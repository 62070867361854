@import '../../css/colors';

#WeightMenu {
  width: 65px;
  border-right: 1px solid #d3d4d5;

  input {
    text-align: center;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid $darkTurquoise !important;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
