$bgColor: white;
$bgDarkColor: rgba(128, 128, 128, 0.3);
$fgColor: #6f8096;

#CampaignRowTimeline {

  .scroll-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: #f1f2f6;
    margin: 0px 5px 0px 5px;
    padding: 10px 35px 0px 0px;

    &.border-status-initiated {
      border-left: 6px solid #a5aaca;
      color: $fgColor !important;
    }

    &.border-status-completed {
      border-left: 6px solid #d2a69b;
      color: $fgColor !important;
    }

    .left-button {
      cursor: pointer;
      margin-right: 10px
    }

    .right-button {
      cursor: pointer;
    }

    .arrow {
      width: 24px;

      .line {
        margin-top: 3px;
        width: 18px;
        background: #77879C;
        height: 2px;
        float: left;
      }
  
      .point-right {
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 6px solid #77879C;
        float: right;
      }
  
      .point-left {
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right: 6px solid #77879C;
        float: left;
      }
    }
   
  }

  .campaign-row-timeline {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-left: 20px;
    min-height: 100px;
    background-color: #f1f2f6;
    margin: 0px 5px 5px 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    scroll-behavior: smooth;

    &.border-status-initiated {
      border-left: 6px solid #a5aaca;
      color: $fgColor !important;
    }

    &.border-status-completed {
      border-left: 6px solid #d2a69b;
      color: $fgColor !important;
    }

    .timeline-surveys {
      display: flex;
      flex-direction: row;
    }
  }
}
