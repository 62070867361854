$leftNavClosedWidth: 56px;
$leftNavOpenWidth: 210px;
$leftNavTransitionDuration: 275ms;
$topNavHeight: 64px;
$calendarTopToolbarHeight: 66.75px;
$userDashboardTopToolbarHeight: 64px;

// MUI Breakpoints
$xs_screen: 0px;
$sm_screen: 600px;
$md_screen: 960px;
$lg_screen: 1280px;
$xl_screen: 1920px;

$spacer: 1rem;

// Calendar Colors
$cal_colors: rgba(95, 68, 129, 0.3), rgba(5, 209, 206, 0.3), rgba(226, 108, 108, 0.3),
  rgba(139, 190, 234, 0.3), rgba(106, 202, 176, 0.3), rgba(2, 2, 2, 0.3), rgba(255, 204, 110, 0.3);

$cal_colors_big: rgb(95, 68, 129), rgb(5, 209, 206), rgb(226, 108, 108), rgb(139, 190, 234),
  rgb(106, 202, 176), rgb(2, 2, 2), rgb(255, 204, 110);
