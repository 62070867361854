@import '../css//index';

.step-container {
  border-radius: 25% !important;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-right: -10px;
  .increase-step,
  .decrease-step {
    height: 14px;
    width: 14px;
  }
  .increase-step {
    border-top-left-radius: 2.5px;
    border-top-right-radius: 2.5px;
    border-bottom: 1px solid white;
  }
  .decrease-step {
    border-bottom-right-radius: 2.5px;
    border-bottom-left-radius: 2.5px;
  }
}
