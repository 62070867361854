@import '../../../css/index';

$toggleSelectedBgColor: rgba(33, 150, 243, 0.3);
$toggleSelectedFgColor: rgb(41, 144, 232);
$fgColor: #848da2;
$chipColor: #e9e9f0;
$bgDarkColor: rgb(213, 213, 222);
$bgDisabled: #ebebeb;
$surveySelectorHeight: calc(100vh - 150px);
$currentSurveyMaxHeight: calc(100vh - 365px);
$currentSurveyMinHeight: 300px;

#PulseCampaignSurveySelector {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 15px 15px 5px 30px;
  overflow-y: none;
  overflow-x: hidden;
  min-height: 60px;

  .survey-selector-status-key {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-end;

    .survey-selector-status-key-item {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;

      .MuiBadge-badge {
        height: 14px;
        width: 14px;
        min-height: 14px !important;
        min-width: 14px !important;
      }

      .status-key-badge {
        height: 14px;
        width: 14px;
      }

      .status-key-text {
        right: 0;
        width: 60px;
        margin-left: 8px;
        font-size: 12px;
        font-weight: 600;
        color: $fgColor;
      }
    }
  }

  .MuiBadge-colorPrimary {
    background-color: #96dccd;
  }

  .MuiBadge-colorSecondary {
    background-color: #fde696;
  }

  .MuiBadge-colorError {
    background-color: #74798b;
  }

  .MuiBadge-anchorOriginTopLeftCircular {
    top: 0%;
    left: 30%;
    transform: scale(1) translate(0%, 0%);
    transform-origin: 0% 0%;
  }

  .survey-selector-root {
    width: 100%;

    .survey-selector-root-grid {
      width: 100%;
      max-width: 100%;
      flex-wrap: wrap;

      .survey-selector-root-grid-btn-grp {
        display: unset !important;
        border-radius: 4px;
      }
    }

    .survey-selector-root-grid-btn {
      max-height: 38px;
      border: 1px solid rgba(0, 0, 0, 0.12);
    }

    .survey-selector-root-grid-item {
      display: flex;
      justify-content: center;

      .MuiGrid-grid-xs-true {
        justify-content: center;
        display: flex;
      }
    }

    .survey-selector-survey-badge {
      .MuiBadge-badge {
        height: 14px;
        width: 14px;
        min-height: 14px !important;
        min-width: 14px !important;
      }

      &.off {
        .MuiBadge-badge {
          display: none !important;
        }
      }
    }

    .MuiBadge-colorPrimary {
      background-color: #96dccd;;
    }

    .MuiBadge-colorSecondary {
      background-color: #fde696;
    }

    .MuiBadge-colorError {
      background-color: #74798b;
    }

    .MuiBadge-anchorOriginTopRightCircular {
      top: -25%;
      right: -10%;
      transform: scale(1) translate(50%, -50%);
      transform-origin: 100% 0%;
    }

    .MuiToggleButton-root {
      background-color: white;
      border-radius: 10px !important;
      margin: 0 5px 5px 5px;
      font-weight: bold;
      font-size: $fs_subheader;
      text-align: center;
      line-height: normal;
    }

    .MuiToggleButton-root.Mui-selected {
      background-color: $toggleSelectedBgColor;
      color: $toggleSelectedFgColor;
    }

    .MuiToggleButton-root.Mui-disabled {
      color: rgba(0, 0, 0, 0.12) !important;
    }
  }

  .selector-error {
    border: 1px solid red !important;
  }

  .survey-selector-title {
    display: flex;
    min-width: 105px;
    @include themify($themes) {
      color: themed('calEventTextColor') !important;
    }
    margin-right: 10px;
    font-size: $fs_default;
    font-weight: bold;
    text-align: center;
  }

  .survey-selector-survey-btn {
    width: 22px;
    height: 22px;
  }
}

#PulseCampaignCurrentSurvey {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 98%;
  flex-grow: 1;
  flex-shrink: 1;
  border-radius: 10px;
  margin: 0 15px 0 15px;
  background-color: white;
  overflow-y: auto;
  min-height: $currentSurveyMinHeight;
  max-height: $currentSurveyMaxHeight;
  height: 100%;

  .survey-selector-survey-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 10px 0 10px;
  }

  .survey-selector-survey-card-header {
    display: flex;
    flex-direction: row;
    margin: 10px 0 0 10px;
    justify-content: space-between;

    .survey-card-header-actions {
      display: flex;
      flex-direction: row;

      span {
        padding: 0 8px;
      }
    }

    .survey-card-btn {
      padding: 5px !important;
    }

    .survey-card-btn-avatar {
      max-height: 32px;
      max-width: 32px;
      color: rgba(0, 0, 0, 0.58);
      background-color: $bgDarkColor !important;

      &.disabled {
        color: rgba(0, 0, 0, 0.18);
        background-color: $bgDisabled !important;
      }
    }
  }

  .survey-selector-survey-card-name {
    display: flex;
    width: 50%;
  }

  .survey-selector-survey-card-status {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    margin-left: 10px;
  }

  .MuiChip-outlinedPrimary {
    border: 1px solid #e8e8e8;
    background-color: $chipColor;
  }

  .MuiChip-sizeSmall {
    height: 30px !important;
    padding: 0 10px 0 10px;
  }

  .MuiChip-root {
    color: $fgColor;
    font-weight: bold;
    font-size: 14px !important;
    font-size: clamp(10px, 1vw, 14px) !important;
    border-radius: 4px !important;
    text-transform: uppercase;
  }

  .survey-selector-survey-card-intro {
    display: flex;
    margin: 20px 0 10px 10px;

    &.hasValue {
      margin-bottom: 25px;
    }
  }

  .survey-selector-survey-card-outro {
    display: flex;
    margin: 0px 0 0px 10px;

    &.hasValue {
      margin-bottom: 25px;
    }
  }

  .survey-selector-survey-card-intro-text {
    width: 100%;
    margin: 10px 10px 0 10px;
  }

  .survey-selector-survey-card-questions {
    display: flex;
    height: 100%;
    width: 100%;
    margin: 10px 0 0 10px;
    padding-right: 10px;

    .quest-error {
      color: red !important;
    }
  }

  .survey-selector-survey-card-question {
    display: flex;
    margin: 0 0 20px 0;
  }

  .no-questions-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    text-align: center;
    letter-spacing: 1px;
  }

  .no-questions-heading {
    margin-bottom: 5px;
    color: $fgColor;
    font-weight: bold;
    font-size: $fs_default;
  }

  .no-questions-subheading {
    color: $fgColor;
    font-size: $fs_secondary;
  }
}

#SortableQuestionsGrid {
  height: 100%;
  width: 100%;

  .survey-question-sortable-grid {
    margin-top: 5px;
    margin-left: 0;
    width: 100%;
    align-self: center !important;
  }

  .survey-question-sortable-grid-item {
    width: 100%;
  }

  .survey-question-container {
    width: 100%;
    margin: 5px;
  }
}

#PulseCampaignSurveySelectorGrid {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: $surveySelectorHeight;
  height: 100%;

  .survey-selector-grid-top-container {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .survey-selector-grid-bottom-container {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .survey-selector-grid-top-item {
    width: 100%;
  }

  .survey-selector-grid-bottom-item {
    height: 100%;
    max-height: $currentSurveyMaxHeight;
    min-height: $currentSurveyMinHeight;
  }
}
