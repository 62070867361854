@import '../css/index';

/* Styleguide Screen Specific */
.styleguide {
  margin: 16px;
  background-color: $whiteSmoke;

  h3 {
    font-size: 18px;
    margin: 0 0 8px;
  }

  h5 {
    font-size: $fs_secondary;
    margin: 12px 0 8px;
  }

  section {
    background: $white;
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 16px;
  }

  Button:not(:first-child) {
    margin-left: 10px;
  }
}

// /* Buttons */
// .MuiButton-root {
//   height: 34px !important;
//   color: $viridianGreen !important;
//   font-size: 12px !important;
//   font-weight: 700 !important;
//   padding: 6px 12px !important;
// }

// .MuiButton-root:not(:first-child):not(.group) {
//   margin-left: 10px !important;
// }

// .MuiButton-outlined {
//   border-color: $lightGray !important;

//   &:disabled {
//     border-color: $lightGray !important;
//   }
// }

// .MuiButton-outlined,
// .MuiButton-text {
//   &.btn-remove {
//     color: $indianRed !important;
//   }

//   &:hover {
//     background-color: $darkTurquoise10 !important;

//     &.btn-remove {
//       background-color: $indianRed10 !important;
//     }
//   }
// }

// .MuiButton-contained {
//   background: $viridianGreen !important;
//   color: $white !important;

//   &.btn-remove {
//     background: $indianRed !important;

//     &:hover {
//       background-color: $indianRed80 !important;
//     }
//   }

//   &:hover {
//     background-color: $darkTurquoise80 !important;
//   }

//   &:disabled {
//     background-color: $platinumGray !important;
//   }
// }

// .Mui-disabled {
//   color: $spanishGray !important;
// }

// /* Input */
// form {
//   margin-left: 6px !important;
// }

// .MuiFormControl-marginDense {
//   margin: 2px 0 12px !important;
// }

// .MuiInputLabel-root {
//   color: $spanishGray !important;
//   font-size: 12px !important;
//   font-weight: 700 !important;
// }

// .MuiInputLabel-asterisk {
//   color: $indianRed !important;
// }

// .MuiInputBase-root {
//   height: 34px !important;

//   .MuiInputBase-input {
//     font-size: 13px !important;
//   }
// }

// .Mui-focused {
//   .MuiOutlinedInput-notchedOutline {
//     border-color: $darkTurquoise !important;
//   }
// }

// /* Drawer */
// .MuiDrawer-paperAnchorRight {
//   width: 500px;
//   top: 64px !important;
//   background-color: $white !important;

//   .MuiPaper-root {
//     background-color: $white !important;

//     .MuiCardHeader-root {
//       height: 50px !important;
//       flex-grow: 1;
//       background-color: $solitudeGray !important;

//       .MuiCardHeader-title {
//         font-size: $fs_secondary !important;
//         font-weight: 700 !important;
//       }

//       .MuiIconButton-root {
//         margin: 0;
//         color: #80868a;
//       }
//     }

//     .button-container {
//       padding: 8px 10px;
//       background-color: $ghostWhite;
//       display: flex;
//       justify-content: flex-end;

//       button:not(:first-child) {
//         margin-left: 10px;
//       }
//     }

//     .MuiCardContent-root {
//       padding: 8px 16px !important;
//     }
//   }
// }
