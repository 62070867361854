#RatingDisplay {

  .MuiFormGroup-root {
    flex-direction: row;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: rgba(0, 0, 0, 0.54);
  }

}
