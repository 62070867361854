#CustomCollectionEditScreen #FieldsTab {
  padding: 1rem 2rem;
  background: #fff;
  height: calc(100vh - 160px);
  overflow-y: auto;
  align-content: flex-start;

  .MuiFormControl-root {
    padding-right: 20px !important;
    margin-bottom: 10px;
  }
  .remove-icon {
    padding-top: 13px;
    cursor: pointer;
  }
  .flex-align {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fields-actions {
    padding: 0px 18px 12px 0px;
  }
}
