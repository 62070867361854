@import '../../../css/index';

.calendar-pill {
  .not-going {
    text-decoration: line-through;
    background-color: $spanishGray !important;
  }

  .unread-indicator {
    width: 8px;
    height: 8px;
    background-color: $errorRed;
    border-radius: 50%;
    top: -2px;
    position: absolute;
    right: -2px;
  }

  .pill-icon {
    min-width: 20px !important;
  }
}
