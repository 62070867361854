.preview-cell {
  text-decoration: underline;
  font-weight: 500;
  span:hover{
    color:#43a1a0;
    text-decoration: underline;
    font-weight: 500;
  }

  .attachment-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    cursor: pointer;

    .media-off {
      display: none;
    }

  }

  .media-preview {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    object-fit: contain;
    object-position: center;
    
    justify-content: center;
    align-items: center;
    z-index: 999999;

    &.iframewrapper::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
    
    &.iframewrapper iframe {
      vertical-align: top;
    }
  
    &.image {
      max-height: 100%;

      max-width: 75px;
      max-height: 40px;
      z-index: 9999;

      max-height: 50px;
      height: 50px;
      background-color: white;
    }
  
    &.file {
      width: 100%;
      max-height: 80px;
      justify-content: center;
      align-items: center;
      color: #788998 !important;

      max-width: 75px;
      max-height: 40px;
      z-index: 1;

      width: 100%;
      max-height: 50px;
      justify-content: center;
      align-items: center;
      color: #788998 !important;
    }
  }

  .cell-content-card-container-type {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    align-items: center;
    left: 0;
    z-index: 1;
  }

  .cell-preview-img {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    align-items: center;
    left: 0;
    z-index: 2;
    background-color: white;
  }

  .media-off {
    display: none;
  }

  .preview-img {
    max-width: 75px;
    max-height: 40px;
  }

}