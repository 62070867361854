@import '../../../../css/index';

#UserTrainingPlanTakeWidget {
  height: 100%;
  width: 100%;
  background-color: white;
  overflow-y: auto;
  
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  .MuiTypography-subtitle1 {
    font-weight: 400 !important;
  }

  .content-item {
    padding: 0px !important;
  }

  .MuiGrid-container {
    height: 100%;
    flex: 1;

    .preview-body {
      display: flex;
      height: 100%;
      min-height: calc(100% - 173px);
      flex: 1;

      .MuiAccordion-root:before {
        background-color: #f2f6ff !important;
      }
    }

    .content-disabled {
      pointer-events: none;
    }

    .preview-column {
      overflow-y: auto;
      padding-bottom: 20px;

      .no-content-selected {
        display: flex;
        justify-content: space-around;
        margin-top: 40px;
        font-weight: 700px;
      }

      .quiz-toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 70px;
        padding-right: 20px;
        .quiz-score {
          color: $red;
        }

        .quiz-answered {
          margin-left: 20px;
        }

        .quiz-answered-chip {
          background-color: #adafbb;
        }

        .quiz-answered-chip-text {
          color: white;
        }

        .quiz-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 20px;
          font-size: 18px !important;
          font-weight: 700 !important;
        }
      }
    }

    .preview-container {
      padding: 20px 25px;
      display: flex !important;
      flex: 1 !important;
      overflow-y: auto;

      .certificate-title,
      .certificate-link {
        display: flex;
        justify-content: space-around;
        margin: 10px;
      }

      .certificate-link {
        color: $darkTurquoise;
        cursor: pointer;
      }

      .take-title-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $nightRider;
      }

      .mark-button-container {
        display: flex;
        justify-content: flex-end;
      }

      .media-background {
        margin-top: 10px;
        display: flex;
        height: 350px;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 10px;

        .media-preview {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          margin: 0 auto;
          object-fit: contain;
          object-position: center;
          
          justify-content: center;
          align-items: center;
          z-index: 999999;
          pointer-events: none;
      
          &.youtube {
            iframe {
              max-width: 800px;
            }
          }
      
          &.vimeo {
            width: 100%;
          }
      
          iframe {
            width: 800px;
            height: 350px;
            pointer-events: all;
          }
      
          &.iframewrapper::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }
          
          &.iframewrapper iframe {
            vertical-align: top;
          }
        
        }

        .mux-player-container {
          display: flex;
          width: 100%;
          justify-content: center;
          min-width: 800px;
          max-width: 800px;
          max-height: 350px;
        }
    
        .mux-player {
          width: 100%;
          min-width: 800px;
          max-width: 800px;
          min-height: 350px;
          max-height: 350px;
        }
      }

      .broken-media-container {
        height: 100%;
  
        .broken-media {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          color: #D9D9D9 !important;
        }
      }

      .media-container {
        height: 100%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        img {
          max-height: 350px;
        }
      }

      .exercise-content-item {
        margin-top: 10px;
        display: flex;
        width: 100%;
        margin-bottom: 10px;
      }

      .link-button {
        display: flex;
        align-items: center;
        .MuiButton-label {
          white-space: nowrap;
        }
        svg {
          margin-left: 5px;
        }
      }

      .link-string {
        margin: 10px 0;
        color: $viridianGreen;
        font-weight: 600;
        text-decoration: underline;
        // color: $spanishGray;
        cursor: pointer;
        width: fit-content;
      }

      .MuiTypography-h5 {
        font-size: 18px !important;
      }

      .trainer-container {
        color: black;
        display: flex;
        margin-top: 10px;
        align-items: center;
        pointer-events: auto !important;
      }

      .description-area {
        border-top: 1.5px solid $whiteSmoke;
      }

      .description-area-title {
        margin-top: 10px;
      }

      .description-area-body {
        margin-top: 10px;
      }
    }

    .training-items-menu {
      background-color: #f2f6ff;
      padding-left: 35px;
      padding-top: 10px;
      position: relative;
      overflow-y: auto;
      padding-bottom: 20px !important;

      .line {
        position: absolute;
        border-right: 1px solid $lavenderGray;
        height: 100%;
        z-index: 1;
        width: 13px;
        top: 15px;
      }

      .content-item-body,
      .content-item-body-disabled {
        padding: 8px 12px;
        height: 70px;

        .MuiGrid-root {
          display: flex;
          align-items: center;
          height: 100%;

          .content-item-info {
            margin-left: 10px;
            height: 100%;
          }
        }
      }

      .content-mark-container {
        display: flex;
        align-items: center;

        .completed-item {
          background-color: $viridianGreen;
          height: 15px;
          width: 15px;
          z-index: 2;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          svg {
            height: 15px;
            width: 15px;
            color: white;
          }
        }

        .content-item-mark-selected {
          height: 15px;
          width: 15px;
          border-radius: 50%;
          background-color: $darkTurquoise;
          z-index: 2;
          margin-left: 5px;
        }
        .content-item-mark {
          height: 15px;
          width: 15px;
          border-radius: 50%;
          border: 1px solid $lavenderGray;
          background-color: #f2f6ff;
          z-index: 2;
          margin-left: 5px;
        }
      }

      .selected-content-item {
        background-color: white;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
        border: none !important;
        color: $viridianGreen;

        .content-item-subtitle {
          color: $gunPowder !important;
          font-weight: 700 !important;
        }
      }

      .quiz-item {
        min-height: 60px;
        max-height: 100px;
        margin-left: 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border: 1px solid $lavenderGray;
        z-index: 1;
        margin-bottom: 10px;
        background-color: #f2f6ff !important;

        &.selected-quiz-item {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          border: none;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
          background-color: $white !important;
        }

        .quiz-item-body {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          padding: 8px 12px;
          align-items: center;
          cursor: pointer;

          .left-menu-item-title {
            display: flex;
            align-items: center;
          }

          .quiz-icon {
            color: $plusIconTeal;
            background-color: white;
            height: 35px;
            width: 35px;
            z-index: 2;
            margin-right: 10px;
            cursor: pointer;
            border-radius: 50%;
          }
          .item-subtitle {
            margin-left: 5px;
            color: $nightRider;
            display: flex;
            align-items: center;
          }
        }
      }

      .training-item {
        background-color: #f2f6ff;
        margin: 0px !important;
        width: 100%;
        padding-bottom: 10px;

        .flex {
          display: flex;
          align-items: center;
          width: 100%;
        }

        .due-date {
          color: $darkTurquoise;
        }

        .MuiCollapse-container {
          margin-left: 5px;
        }

        .training-item-container {
          padding-left: 0px;

          .training-item-body {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .training-item-body-disabled {
            .left-menu-item-title,
            .item-subtitle {
              opacity: 0.5;
            }
          }
        }

        .content-item-container {
          padding-right: 0px;
          cursor: pointer;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          border: 1px solid $lavenderGray;
          border-right: 0px !important;
          margin: 6px 0px;

          .thumbnail-container {
            height: 50px;
            width: 50px;
            min-width: 50px;
            border-radius: 10px;
            background-color: $lavenderGray;
            display: flex;
            align-items: center;
            justify-content: space-around;
            img {
              border-radius: 10px;
            }
          }

          .indicator-icon {
            margin-right: 5px;
          }
        }

        .content-item-title {
          font-weight: 700 !important;
          height: 66%;
          overflow: hidden;
          line-height: 1.25;
        }

        .content-item-subtitle {
          letter-spacing: 0.05em;
          font-weight: 600;
          font-size: 12px;
          color: $spanishGray;
          display: flex;
          align-items: center;
          text-transform: capitalize;
        }

        .MuiAccordionSummary-content {
          margin: 0px !important;
        }

        .training-item-check-box,
        .content-item-check-box {
          padding: 0 !important;
        }

        .shown {
          -moz-transition: height 0.5s;
          -ms-transition: height 0.5s;
          -o-transition: height 0.5s;
          -webkit-transition: height 0.5s;
          transition: height 0.5s;
        }

        .hidden {
          display: none;
        }

        .left-menu-item-title {
          display: flex;
          align-items: center;
          letter-spacing: 0.05em;
          font-size: $fs_secondary !important;
        }

        .quiz-icon {
          color: $plusIconTeal;
          background-color: white;
          height: 25px;
          width: 25px;
          z-index: 2;
          margin-right: 10px;
          cursor: pointer;
          border-radius: 50%;
        }

        .menu-icon {
          height: 25px;
          width: 25px;
          z-index: 2;
          margin-right: 10px;
          background-color: $plusIconTeal;
          border-radius: 50%;
          color: WHITE;
          cursor: pointer;
        }

        .expanded-icon {
          transform: rotate(90deg);
        }

        .item-subtitle {
          margin-left: 40px;
          color: $nightRider;
          display: flex;
          align-items: center;

            .block {
                margin-right: 3px;
              }

            .title {
                font-weight: 500;
                color:#727070
              }
        }

        .MuiCheckbox-root {
          padding-top: 0px !important;
        }
      }
    }

    .preview-header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 30px 35px 10px;
      min-height: 170px;
      background-color: #f2f6ff;

      .MuiChip-root {
        margin-top: 2px;
        margin-right: 2px;
        height: 24px !important;
        max-width: 300px;
      }

      .MuiButton-root {
        margin-left: -15px;
        margin-top: -15px;
        display: flex;
        align-items: center;
      }

      .MuiTypography-root {
        // margin-bottom: 5px;
        color: $nightRider;
      }

      .MuiTypography-h5 {
        font-size: 20px !important;
      }

      .title-container {
        display: flex;
        flex-direction: row;
      }

      .readonly-status {
        display: flex;
        margin-left: 5px;
      }

      .status-active {
        @include themify($themes) {
          color: themed('activeTabBgColor') !important;
        }
      }
    
      .status-due {
        color: #e85c5c;
      }

      .progress-bar-container {
        position: relative;
        display: flex;
      }

      .progress-label {
        position: absolute;
        right: 1% !important;
      }

      .MuiLinearProgress-root {
        width: 100%;
        height: 20px;
        border-radius: 20px;
        background-color: $darkTurquoise20;
      }

      .MuiLinearProgress-bar1Determinate {
        background-color: $darkTurquoise;
        border-radius: 20px;
      }
    }
  }

  .content-item-disabled {
    .content-item-container {
      border-color: #f2f6ff !important;

      cursor: unset !important;
    }
    .content-item-body {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      opacity: 0.4;
      background-color: $platinumGray;
      border: none;
    }
  }
}
