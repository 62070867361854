@import '../../../../css/colors';

#ResponsesTabPanel {
  padding: 10px;
  background: #fff;
  height: calc(100vh - 128px);
  align-content: flex-start;
  overflow-y: auto;

  #ResponsesListContainer {
    height: 100%;

    .add-response-button {
      margin-bottom: 10px;
    }
  }

  .no-responses {
    margin: 26px;
    margin-left: 47px;
  }
}

#PaperSurveyParticipantsPicker {
  height: 80vh;
  width: 50vw;
  min-width: 450px;
  min-height: 375px;
  margin: 10vh auto;
  overflow: hidden;
  border-radius: 4px;

  .participants-select-container {
    height: calc(100% - 46px) !important;
    max-height: calc(100% - 46px) !important;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  .paper-survey-participants-picker-footer {
    height: 46px;
    background-color: $whiteSmoke;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 10px;
  }
}
