@import '../../css/index';

#PulseCategoryEditModal {
  .MuiDialog-paperWidthSm {
    min-width: 60vw;
    min-height: 60vh;
  }

  .dialog-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px 10px 40px;
    border-bottom: 1px solid gray;

    .dialog-title {
      font-size: $fs_subheader;
      margin-right: 20px;
    }

    .dialog-left {
      display: flex;
      flex-direction: row;
    }

    .dialog-right {
      .delete-btn {
        color: $errorRed !important;
      }
    }
  }

  .dialog-content {
    padding: 20px 40px 20px 40px;

    .category {
      .category-header {
        margin-bottom: 8px;
        font-weight: bold;
      }
      .category-select {
        width: 300px;
        margin-bottom: 20px;
      }
    }
  }
}
