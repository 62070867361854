#YouTubePreviewModal {
  
  .header-text{
    background-color: #f4f5fa;
    color:#6f8096;
    border-bottom: #dee3ee solid 1px;
  
    .details-title{
      display:flex;
      justify-content: space-between;
      align-items: center;
    }
    
    .MuiTypography-h6{
      font-weight: 700 !important;
    }
  }
}