#calendarEventDrawerLocationSearchBar {
  .autocomplete-dropdown-container {
    .suggestion-row {
      display: flex !important;
      margin: 5px 0 !important;

      h6 {
        margin-bottom: 0px !important;
      }

      .suggestion-icon {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
      }
    }
  }
}
