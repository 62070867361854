#OptionsTabPanel {
  padding: 16px;

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0;
    padding-left: 10px;
    padding-right: 40px;
    min-width: 250px;
    margin-bottom: 10px;
  }

  .MuiInputBase-root {
    margin-right: 1rem;
    padding-right: 20px;
    min-width: 200px;
    max-width: 240px;
    margin-bottom: 10px;
  }

  .toggle-text{
    display: flex;
    align-items: center;
  }
}

.bold-underlined-infotext{
  text-decoration: underline !important;
  font-weight: bold !important;
}


