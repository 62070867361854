@import '../../css/_index.scss';

$bgColor: white;
$bgDarkColor: rgba(128, 128, 128, 0.3);
$fgColor: #6f8096;
$inactiveColor: #f7f7f7;

#PulseCampaignTypesNew {
  padding: 20px 100px 0 100px;
  width: calc(100vw - 210) !important;
  background-color: #f1f2f6;
  height: calc(100vh - #{$topNavHeight});
  overflow-x: hidden;
  overflow-y: auto;

  #DashboardToolbar {
    height: $topNavHeight;
  }

  #PulseCampaignTypesNewForm {
    margin-top: 30px;
  }

  .set-details {
    display: flex;
    align-items: flex-end;
    gap: 60px;
  }

  .set-actions {
    margin-top: 20px;
  }

  .primary-text {
    font-size: $fs_default;
    font-weight: 700;
    margin-bottom: 5px;

    &--horizontal {
      margin-bottom: 0;
    }
  }

  .name-input {
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
      padding: 0;
    }

    .name-container {
      display: flex;
      height: fit-content;
      width: 325px;
      flex-wrap: wrap;
      min-width: 0;
      background-color: white;
      border-radius: 4px;

      .error-text {
        margin-top: 4px;
      }

      .set-name {
        justify-content: flex-start;
      }
    }
  }

  .active-checkbox {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .delete-btn {
    background-color: $errorRed !important;
    color: white;
  }
}
