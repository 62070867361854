@import '../../../css/index';

#UserSurveyTakeWidget {
  @include themify($themes) {
    background-color: themed('primarySurfaceColor');
  }
  margin: 15px;
  margin-top: 0;

  /* please do not delete - Linda */
  // height: calc(100% - 80px);
  /* please do not delete - Linda */
}

#PaperSurveyInfoCard {
  margin: 15px;
  padding: 16px;
  @include themify($themes) {
    background-color: themed('primarySurfaceColor');
  }
  width: 600px;

  .paper-survey-info-top {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      margin: 0px 10px;

      .paper-survey-info-user-card {
        display: flex;
        flex-direction: column;
        flex-basis: auto;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border: 1px solid $black40;
        border-radius: 4px;

        .MuiAvatar-root {
          cursor: auto !important;
          margin-bottom: 10px;
        }
      }
    }
  }

  .paper-survey-info-bottom {
    margin-top: 16px;

    .paper-survey-response-date-time-pickers-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .MuiFormControl-fullWidth {
        margin-right: 15px;
      }

      .MuiTextField-root {
        margin-top: 0px !important;
      }
    }
  }
}
