#InvitationsLinkDialog {
  
  .linkFieldPaper {
    padding: 2px 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .linkFieldInput {
    margin-left: 6px;
    width: 90%;
  }

  .linkFieldDivider {
    height: 28px;
    margin: 4px;
  }

  .linkIconButton {
    padding: 10px;
  }

  .surveyLinkDialogContent {
    min-width: 600px;
    padding: 0px 20px 20px 20px;
  }

  .surveyLinkDialogTitle {
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
  }
}

.MuiConfirmDialogTitle {
  text-align: center;
}


