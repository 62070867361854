@import '../../../css/colors';

#WeightProfileEditScreen {
  .grid-layout {
    display: flex;
    height: 100%;
    width: 100%;
    // display: grid;
    // height: 100%;
    // grid-template-columns: 18% minmax(82%, max-content);
    // // grid-template-columns: minmax(80%, max-content);
    // grid-template-rows: auto;
  }

  .categories-screen {
    min-width: 77vw;
    width: max-content;
    width: -moz-max-content;
    max-width: 100vw;
  }

  .roles-sidebar {
    width: 20vw;
    min-width: 20vw;
  }

  .checkbox {
    display: flex;
  }

  .checkbox input[type='checkbox'] {
    position: absolute;
    margin-left: unset;
  }

  .w-2 {
    width: 20%;
  }

  .w-3 {
    width: 30%;
  }

  .w-4 {
    width: 40%;
  }

  .w-7 {
    max-width: 160px;
    width: 70%;
  }

  .w-8 {
    width: 80%;
  }
}
