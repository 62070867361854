@import '../css/index';

#SelectedArchivedLabel {
  .default-label-btn {
    color: #474852;
    background-color: #c1c1d3;
    justify-self: center;
    align-self: center;
    max-width: 40px;
  }

  .default-label-text {
    color: #474852;
    font-weight: 500;
  }

  .default-btn-sizing {
    margin: 4px 0px 4px 8px;
    max-height: 20px;
    line-height: 20px;
  }

  .small-btn-sizing {
    margin: 4px 8px 4px 8px;
    max-height: 15px;
    line-height: 15px;
  }

  .default-text-sizing {
    font-size: 0.7rem;
  }

  .small-text-sizing {
    font-size: 0.65rem;
  }
}
