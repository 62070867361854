#UpcomingPulsesCard {
  height: 48%;
  width: 100%;
  padding: 0px 0px 0px 20px;
  min-width: 250px;

  .upcoming-pulses-card {
    height: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    background: url('../../../../../assets/img/campaign/Upcoming.png');
    background-size: 100% 100%;
  }

  .icon-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;

  .upcoming-pulses-icon {
    background-color: #6d79aa;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height:30px;
    width: 30px;
    color:white;

    .file-icon{
      height:16px;
      width: 16px;
    }
  }
}

  .MuiCardHeader-root{
    padding: 16px 16px 8px 16px;
  }

  .header-text {
  .MuiCardHeader-title{
    color:white;
    font-weight: 700 !important;

    &.MuiTypography-h5{
      font-size: 16px !important;
    }
  }

  .MuiCardHeader-subheader{
    color:white;
    font-size: 8pt;
  }
}
.no-drafts{
  font-size: 10pt;
  font-weight: 600;
  display: flex;
  justify-content: center;
  color:white;
}
.draft-container{
  width:100%;
  height: 100% !important;
  padding: 0px 20px 80px 20px;
  overflow-y: scroll;
}

.draft-container::-webkit-scrollbar {
  display: none;
}

.MuiGrid-item{
  width:100%;
}

.draft-row{
  width:100%;
  height: 50px;
  padding: 3px 10px;

  &:hover {
    // box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 8px 0px !important;
    cursor: pointer;
  }

      .draft-text .full-title {
      display: inline;
    }
    
    .draft-text .short-title {
      display: none;
    }
    
    @media (max-width: 1550px) {
      .draft-text .full-title {
        display: none;
      }
    
      .draft-text .short-title {
        display: inline;
      }
    }

  .draft-text{

  .name-text{
    color:white;
    font-size: 10pt;
    font-weight: 700 !important;
  }

  .date-text{
    color:#9796a9;
    font-size: 9pt;
  }
}
}

.date-cont{
  background-color:#3c5188;
  border-radius: 10px;
  width:38px;
  height:38px;
  padding-top: 9px;
 

  .day-text{
    color: #cfd4e1;
    font-size: 12pt;
    display:flex;
    justify-content: center;
    align-items: flex-start;
    font-weight: 600 !important;
    line-height: 1;
  }

  .month-text{
    color: #cfd4e1;
    font-size: 8pt;
    display:flex;
    justify-content: center;
    align-items: flex-end;
    line-height: .75;
  }
}

.arrow-cont{
  display:flex;
  justify-content: center;
  align-items: center;

.arrow {
  width: 24px;
}

.line {
  margin-top: 3px;
  width: 18px;
  background: white;
  height: 2px;
  float: left;
}

.point {
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid white;
  float: right;
}
}
}