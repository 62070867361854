@import '../../../css/colors';

#SurveyTagsWidget #WidgetHeader {
  color: #000;
  border-bottom: 1px solid #d7d7e2;

  .MuiCardHeader-content {
    align-self: center;
  }

  .MuiCardHeader-action {
    align-self: center;
    vertical-align: middle;
    margin-top: 0;
    margin-right: 0;

    .MuiTypography-root {
      font-size: 1.2rem;
      color: #888;
    }
  }

  .search {
    position: relative;
    float: right;
  }

  .search-icon {
    align-content: center;
    margin-left: 5px;
    height: 100%;
    position: absolute;
  }

  .search-textbox {
    padding-left: 30px;
    float: right;
    transition: width 0.5s;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }

  .search-textbox:focus-within {
    transition: width 0.5s;
    width: 200px;
  }
}
