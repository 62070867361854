@import '../../../../css/index';

.marginContainer {
  border-right: 2px $gainsboroGray solid;
  height: 100%;
}

.margin {
  margin: 6px;
}

.dateContainer {
  position: relative;
  border-right: 2px $gainsboroGray solid;
  cursor: pointer;
}

#TimelineRow:before {
  content: '';
  position: absolute;
  right: 100%;
  top: calc(50% - 12px);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid white;
}

#TimelineRow {
  border-bottom: 2px solid $whiteSmoke;
  padding: 10px 18px;
  background-color: white !important;
  border-radius: 4px;
  position: relative;
  margin: 6px 0px 6px 15px;
  display: inline-block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  .padded-container {
    width: 101%;
  }

  .time-plan-text {
    align-items: flex-start !important;
  }

  .time-plan-title {
    font-size: 16px;
    font-weight: 700;
  }

  .progress-label2 {
    font-size: 16px;
    font-weight: 700;
    position: absolute;
    right: 26px;
    bottom: 8px;
  }

  .due {
    margin-bottom: 15px;
  }

  .readonly-status {
    display: flex;
  }

  .lock {
    margin-left: 5px;
    height: 20px;
    width: 20px;
  }

  .lock-active {
    @include themify($themes) {
      color: themed('activeTabBgColor') !important;
    }
  }

  .lock-due {
    color: #e85c5c;
  }

  .past-due {
    color: red;
  }

  .break {
    color: gray;
  }

  .MuiLinearProgress-root {
    width: 100%;
    height: 20px;
    border-radius: 20px;
    background-color: rgba(208, 208, 208, 0.5) !important;
  }

  .owned .MuiLinearProgress-bar1Determinate {
    background-color: $tuscanYellow;
    border-radius: 20px;
  }

  .not-owned .MuiLinearProgress-bar1Determinate {
    background-color: $plusIconTeal;
    border-radius: 20px;
  }

  .MuiGrid-item {
    display: flex;
    // align-items: center;
    justify-content: space-between;
  }

  .active {
    margin-right: 5px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: $confirmGreen;
  }
  .inactive {
    margin-right: 5px;

    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: $errorRed;
  }
}

#PlanDateContainer {
  .circle-active {
    @include themify($themes) {
      background-color: themed('activeTabBgColor') !important;
    }
  }

  .circle-due {
    background-color: #e85c5c;
  }

  .arrow-up {
    transform: translate(50%, -50%) rotate(0.75turn);
  }

  .arrow-down {
    transform: translate(50%, -50%) rotate(0.25turn);
  }

  .circle {
    position: absolute;
    top: 48%;
    right: -2%;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    color: white;
  }

  .month {
    font-size: 15px;
    font-weight: 900;
  }

  .day {
    font-weight: 700;
    font-size: 22px;
  }

  .date-color {
    color: $spanishGray;
  }

  .past-due {
    color: #e85c5c;
  }
}

#ItemDateContainer {
  .circle-active {
    background-color: $spanishGray;
  }

  .circle-due {
    background-color: #e85c5c;
  }

  .circle {
    position: absolute;
    top: 50%;
    right: -2%;
    transform: translate(50%, -50%);
    height: 6px;
    width: 6px;
    border-radius: 50%;
    display: inline-block;
    z-index: 9;
  }

  .month {
    margin-left: 10px;
    color: $spanishGray;
    font-size: 11px;
    font-weight: 900;
  }

  .day {
    margin-left: 10px;
    color: $spanishGray;
    font-weight: 700;
    font-size: 18px;
  }

  .due-month {
    margin-left: 10px;
    color: #e85c5c;
    font-size: 11px;
    font-weight: 900;
  }

  .due-day {
    margin-left: 10px;
    color: #e85c5c;
    font-weight: 700;
    font-size: 18px;
  }
}

.button-container {
  justify-content: center;
  align-items: flex-end;
}

.MuiTypography-h6 {
  line-height: 1.2 !important;
}

hr {
  position: relative;
  border-top: 1px $gainsboroGray solid;
  top: calc(50% - 9px);
}

.timeline-item-card:before {
  content: '';
  position: absolute;
  right: 100%;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid white;
}

.timeline-item-card {
  margin: 2px 0px !important;
  min-height: 40px;
  padding: 4px 18px;
  background-color: white !important;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;

  .MuiButton-root {
    height: 26px !important;
  }

  .time-plan-title {
    margin-right: 10px;
  }

  .time-plan-type {
    font-style: italic;
    justify-content: center;
    margin-right: 10px;
  }

  .time-plan-due {
    font-weight: bold;
  }

  .time-plan-questions {
    font-weight: bold;
  }

  .time-plan-uploaded {
    font-weight: bold;
    color: $viridianGreen;
  }

  .time-plan-notup {
    font-weight: bold;
    color: #e85c5c;
  }

  .time-plan-overdue {
    font-weight: bold;
    color: #e85c5c;
  }
}

.upcoming-header {
  min-height: 40px;
  background-color: rgba(208, 208, 208, 0.5);
  border-radius: 5px;
  padding: 0px 25px;

  .header-title {
    font-size: 15px;
    font-weight: 600;
  }
}
