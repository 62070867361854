@import '../../../../css/index';

#MeasureModal {
  // width: 50%;

  .MuiDialogTitle-root {
    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
    }
    padding: 8px !important;
    background-color: $lavenderGray;
  }

  .measure-modal-toolbar {
    background-color: $platinumGray;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    .MuiButton-outlined {
      background-color: white;
    }
  }

  .MuiDialogContent-root {
    padding-bottom: 16px;
  }

  .subtext {
    margin-left: 20px;
  }

  .MuiFormControlLabel-root {
    margin-bottom: 0px;
  }

  .MuiFormControlLabel-label {
    font-weight: 600;
    font-size: $fs_secondary;
    color: black;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: $darkTurquoise;
  }

  .aggregation-select {
    width: 200px;
    margin: 0 10px;
  }

  .aggregation-row {
    margin: 20px;
  }

  .aggregation-row-2 {
    margin-left: 20px;
  }

  .date-range {
    width: 80px;
    margin: 0 10px;

    .MuiOutlinedInput-input {
      padding: 18.5px 10px;
    }
  }
}
