@import '../../css/index';

.authentication {
  .loading {
    z-index: 1;
    position: absolute;
    @include themify($themes) {
      background-color: themed('secondaryBgColor') !important;
    }
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  position: relative;
  height: 100vh;
  width: 100vw;
}
