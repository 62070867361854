#OrgUsersListColumnPopover{
    

.org-users-apply{
    display:flex;
    justify-content: flex-end;
}


}

#ColumnPopoverCard{
    width: 45vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  padding: 10px 20px;

  .column-checkbox{
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .column-card-actions{
    justify-content: end;
    align-items: flex-end;

    &.MuiCardActions-root{
      padding: 0px !important;
    }
  }
   
}

