@import '../../../../css/colors';
@import '../../../../css/constants';

#ImpactDashboard {
  height: calc(100vh - #{$topNavHeight});
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;

  .react-resizable-handle {
    display: none;
  }

  .drag-handle,
  .MuiCardHeader-root {
    cursor: default !important;
  }

  .react-grid-layout {
    .widget-container {
      overflow: hidden;
      border-radius: 4px;
      position: relative;
      overflow: hidden;
    }

    .react-grid-placeholder {
      background-color: $light;
    }
  }
}

.goal-create-menu {
  .MuiListItem-gutters {
    padding: 8px 24px 8px 14px !important;
    font-weight: 600;
    font-size: 15px;
  }

  .icon {
    margin-right: 6px;

    &.goals {
      color: $medAquamarine;
    }

    &.items {
      color: $airforceBlue;
    }

    &.plans {
      color: $topazYellow;
    }
  }
}
