@import '../../../css/index';

#detailsArea {
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 3px 28px 3px 8px !important;
    min-height: 45px;
    height: fit-content !important;
    align-items: flex-start;
    input {
      min-height: 20px !important;
    }
  }

  .required {
    color: $red;
  }

  .input-label {
    margin-bottom: 5px;
  }

  .groups-roles-error {
    fieldset {
      border: 1px solid $errorRed;
    }
  }

  .left-panel {
    .MuiInputBase-root,
    .MuiOutlinedInput-root,
    .MuiInputBase-fullWidth,
    .category-picker-container {
      background-color: white;
      .category-name-display {
        min-height: 45px;
      }
    }

    .details-item {
      .roles-groups-input {
        margin-bottom: 20px;
      }
    }
  }

  .right-panel {
    padding: 20px;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .training-items-details-svg {
      margin-left: auto;
      width: 600px;
    }
  }
}
