#OrganizationUsersWidget {
  width: 100%;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .MuiTableBody-root {
    .MuiTableCell-root {
      &:first-child {
        border-left: solid #ffcd6e 3px !important;
      }
    }
  }

  .MuiCardContent-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .user-avatar {
    height: 18px;
    width: 18px;
  }

  .userName {
    padding-left: 12px;
  }

  .hidden {
    color: #474852 !important;
    background-color: #C1C1D3 !important;
  }
}
