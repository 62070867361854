@import '../../css/index';

#NotificationTemplateEditor {
    width: 100% !important;
    max-height: 350px;
}

#EditorContainer {
    display: flex;
    width: 100% !important;
    height: 100% !important;
}