@import '../../../css/colors';
@import '../../../css/index';

#OrgUsersListFilterPopover{
    width: 65vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  flex-basis: auto;

  .filter-popover-card-header {
    border-bottom: 1px solid #e3e3e8;
    height: 48px;
    background-color: #eaeaea;

    .card-header-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .MuiIconButton-root {
        margin-right: -8px;
        margin-left: 4px;
      }
    }

  

    > .MuiCardHeader-content > span {
      font-size: $fs_default;
      font-weight: 700;
      color: black;
    }}

.org-users-apply{
    display:flex;
    justify-content: flex-end;
}



#ParticipantsSelect {
    .ag-root{
        height:50vh;
    }
        #SelectedPane_VirtualList{
            overflow-y:scroll!important;
            height:52vh !important;
            :first-child::-webkit-scrollbar {
                display: none;}
        }
        #SelectedPane_VirtualList::-webkit-scrollbar {
            display: none;}
    }
}

