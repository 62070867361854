@import '../../../../../css/index';

.ag-theme-alpine {
  /* use theme parameters where possible */
  font-size: 13px;
  font-family: 'Nunito', 'sans-serif';

  .ag-header-container {
    background-color: #f8f5f5;
  }

  .ag-row {
    background-color: #fff;
  }

  .ag-row-selected {
    @include themify($themes) {
      background-color: themed('buttonHoverColor') !important;
    }
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    @include themify($themes) {
      color: themed('accentColor') !important;
    }
  }
}
.ag-tooltip {
  background-color: rgb(46, 48, 63) !important;
  color: white !important;
  border: none !important;
}

.invitationHistory {
  text-transform: capitalize;
  .MuiPaper-root {
    width: 100%;
  }
}

.user-card {
  margin: 5px;

  .type-email-title-text {
    color: #aeb8d3;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2.2px;
  }
  .type-text {
    font-size: 13px;
    font-style: italic;
    padding-left: 10px;
  }
  .email-text {
    font-size: 13px;
    font-style: italic;
    padding-left: 10px;
  }
}
