@import '../../css/index';

$bgColor: #dce1ed;
$fgColor: #6f8096;

.cat-tab-btns {
  display: flex;
  align-items: center;
  gap: 2px;
  flex-wrap: wrap;
  .category-filter-btn {
    display: flex;
    align-items: center;
    padding: 6px 6px !important;
    justify-content: center;
    width: 325px;
    font-size: $fs_secondary !important;
    color: #74798b !important;

    &:hover {
      background-color: #dce1ed !important;
    }

    .chevron {
      color: #ffffff !important;
      transform: rotate(0deg);
      transition: transform 0.25s ease-in-out;
    }

    .spin {
      transform: rotate(180deg);
    }
  }

  .category-filter-btn-wrapper {
    color: #ffffff !important;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .dashboard-action {
    margin-left: auto !important;
  }
}

.mgmt-tab-btns {
  margin-left: 20px;

  .MuiToggleButton-root {
    background-color: rgba(255, 255, 255, 0.7) !important;
    @include themify($themes) {
      color: rgba(0, 0, 0, 0.38) !important;
    }
  }

  .MuiToggleButton-root.Mui-selected {
    @include themify($themes) {
      background-color: themed('buttonOtlBgColor') !important;
      color: themed('buttonOtlTextColorAlt') !important;
    }
  }

  .tab-btn-selected {
    max-height: 34px;
    max-width: 240px;
    text-transform: uppercase;
    color: $fgColor !important;
    font-weight: 700 !important;
    font-size: $fs_tertiary !important;
    background-color: $bgColor;
    margin-top: 6px;
    margin-right: 5px;
    padding: 18px 18px 18px 18px !important;
    box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1), inset 0 6px 8px 0 rgba(0, 0, 0, 0.1);

    .check-icon {
      margin-left: 5px;
      color: #0097fb;
      font-weight: 600;
    }

    &.border-cat-1 {
      border-left: 6px solid #a5aaca;
      color: $fgColor !important;
    }

    &.border-cat-2 {
      border-left: 6px solid #d7aba0;
      color: $fgColor !important;
    }

    &.border-cat-3 {
      border-left: 6px solid #99cad1;
      color: $fgColor !important;
    }

    &.border-cat-4 {
      border-left: 6px solid #4dabf5;
      color: $fgColor !important;
    }

    &.border-cat-5 {
      border-left: 6px solid #ffc107;
      color: $fgColor !important;
    }

    &.border-cat-6 {
      border-left: 6px solid #dd33fa;
      color: $fgColor !important;
    }

    &.border-cat-7 {
      border-left: 6px solid #d1ff33;
      color: $fgColor !important;
    }

    &.border-cat-8 {
      border-left: 6px solid #4aedc4;
      color: $fgColor !important;
    }

    &.border-cat-9 {
      border-left: 6px solid #3d5afe;
      color: $fgColor !important;
    }

    &.border-cat-10 {
      border-left: 6px solid #834bff;
      color: $fgColor !important;
    }
  }

  .tab-btn-unselected {
    max-height: 34px;
    max-width: 240px;
    font-weight: 700 !important;
    color: #6c6c7f !important;
    font-size: $fs_tertiary !important;
    background-color: $bgColor;
    margin-top: 6px;
    padding: 18px 18px 18px 18px !important;
    border-bottom: 0px !important;

    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);

    .MuiButton-root {
      height: 34px;
      max-height: 34px;
      margin-left: none !important;
      margin-right: 10px !important;
    }

    &.border-cat-1 {
      border-left: 6px solid #a5aaca;
      color: $fgColor !important;
    }

    &.border-cat-2 {
      border-left: 6px solid #d7aba0;
      color: $fgColor !important;
    }

    &.border-cat-3 {
      border-left: 6px solid #99cad1;
      color: $fgColor !important;
    }

    &.border-cat-4 {
      border-left: 6px solid #4dabf5;
      color: $fgColor !important;
    }

    &.border-cat-5 {
      border-left: 6px solid #ffc107;
      color: $fgColor !important;
    }

    &.border-cat-6 {
      border-left: 6px solid #dd33fa;
      color: $fgColor !important;
    }

    &.border-cat-7 {
      border-left: 6px solid #d1ff33;
      color: $fgColor !important;
    }

    &.border-cat-8 {
      border-left: 6px solid #4aedc4;
      color: $fgColor !important;
    }

    &.border-cat-9 {
      border-left: 6px solid #3d5afe;
      color: $fgColor !important;
    }

    &.border-cat-10 {
      border-left: 6px solid #834bff;
      color: $fgColor !important;
    }
  }
}
