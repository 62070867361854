@import '../../../css/colors';
@import '../../../css/index';

#OrgSettingsTab {
  .toolbar-filter {
    
    height: fit-content;
    
    margin: 0 0 0 0;

    .MuiOutlinedInput-notchedOutline {
        @include themify($themes) {
            border: 2px solid themed('buttonBorderColor') !important;
        }
    }

    .MuiInputLabel-root {
        font-size: 14px !important;
        font-weight: 600 !important;
        z-index: 99999;
    }

    .MuiAutocomplete-clearIndicator {
        padding: 0 !important;
    }

    .MuiChip-deletable {
      margin-top: 5px;
      height: 24px;
    }

    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
      padding: 0 !important;
      background-color: white;
      min-height: 40px;
    }

    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      padding: 0 55px 0 9px !important;
      height: fit-content !important;
      background-color: white;
      z-index: 9999;
    }

  }
  .subsection {
    margin-top: 40px;

    .auth-error {
      color: red;
    }

    .test-text {
      display: flex;
      align-items: center;
      padding-top: 0.2em;
    }
    .test-status {
      padding-left: 1em;
      display: flex;
      align-items: center;
    }
    .test-error {
      color: red;
    }

    .test-success {
      color: green;
    }
    .test-notrun {
      color: grey
    }

    .input-wrapper {
      display: flex;
      align-items: flex-end;

      .details-info-label.auth-req {
        color: red!important;
        padding-left: 2px;
      }
    }

    .details-required {
      margin-top: 10px;
    }
  }

  .settings-paper {
    padding: 10px 10px 30px;

    .task-settings {
      padding: 10px;
    }

    .features-label {
      margin: 0px !important;
    }
  }

  .anon-cont{
    margin-top: 10px;
    margin-bottom: 10px;
  .anon-survey-settings {
    display: flex;
    align-items: center;
  }
}

.add-settings-cont{
  margin-top: 15px;
  margin-bottom: 10px;
.add-setting {
  display: flex;
  align-items: center;
}
.margin-top{
  margin-top: 10px;
}
}

  .file-types-header {
    display: flex;
    align-items: center;
  }

  .details-paper {
    margin-bottom: 20px;

    .module-row {
      margin-top: 10px;
    }
  }

  .MuiButtonGroup-root .MuiButton-root {
    color: #444444 !important;
    font-weight: 600!important;
  }
  .MuiButtonGroup-root .MuiButton-root.Mui-selected {
    font-weight: 700!important;
  }
  .theme-ripple,.MuiButtonGroup-root .MuiButton-root.Mui-selected {
    background-color: rgba(5,209,207,.1)!important;
    border-color: rgb(5,209,207) !important;
    color: #009f9e !important;
  }
  .field-label,.MuiInputLabel-root {
    color: #444444!important;
    font-weight: 600!important;
  }
}

#OrgNameSpinner {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  transform: translate(0%, -50%);

  .spinner {
    width: 25px !important;
    height: 25px !important;
  }
}

.MuiPaper-root {
  background-color: red;
}

#OrgDetailsTab {
  #OrganizationLanguages {
    margin-top: 20px;
  }
  #OrganizationToken {
    margin-top: 20px;

    #token > * {
      display: inline;

      .progressCircle {
        align-content: center;
      }
    }
    .icon {
      cursor: pointer;
      color: $spanishGray;
    }
    .token-label {
      display: inline-flex;
      align-items: baseline;
    }
  }
  #OrganizationTemplates {
    margin-top: 20px;
  }

  .temmplateSnackBar {
    .MuiPaper-root {
      background-color: rgb(49, 48, 48) !important;
    }
  }

  .org-temp-input {
    margin: 0;
    padding: 0 !important;

    .MuiAutocomplete-inputRoot {
      padding-top: 0 !important;
    }

    .MuiOutlinedInput-input {
      padding-top: 0 !important;
    }
  }

  .progressCircle {
    width: 1.5em !important;
    height: 1.5em !important;
  }

  .createTemplateButton {
    float: right;
  }

  .templateNameError {
    color: red;
  }
}

#OrgLabelsTab {
  .title-bar {
    height: 30px;
    border-bottom: 1px solid $searchBarGray;
    margin-bottom: 10px;
  }

  .title-row {
    height: 20px;
    display: flex;
    align-items: center;
  }

  .title-text {
    width: 20%;
  }

  .labels-paper {
    padding: 10px 10px 30px;
    margin-bottom: 20px;
  }

  .label-text {
    width: 50%;
  }

  .label-value {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px !important;
  }

  .label-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
  }

  .label-row:hover {
    background-color: $darkTurquoise40;
  }
}