@import '../../../css/index';

$bgColor: #F1F2F6;
$bgDarkColor: #D5D5DE;

#AddSurveyIntroductionButton {
  height: 100%;
  width: 100%;
  display: flex;

.intro-error{
  border: 1px solid red;
}

  .MuiPaper-root {
    display: flex;
    height: fit-content;
    width: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid #e8e8e8;
  }

  .add-intro-screen-create-btn {
    display: flex;
    min-height: 100%;
    height: 100%;
    justify-content: center;
    background-color: $bgColor;

    .add-intro-screen-label {
      color: rgb(132, 141, 162);
      font-size: $fs_secondary;
    }
  }

  .add-intro-screen-avatar {
    height: 22px;
    width: 22px;
    margin-right: 15px;
    color: rgba(0, 0, 0, 0.48);
    background-color: $bgDarkColor !important;
  }
}
