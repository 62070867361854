#visibilityEditor {
  .select-people-btn {
    margin-left: 0px !important;
    margin-top: 8px;
  }
  
  .visibility-participants-container {
    flex-direction: column;
  }
  
  .visibility-participant-row {
    display: flex;
  }
}

#visibility-modal {
  .participant-toolbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .visibility-participant-dialog-content {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    .visibility-participant-select {
      height: 600px;
      overflow-y: auto;
    }
  }
}
