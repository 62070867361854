@import '../../../css/index';

#EmailTemplateDialogHeader {
    .dialog-header-title {
        display: flex;
        font-size: $fs_subheader;
        color: $viridianGreen !important;
        font-weight: bolder;
        font-size: $fs_header;
        justify-content: center;
    }

    .dialog-header-close {
        margin-right: 10px;
        color: #ABB0B8;
    }
}