@import '../../../css/index';

#PulseQuestionSets {
  #pulseSets {
    display: flex;
    flex-direction: column;

    .sets-header {
      font-weight: bold;
    }

    .pulse-set-add-button {
      width: 50px;
    }

    .pulse-set-edit {
      display: flex;
      flex-direction: row;
      align-items: center;

      .pulse-set-name {
        width: 300px;
        margin-right: 5px;
      }

      .pulse-set-cancel-button {
        color: $errorRed;
      }

      .pulse-set-save-button {
        color: $confirmGreen;
      }
    }
  }
}