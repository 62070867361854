@import '../../css/index';

#ContentLayoutTopToolbar {
    display: flex;
    z-index: 9999;
    position: relative;
    height: fit-content !important;
    width: 100%;

    .search {
        background-color: white;
        color: black;
        height: 40px;
        border-radius: 4px;

        @include themify($themes) {
            border: 2px solid themed('buttonBorderColor') !important;
        }

        .searchIcon {
            background-color: rgb(78, 78, 94) !important;
        }

        .MuiInputBase-root .MuiInputBase-input {
            font-size: 14px !important;
        }

    }

    .dashboard-toolbar {
        height: fit-content;
        width: 100%;
        justify-content: space-between;
        padding-top: 5px;
        flex-wrap: wrap;
    }

    .toolbar-action-btn {
        height: 40px;
        min-height: 40px;
        min-width: 40px;
        border-radius: 5px;
        margin: 0 10px 0 0 !important;

        @include themify($themes) {
            border: 2px solid themed('buttonBorderColor') !important;
            color: themed('buttonOtlTextColor') !important;
        }

    }

    .toolbar-left {
        display: flex;
        flex-direction: row;
        height: 100%;
        padding-bottom: 10px;
    }

    .toolbar-right {
        display: flex;
        flex-direction: row;
        height: 100%;
        padding-bottom: 10px;

        .MuiOutlinedInput-notchedOutline {
            @include themify($themes) {
                border: 2px solid themed('buttonBorderColor') !important;
            }
        }

        span {
            display: block;
            min-width: fit-content;
            height: fit-content;
        }
        
        .sort-by-select {
            width: 240px;
            max-width: 240px;
            min-height: 40px;
            margin-right: 15px;

            .MuiInputBase-input {
                font-size: 14px !important;
            }

            .sort-by-select-item {
                font-size: 14px !important;
            }
        }
    }

    .toolbar-sort {
        display: flex;
        width: 45px;
    }

    .toolbar-filter {
        width: 240px;
        height: fit-content;
        max-width: 240px;
        margin: 0 0 0 15px;

        .MuiOutlinedInput-notchedOutline {
            @include themify($themes) {
                border: 2px solid themed('buttonBorderColor') !important;
            }
        }

        .MuiInputLabel-root {
            font-size: 14px !important;
            font-weight: 600 !important;
            z-index: 99999;
        }

        .MuiAutocomplete-clearIndicator {
            padding: 0 !important;
        }

        .MuiChip-deletable {
          margin-top: 5px;
          height: 24px;
        }
    
        .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
          padding: 0 !important;
          background-color: white;
          min-height: 40px;
        }
    
        .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
          padding: 0 55px 0 9px !important;
          height: fit-content !important;
          background-color: white;
          z-index: 9999;
        }

      }
}

#ContentPickerLayoutFilters {
    display: flex;
    margin-right: 15px;
    max-height: 40px;
}