@import '../css/index';

$iconSize: $fs_header;

/*  Transition duration should be slightly less than the resize event timeout duration in Appstore 
    (toggleDrawer function). If you make changes, make sure to change both. */

#Navigation {
  display: flex;
  height: 100%;
  overflow:hidden;

  .left-nav-badge,
  .top-nav-badge {
    color: white;
    font-weight: 700;
    height: 19px;
    width: 19px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 7px;
    position: absolute;
    background-color: $indianRed;
  }

  .left-nav-badge {
    top: -8px;
    padding-top: 1px;
  }
  .top-nav-badge {
    right: 7px;
    top: 2px;
  }

  .content {
    @include themify($themes) {
      background-color: themed('secondaryBgColor');
    }
  }

  #NavToolbar {
    padding: 0 18px;
  }

  .MuiDrawer-paperAnchorDockedLeft {
    border-right: none;
  }

  .drawer {
    flex: 0 0 auto;
    white-space: nowrap;
    border-right: none;
  }

  .drawer-open {
    width: $leftNavOpenWidth;
    transition: width $leftNavTransitionDuration cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    color: $whiteSmoke !important;
    overflow-x: hidden !important;
    @include themify($themes) {
      background-color: themed('primaryBgColor');
    }
  }

  .drawer-close {
    width: 56px;
    transition: width $leftNavTransitionDuration cubic-bezier(0.4, 0, 0.6, 1) 0ms;

    color: $whiteSmoke !important;
    overflow-x: hidden !important;
    @include themify($themes) {
      background-color: themed('primaryBgColor');
    }

    @media (min-width: 600px) {
      width: 56px;
    }
  }

  .announcements-badge {
    color: white;
    height: 13px;
    width: 13px;
    background-color: $errorRed;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .announcements-count {
      font-size: 8px;
      margin-left: -1px;
      line-height: 10px;
    }
  }

  .content {
    padding: 0;
    flex-grow: 1;
  }

  .toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 8px;
    min-height: 64px;

    .MuiIconButton-root {
      color: $white;
    }
  }

  .MuiList-root {
    overflow: hidden;
    padding-top: 0;

    .nav-button {
      color: $lavenderGray;
      height: 56px;
      padding: 0;
      border-bottom: 1px solid #d7d7e21f;
      @include themify($themes) {
        background-color: themed('primaryBgColor');
      }

      &.active-button {
        color: $white;
        border-left: 3px solid;
        @include themify($themes) {
          background-color: themed('secondaryBgColor');
          border-left-color: themed('accentColor');
        }
      }

      &:hover:not(.active-button) {
        background-color: $black30 !important;
        border-left: 3px solid;
        @include themify($themes) {
          background-color: themed('secondaryBgColor');
          border-left-color: themed('accentColor');
        }
        transition-delay: 100ms;
        transition-property: border-left;

        .nav-button-icon {
          font-size: 26px !important;
          transition: font-size 100ms;
        }
      }

      .MuiListItemIcon-root {
        color: $lavenderGray;
        width: 56px;
        display: flex;
        min-width: fit-content;
        justify-content: center;
        position: relative;
      }

      .nav-button-icon {
        font-size: $iconSize;
      }

      .active-icon {
        color: $white !important;
        margin-left: -2px;
      }

      .MuiTypography-body1 {
        font-size: $fs_subheader;
        text-transform: capitalize;
      }
    }
  }

  .hide {
    display: none;
  }

  #Appbar {
    .MuiToolbar-root {
      position: relative;
    }

    .company-logo {
      cursor: pointer;
      background-color: transparent;
      position: absolute;
      top: 17px;
      left: 0px;
      transform: translate(56px, 0);
      transition: transform $leftNavTransitionDuration cubic-bezier(0.4, 0, 0.6, 1) 0ms;

      &.isDrawerOpen {
        transform: translate(15px, 0);
        transition: transform $leftNavTransitionDuration cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      }

      .logo-img {
        max-height: 30px;
      }
    }

    .app-bar-right {
      display: flex;
      align-items: center;
      margin-left: auto;

      .app-bar-icon .MuiSvgIcon-root {
        font-size: $iconSize;
        color: $white;
      }

      .drop-icon {
        height: 20px;
        margin-right: 10px;
      }

      .drop-icon2 {
        height: 23px;
        margin-right: 10px;
        margin-top: 2px;
      }

      .dropbtn {
        height: 40px;
        width: 80px;
        background-color: #242438;
        color: #c8c9e3;
        font-size: 16px;
        border: none;
        border-radius: 25px;
        cursor: pointer;

        .h4{
          font-size: 19px;
        }
      }

      .dropdown {
        position: relative;
        display: inline-block;
      }

      .zero {
        min-width: 330px;
        min-height: 210px;
      }

      .non-zero {
        min-width: 330px;
        min-height: 400px;
      }

      .dropdown-content {
        display: none;
        position: absolute;
        right: 0;
        background-color: #1a192e;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
      }

      .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
      }

      .dropdown-content a:hover {
        background-color: #1a192e;
      }
      .dropdown:hover .dropdown-content {
        display: block;
        border-radius: 20px 0 20px 20px;
      }
      .dropdown:hover .dropbtn {
        background-color: #1a192e;
        border-radius: 20px 20px 0 0;
      }
    }
  }

  .ripple-logo-container {
    width: inherit;
    position: relative;
    margin-top: auto;

    @media only screen and (max-height: 460px) {
      display: none;
    }

    .ripple-logo-position {
      width: inherit;
      display: flex;
      position: absolute;
      left: 0;
      bottom: 0;

      .ripple-logo {
        margin: 0px 0px 16px 17.6px;
      }

      .ripple-logo-text {
        padding-left: 5%;
      }
    }
  }

  .app-bar {
    @include themify($themes) {
      background-color: themed('primaryBgColor');
    }
    z-index: 1201;
    transition: width $leftNavTransitionDuration cubic-bezier(0.4, 0, 0.6, 1) 0ms,
      margin $leftNavTransitionDuration cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }

  .app-bar-shift {
    width: calc(100% - #{$leftNavOpenWidth});
    transition: width $leftNavTransitionDuration cubic-bezier(0.4, 0, 0.6, 1) 0ms,
      margin $leftNavTransitionDuration cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    margin-left: $leftNavOpenWidth;
  }

  .no-transition {
    transition: none !important;
  }
}
