@import '../../../css/index';

#TasksManagementWidget {
  width: 100%;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .title-tabs {
    display: flex;
  }

  .search {
    font-weight: 400 !important;
  }

  .widget-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filtered {
    justify-content: flex-end !important;
  }

  .paging-row {
    margin-top: auto;
  }

  .no-rows {
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }

  .tabs-toolbar {
    padding: 0px;
    min-height: unset;
  }

  .tabs-root {
    flex: 1 0 auto;
    height: unset !important;
    button {
      height: 32px !important;
    }
  }

  .table-row {
    cursor: pointer;
  }

  table th {
    background-color: $whisper;
  }

  .userTaskBadge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    font-size: 8px;
    border-radius: 50%;
    color: white;
  }

  .cardContent {
    height: 100%;
    padding: 0;
    width: 100%;
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .MuiBadge-anchorOriginBottomRightRectangle {
    transform: scale(1) translate(38%, 38%) !important;
  }

  .completedBadge {
    background-color: $confirmGreen;
  }

  .incompleteBadge {
    background-color: $topazYellow;
  }

  #TaskManagementWidgetCompletedTasksTable,
  #TaskManagementWidgetIncompleteTasksTable {
    flex: 1 auto;

    .head-row {
      cursor: pointer;
    }

    .MuiTableBody-root {
      .MuiTableCell-root {
        &:first-child {
          width: 30%;
          border-left: solid #ffcd6e 3px;
        }
      }
    }

    .cell {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
    }

    // .name-cell {
    //   min-width: 100px;
    //   max-width: 200px;
    //   text-transform: capitalize;
    // }

    // .discipline-cell {
    //   min-width: 120px;
    //   max-width: 150px;
    //   text-transform: capitalize;
    // }

    // .due-date-cell {
    //   min-width: 115px;
    //   max-width: 115px;
    // }

    .participants-cell {
      min-width: 115px;
      max-width: 200px;
      overflow: hidden;
    }

    // .participants-cell {
    //   padding-top: 0px !important;
    // }

    .action-cell {
      min-width: 150px;
      width: 150px;
      max-width: 150px;

      .MuiButton-outlined {
        @include themify($themes) {
          background-color: themed('buttonOtlBgColor') !important;
          color: themed('buttonOtlBorderColor') !important;
        }
      }
    }
  }

  @import '../../../css/ag-grid-tables';
}

.comment-card {
  padding-left: 10px !important;
  margin-bottom: 10px !important;

  .comments-avatar {
    margin: 0 auto;
  }

  .userName {
    font-weight: bold;
    color: $black50;
  }

  .datePosted {
    opacity: 0.5;
  }
}

#TasksManagementWidgetDrawer {
  .add-icon-container {
    background-color: $platinumGray;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add-participant-text {
    margin: 0 -4px;
    font-weight: 500 !important;
  }

  .autocompleteInput {
    padding: 0px;
    padding-right: 14px;

    .MuiAutocomplete-endAdornment {
      top: unset;
      padding-right: 8px;
    }
  }

  .participants-container {
    align-items: center;
    padding-left: 6px;
  }

  .audience-member {
    padding: 10px 12px;
    cursor: pointer;

    &:hover {
      background-color: $black10 !important;
    }
  }

  .participantsToolbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: auto;
  }

  .participantsSearchBar {
    padding-top: 0px;
    width: 100%;

    .search-textbox {
      width: 100%;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 5px;
    }
  }

  #participantsPickerTabsBar {
    padding: 0px;
    height: 40px;
    min-height: unset;

    span {
      font-size: 9px;
    }
  }

  .participantPickerContainer {
    height: 100%;
    overflow-y: auto;
  }

  .avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
  }

  .avatar {
    display: inline-block;
    height: 100%;
    width: auto;
  }

  .defaultAvatarIcon {
    font-size: 4.166rem;
  }

  .participantCardInfo {
    padding-left: 10px;

    span {
      display: block;
    }

    .participantName {
      font-size: $fs_secondary;
      font-weight: bold;
    }
  }

  .selectedParticipantChip {
    max-width: 150px;
    margin: 0.25em;
  }

  .participants-section-header-row,
  .documents-section-header-row {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
  }
}

#TaskCommentInput {
  .comment-avatar {
    margin-right: 6px;
  }

  .add-comment-btn {
    margin-top: 6px;
  }
}

#TasksDrawer {
  height: 100%;

  .MuiToolbar-regular {
    min-height: 48px !important;
    padding: 0 10px !important;
  }

  #ParticipantsSelect {
    height: 100%;

    .select-column {
      padding-bottom: 50px;
    }
  }
}
