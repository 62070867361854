@import '../../css/index';

#NotificationTemplatesDialogRight {
  margin-left: auto;

  .notification-template-dialog-action {
    margin-left: 10px !important;
  }

  .MuiButton-contained.Mui-disabled {
    @include themify($themes) {
      background-color: #e3e3e8 !important;
      color: #ffffff !important;
    }
  }

  .notification-template-dialog-action-save {
    margin-left: 10px !important;
  }
}

.notification-template-name-toolbar {
  width: 30% !important;

  .MuiOutlinedInput-notchedOutline {
    border-color: white;

    &:hover {
      border: 0.25px solid rgba(255, 255, 255, 0.6) !important;
      background-color: $viridianGreen10 !important;
    }
  }

  .MuiInputBase-root {
    &:hover {
      border: 0.25px solid rgba(255, 255, 255, 0.6) !important;
      background-color: $viridianGreen10 !important;
    }

    @include themify($themes) {
      color: themed('emailTemplateEditorTextColor') !important;
    }
  }
}