@import '../../../css/colors';

#DrawerStatusContainer {
  .userTaskBadge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    font-size: 8px;
    border-radius: 50%;
    color: white;
  }

  .completedBadge {
    background-color: $confirmGreen;
  }

  .incompleteBadge {
    background-color: $topazYellow;
  }
}
