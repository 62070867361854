#InvitationsTabPanel {
  padding: 20px;
  background: #fff;
  height: calc(100vh - 158px);
  align-content: flex-start;

  .ag-body-horizontal-scroll-viewport {
    overflow-x: hidden;
  }

  .ag-root.ag-layout-normal {
    height: 68vh;
    overflow-x: hidden;
  }

  .number-selected {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }

  .table-header {
    background-color: #f8f5f5;
  }

  .buttons {
    margin-bottom: 20px;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .buttons-item {
    display: flex;
  }

  .search {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .display-all-check {
    margin-left: 10px;
  }

  .search {
    float: right;
  }

  .progress-text {
    margin-left: 10px;
  }
}
