@import '../../../css/index';

#ScheduleStep {
  display: flex;
  background: #f0f1f5;
  padding: 5px 20px 0px 20px;
  height: 99%;

  .schedule-section {
    margin: 0px 5px;
    background: white;
    padding: 10px 10px 20px 20px;
    // max-height: 610px;
    border-radius: 10px;
    overflow: auto;
  }

  .schedule-text-margin {
    margin-top: 20px;
  }

  .schedule-text {
    font-weight: 700;
    color: #6c6c7f;
    font-size: 19px;
  }

  .schedule-text-secondary {
    font-weight: 700;
    color: #6c6c7f;
    font-size: 16px;
  }
  .schedule-text-tertiary {
    font-weight: 900;
    color: #a9a2b9;
    font-size: 13px;
  }

  .schedule-text-alarm {
    font-weight: 500;
    color: #e95a5e;
    font-size: 16px;
  }

  .schedule-header {
    display: flex;
    justify-content: space-between;
  }

  .date-margin {
    padding-right: 20px;
  }

  .date-picker {
    background-color: #f8f8fb;
    border-radius: 5px;
  }

  .MuiButton-root {
    margin-left: 0px !important;
    margin-right: 10px !important;
  }

  .weekday-selected {
    font-weight: 700 !important;
    color: #0064e9 !important;
    font-size: 15px !important;
    background-color: #add2f1;
    margin-top: 6px;
    padding: 18px 18px 18px 18px !important;
    box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1), inset 0 6px 8px 0 rgba(0, 0, 0, 0.1);

    .check-icon {
      margin-left: 5px;
      color: #0064e9 !important;
      font-weight: 600;
    }
  }

  .send-section {
    margin-left: 10px;
  }

  .pulse-survey-date-picker {
    margin: 3px 0px;
    height: 50px;
    background-color: #f8f8fb;
    padding-top: 6px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }

  .date-picker-edited {
    margin: 3px 0px;
    height: 50px;
    padding-top: 6px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: #ced9fb;
    .MuiIconButton-root{
      color: #0835be !important;
    }
  }

  .text-edited {
    color: #0835be !important;
    font-weight: 800 !important;
  }

  .pulse-survey-timeZone-picker {
    height: 42px;
    max-width: 300px;
    padding: 0px 0px 0px 10px;
    border-radius: 5px;
    background-color: #f8f8fb !important;
    display: flex;
    align-items: center;

    .MuiInput-underline:before {
      border-bottom: none !important;
    }
  }

  .pulse-time-picker {
    margin-top: 0px !important;
  }

  .weekday-unselected {
    font-weight: 700 !important;
    color: #6c6c7f !important;
    font-size: 16px !important;
    background-color: rgba(248, 248, 251, 0.7) !important;
    margin-top: 10px;
    padding: 20px 20px 20px 20px !important;
    border-bottom: 0px !important;

    .MuiButton-root {
      margin-left: none !important;
      margin-right: 10px !important;
    }
  }

  .reset-dates {
    color: #009f9e !important;
  }

  .sch-error {
    border: 1px solid red;
  }

  .survey-number-cont {
    width: 100%;
  }

  .survey-number-item {
    max-height: 50px;
    width: 40px;
    margin: 3px 0px;
    height: 50px;
    padding: 0px 6px 0px 6px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 19px;
    text-align: center;
    color: rgba(0, 0, 0, 0.38);
  }

  .reminder-row {
    .reminder-text {
      font-size: 13px;
    }
    .reminder-input-row {
      display: flex;
      align-items: center;
      font-size: 10px !important;

      .MuiTextField-root {
        width: 4.5rem;
      }

      .reminder-value-select {
        width: 4.5rem;
      }

      .reminder-period-select {
        margin-left: 0.75rem;
        margin-right: 0.75rem;
        width: 7rem;
        height: 1rem;

        .MuiSvgIcon-root {
          margin-top: 0.2rem;
          margin-bottom: 0.2rem;
        }
      }

      .MuiIconButton-root {
        padding: 0.75rem;
        margin-left: 0.5rem;
      }
    }

    .MuiDivider-root {
      margin-top: 0.5rem;
    }
  }

  .survey-owner {
    padding-top: 4px;
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
      padding: 0;
    }

    .owner-container {
      display: flex;
      gap: 10px;
      height: fit-content;
      width: 100%;
      max-width: 100%;
      background-color: white;
      flex-wrap: wrap;
      min-width: 0;

      .owner-name {
        max-width: 310px;
        justify-content: flex-start;
      }
    }
  }
}
