@import '../../css/index';

#EventSendNotificationsPrompt {
  .dialog-title {
    background-color: $antiflashWhite;
    padding: 16px;

    .MuiTypography-root {
      letter-spacing: 0 !important;
      font-size: $fs_secondary !important;
      font-weight: 700 !important;
    }
  }
  
  .MuiDialogContent-root {
    padding: 20px 30px;

    .send-image {
      height: 100%;
      width: 100%;
    }

    .confirm-message-view {
      padding-left: 12px;
      padding-top: 6px;

      .confirm-message {
        font-size: $fs_secondary;
        font-weight: bold;
        margin-bottom: 0px;
      }

      .note-span {
        font-weight: 700;
      }

      .cannot-undo-text {
        font-size: $fs_secondary;
        color: $spanishGray;
      }
    }
  }

  .MuiDialogActions-root {
    padding: 0 16px 16px;

    .send-icon-btn {
      height: 15px;
      width: 15px;
      margin-right: 5px;
    }

    .btn-cancel {
      color: $black50 !important;
    }
  }
}
