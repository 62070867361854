@import '../../css/index';

$iconSize: $fs_header;
$announceIconFill: #7892F1;

#announce-icon-div svg {
  height: 40px;
  width: 40px;
}

#announce-icon-div svg path {
  fill: $announceIconFill;
}

#AnnouncementsWidget .announcement-container .announcement-card .announce-icon {
  &:hover {  
    background: none;
  }
}

#announce-form-label button {
  &:hover {
    background: none;
  }
}

#AnnouncementsWidget {
  width: 800px;
  background-color: $white !important;

  .file-name-text {
    margin-left: 5px;
  }

  .widget-title {
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $antiflashWhite;
    padding: 5px 16px;

    .title-text {
      font-size: $fs_default;
      font-weight: 700;
    }
  }

  .new-announcement {
    color: $viridianGreen;
    cursor: pointer;
    font-weight: 700 !important;
  }

  .edit-icon {
    color: $viridianGreen;
    margin: 12px;
    cursor: pointer;
    z-index: 1;
  }

  .notification-title {
    color: $gunPowder !important;
  }

  .created-date {
    text-align: end;
  }

  .is-not-read {
    background-color: $darkTurquoise20 !important;
    cursor: pointer;
  }

  .no-announcements {
    height: 500px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.75px;
    font-size: $fs_secondary;
    color: $spanishGray;
    padding-top: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .done-icon {
      font-size: 26px;
      margin-top: 8px;
      color: $lightGray;
    }
  }

  .card-wrapper {
    display: flex;
    border-bottom: 1px solid $lavenderGray;
    padding: 10px 10px;
    min-height: 120px;
  }

  .is-not-read {
    cursor: pointer;
    background-color: $darkTurquoise20;
  }

  .announcement-container {
    width: calc(100% - 20px) !important;

    .announcement-card {
      .card-inset {
        margin-top: 0.25rem;
        margin-left: 0.5rem;
      }

      .card-content {
        padding: 0 16px 16px 16px;
      }

      .card-item {
        display: flex;
        flex-direction: column;
        height: auto;
      }

      .announce-icon {
        display: flex;
        align-items: center;
        margin-left: auto;
        cursor: pointer;
        z-index: 1;
      }
    }

    .spinner {
      padding: 1px;
    }

    .announcement-action-btn {
      width: 20px;
      margin-left: 5px;
      font-size: 16px;
      font-family: 'Nunito', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, 'sans-serif', "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-weight: 400;
      line-height: 1.5;
    }
  }

  .row-title-bar {
    display: flex;
    justify-content: space-between;
    height: 30px;
    width: 100%;
    max-width: 100%;
    margin: 0 0 5px 5px;

    .announcement-title {
      max-width: 600px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
    }
  }

  .swiper-body {
    display: flex;
    height: 100%;
    max-height: 250px !important;
    width: 100%;
    margin: 5px;

    .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
      bottom: 50px !important;
    }

    .swiper-swipe {
      min-height: 250px;
      max-height: 100%;
      width: 100%;
    }

    .swiper-slide {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
    }

    .swiper-lazy-preloader-white {
      --swiper-preloader-color: transparent;
    }

    .swiper-slide {

      img {
        width: auto;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
        object-fit: contain;
        object-position: center;
      }

      video {
        width: auto;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
        object-fit: contain;
        object-position: center;
      }
    }

    .swiper-slide-container {
      justify-content: center;
      
      .mux-player-container {
        display: flex;
        width: 100%;
        justify-content: center;
        min-width: 450px;
        max-width: 450px;
        max-height: 250px;
      }
  
      .mux-player {
        width: 100%;
        min-width: 450px;
        max-width: 450px;
        min-height: 250px;
        max-height: 250px;
      }
    }
  }

  .row-body {
    max-width: 95%;
    padding-right: 10px;
    padding: 0 0 5px 5px;
    white-space: pre-line;

    .updated-at {
      height: 20px;
    }
  }

  .read-indicator-row {
    height: 100%;
    width: 20px;
    display: flex;
    align-items: center;
    .read-indicator {
      height: 10px;
      width: 10px;
      margin-bottom: 5px;
      border-radius: 50%;
      color: $viridianGreen;
      background-color: $viridianGreen;
    }
  }

  .announcement-card {
    height: 100%;

    .no-owner{
      padding: 10px 0px 0px 10px
    }

    .parent-split-flex {
      display: flex;
      justify-content: space-between;
    }

    .right-align-item {
      margin-left: auto;
    }

    .left-align-item {
      margin-left: auto;
    }
  }

  .MuiTooltip-tooltip {
    max-width: 100%;
  }

  .input-attachment-row {
    margin-top: 10px;
    margin-left: 5px;
    margin-right: auto;
    background-color: $whiteSmoke;
    border-radius: 5px;
    padding: 2.5px;
    padding-left: 0px !important;

    .img-container {
      height: 100%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 20px;
      margin-right: 2.5px;
    }

    .attachment-body {
      display: flex;
      align-items: center;
      height: 20px;
      svg {
        color: $spanishGray;
      }
    }
  }
    
  .announcement-body-trunc {
    width: 100%;
  }
  
  .announcement-body-html {
    width: 100%;
  }

  .announcement-body-html p {
    margin-bottom: 0;
  }

  .expand-button {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
  }

  .expand-button-dot {
    color: #4183c4;
  }

  .expand-button-text {
    color: #4183c4;
    text-decoration: underline;
    cursor: pointer;
  }

  .media-preview {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    object-fit: contain;
    object-position: center;
    
    justify-content: center;
    align-items: center;
    z-index: 999999;
    pointer-events: none;

    &.youtube {
      iframe {
        max-width: 450px;
      }
    }

    &.vimeo {
      width: '100%';
    }

    iframe {
      max-width: 450px;
      width: 718px;
      height: 250px;
      pointer-events: all;
    }

    &.iframewrapper::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
    
    &.iframewrapper iframe {
      vertical-align: top;
    }
  
  }

}
