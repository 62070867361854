#OptionsList,
.option-draggable-item {
  border: solid #c0c0c0 1px;
  border-radius: 4px;
  padding-bottom: 0;
  margin-bottom: 5px;

  .MuiOutlinedInput-notchedOutline {
    border-radius: 0 !important;
    border: none;
    border-bottom: solid #c0c0c0 1px;
  }

  .MuiOutlinedInput-input {
    padding: 10.5px 10px;
    padding-right: 0;
  }

  .MuiOutlinedInput-adornedEnd {
    padding: 0;
  }

  .more-button {
    border-left: solid #c0c0c0 1px;
    margin-top: 0;
    border-radius: 0;
  }

  .order-gray {
    color: #c0c0c0;
  }

  .emoji-button {
    border-right: solid #c0c0c0 1px;
    margin-top: 0;
    border-radius: 0;
    width: 20px;
    span {
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .response-type-button {
    min-height: 30px;
    border-right: solid #c0c0c0 1px;
    margin-top: 0;
    border-radius: 0;
    width: 20px;
    span {
      .positive {
        color: #6aca9a;
      }
      .neutral {
        color: grey;
      }
      .negative {
        color: #e85c5c;
      }
    }
  }

  .form-button {
    background-color: #fafafa;
    margin-left: 0 !important;
  }

  .drag-handle {
    padding: 0px !important;
    min-width: 0px !important;
    margin: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: solid #c0c0c0 1px;
    span svg {
      color: rgba(0, 0, 0, 0.54);
    }
  }
}

#OptionsList {
  border: none;
}
