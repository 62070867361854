#NotificationTemplatesEditor {
  .template-select {
    width: 300px;

    .Select-menu-outer {
      z-index: 100;
    }
  }

  .editor-container {
    &.error {
      border: 1px solid red;
    }
  }

  .tox-editor-header {
    z-index: 0;
  }

  .tag {
    border: none !important;
    padding: 0px !important;
  }

  h6 {
    margin-top: 10px !important;
  }

  .default-button {
    margin: 10px 4px 10px 0px !important;
  }

  .tox-statusbar__branding {
    display: none;
  }

  .notification-templates-editor-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .editor-row-label {
    display: flex;

    .icon-error {
      margin-left: 10px;
      color: red;
    }
  }
}
