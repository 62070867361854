@import '../../../css/index';

$bgColor: white;
$bgDarkColor: rgba(128, 128, 128, 0.3);
$fgColor: #6f8096;

#PulseOptionFollowUpEdit {
  margin: 0px 0px 20px 20px;

  .media-row {
    margin-left: 40px;
  }

  .link-question {
    margin-top: 5px;
    margin-bottom: 5px;
    .question-link {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .follow-up-container {
    padding: 10px 20px;
    background-color: #ecedf2;
    border-radius: 10px;

    .follow-up-header {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon-container {
        padding: 0px;
        margin: 0px 20px 0px 0px;
        background-color: $bgDarkColor !important;

        .expand-icon {
          color: #6b6985;
          height: 20px;
          width: 20px;
        }
      }

      .follow-up-question-type {
        margin-right: 10px;
        width: 25%;
      }

      .follow-up-question-title {
        width: 75%;
      }

      .question-required {
        margin: 0px 0px 0px 5px;
      }
    }

    .question-options {
      margin: 10px 0px 0px 40px;

      .question-options-header {
        font-weight: normal;
      }
    }

    .rating-display {
      margin: 10px 0px 0px 40px;

      .rating-display-header {
        font-weight: normal;
      }
    }

    .dropdown-info {
      margin: 0 0 0 40px;
    }
  
    .dropdown-toggle,
    .dropdown-label {
      width: 50%;
      margin: 10px 0 10px 0;
  
      .dropdown-label-header {
        margin: 10px 0 8px 0;
        font-weight: bold;
      }
    }
  }
}
