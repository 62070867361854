@import '../.././../../css/index';

#TrainerWidget {
  overflow-y: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  .filter-row {
    border-bottom: 1px solid $platinumGray;
  }
  .filter {
    display: flex;
    align-items: center;
    padding-right: 20px;
    .MuiInput-underline:after {
      border-bottom: 0px !important;
    }
    .MuiFormLabel-root {
      margin-bottom: 0px !important;
      margin-right: 5px;
    }
    .Muifocused {
      &:before {
        border-bottom: 0px;
      }
    }
    .MuiInputBase-root {
      &:before {
        border-bottom: 0px;
      }
    }
  }
  #Content {
    height: calc(100% - 48px - 34px);
    overflow-y: scroll;
    #TrainerPlanRow {
      background-color: white;
      padding: 5px 10px;
      border-bottom: 1px solid $platinumGray;
      box-shadow: none !important;
      border-radius: 0px !important;

      .title-text {
        font-size: $fs_default !important;
      }

      .subtitle {
        display: flex;
        .break {
          margin: 0 10px;
        }
      }
      .trainer-summary {
        .MuiAccordionSummary-content {
          flex-direction: column;
        }
      }
      .user-plan-container {
        display: flex;
        align-items: center;
        margin: 8px 0px;
        cursor: pointer;

        .progress-label {
          position: absolute;
          right: 30px;
        }

        .MuiLinearProgress-root {
          margin-left: 10px;
          width: 100%;
          height: 20px;
          border-radius: 20px;
          background-color: rgba(208, 208, 208, 0.5) !important;
        }

        .owned .MuiLinearProgress-bar1Determinate {
          background-color: $tuscanYellow;
          border-radius: 20px;
        }

        .not-owned .MuiLinearProgress-bar1Determinate {
          background-color: $plusIconTeal;
          border-radius: 20px;
        }
      }

      .skills {
        color: $jordyBlue;
      }
      .avatars {
        display: flex;
        align-items: center;

        .badge-container {
          position: relative;
          .complete-badge,
          .incomplete-badge {
            position: absolute;
            bottom: -2px;
            right: -2px;
            border-radius: 50%;
            height: 10px;
            width: 10px;
            color: white;
          }
        }
        .complete-badge {
          background-color: $green;
        }
        .incomplete-badge {
          background-color: $topazYellow;
        }
        .MuiAvatar-root {
          height: 25px !important;
          width: 25px !important;
        }
      }
    }
  }
}
