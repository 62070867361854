@import '../../../css/index';

#MediaUploadPicker {
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;

  .add-media-check {
    color: $confirmGreen;
  }

  .media-view {
    max-height: calc(100vh - 50px - (5 * 42px));
    padding-bottom: 0.75rem;
    overflow-y: auto;
  }

  .media-searchbar-toolbar-right {
    height: 35px !important;

    .media-toggle-button-group {
      height: inherit;

      .toggle-button {
        height: inherit;
        padding: 8px 12px;
        font-size: $fs_secondary;
      }
    }
  }

  .add-media-tile {
    cursor: pointer;
    .MuiGridListTile-tile {
      align-self: center;
      align-items: center;
      display: flex;
      justify-content: space-around;
      height: 100%;
      width: 100%;
      border: 3px solid gray;
      &:hover {
        border: 3px solid #05d1cf;
        .MuiSvgIcon-root {
          color: #05d1cf;
          height: 90px;
          width: 90px;
        }
      }
    }
    .MuiSvgIcon-root {
      height: 80px;
      width: 80px;
      color: gray;
    }
    &:hover {
      border: 2px solid white;
    }
  }
  .material-icons {
    justify-content: space-around;
    text-align: center;
    width: 40px !important;
    display: flex;
    .weekday-icon {
      color: $gunPowder;
    }
  }
  .media-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 6px;
  }
  #MediaSearchBar {
    .media-searchbar-toolbar {
      padding-left: 0px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .media-right-toolbar {
        display: flex;
        align-items: center;
        .search-bar {
          height: 34px;
        }
      }
      .media-searchbar-toolbar-right {
        margin-left: 10px;
        .MuiToggleButtonGroup-grouped:not(:first-child) {
          border-left: 1px solid rgba(0, 0, 0, 0.12);
        }
        .active {
          background-color: lightgray;
          font-weight: 600px;
        }
      }
      label {
        margin-bottom: 0;
      }
    }
  }
}
#MediaGrid {
  .edit-button {
    color: $confirmGreen;
  }
}
