@import '../../css/index';

#ContentLayoutGridBody {
  height: 100%;
  width: 100vw;
}

#ContentPickerGrid,
#ContentLayoutGridBody {
  .content-empty-container {
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: center;
  }

  .content-empty-avatar {
    margin: 0 5px 5px 5px;
    align-self: center;
    justify-content: center;
  }

  .content-empty-img {
    width: 10vw;
    height: auto;
    max-width: 140px;
    max-height: 140px;
    min-width: 60px;
    min-height: 60px;
    align-self: center;
    justify-content: center;
  }

  .content-empty-header {
    margin: 0 5px 5px 5px;
    font-size: 20px !important;
    font-weight: 700;
    line-height: 1.5;
    align-self: center;
    justify-content: center;
  }

  #ContentPickerEmptyText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content-empty-text {
      margin: 0 5px 0 5px;
      font-size: 16px !important;
      font-weight: 400;
      line-height: 1.5;
      align-self: center;
      justify-content: center;

    }

    .content-empty-action-btn {
      margin-top: 15px;
    }
  }
}

.MuiSkeleton-text {
  max-width: 280px !important;
  max-height: 160px !important;
  transform: none !important;
  margin-top: 0;
  border-radius: 4px;
  margin-bottom: 0;
  transform-origin: 0 60%;
}

#content-card-container {
  display: flex;
  align-items: center;
}

.content-card-container-type {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;

  .skeleton-preview {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    object-fit: contain;
    object-position: center;
    
    justify-content: center;
    align-items: center;
  
    &.file {
      width: 100%;
      max-height: 80px;
      justify-content: center;
      align-items: center;
      color: #788998 !important;
    }
  }
}

.iframewrapper iframe {
  border: none !important;
}

#ContentPickerDialogAppBar {
  .MuiAppBar-colorPrimary {
    @include themify($themes) {
      background-color: themed('primaryBgColor');
    }
  }
}

#ContentPickerGrid {
  height: 100%;
  margin-right: 5px;
  margin-left: 15px;

  .root {
    padding: 0 85px;
    margin-top: 20px;
    justify-content: flex-start;
  }

  .row {
    display: flex;
    justify-content: flex-start,
  }

  .spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1002;
  }
}
