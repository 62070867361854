@import '../../../css/index';

.widget-drawer {
  .MuiDrawer-paper {
    height: calc(100vh - 60px) !important;
  }

  .MuiCardHeader-root {
    @include themify($themes) {
      background-color: themed('primaryBgColor') !important;
    }
    color: $white !important;
  }

  .widget-card {
    overflow-y: auto !important;
    height: 100%;
    padding: 10px;
    border-radius: 0;
    @include themify($themes) {
      background-color: themed('primaryBgColor') !important;
    }

    .widget-item {
      height: 300px !important;
    }

    .grid-list {
      margin: 0 !important;
      overflow: hidden;

      .grid-list-item {
        transition: all 0.2s ease-in-out;
        color: $white !important;

        h6 {
          margin: 6px 0 !important;
        }

        &.disabled {
          opacity: 0.5;

          &:hover {
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
            cursor: default;
          }

          .MuiGridListTile-tile {
            .MuiPaper-root {
              img {
                opacity: 0.8;
              }
            }
          }
        }

        &:hover {
          -webkit-transform: scale(1.05);
          -ms-transform: scale(1.05);
          transform: scale(1.05);
          cursor: pointer;
        }

        .MuiGridListTile-tile {
          .MuiPaper-root {
            padding: 12px;
            height: 140px;
            background-color: $whiteSmoke80 !important;
            color: $white !important;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          img {
            opacity: 0.8;
          }

          &:hover {
            img {
              opacity: 1;
            }
          }
        }
      }
    }

    .widget-list {
      width: 100%;
      height: 100%;
    }
  }

  .makeStyles-searchIcon-4 {
    color: white;
  }

  .MuiSvgIcon-colorSecondary {
    color: white;
  }
}
