@import '../../../css/index';

#PlanNotFound {
  height: calc(100vh - 64px) !important;
  width: 100% !important;
  overflow: hidden !important;
  .plan-not-found-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .plan-not-found-card {
      width: 80%;
      text-align: center;
      background-color: white;
      border-radius: 10px;
      padding: 40px;
    }
  }
  .plan-helper-subtitle {
    font-weight: 700;
  }
  .plan-helper-text {
    margin-bottom: 10px;
  }
}
