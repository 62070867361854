@import '../../../css/index';

#weightProfileModal {
  .MuiDialog-paperWidthSm {
    max-width: 60vw;
  }
  .MuiPaper-root {
    height: 60vh;
    width: 60vw;
  }
  .MuiDialogTitle-root {
    padding: 0 0 0 10px !important;
    .MuiTypography-root {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .MuiDialogContent-root {
    padding: 0px;
    height: calc(100% - 54px);
    overflow: hidden;
  }

  .error-msg {
    background-color: $indianRed15;
    border-radius: 10px;
    padding: 6px 12px;
    color: $indianRed;
    display: flex;
    align-items: center;
    height: 40px;
    font-weight: 600;

    svg {
      margin: 0 6px 3px 0;
    }

    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  .options-collapse {
    top: 35px;
    position: absolute;
    z-index: 1;
    border: 1px solid $lightGray;
    border-radius: 4px;
    padding: 10px;
    background-color: #f8f5f5;
  }

  .weight-profile-toolbar {
    background-color: $platinumGray;
    width: 100%;
    padding: 10px 14px;
    display: flex;
    justify-content: space-between;

    .role-selector {
      max-width: 40%;
      background-color: white;
    }
    .white-button {
      background-color: white;
    }
  }

  .weight-profile-searchbar {
    padding: 10px 14px;
    display: flex;
    .MuiGrid-item {
      // text-align: -webkit-right;
      display: flex;
      justify-content: flex-end;
    }
  }
  .child-message-shown {
    justify-content: space-between !important;
  }

  #CategoriesTree {
    height: calc(100% - 114px);
    width: 100%;
    padding-bottom: 5px;
    // overflow-y: scroll;

    .node-invalid-true {
      border: 1.5px solid $red;
      border-radius: 7px;
    }

    .cat-input-hidden {
      display: none;
    }

    .MuiTextField-root {
      background-color: white;
      border-radius: 4px;
    }

    .selected-true > .rst__rowContents {
      @include themify($themes) {
        background-color: themed('accentColor30') !important;
      }
    }

    .primary-border-1 {
      .rst__rowContents {
        @include themify($themes) {
          border-left: 5px solid themed('primaryBorderAccent') !important;
        }
      }
    }

    .primary-border-2 {
      .rst__rowContents {
        @include themify($themes) {
          border-left: 5px solid themed('secondaryBorderAccent') !important;
        }
      }
    }

    .primary-border-3 {
      .rst__rowContents {
        @include themify($themes) {
          border-left: 5px solid themed('tertiaryBorderAccent') !important;
        }
      }
    }

    .tree-item {
      display: flex;
      align-items: center;

      .rst__rowContents {
        border-radius: 5px;
      }
    }

    .rst__collapseButton,
    .rst__expandButton {
      position: absolute;
      &:active {
        left: -2.5px;
      }
    }
    .rst__collapseButton:hover:not(:active),
    .rst__expandButton:hover:not(:active) {
      width: 16px;
      height: 16px;
    }
    .rst__collapseButton:before,
    .rst__expandButton:before {
      font-size: 18px;
      border-radius: 50%;
      color: white;
      font-style: normal !important;
      font-family: 'Material Icons';
      left: -1px;
      top: -2.5px;
      position: relative;
    }
    .rst__collapseButton:before {
      content: 'expand_more' !important;
    }
    .rst__expandButton:before {
      content: 'expand_less' !important;
    }

    .rst__collapseButton:focus,
    .rst__expandButton:focus {
      box-shadow: none;
    }

    .rst__node {
      @for $i from 1 through 6 {
        div.rst__lineFullVertical:nth-child(#{$i}):after {
          @include get-color-for-list($i);
          width: 2px !important;
        }
        div.rst__lineHalfHorizontalRight:nth-child(#{$i}):after {
          @include get-color-for-list($i);
          width: 2px !important;
        }
        div.rst__lineHalfHorizontalRight:nth-child(#{$i}):before {
          @include get-color-for-list($i);
          height: 2px !important;
        }
        div:nth-child(#{$i + 1}) > div > div > div.rst__lineChildren:after {
          @include get-color-for-list($i + 1);
          width: 2px;
        }
        div:nth-child(#{$i + 1}) > div > div > button:before {
          @include get-color-for-list($i);
        }

        div:first-child:nth-last-child(#{$i}):not(.rst__rowLabel),
        div:first-child:nth-last-child(#{$i}):not(.rst__rowLabel) ~ div {
          $offset: #{$i - 1} * 30 * 1px;
          width: calc(100% - #{$offset});
        }
        $color: null;
        $color2: null;
      }
    }
  }
}

.dialog-content {
  .dropzone {
    background-color: #f4f4f7;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #d7d7e2;
    border-radius: 4px;
    padding: 1em;
    cursor: pointer;

    .iconContainer {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      .progressCircle {
        font-size: 5em;
        color: #05d1cf;
        margin-bottom: 15px;
      }

      .noteAddIcon {
        font-size: 5em;
        color: #05d1cf;
        margin-bottom: 15px;
      }

      .thumbUpIcon {
        font-size: 5em;
        color: #6aca9a;
        margin-bottom: 15px;
      }

      .thumbDownIcon {
        font-size: 5em;
        color: #e85c5c;
        margin-bottom: 15px;
      }
    }

    .dropzoneText {
      color: #91919a;
    }

    .dropLink {
      color: #05d1cf;
      text-decoration: underline #05d1cf;
    }
  }

  .dialogText {
    margin-top: 7px;
  }
}

.dialog-actions {
  padding: 0px 18px 12px 18px;

  .downloadButton {
    color: #009f9e;
    border-color: #009f9e;
  }

  .cancelButton {
    color: #009f9e;
  }

  .saveButton {
    color: #ffffff;
    background-color: #05d1cf;
  }
  .saveButton:hover {
    background-color: #05d1cf;
  }
}
