@import '../../../css/index';

#PulseCampaignDialogHeader {    
    .pulse-campaign-header-title {
        font-size: $fs_subheader;
        color: #ABB0B8;
        font-weight: bolder;
    }
    
    .pulse-campaign-header-right {
        display: flex;
        justify-content: flex-end;
    }

    .pulse-campaign-header-close {
        margin-right: 25px;
        color: #ABB0B8;
    }
}
  