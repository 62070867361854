@import '../../../css/colors';
@import '../../../css/index';

#CustomCollectionEditScreen #RecordsTab {
  padding: 1rem 2rem;
  background: #fff;
  align-content: flex-start;

  .cc-records-ag-grid-container {
    height: calc(100vh - 64px - 50px - 46px - 120px);
    max-height: calc(100vh - 64px - 50px - 46px - 120px);
    @import '../../../css/ag-grid-tables';
    .ag-theme-alpine {
      height: inherit;
      width: inherit;
    }
  }
}

.buttons {
  padding-top: 10px;
}

.deleteButton {
  padding-bottom: 10px;
  color: $indianRed !important;
}

.invalid-warning {
  background-color: $indianRed !important;
}

.dirty-ready {
  background-color: $confirmGreen !important;
}