@import '../../css/index';

$modalHeight: 75vh;
$toolbarHeight: 46px;
$selectedColor: #d3f7f7;

.node {
  .checkbox-item {
    margin-right: 5px;
    margin-top: 0px;
  }
}

.chip {
  margin: 0 2.5px;
}

.category-picker-container {
  background: transparent;
  min-width: fit-content;
  max-width: 250px;
  width: fit-content;
  height: fit-content;
  display: flex;

  .border-error {
    border-color: $errorRed !important;
  }

  .categories-overflow {
    width: 350px;
    overflow: scroll;
    padding: 6px !important;
  }

  .category-name-display {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-right: 0px solid transparent;
    border-radius: 4px 0px 0px 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: fit-content;
    max-width: 150px;
    min-height: 40px;
    height: fit-content;
    align-items: flex-start;
  }

  .single-select {
    align-items: center;
    min-height: calc(#{$fs_default} * 2.35);
  }

  .MuiChip-clickable:hover {
    background-color: rgb(209, 209, 209) !important;
  }

  .select-arrow {
    background-color: white;
    border-radius: 0px;
    padding: 0px 10px !important;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-left: 0px solid transparent;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .select-button {
    margin-left: 0 !important;
    border-radius: 0px 4px 4px 0px;
    min-height: calc(#{$fs_default} * 2.35);
  }

  .select-button-with-clear {
    border-radius: 0;
  }

  .clear-button {
    border-left: 0px solid transparent !important;
    border-radius: 0px 4px 4px 0px;
    min-height: calc(#{$fs_default} * 2.35);
  }

  .MuiButton-root:not(:first-child):not(.MuiButtonGroup-grouped) {
    margin-left: 0px !important;
  }
}

.category-picker-container-full-width {
  background: transparent;

  width: 100%;
  max-width: 100%;
  min-width: fit-content;

  .category-name-display {
    background-color: white;
    max-width: unset;
    flex-grow: 1;
    justify-content: flex-start;
    flex-wrap: wrap;

    .MuiChip-root {
      margin: 2.5px;
    }
  }
}

.container-single-select {
  flex-wrap: wrap;
  min-width: 0;
  .category-name-display {
    flex: 1 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    line-height: 35px;
  }
}

.category-picker-modal {
  height: $modalHeight;
  width: 100%;
  min-width: 425px;
  max-width: 1000px;
  min-height: 500px;

  .MuiToolbar-root {
    height: 46px !important;
    min-height: 46px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $fs_header;
    background-color: #f0f1f4 !important;
  }

  .picker-title {
    width: 100%;
    text-align: center;
  }

  .picker-actions {
    padding: 0 1.75rem;
    height: 46px;
    display: flex;
    justify-content: flex-end !important;
    align-items: center !important;
  }

  .picker-modal-content {
    height: 46px;
    padding: 6px 1.75rem;

    .react-dropdown-tree-select {
      height: 34px;

      .dropdown {
        height: 34px;
        display: block;

        .dropdown-trigger {
          box-sizing: border-box;
          height: 34px;
          width: 100%;
          border-radius: 4px;
          padding: 0;
          overflow: hidden;

          ul.tag-list {
            width: 100%;
            height: 100%;

            li.tag-item {
              display: none;
            }

            li.tag-item:nth-last-child(1) {
              display: block;
              margin: 0;
              height: 100%;
            }

            li.tag-item > input {
              width: 100%;
              height: 100%;
              border-bottom: none;
              font-size: 13px !important;
              padding: 0 0.75rem;
            }
          }
        }

        .dropdown-trigger.arrow.top::after,
        .dropdown-trigger.arrow.bottom::after {
          display: none;
        }

        .dropdown-content {
          overflow-y: auto;
          max-height: calc(#{$modalHeight} - 3 * #{$toolbarHeight} - 20px);
          padding: 0;
          width: 100%;
          min-width: 100%;
          background: none;
          border-top: none;
          box-shadow: none;

          .node {
            width: 100%;
            max-width: 100%;
            min-height: 34px;
            padding: 0;
            display: flex;
            align-items: center;
            border-radius: 4px;

            label {
              margin: 0;
              padding-left: 0.75rem;
              width: 100%;
              max-width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              border-radius: 4px;
            }

            input.radio-item {
              display: none;
            }
          }

          .node.checked > label {
            background-color: #d3f7f7;
          }

          .toggle {
            // Material Icons font default styles
            font-family: 'Material Icons';
            font-weight: normal;
            font-style: normal;
            font-size: 18px; // Icon size
            display: inline-block;
            line-height: 1;
            text-transform: none;
            letter-spacing: normal;
            word-wrap: normal;
            white-space: nowrap;
            direction: ltr;
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            -moz-osx-font-smoothing: grayscale;
            font-feature-settings: 'liga';

            // Other styles...
            margin: 0;
          }

          .toggle.collapsed::after {
            cursor: pointer;
            content: '\e313';
          }

          .toggle.expanded::after {
            cursor: pointer;
            content: '\e316';
          }
        }
      }
    }
  }
}

.picker-modal-content-tree {
  .dropdown .dropdown-content ul .infinite-scroll-component {
    height: calc(#{$modalHeight} - 3 * #{$toolbarHeight} - 20px) !important;
    max-height: calc(#{$modalHeight} - 3 * #{$toolbarHeight} - 20px) !important;
    margin: 0;
    padding: 0;
  }
}