@import '../../../css/index';

#MainFooterForm {
  bottom: 0;

  .icon {
    margin-left: 10px;
  }

  .toolbar-campaign-name {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .MuiFormControl-root {
      height: 100% !important;
    }
  }

  .toolbar-campaign-status {
    display: flex;
    margin-left: 5px;
    font-size: $fs_default - 4px !important;
    font-weight: 400 !important;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .toolbar-input {
    input {
      font-size: $fs_default !important;
      font-weight: 700 !important;
    }

    .MuiInput-underline:before {
      display: none;
    }
    .MuiInput-underline:after {
      display: none;
    }
  }

  .error > .Mui-error {
    color: $red !important;
  }

  .tabs-toolbar {
    height: 50px;
  }

  .back-to-step-btn {
    margin-right: 10px;
    white-space: nowrap;
    border: 2px solid #009f9f !important;
    text-transform: capitalize;

    .MuiSvgIcon-root {
      margin-right: 4px;
    }
  }

  .draft-btn {
    border: 2px solid #009f9f !important;
  }

  .initiate-btn {
    margin-right: 10px;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .MuiLinearProgress-root {
    height: 2px;
  }

  .MuiToolbar-root {
    padding: 0 16px 5px !important;
    justify-content: space-between;
    min-height: inherit !important;
    background-color: white;
  }

  .MuiInputBase-root.error {
    border-bottom: solid $red 1px;
  }

  .survey-name {
    font-size: $fs_default !important;
  }

  form {
    display: flex;
    align-content: center;
    margin-left: 0 !important;

    a {
      align-self: center;
      font-size: 13px;
    }
  }

  input[type='text'] {
    font-size: 20px;
    padding: 10px;
    // max-width: 70%;
  }

  input[type='text']:focus {
    border-bottom: solid #888 1px;
    margin-bottom: -1px;
  }

  .end-buttons {
    white-space: nowrap;

    .MuiButton-root {
      letter-spacing: 0;
      opacity: 1;
      margin: 10px 5px;
    }

    .btn-icon {
      min-width: 34px !important;

      .MuiButton-label {
        position: absolute;

        svg {
          font-size: 20px !important;
        }
      }
    }
  }
}

body {
  .MuiAutocomplete-popup {
    z-index: 5000;
  }
}
