@import '../../../css/index';

$toggleSelectedBgColor: rgba(33, 150, 243, 0.3);
$toggleSelectedFgColor: rgb(41, 144, 232);
$fgColor: #848da2;
$chipColor: #E9E9F0;
$bgDarkColor: rgb(213, 213, 222);
$bgDisabled: #EBEBEB;
$surveySelectorHeight: calc(100vh - 150px);
$currentSurveyMaxHeight: calc(100vh - 270px);
$currentSurveyMinHeight: 300px;

$paginSelectedBgColor: rgba(33, 150, 243, 0.30);
$paginSelectedFgColor: #6B6985;
$paginBgColor: rgb(232, 232, 232);
$bgColor: #F2F3F6;
$fgColor: #6B6985;
$bgDarkColor: #DCE1ED;
$summaryDrawerWidth: 460px;

#PulseCampaignTemplatePreviewer {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 15px 15px 5px 15px;
  overflow-y: none;
  overflow-x: hidden;
  min-height: 60px;

  .survey-selector-status-key {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-end;

    .survey-selector-status-key-item {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;

      .MuiBadge-badge {
        height: 14px;
        width: 14px;
        min-height: 14px !important;
        min-width: 14px !important;
      }

      .status-key-badge {
        height: 14px;
        width: 14px;
      }

      .status-key-text {
        right: 0;
        width: 60px;
        margin-left: 8px;
        font-size: 12px;
        font-weight: 600;
        color: $fgColor;
      }
    }
  }

  .survey-selector-root {
    width: 100%;

    .survey-selector-root-grid {
      width: 100%;
      max-width: 100%;
      flex-wrap: wrap;

      .survey-selector-root-grid-btn-grp {
        display: unset !important;
        border-radius: 4px;
      }
    }

    .survey-selector-root-grid-btn {
      max-height: 38px;
      border: 1px solid rgba(0, 0, 0, 0.12)
    }

    .survey-selector-root-grid-item {
      display: flex;
      justify-content: center;

      .MuiGrid-grid-xs-true {
        justify-content: center;
        display: flex;
      }
    }

    .MuiToggleButton-root {
      background-color: white;
      border-radius: 10px !important;
      margin: 0 5px 5px 5px;
      font-weight: bold;
      font-size: $fs_subheader;
      text-align: center;
      line-height: normal;
    }

    .MuiToggleButton-root.Mui-selected {
      background-color: $toggleSelectedBgColor;
      color: $toggleSelectedFgColor;
    }

    .MuiToggleButton-root.Mui-disabled {
      color: rgba(0, 0, 0, 0.12) !important;
    }
  }

  .selector-error{
    border: 1px solid red !important;
  }

  .survey-selector-title {
    display: flex;
    min-width: 100px;
    width: 100px;
    @include themify($themes) {
      color: themed('calEventTextColor') !important;
    }
    margin-right: 10px;
    font-size: $fs_default;
    font-weight: bold;
    text-align: center;
  }

  .survey-selector-survey-btn {
    width: 22px;
    height: 22px;
  }
}

#PulseCampaignCurrentSurveyPreview {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 98%;
  flex-grow: 1;
  flex-shrink: 1;
  border-radius: 10px;
  margin: 0 15px 0 15px;
  background-color: white;
  overflow-y: auto;
  min-height: $currentSurveyMinHeight;
  max-height: $currentSurveyMaxHeight;
  height: 100%;
  padding: 0 15px 0 15px;

  .survey-selector-survey-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 10px 0 10px;
  }

  .survey-selector-survey-card-header {
    display: flex;
    flex-direction: row;
    margin: 10px 0 0 10px;
    justify-content: space-between;

    .survey-card-header-actions {
      display: flex;
      flex-direction: row;
    }
  }

  .survey-selector-survey-card-questions {
    display: flex;
    width: 100%;
    margin: 10px 0 0 10px;
    padding-right: 10px;

    .quest-error{
      color: red !important;
    }

    .selected-template-screen {
      text-align: center;
      margin: 50px auto 0
    }

    .template-title {
      margin-bottom: 24px;
    }
  
    .template-body-item {
      margin-bottom: 2px;
    }

    .template-selected-actions {
      margin: 22px 0 12px;

      .preview-button{
        margin-top: 10px;
      }
    }
  }

  .survey-selector-survey-card-question {
    display: flex;
    width: 100%;
  }

  .no-questions-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    text-align: center;
    letter-spacing: 1px;
  }

  .no-questions-heading {
    margin-bottom: 5px;
    color: $fgColor;
    font-weight: bold;
    font-size: $fs_default;
  }

  .no-questions-subheading {
    color: $fgColor;
    font-size: $fs_secondary;
  }
}

#PreviewQuestionsGrid {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  padding: 0 0 5px 0;
}

#PulseCampaignTemplatePreviewerGrid {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: $surveySelectorHeight;
  height: 100%;

  .previewer-empty-state {
    height: 100%;
    width: 100%;

    #EmptyState {
      height: 100%;
  
      .empty-state-container {
        .empty-state-header {
          font-weight: bold;
          color: #6F8096 !important;
        }
        #EmptyStateText {
          .empty-state-text {
            color: #6F8096 !important;
          }
        }
      }
    }
  }

  .survey-selector-grid-top-container {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .survey-selector-grid-bottom-container {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .survey-selector-grid-top-item {
    width: 100%;
  }

  .survey-selector-grid-bottom-item {
    height: 100%;
    max-height: $currentSurveyMaxHeight;
    min-height: $currentSurveyMinHeight;
  }

  .pc-card-header-actions {
    transform: translate(35%, -30%);
  }

  .pulse-campaign-previewer-grid-categories {
    width: 100%;
    margin: 5px 0 5px 0;
    text-align: center;
    max-height: 71px;
  }

  .pulse-campaign-previewer-grid-categories-grps {
    width: 100%;
    text-align: center;
    margin: 0;
  }

  .pulse-campaign-previewer-grid-questions-list {
    display: flex;
    flex-direction: row;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .pulse-campaign-previewer-grid-questions-list-root {
    width: 100%;
    height: 100%;
  }

  .pulse-campaign-previewer-grid-questions-list-grp {
    height: calc((100vh - 490px) / 5) !important;
    max-height: calc((100vh - 480px) / 5) !important;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    margin-bottom: 5px;
    cursor: pointer;

    &.border-cat-1 {
      border-left: 6px solid #A5AACA;
      color: $fgColor  !important;
      height: 100%;
    }

    &.border-cat-2 {
        border-left: 6px solid #D7ABA0;
        color: $fgColor  !important;
        height: 100%;
    }

    &.border-cat-3 {
        border-left: 6px solid #99CAD1;
        color: $fgColor  !important;
        height: 100%;
    }

    &.border-cat-4 {
      border-left: 6px solid #4dabf5;
      color: $fgColor !important;
      height: 100%;
    }

    &.border-cat-5 {
      border-left: 6px solid #ffc107;
      color: $fgColor !important;
      height: 100%;
    }

    &.border-cat-6 {
      border-left: 6px solid #dd33fa;
      color: $fgColor !important;
      height: 100%;
    }

    &.border-cat-7 {
      border-left: 6px solid #d1ff33;
      color: $fgColor !important;
      height: 100%;
    }

    &.border-cat-8 {
      border-left: 6px solid #4aedc4;
      color: $fgColor !important;
      height: 100%;
    }

    &.border-cat-9 {
      border-left: 6px solid #3d5afe;
      color: $fgColor !important;
      height: 100%;
    }

    &.border-cat-10 {
      border-left: 6px solid #834bff;
      color: $fgColor !important;
      height: 100%;
    }
  }

  .pulse-campaign-previewer-grid-questions-list-grp-item {
    height: 100%;
    width: 100%;
    text-align: left;
    padding: 5px !important;
    justify-content: space-around;

    .MuiChip-outlinedPrimary {
      border: 1px solid rgba(0, 0, 0, 0.23);
    }

    .MuiChip-sizeSmall {
      height: 18px !important;
    }

    .MuiChip-root {
      font-size: 10px !important;
      font-size: clamp(8px, 0.9vw, 10px) !important;
      border-radius: 4px !important;
      background-color: transparent;
    }
  }

  .pulse-campaign-previewer-grid-questions-list-grp-item-text {
    width: 100%;
    font-weight: 400;
    font-size: 12px !important;
    font-size: clamp(10px, 0.9vw, 12px) !important;
    white-space: normal;
    flex-wrap: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 35px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .pulse-campaign-previewer-grid-questions-list-grp-item-chip {
    margin-top: 5px;
    color: $paginSelectedFgColor;
  }

  .questions-recap-text {
    display: flex;
    flex-direction: column;
    height: 100%;
    @include themify($themes) {
      color: themed('calEventTextColor') !important;
    }
    font-size: $fs_default;
    font-weight: bold;
    text-align: center;
    justify-content: center;
  }

  .icon-container {
    padding: 0px;
    margin: 0px 10px 0px 10px;
    background-color: $bgDarkColor !important;

    .expand-icon {
      color: #6B6985;
      height: 24px;
      width: 24px;
    }
  }
}


