@import '../../../../css/index';

#CampaignTemplatesList {
  height: calc(100vh - #{$topNavHeight});
  width: 100%;

  .templates-container::-webkit-scrollbar {
    display: none;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 10px;
  }

  .campaign-template-row {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    height: 100px;
    margin-bottom: 15px;
    background-color: #fff;

    .template-preview {
      width: 100px;
      background-color: #f6f7fc;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .template-details {
      padding: 20px;
      width: 100%;

      .template-name {
        font-size: $fs_subheader;
        font-weight: 700;
      }

      .template-actions {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .campaign-template-grid {
    border-radius: 10px;
    height: 250px;
    background-color: #fff;
    position: relative;

    .template-preview {
      height: 125px;
      background-color: #f6f7fc;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .template-details {
      padding: 20px;

      .template-name {
        font-size: $fs_subheader;
        font-weight: 700;
      }

      .template-actions {
        display: flex;
        justify-content: flex-end;

        .use-template {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;        
          position: absolute;
          bottom: 5px;


          .arrow {
            width: 24px;
            margin-right: 10px;

            .line {
              margin-top: 3px;
              width: 18px;
              background: #77879c;
              height: 2px;
              float: left;
            }

            .point-right {
              width: 0;
              height: 0;
              border-top: 4px solid transparent;
              border-bottom: 4px solid transparent;
              border-left: 6px solid #77879c;
              float: right;
            }
          }

          .use-template-text {
            color: #77879c;
            font-size: $fs_tertiary;
            font-weight: 700;
          }
        }
      }
    }
  }
}
