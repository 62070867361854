/* Standard */
$black: #000000;
$blue: #0000ff;
$red: #ff0000;
$white: #ffffff;

/* Background and Surfaces */
$blackRock: #2d2d3c;
$bastille: #292936;
$gunPowder: #4e4e5e; // dashboard background
$indianRed: #e85c5c; // validation error + required asterisk
$indianRedLight: #e4a3a3;
$whisper: #ede8e8;
$whiteSmoke: #f8f5f5; // widget background

/* Text */
$nightRider: #333333;
$errorRed: $indianRed;
$confirmGreen: #6aca9a;

/* Accent and Data Vis */
$airforceBlue: #587ca3;
$darkTurquoise: #05d1cf; // ripple blue
$darkTurquoiseDark: #049b98; // ripple blue
$electricBlue: #66ffff;
$geraldinePink: #e26c6c;
$jordyBlue: #8bbdea;
$medAquamarine: #6acab1;
$morningGlory: #94dad8;
$peruGold: #cd853f;
$rhythmPurple: #87729f;
$topazYellow: #ffcd6e;
$topazYellowDark: #b48d44;
$tuscanYellow: #f5d597;
$viridianGreen: #009f9e; // primary CTA
$lightGreenCyan: #a2cabb;
$vodka: #bfc0ee;

/* Whites & Grays */
$altoGray: #dddddd;
$antiflashWhite: #efeff4; // drawer header background
$gainsboroGray: #d8d8e2;
$ghostWhite: #f9f9fc; // drawer toolbar background
$lavenderGray: #cacace; // widget drag handle
$lightGray: #ceced5;
$platinumGray: #e3e3e8;
$solitudeGray: #f0f1f4;
$spanishGray: #91919a;
$whiteLilac: #edf1f9;

/* Opacities */
$blackRock50: rgba(45, 45, 60, 0.5);
$black10: rgba(0, 0, 0, 0.1);
$black40: rgba(0, 0, 0, 0.4);
$black30: rgba(0, 0, 0, 0.3);
$black50: rgba(0, 0, 0, 0.5);
$black90: rgba(0, 0, 0, 0.9);
$darkTurquoise10: rgba(5, 209, 207, 0.1);
$darkTurquoise20: rgba(5, 209, 207, 0.2);
$darkTurquoise30: rgba(5, 209, 207, 0.3);
$darkTurquoise40: rgba(5, 209, 207, 0.4);
$darkTurquoise80: rgba(5, 209, 207, 0.8);
$gunPowder30: rgba(78, 78, 94, 0.3);
$gunPowder80: rgba(78, 78, 94, 0.8);
$indianRed10: rgba(232, 92, 92, 0.1);
$indianRed40: rgba(232, 92, 92, 0.2);
$indianRed80: rgba(232, 92, 92, 0.8);
$jordyBlue20: rgba(139, 189, 234, 0.2);
$jordyBlue60: rgba(139, 189, 234, 0.6);
$jordyBlue10: rgba(139, 189, 234, 0.2);
$viridianGreen10: rgba(0, 159, 158, 0.1);
$viridianGreen20: rgba(0, 159, 158, 0.2);
$viridianGreen40: rgba(0, 159, 158, 0.4);
$viridianGreen60: rgba(0, 159, 158, 0.6);
$viridianGreen80: rgba(0, 159, 158, 0.8);
$white60: rgba(255, 255, 255, 0.6);
$white80: rgba(255, 255, 255, 0.8);
$white90: rgba(255, 255, 255, 0.9);
$whiteSmoke50: rgba(248, 245, 245, 0.5);
$whiteSmoke80: rgba(248, 245, 245, 0.8);
$green80: #15d7a680;
$red80: #f4776e80;
$yellow80: #ffe27280;
$indianRed15: rgba(232, 92, 92, 0.15);

/* Mui-Primary */
$primary: $blackRock;
$primaryLight: $gunPowder;
$primaryDark: $bastille;
$primaryContrastText: $whiteSmoke;

/* Since secondary is taken (see below), light is the name of the secondary palette (since it is a light palette). */
$light: $whiteSmoke;
$lightContrastText: $nightRider;
$light50: $whiteSmoke50;

/* Mui-Accent */
$accent: $viridianGreen;

/*  I misused some variable names early on, so they're not named the Mui way.
    Avoid using these for now, and we will remove them in the future. */
$secondary: $gunPowder;
$primaryText: $whiteSmoke;
$secondaryText: $white;
$lightText: $nightRider;

/* Previous Colors - Will refactor at a later time */
// $lightGray: #e6e9ed;
$mediumGray: #62626d;
$darkGray: #262634;
$teal: #3dc1d5;
$orange: #f79e56;
$yellow: #ffe272;
$green: #15d7a6;
$red: #f4776e;
$purple: #a389d4;
$blue: #009fe8;

/* Audience Tab */
$greenHover: #0cc093;
$redHover: #e4635a;
$tableRowLightGray: #f9f9f9;
$searchBarGray: #d7d8da;

/* Choice Question */
$plusIconTeal: #70cccc;
$minusIconOrange: #f79e56;

/* Survey Taking */
$surface: $white;
$textButtonHoverBackgroundColor: #009f9e0d;
$textButtonHoverColor: $viridianGreen;
$checkboxActiveColor: $darkTurquoise;
