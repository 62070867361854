@import '../../../../css/index';

$bgColor: #dce1ed;
$fgColor: #6f8096;

#FilterBar {
  padding: 0px 25px 10px 0px;

  .filter-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .filter-bar-left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .date-range-btns {
      display: flex;
      flex-direction: row;
      align-items: center;

      .date-range-btn {
        max-height: 34px;
        max-width: 240px;
        text-transform: uppercase;
        color: $fgColor !important;
        font-weight: 700 !important;
        font-size: $fs_tertiary !important;
        background-color: $bgColor;
        margin-left: 20px;
        padding: 18px 9px 18px 9px !important;

        .event-icon {
          margin-right: 10px;
          font-weight: 600;
        }
      }

      .clear-date-range-btn {
        margin-left: 5px;
        padding: 0px !important;
      }
    }

    .status-btns {
      margin-left: 20px;
      display: flex;
      flex-direction: row;

      .MuiToggleButton-root {
        background-color: rgba(255, 255, 255, 0.7) !important;
        @include themify($themes) {
          color: rgba(0, 0, 0, 0.38) !important;
        }
      }

      .MuiToggleButton-root.Mui-selected {
        @include themify($themes) {
          background-color: themed('buttonOtlBgColor') !important;
          color: themed('buttonOtlTextColorAlt') !important;
        }
      }

      .tab-btn-selected {
        max-height: 34px;
        max-width: 240px;
        text-transform: uppercase;
        color: $fgColor !important;
        font-weight: 700 !important;
        font-size: $fs_tertiary !important;
        background-color: $bgColor;
        margin-right: 5px;
        padding: 18px 9px 18px 9px !important;
        box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1), inset 0 6px 8px 0 rgba(0, 0, 0, 0.1);

        .check-icon {
          margin-left: 5px;
          color: #b0b7c4;
          font-weight: 600;
        }

        &.border-status-initiated {
          border-left: 6px solid #a5aaca;
          color: $fgColor !important;
        }

        &.border-status-completed {
          border-left: 6px solid #d2a69b;
          color: $fgColor !important;
        }
      }

      .tab-btn-unselected {
        max-height: 34px;
        max-width: 240px;
        font-weight: 700 !important;
        color: #6c6c7f !important;
        font-size: $fs_tertiary !important;
        background-color: $bgColor;
        padding: 18px 9px 18px 9px !important;
        border-bottom: 0px !important;

        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);

        .MuiButton-root {
          height: 34px;
          max-height: 34px;
          margin-left: none !important;
          margin-right: 10px !important;
        }

        &.border-status-initiated {
          border-left: 6px solid #a5aaca;
          color: $fgColor !important;
        }

        &.border-status-completed {
          border-left: 6px solid #d2a69b;
          color: $fgColor !important;
        }
      }
    }
  }
}
