@import '../../../css/index';

#CampaignEditOptions {
  .icon {
    margin-left: 10px;
  }

  .toolbar-campaign-name {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .MuiFormControl-root {
      height: 100% !important;
    }
  }

  .toolbar-campaign-status {
    display: flex;
    margin-left: 5px;
    font-size: $fs_default - 4px !important;
    font-weight: 400 !important;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .toolbar-input {
    input {
      font-size: $fs_default !important;
      font-weight: 700 !important;
    }

    .MuiInput-underline:before {
      display: none;
    }
    .MuiInput-underline:after {
      display: none;
    }
  }

  .error > .Mui-error {
    color: $red !important;
  }

  .tabs-toolbar {
    height: 32px;
    justify-content: space-between;

    .campaign-name {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 10px;
    }
  }

  .back-to-campaigns-btn {
    margin-right: 10px;
    margin-left: 10px;
    white-space: nowrap;

    .MuiSvgIcon-root {
      margin-right: 4px;
    }
  }

  .MuiLinearProgress-root {
    height: 2px;
  }

  .MuiToolbar-root {
    padding: 0 16px !important;
    justify-content: space-between;
    min-height: 100% !important;
  }

  .MuiInputBase-root.error {
    border-bottom: solid $red 1px;
  }

  .survey-name {
    font-size: $fs_default !important;
  }

  form {
    display: flex;
    align-content: center;
    margin-left: 0 !important;

    a {
      align-self: center;
      font-size: 13px;
    }
  }

  input[type='text'] {
    font-size: 20px;
    margin: 10px;
    max-width: 30%;
  }

  input[type='text']:focus {
    border-bottom: solid #888 1px;
  }

  .end-buttons {
    white-space: nowrap;

    .MuiButton-root {
      letter-spacing: 0;
      opacity: 1;
      margin: 10px 5px;
    }

    .btn-icon {
      min-width: 34px !important;

      .MuiButton-label {
        position: absolute;

        svg {
          font-size: 20px !important;
        }
      }
    }
  }
}

body {
  .MuiAutocomplete-popup {
    z-index: 5000;
  }
}
