@import '../../css/index';

#MyGoalsWidget {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  .MuiCardContent-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .filter {
    display: flex;
    align-items: center;
    padding-right: 20px;
    .MuiInput-underline:after {
      border-bottom: 0px !important;
    }
    .MuiFormLabel-root {
      margin-bottom: 0px !important;
      margin-right: 5px;
    }
    .Muifocused {
      &:before {
        border-bottom: 0px;
      }
    }
    .MuiInputBase-root {
      &:before {
        border-bottom: 0px;
      }
    }
  }

  #Content {
    overflow-y: scroll;
    .progress-label {
      margin-left: -40px;
      // position: absolute;
    }

    .goal-row {
      border-bottom: 2px solid lightgray;
      padding: 10px 20px;
      height: 140px;
      position: relative;
      background-color: white;

      .unread {
        cursor: pointer;
      }

      .unread-indicator {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        background-color: $blue;
        z-index: 1;
        left: 5px;
        top: 63px;
      }

      .goal-title {
        font-size: 20px;
        font-weight: 600;
      }

      .edit-icon {
        cursor: pointer;
        color: gray;
      }

      .break {
        color: gray;
      }

      .MuiLinearProgress-root {
        width: 100%;
        height: 20px;
        border-radius: 20px;
        background-color: rgba(208, 208, 208, 0.5) !important;
      }

      .owned .MuiLinearProgress-bar1Determinate {
        background-color: $tuscanYellow;
        border-radius: 20px;
      }

      .not-owned .MuiLinearProgress-bar1Determinate {
        background-color: $plusIconTeal;
        border-radius: 20px;
      }

      .user-name {
        margin-left: 5px;
      }

      .MuiGrid-item {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
      }

      .active {
        margin-right: 5px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: $confirmGreen;
      }
      .inactive {
        margin-right: 5px;

        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: $errorRed;
      }
    }
  }
}
