@import '../../../css/colors';

#CalendarManagementWidget {
  height: 100%;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .MuiTableBody-root {
    .MuiTableCell-root {
      &:first-child {
        border-left: solid #ffcd6e 3px !important;
      }
    }
  }

  .MuiCardContent-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .cardContent {
    height: inherit;
    padding: 0;

    .MuiTypography-root {
      height: inherit;
    }
  }

  .margin-right {
    margin-right: 10px;
  }

  .tabs {
    margin-top: 2px;
  }

  .MuiTab-textColorInherit.Mui-selected {
    color: #05d1cf !important;
  }
  .MuiTabs-indicator {
    background-color: #05d1cf !important;
  }

  .MuiBox-root {
    height: 100%;
    padding: 0px !important;
  }

  @import '../../../css/ag-grid-tables';

}
