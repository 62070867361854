@import '../../../../css/index';

#WelcomeBannerEdit {

  .main-content {
    top: 0;
    height: calc(100vh - 290px);
    overflow-y: auto;

    .section:not(:last-child) {
      margin-bottom: 18px;
    }
  }

  .banner-edit-header {
    .banner-edit-title {
      padding-top: 10px;
    }

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .body-invalid {
    padding: 1px;
    border: 1px solid #f44336
  }

  .required {
    color: $errorRed;
  }

  .section {
    margin-bottom: 10px;
  }

  .input-label {
    font-weight: 700;
    color: $gunPowder;
    margin: 5px 0px;
  }

  .btn-row {
    display: flex;
    justify-content: flex-end;
  }

  .attachment-header {
    display: flex;
    flex-direction: row;
  }

  .attachments-list {
    .MuiGrid-grid-xs-6 {
      flex-basis: 48% !important;
      max-width: 48%;
    }
  }
  
  .attachment-container {
    margin: 2.5px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid $lavenderGray;

    .attachment-content {
      height: 100%;
      display: flex;
      align-items: center;
      height: 48px;
      width: 80%;

      span {
        text-overflow: ellipsis;
        max-width: 80%;
        overflow: hidden;
      }
    }

    .file-container {
      border-top-left-radius: 10px;
      background-color: $whiteSmoke;
      border-bottom-left-radius: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 40px;
      height: 100%;
      max-height: 48px;
      border-right: 1px solid $lavenderGray;
      margin-right: 5px;
      svg {
        color: $lavenderGray;
      }
    }

    .file-container {
      border-top-left-radius: 10px;
      background-color: $whiteSmoke;
      border-bottom-left-radius: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 40px;
      height: 100%;
      max-height: 48px;
      border-right: 1px solid $lavenderGray;
      margin-right: 5px;
      svg {
        color: $lavenderGray;
      }
    }

    .thumbnail-container {
      width: 40px;
      max-height: 48px;
      height: 100%;
      border-right: 1px solid $lavenderGray;
      margin-right: 5px;
      justify-content: center;
      align-items: center;
      display: flex;
      img,
      video {
        height: 48px;
        width: 40px;
        position: relative;
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}