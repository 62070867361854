@import '../../../../css/index';

.userTaskPopupCard {
  width: 450px;

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0 9px !important;
  }

  .user-task-popup-title-grid {
    h5 {
      font-size: $fs_default !important;
    }
  }

  .user-task-popup-title-grid,
  .user-task-popup-due-grid,
  .user-task-popup-category-grid,
  .user-task-popup-assigned-by-grid {
    padding: 0 8px !important;
    margin-bottom: 10px;
  }

  .MuiCardHeader-root {
    padding: 2px;
    background-color: #ede8e8;

    .MuiCardHeader-action {
      margin: 0 0;
    }
  }

  .task-link {
    padding: 4px 8px;
    font-weight: 700;
    float: right;
    cursor: pointer;
  }

  .popup-due {
    font-weight: 600 !important;
  }

  .maybe-going {
    font-weight: 9rem;
  }

  .icon {
    color: $spanishGray;
  }

  .MuiAvatar-root {
    height: 20px !important;
    width: 20px !important;
  }

  .MuiCardContent-root {
    padding-bottom: 0;
  }
}

.participants-container {
  margin-top: 5px;
}

.location-container {
  flex-wrap: nowrap !important;
  margin-bottom: 10px;
}

.popup-participant-row {
  display: flex;
  align-items: center;
}

.participant-name {
  margin-left: 5px !important;
}

.calendarEventModal {
  z-index: 100;
}
