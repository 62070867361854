@import '../../../../css/index';

#UserDashboardHeaderContainer,
#DashboardToolbar {
  @media only screen and (max-width: 1110px) {
    height: 80px;
  }
  @media only screen and (max-width: 850px) {
    height: 110px;
  }
  @media only screen and (max-width: 520px) {
    height: 130px;
  }
  @media only screen and (max-width: 360px) {
    height: 170px;
  }
  @media only screen and (max-width: 320px) {
    height: 220px;
  }
}

#DashboardToolbar {
  width: 100%;
}

.dashboard-toolbar {
  background-color: transparent;
  height: $userDashboardTopToolbarHeight;

  &.edit-mode {
    background-color: $black40 !important;
  }
}

.dashboard-header {
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  background-color: transparent;

  .dashboard-title {
    .MuiInputBase-root {
      height: 48px !important;
      margin-left: -10px;
    }
    input {
      padding: 12px 16px 17px 10px;
      align-items: center;
      font-size: 22px !important;
      @include themify($themes) {
        color: themed('dashboardHeaderText') !important;
      }
    }

    .MuiInput-underline {
      border-bottom: 1px solid $whiteSmoke;
    }
  }

  .dashboard-dropdown {
    align-items: center;

    .list-button {
      margin-left: -10px;
      width: auto;
      max-width: 350px;
      padding: 10px 16px 14px 10px;
      font-size: 22px;
      font-weight: 600;
      border-radius: 4px;

      @include themify($themes) {
        color: themed('dashboardHeaderText') !important;
      }

      &:hover {
        background-color: $black30;
      }

      &.is-Menu-Open {
        box-shadow: 0px 0px 0px 2px $black10 inset;
        background-color: $blackRock50;
      }

      .text {
        width: 200px;
      }

      svg {
        margin-left: 8px;
      }
    }
  }

  .dashboard-action {
    align-items: center;

    .edit-memo {
      border-bottom: 1px solid $white90;
      margin-right: 10px;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      color: $white90;
      letter-spacing: 2px;
    }

    .MuiButton-outlined {
      @include themify($themes) {
        background-color: themed('buttonOtlBgColor') !important;
        color: themed('buttonOtlTextColorAlt') !important;
      }
    }

    .iconButtons {
      color: #f8f5f5;
      margin-left: 16px;
    }

    .btn-icon {
      margin-left: 10px;
      &.btn-icon-white {
        color: $white !important;

        &:hover {
          background-color: $viridianGreen10 !important;
        }
      }

      &.btn-fave {
        color: $white !important;

        &:hover {
          background-color: $indianRed10 !important;
        }
      }

      &.btn-isFaved,
      &.btn-icon-red {
        .MuiButton-label {
          color: $white !important;

          .MuiSvgIcon-root {
            color: $indianRed !important;
          }
        }

        &:hover {
          background-color: $indianRed10 !important;
        }
      }
    }

    .btn-cancel {
      color: $white !important;
      background-color: $indianRed !important;

      &:hover {
        background-color: $indianRed80 !important;
      }
    }

    .btn-outlined-white {
      color: $white !important;
    }

    .MuiButton-outlined.btn-outlined-red {
      color: $indianRed !important;
    }
  }

  .moreDashboardsList {
    z-index: 1;
    width: 350px;

    .dash-list {
      max-height: calc(90vh - 64px - 35px * 3);
      overflow-y: auto;
    }

    .selected {
      @include themify($themes) {
        background-color: themed('primaryBgColor') !important;
      }
    }

    .favoriteIcon {
      margin-right: 6px;
    }

    .textGap {
      margin-right: 20px;
    }

    .allSharedDash {
      color: $darkTurquoise !important;
    }

    .popper-paper {
      border-top-left-radius: 0;
      // border-top-right-radius: 0;
      width: auto;
      padding: 8px 0 !important;
      @include themify($themes) {
        background-color: themed('primaryBgColor') !important;
      }

      .popper-root {
        padding: 0;
      }
    }

    .popper-listItem {
      color: #f8f5f5;
      font-size: 1.25rem;
      padding: 8px 16px 8px 12px;

      &:hover {
        background-color: $black30;
      }

      .text {
        width: 285px;
      }

      svg {
        font-size: 1.2rem;
      }

      .checkIcon {
        margin-left: auto;
        color: $darkTurquoise;
      }
    }
  }
}
#UndoButton {
  .MuiButton-label {
    @include themify($themes) {
      color: themed('buttonOtlTextColorAlt') !important;
    }
  }
}
