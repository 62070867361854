@import '../../../../css/index';


#CampaignDetails {
  padding: 10px 10px 10px 10px;

  .MuiDialog-paper{
    width: 100%;
    overflow-y: none !important;
  }

 
.campaign-details{
  height:720px;
  position:relative;
}

.header-text{
  background-color: #f4f5fa;
  color:#6f8096;
  border-bottom: #dee3ee solid 1px;

  .details-title{
    display:flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .MuiTypography-h6{
    font-weight: 700 !important;
  }
}
.details-summary{
  height:130px;
  background-color: #f1f2f6;
  display:flex;
  flex-direction: column;
  padding: 20px 10px 10px 20px;

  .summary-header{
    height:40px;
    display:flex;
    align-items: center;

    .pulse-icon{
      transform: rotate(60deg);
    }
  }
 
  .pulse-name{
    font-size: 15pt;
    font-weight: 800 !important;
    color: #333333;
    margin-left: 10px;
  }

  .summary-info-bar{
    height:60px;
    display:flex;
    flex-direction: row;

    .status-header{
      margin-top:10px;
      margin-left: 50px;
      color:#68686a;
      font-weight: 800 !important;
      font-size: 10pt ;
    }

    .status-text{
      margin-left: 50px;
      display: flex;
    }


    .status{
      color:#68686a;
      font-size: 10pt ;
    }

    .status-indicator {
      height: 10px;
      width: 10px;
      background-color: #92ddc0;
      border-radius: 50%;
      margin-right: 5px;
      margin-top:5px;
    }

    .effective-header{
      margin-top:10px;
      margin-left: 50px;
      color:#68686a;
      font-weight: 800 !important;
      font-size: 10pt ;
    }

    .effective-text{
      margin-left: 50px;
      color:#68686a;
      font-size: 10pt ;
    }

    .summary-polarity {
      margin-right: 30px;
    }

    .polarity-header{
      margin-top:10px;
      color:#68686a;
      font-weight: 800 !important;
      font-size: 10pt ;
    }

    .responses-header{
      margin-top:10px;
      color:#68686a;
      font-weight: 800 !important;
      font-size: 10pt ;
    }

    .responses-text{
      color:#68686a;
      font-size: 10pt ;
    }
  }

.pulse-icon{
  background-color:#d2caeb;
  color:#73679e;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 7px
}
}

.campaign-actions{
  position: absolute;
  bottom:0;
  background-color: #f1f2f6;
  height: 60px;
  width:100%;
  display:flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;

.MuiButton-outlined{
  border: solid 2px #009f9e !important;
  font-size: 11pt !important;
  font-weight: 800 !important;
}

.actions-icon{
  color: #009f9e;
  height:14pt;
}

.actions-icon2{
  color: white;
  height:14pt;
}

}

.campaign-participants{

  .participants-header{
    display:flex;
    margin-left: 20px;
    font-weight: 700;
    display:flex;
    align-items: center;

    .text-cont{
      padding-top: 15px;
    }

    .active-survey-name{
      color:#6b6985;
      font-size: 10pt;
      font-weight: 700 !important;
      padding-top:3px;
      margin-left: 5px;
    }

  .participant-icon-cont{
    background-color: #daeffe;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  .participant-icon{
    height: 12px;
    width: 12px;
    color:#778df2;
     }
   }
  }

  .participant-list{
    padding: 5px 10px 5px 10px;
  }

}

.campaign-timeline{
  height: 175px;
  width: 100%;
  background-color: #f4f5fa;
  overflow: hidden;
}

  
}