@import '../../css/index';

#GroupsListCol {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding-bottom: 1em;
}

#RolesListGridContainer {
  max-height: 100%;
  width: 100%;
  padding-bottom: 1em;
  overflow-y: auto;
  overflow: hidden;
  flex-grow: 1;
}

#RolesListGrid {
  max-height: 100%;
  padding-bottom: 1em;
  width: 100%;
  overflow-y: auto;
}

#RolesListAGGrid {
  height: 53vh;
  max-height: 53vh;
  padding-bottom: 1.2em;
  width: 100%;
  overflow-y: auto;
}
