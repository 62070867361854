@import '../../../css/colors';

#QuizConfirmDialog {
  .quiz-dialog-title-div {
    background-color: #efeff4;
    .quiz-dialog-title {
      font-weight: bold;
    }
  }

  .quiz-confirm-content {
    padding-top: 10px;
    padding-bottom: 10px;

    .go-back-button {
      .MuiButton-label {
        color: #8e8d8d;
      }
    }

    .warning-icon-div {
      .warning-icon {
        margin-top: 5px;
        margin-left: 15px;
        width: 50px;
        height: 50px;
        color: #ffca3d;
      }
    }

    .quiz-dialog-subtitle {
      padding-bottom: 30px;
    }
  }
}
