@import '../../../../css/index';

$bgColor: white;
$bgDarkColor: rgba(128, 128, 128, 0.3);
$fgColor: #6f8096;

#CampaignRow {
  .campaign-row {
    display: flex;
    align-items: center;  
    // justify-content: space-between;
    height: 40px;
    margin: 5px;
    border-radius: 5px;
    

    &:hover {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
      cursor: pointer;
    }

    &.border-status-initiated {
      border-left: 6px solid #a5aaca;
      color: $fgColor !important;
    }

    &.border-status-completed {
      border-left: 6px solid #d2a69b;
      color: $fgColor !important;
    }

    &.expanded-true {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      margin: 5px 5px 0px 5px;
      background-color: #f1f2f6;
    }

    .title-text {
      color: #333333;
      font-size: $fs_tertiary;
      font-weight: 700;
    }

    .other-text {
      font-size: $fs_tertiary;
      font-weight: 700;
    }

    .icon-container {
      padding: 0px;
      margin: 0px 10px 0px 10px;
      background-color: $bgDarkColor !important;

      .expand-icon {
        color: #6b6985;
        height: 18px;
        width: 18px;
      }
    }

    .name-column {
      width: 12%;
      margin-right: 20px;
      white-space: nowrap;

      .title-text .full-title {
        display: flex;
      }
      
      .title-text .short-title {
        display: none;
      }
    }

    .progress-column {
      display: flex;
      flex-direction: row;
      width: 15%;
      text-align: center;
      align-content: center;
      align-items: center;

      .progress-container {
        background-color: #dce1ed;
        width: 65px;
        height: 16px;
        margin-right: 10px;
        border-radius: 10px;
        padding: 3px;

        .progress-bar {
          background-color: #8e94be;
          height: 100%;
          border-radius: 10px;
        }
      }
    }

    .start-column {
      width: 10%;
      text-align: center;
    }

    .end-column {
      width: 10%;
      text-align: center;
    }

    .polarity-column {
      width: 8%;
      display: flex;
      justify-content: center;
      text-align: center;

      .polarity-container {
        padding: 10px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        .polarity-text {
          font-size: $fs_tertiary;
          font-weight: 700;
        }

        &.positive {
          background-color: #c9e3d7;
        }

        &.neutral {
          background-color: #fef3e9;
        }

        &.negative {
          background-color: #f9e6e7;
        }
      }
    }

    .responded-column {
      width: 8%;
      text-align: center;
    }

    .actions-column {
      width: 12%;
      text-align: center;
    }

    .type-column {
      width: 9%;
      text-align: center;
    }

    .owner-column {
      width: 10%;
      text-align: center;
    }

    @media (max-width: 1250px) {

      .hide-on-small-screen {
        display: none;
      }
  
      .name-column {
        width: 13%;
        margin-right: 20px;
        text-overflow: ellipsis;
        
          .title-text .full-title {
            display: none;
          }
        
          .title-text .short-title {
            display: inline;
          }
      }
  
      .progress-column {
        display: flex;
        flex-direction: row;
        width: 20%;
        text-align: center;
        align-content: center;
  
        .progress-container {
          background-color: #dce1ed;
          width: 65px;
          height: 16px;
          margin-right: 5px;
          border-radius: 10px;
          padding: 3px;
  
          .progress-bar {
            background-color: #8e94be;
            height: 100%;
            border-radius: 10px;
          }
        }

        .progress-text {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
  
      .polarity-column {
        width: 13%;
        display: flex;
        justify-content: center;
        text-align: center;
  
        .polarity-container {
          padding: 10px;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
  
          .polarity-text {
            font-size: $fs_tertiary;
            font-weight: 700;
          }
  
          &.positive {
            background-color: #c9e3d7;
          }
  
          &.neutral {
            background-color: #fef3e9;
          }
  
          &.negative {
            background-color: #f9e6e7;
          }
        }
      }
  
      .responded-column {
        width: 14%;
        text-align: center;
      }
  
      .actions-column {
        width: 16%;
        text-align: center;
        min-width: 102px;
        padding-right: 2px;
      }
  
      .type-column {
        width: 12%;
        text-align: center;
      }
    }
  }

  @media (max-width: 1250px) {

    .campaign-row {
      justify-content: space-between;
    }}
}
