@import '../../../css/index';

#ContentPickerDialog {

  .MuiDialog-paperFullScreen {
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: 100%;
    max-height: none;
    border-radius: 0;
    overflow-y: hidden !important;
    background-color: #FFFFFF;
  }

  .dialog-item {
    display: flex;
    align-items: center;
  }

  .dialog-item-right {
    display: flex;
    align-items: center;
    margin-right: 25px;
  }

  .dialog-header-selected {
    display: flex;
    align-items: center;

    .MuiDivider-vertical {
      height: 20px;
    }
  }
  
  .dialog-title {
    font-size: 22px;
    font-weight: 600;
    color: #6f8096;
  }

  .dialog-header-title-light {
    font-size: $fs_subheader;
    color: #ABB0B8;
    font-weight: 400;
  }

  .dialog-actions {
    padding: 0px 18px 12px 18px;
    justify-content: flex-end;

    .dialog-actions-right {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }

    .cancelButton {
      color: #009f9e;
    }

    .saveButton {
      color: #ffffff;
      background-color: #05d1cf;
    }
    .saveButton:hover {
      background-color: #05d1cf;
    }
  }
}
