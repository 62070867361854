@import '../../../css/index';

#SystemDashboardToolbar {
    #DashboardToolbar {
        .dashboard-appbar {
            background-color: rgb(192, 0, 0) !important;
        }

        .end-action {
            max-width: 250px !important;
            min-width: 100px !important;
        }

        @media only screen and (max-width: 1080px) {

            .dashboard-title {
                font-size: calc(0.75em + 0.9vmin) !important;
            }

            .end-action {
                font-size: calc(0.4em + 0.8vmin) !important;
                min-width: 80px !important;
            }
         }

         @media only screen and (max-width: 1000px) {

            .dashboard-title {
                font-size: calc(0.75em + 0.6vmin) !important;
            }

            .end-action {
                font-size: calc(0.4em + 0.65vmin) !important;
                min-width: 80px !important;
            }
         }

         @media only screen and (max-width: 940px) {

            .end-content {
                flex-wrap: wrap;
            }

            .dashboard-title {
                font-size: calc(0.75em + 0.6vmin) !important;
            }

            .end-action {
                font-size: calc(0.4em + 0.3vmin) !important;
                min-width: 80px !important;
            }
         }
    }
}