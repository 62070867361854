/* Material-UI Theme Styles */
@import './index';

/******************************************************************
*                             BUTTONS                             *
******************************************************************/
.MuiButton-root {
  height: 34px !important;
  font-size: $fs_secondary !important;
  font-weight: 700 !important;
  padding: 6px 12px !important;
  @include themify($themes) {
    color: themed('buttonOtlTextColor') !important;
  }
  &:not(:first-child):not(.MuiButtonGroup-grouped) {
    margin-left: 10px !important;
  }
}

.MuiButton-outlined {
  @include themify($themes) {
    color: themed('buttonOtlTextColor') !important;
    border: 1px solid themed('buttonOtlBorderColor') !important;
  }

  &:disabled {
    border-color: $lightGray !important;
  }
}

.MuiButton-outlined,
.MuiButton-text {
  &:hover {
    @include themify($themes) {
      background-color: themed('buttonOtlHoverColor') !important;
    }
  }
}

// Icons
.MuiSvgIcon-root {
  font-size: $fs_header !important;
}

// Icon Buttons
.MuiIconButton-sizeSmall {
  padding: 5px !important;
  min-width: 34px !important;
  min-height: 34px !important;
}

.btn-icon {
  min-width: 34px !important;
}

.increase-step,
.decrease-step {
  @include themify($themes) {
    background-color: themed('buttonIconCTAColor') !important;
  }
  color: $white !important;

  &:hover {
    @include themify($themes) {
      background-color: themed('buttonBgColor') !important;
    }
  }
}

.btn-icon-cta {
  @include themify($themes) {
    background-color: themed('buttonIconCTAColor') !important;
  }
  color: $white !important;

  &:hover {
    @include themify($themes) {
      background-color: themed('buttonBgColor') !important;
    }
  }
}

// Remove Buttons
.btn-remove:not(.Mui-disabled) {
  &.MuiButton-outlined,
  &.MuiButton-text {
    color: $indianRed80 !important;

    &:hover {
      background-color: $indianRed10 !important;
      color: $indianRed !important;
    }
  }

  &.MuiButton-contained {
    background: $indianRed !important;

    &:hover {
      background-color: $indianRed80 !important;
    }
  }
}

.MuiButton-contained {
  @include themify($themes) {
    background-color: themed('buttonBgColor') !important;
    color: themed('buttonTextColor') !important;
  }

  &:hover {
    @include themify($themes) {
      background-color: themed('buttonHoverColor') !important;
    }
  }

  &:disabled {
    background-color: $platinumGray !important;
  }
}

.Mui-disabled {
  color: $spanishGray !important;
}

.MuiButtonGroup-root {
  .MuiButton-root {
    color: $spanishGray !important;
    font-weight: 500 !important;
    text-transform: inherit !important;

    &.Mui-selected {
      @include themify($themes) {
        background-color: themed('accentColor30') !important;
        border-color: themed('accentColor30') !important;
        color: themed('buttonTextColor') !important;
      }
      font-weight: 600 !important;
    }
  }
}

.MuiCheckbox-root {
  &.Mui-checked {
    @include themify($themes) {
      color: themed('buttonBgColor') !important;
    }
  }

  &.Mui-disabled {
    color: $spanishGray !important;
  }
}

/******************************************************************
*                             INPUTS                              *
******************************************************************/
form {
  margin-left: 6px !important;
}

.MuiFormControl-marginDense {
  margin: 2px 0 12px !important;
}

.MuiFormGroup-root {
  .MuiFormControlLabel-root {
    margin-right: 20px !important;
  }
}

.MuiInputLabel-root,
.field-label {
  color: $spanishGray !important;
  font-size: $fs_default !important;
  font-weight: 700 !important;
  margin-bottom: 8px !important;

  &:not(:first-child) {
    margin-top: 14px !important;
  }

  @include xl-desktop-up {
    font-size: $fs_default !important;
  }
}

// Required Field Indicator
.MuiInputLabel-asterisk {
  color: $indianRed !important;
}

.MuiInputBase-root {
  height: calc(#{$fs_default} * 2.35) !important;

  .MuiInputBase-input {
    font-size: $fs_default !important;
  }

  &.MuiInputBase-multiline {
    height: initial !important;
  }
}

.Mui-focused {
  .MuiOutlinedInput-notchedOutline {
    @include themify($themes) {
      border-color: themed('accentColor') !important;
    }
  }
}

/******************************************************************
*                              TABS                               *
******************************************************************/
.MuiTabs-root,
.MuiTab-root {
  font-size: $fs_secondary !important;
  font-weight: 600 !important;
  letter-spacing: 0.1em !important;
  color: $spanishGray;
  height: 40px !important;
  min-height: 32px !important;
  min-width: 100px !important;
  background-color: $white !important;

  &.Mui-selected {
    @include themify($themes) {
      color: themed('activeTabColor') !important;
    }
    font-weight: 700 !important;
  }
}

.MuiTab-root {
  padding: 8px 12px !important;
}

.MuiTabs-indicator {
  @include themify($themes) {
    background-color: themed('activeTabBgColor') !important;
  }
}

.table-tabs.MuiTabs-root {
  height: 32px !important;

  .MuiTab-root {
    height: 32px !important;
    background-color: $whiteSmoke !important;
  }
}

/******************************************************************
*                             DIVIDER                             *
******************************************************************/
.MuiDivider-root {
  background-color: $black10 !important;
  margin: 8px 0;

  &.delete-divider {
    margin: 16px -16px !important;

    ~ .btn-remove {
      margin-left: 0 !important;
    }
  }
}

/******************************************************************
*                          LOADING SPINNER                        *
******************************************************************/
.MuiCircularProgress-root {
  @include themify($themes) {
    color: themed('buttonBgColor') !important;
  }
}

/******************************************************************
*                           TOGGLE SWITCH                         *
******************************************************************/
.MuiSwitch-root {
  .Mui-checked {
    @include themify($themes) {
      color: themed('accentColor') !important;
    }
  }

  .Mui-checked + .MuiSwitch-track {
    @include themify($themes) {
      background-color: themed('buttonOtlTextColorAlt') !important;
    }
  }
}

/******************************************************************
*                             MORE MENU                           *
******************************************************************/
#MoreButton {
  @include themify($themes) {
    color: themed('buttonOtlTextColor') !important;
  }

  &.focused {
    @include themify($themes) {
      background-color: themed('accentColor20') !important;
    }
  }
}

#MoreMenu {
  .MuiMenu-paper {
    border: none;
    min-width: 100px;
    background-color: $white;
  }

  .MuiDivider-root {
    margin: 8px 0;
  }

  .MuiListItemIcon-root {
    padding-right: 8px;
    min-width: auto;

    &.red {
      color: $indianRed;
    }

    &.blue {
      color: $airforceBlue;
    }

    &.green {
      color: $viridianGreen;
    }
  }

  .MuiListItemText-primary {
    font-weight: 600;
    font-size: $fs_default;
    text-transform: capitalize;
  }
}

.MuiListItemIcon-root {
  min-width: auto !important;
}

/******************************************************************
*                              SPACING                            *
******************************************************************/
.m-0 {
  margin: 0;
}

.mr-2 {
  margin-right: $spacer * 0.5 !important;
}

.includes-info {
  display: flex;
  align-items: center;
}

// MuiAutocomplete
.MuiAutocomplete-option {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.MuiAutocomplete-input {
  min-height: calc(#{$fs_default} * 2.35);
  padding-top: 3px !important;
}

// Menu Lists/Items
.MuiMenuItem-root {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.MuiListItemText-root {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

// MuiTabs
.MuiTab-fullWidth {
  flex-basis: auto !important;
}

/******************************************************************
*                              ToolTip                            *
******************************************************************/

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    font-size: 14.5px;
    background-color: $black90;

    .MuiTooltip-arrow {
      color: $black90;
    }
  }
}
