@import '../../../css/index';

#TimesTab {
  padding: 12px 12px 12px 24px !important;
  @include themify($themes) {
    background-color: themed('primarySurfaceColor') !important;
  }

  .pickerContainer {
    margin-bottom: 3em;

    label {
      font-size: 11px;
    }

    .MuiInput-underline.Mui-disabled:before {
      border-bottom: none;
    }

    .hide-adornment {
      .MuiInputAdornment-positionEnd {
        display: none;
      }
    }

    .MuiInputBase-root.Mui-disabled {
      color: $nightRider !important;
    }
  }

  .doneIcon {
    color: $viridianGreen;
  }

  .timesError {
    color: $indianRed;
  }

  .MuiIconButton-sizeSmall {
    padding: 5px;
    min-width: 34px;
    min-height: 34px;
  }
}
