@import '../../../css/colors';

#OrganizationUserEditDrawer {
  height: calc(100vh - 64px);

  .MuiTabs-root{
    height: 48px !important;
  }

  .MuiTab-root{
    height: 46px !important;
  }

  > .MuiCard-root {
    height: 100%;

    > .MuiCardContent-root {
      &.edit-user-drawer-card-content-on-participants-select-screen {
        height: calc(100% - 50px) !important;
        padding: 0 !important;

        .actions-container {
          padding: 8px 10px;
        }
      }

      > #simple-tabpanel-2 {
        height: 100%;
        max-height: 100%;
        overflow: hidden;

        > div {
          height: 100%;
        }

        > #UserAudienceTab {
          height: 100%;

          // .user-audience-tab-participants-select-container {
          //   height: calc(100% - 49.25px);
          // }
        }
      }
    }
  }
}

.manage-user-table {
  border: 1px solid $whiteSmoke !important;
  border-radius: 5px !important;

  .MuiTableHead-root {
    background-color: $ghostWhite !important;

    .MuiTableCell-head {
      font-weight: 700;
    }
  }

  .MuiTableCell-root {
    padding: 6px 16px !important;

    &.checkbox-cell {
      padding: 6px !important;
    }
  }
}

.error {
  color: $red !important;
}

#UserOrganizationTab {
  .paper {
    margin-bottom: 1.5rem;
  }

  .list {
    padding: 0;

    .MuiListItem-root {
      justify-content: space-between !important;
      padding-right: 5px;
    }

    li {
      height: 36px;
      background-color: $ghostWhite;

      .MuiListItemText-root.list-header {
        span {
          font-weight: 700;
        }
      }
    }

    .MuiCollapse-container {
      .MuiList-padding {
        padding: 0;
      }
    }
  }

  .user-avatar {
    height: 20px;
    width: 20px;
    margin-right: 1em;
  }

  .search-field {
    margin-right: 1em;
  }

  .chip {
    margin: 0.5em;
    background-color: $antiflashWhite;
    font-size: 12px !important;
    height: 30px !important;

    .MuiChip-label {
      padding-left: 12px;
      padding-right: 12px;
    }

    .MuiChip-avatar {
      height: 20px;
      width: 20px;
      margin-left: 8px;
      cursor: auto;
    }

    .MuiChip-deleteIcon {
      margin-right: 6px;
    }
  }

  .chips-grid {
    padding: 2px 8px;
  }

  .MuiAvatar-root {
    cursor: auto !important;
  }
}

#UserAudienceTab {
  width: 100%;

  .audience-paper {
    background-color: white;
    z-index: 100;
  }

  .audience-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 0;
  }

  .search-field {
    margin-left: 1rem;
  }
}

#EditUserDrawer {
  height: calc(100vh - 64px);

  .MuiPaper-root {
    height: 100%;

    .MuiCardContent-root {
      height: calc(100% - 50px - 60px - 40px) !important;

      #userNotificationInfo {
        padding-bottom: 10px;
      }
    }
  }
}
