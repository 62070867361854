@import '../../../css/index';

#BuilderDrawer {
  height: calc(100% - 64px - 64px - 46px);
  max-height: calc(100% - 64px - 50px - 46px);
  overflow-y: hidden;
  position: absolute;
  right: 0;
  margin-top: 0;
  background: #fff;
  z-index: 1;
  width: 35%;
  min-width: 400px;

  .catalog-drawer-icon {
    color: $jordyBlue !important;
    margin-right: 5px;
  }
  
  .delete-drawer-icon {
    color: $errorRed !important;
    margin-right: 5px;
  }

  .MuiGrid-grid-xs-6 {
    flex-grow: 0;
    max-width: 80%;
    flex-basis: 80%;
  }
  
  .attachment-container {
    margin: 2.5px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid $lavenderGray;

    .attachment-content {
      height: 100%;
      display: flex;
      align-items: center;
      height: 48px;
      width: 80%;

      span {
        text-overflow: ellipsis;
        max-width: 80%;
        overflow: hidden;
      }
    }
  
    .file-container {
      border-top-left-radius: 10px;
      background-color: $whiteSmoke;
      border-bottom-left-radius: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 40px;
      height: 100%;
      max-height: 48px;
      border-right: 1px solid $lavenderGray;
      margin-right: 5px;
      svg {
        color: $lavenderGray;
      }
    }
  
    .thumbnail-container {
      width: 40px;
      max-height: 48px;
      height: 100%;
      border-right: 1px solid $lavenderGray;
      margin-right: 5px;
      justify-content: center;
      align-items: center;
      display: flex;
      img,
      video {
        height: 48px;
        width: 40px;
        position: relative;
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  
  .upload {
    display: flex;
    align-items: center;
  }
  
  .duplicate-drawer-icon {
    color: $confirmGreen !important;
    margin-right: 5px;
  }

  .catalog-drawer-icon {
    color: $jordyBlue !important;
    margin-right: 5px;
  }
  
  .delete-drawer-icon {
    color: $errorRed !important;
    margin-right: 5px;
  }
  
  .attachment-container {
    margin: 2.5px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid $lavenderGray;

    .attachment-content {
      height: 100%;
      display: flex;
      align-items: center;
      height: 48px;
      width: 80%;

      span {
        text-overflow: ellipsis;
        max-width: 80%;
        overflow: hidden;
      }
    }
  
    .file-container {
      border-top-left-radius: 10px;
      background-color: $whiteSmoke;
      border-bottom-left-radius: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 40px;
      height: 100%;
      max-height: 48px;
      border-right: 1px solid $lavenderGray;
      margin-right: 5px;
      svg {
        color: $lavenderGray;
      }
    }
  
    .thumbnail-container {
      width: 40px;
      max-height: 48px;
      height: 100%;
      border-right: 1px solid $lavenderGray;
      margin-right: 5px;
      justify-content: center;
      align-items: center;
      display: flex;
      img,
      video {
        height: 48px;
        width: 40px;
        position: relative;
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  
  .upload {
    display: flex;
    align-items: center;
  }
  
  .duplicate-drawer-icon {
    color: $confirmGreen !important;
    margin-right: 5px;
  }

  .media-upload-button {
    padding: 7px;
    margin-right: 5px;
  }

  .upload-error {
    color: $red;
    background-color: $indianRed15;
    height: 40px;
    width: 100%;
    margin-top: -10px !important;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 3px;
    margin-bottom: 10px;
    svg {
      margin: 0 3px 3px 0;
      color: $red;
    }
  }

  .media-item-error {
    border-color: $errorRed !important;
  }

  .required {
    color: $red;
  }

  .input-label {
    font-weight: 600 !important;
    margin-bottom: 5px;
  }

  .description-body {
    margin-top: 5px !important;
  }

  .details-item {
    margin-bottom: 20px;
  }

  .exercise-items {
    display: flex;
    width: 100%;
  }

  .exercise-item {
    margin-bottom: 20px;
    justify-content: flex-start;
  }

  .drawer-title {
    padding-left: 10px;
    background-color: $platinumGray;

    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .MuiSelect-root {
    display: flex;
    align-items: center;
  }

  .attach-icon {
    cursor: pointer;
  }

  .menu-icon {
    color: $darkTurquoise;
  }

  .link {
    color: $airforceBlue !important;
  }

  .file {
    color: $medAquamarine !important;
  }

  .media {
    color: $peruGold !important;
  }

  .hands-on {
    color: $rhythmPurple !important;
  }

  .exercise {
    color: $airforceBlue !important;
  }

  .link-input {
    padding-right: 0px !important;
  }

  .img-container {
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .drawer-header {
    height: 50px;
    background-color: $whiteSmoke;

    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .drawer-body {
    padding: 10px 7.5px;
    width: 100%;
    max-width: 100%;
    height: calc(100% - 100px);
    overflow-y: auto;
    padding-right: 10px;
    box-sizing: border-box;
  }
}
