/* Material-UI Drawers */
@import './index';

.MuiBackdrop-root {
  background-color: $black30 !important;
}

.MuiDrawer-paperAnchorRight {
  width: 600px;
  top: 64px !important;
  background-color: $white !important;

  .MuiPaper-root {
    background-color: $white !important;
  }

  .MuiCardHeader-root {
    height: 50px !important;
    background-color: $antiflashWhite !important;

    .MuiCardHeader-title,
    .MuiTypography-root,
    .MuiTypography-h5 {
      font-size: $fs_default !important;
      font-weight: 700 !important;
    }

    .MuiIconButton-root {
      margin: 0;
      color: $black50;
    }

    .MuiCardHeader-action {
      align-self: center;
      margin-right: -12px;
    }
  }

  .MuiToolbar-root {
    background-color: $ghostWhite !important;
    display: flex;
    justify-content: flex-end;
    padding: 8px 10px;
  }

  .MuiCardContent-root {
    overflow-y: auto;
    height: calc(100vh - 204px) !important;
    padding: 20px !important;
  }
}
