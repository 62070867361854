@import '../../css/index';

#VisualizationsLayout {
  overflow: hidden;

  .visualizations-container {
    height: 100%;
    width: 100%;
  }
}


#VizDialogRight {
  margin-left: auto;

  .viz-dialog-action {
    margin-left: 10px !important;

    .checked-icon-filled {
      fill: #e5bc73;
      cursor: pointer;
      z-index: 1;
    }
  }
}

.dashboardToolbar-header {
  height: $topNavHeight;
  width: 100%;
}

#VisualizationsTopToolbar {
  height: $topNavHeight;
  width: 100%;

  .MuiAppBar-root {
    height: $topNavHeight;
  }

  .MuiButton-outlined {
    @include themify($themes) {
      background-color: themed('buttonOtlBgColor') !important;
      color: themed('buttonOtlTextColorAlt') !important;
    }
  }

  .MuiToolbar-root {
    justify-content: space-between;
  }

  .MuiToolbar-gutters {
    padding: 12px 16px;
  }

}

.end-content {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.end-action {
  min-width: 250px !important;
}

.toggle-filter {
  height: 34px;
  width: 34px;
  max-height: 34px;
  max-width: 34px;
  margin-left: 10px !important;
}

#ToggleFilter .MuiToggleButton-root {
  background-color: rgba(255, 255, 255, 0.7) !important;
  @include themify($themes) {
    color: rgba(0, 0, 0, 0.38) !important;
  }
}

#ToggleFilter .MuiToggleButton-root.Mui-selected {
  @include themify($themes) {
    background-color: themed('buttonOtlBgColor') !important;
    color: themed('buttonOtlTextColorAlt') !important;
  }
}