@import '../../../../css/index';

#CatalogPlanRow {
  box-shadow: none !important;
  border-bottom: 1px solid $whiteSmoke;
  border-radius: 0px !important;
  background-color: white !important;
  cursor: default !important;

  .MuiGrid-root {
    cursor: default;
  }

  .MuiAccordionSummary-root {
    padding: 14px 16px !important;
  }

  .MuiAccordionDetails-root {
    padding: 12px 16px;
    background-color: white !important;
  }

  .MuiAccordionSummary-content {
    margin: 0px;
  }

  .thumbnail {
    height: 60px;
    width: 60px;
    margin: 0 12px auto 0;
    border-radius: 5px;
    padding: 0px;
  }

  .default-thumbnail {
    height: 60px;
    width: 67px !important;
    background-color: $lavenderGray;
    margin: 0px 10px auto 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    svg {
      color: $gunPowder;
    }
  }

  .flow-container {
    display: flex;
    flex: 1;
  }

  .flow-icon {
    margin-left: 5px;
    height: 20px;
    width: 20px;
  }

  .title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;

    .title-text {
      font-size: $fs_default !important;
    }

    .icons {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;
      }

      .edit {
        color: $medAquamarine;
      }

      .assign {
        color: $airforceBlue;
        margin-left: 8px;
      }

      .trainer {
        color: $rhythmPurple;
      }
      .skills {
        color: $jordyBlue;
      }
    }
  }

  .subtitle {
    display: flex;
    align-items: center;
    color: $gunPowder80;
    font-size: $fs_default !important;
    padding: 2px 0;
    font-weight: 600 !important;

    .subtitle-text {
      color: $gunPowder80;
      font-weight: 600;
      font-size: $fs_default;
    }

    .expand-icon {
      align-items: center;
      display: flex;
      color: $viridianGreen;
      cursor: pointer;
    }
  }

  #TrainingPlanItemRow {
    box-shadow: none !important;
    border-radius: 0px !important;
    background-color: white !important;

    .MuiAccordionSummary-root {
      min-height: 20px !important;
      height: 20px;
      padding: 0px;
    }

    .MuiAccordionSummary-content {
      margin: 0px !important;
    }

    .MuiPaper-elevation1 {
      box-shadow: none !important;
    }

    .MuiAccordion-rounded {
      border-radius: 0px !important;
    }

    .content-items-container {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    .content-item-row {
      padding: 4px 3%;
      color: #6b6b84;

      .content-item-text {
        font-size: $fs_secondary !important;
      }
    }

    .content-item-row:last-child {
      margin-bottom: 6px;
    }
  }
}

#CatalogPlanRow.Mui-expanded {
  margin: 0px;
}

.trainer-menu {
  .MuiPaper-root {
    padding: 5px 10px;
    .mail-link {
      z-index: 1;
      .mail {
        color: $darkTurquoise;
        cursor: pointer;
      }
    }

    .trainer-label {
      color: $spanishGray;
    }
    .trainer-container {
      display: flex;
      align-items: center;
      .MuiAvatar-root {
        height: 30px !important;
        width: 30px !important;
        margin-right: 5px;
      }
    }
  }
  .MuiGrid-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
