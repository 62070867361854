@import '../../css/colors';

#NotificationsWidget {
  @import '../../css/ag-grid-tables.scss';

  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

#NotificationsWidget .paging-row {
  margin-top: auto;
  padding: 0 6px;

  button {
    padding: 10px !important;
  }
}

#NotificationsWidget #WidgetTable {
  flex: 1;

  th {
    width: 25%;
    font-weight: 700;
    background-color: $whisper;
  }

  tr {
    cursor: pointer;
  }

  .MuiTableCell-root {
    font-size: inherit;
  }
}
