@import '../../../css/colors';

#UserImportFooter{
  background-color: #f4f4f7;
  position: fixed;
  bottom:0;
  width:95%;

  .dialog-actions {
    padding: 5px 18px 12px 18px;
  
    .downloadButton {
      color: #009f9e;
      border-color: #009f9e;
    }
  
    .cancelButton {
      color: #009f9e;
    }
  
    .saveButton {
      color: #ffffff;
      background-color: #05d1cf;
    }
    .saveButton:hover {
      background-color: #05d1cf;
    }
    .dialog-actions-right{
      display:flex;
      justify-content: flex-end;
    }
  }
}
