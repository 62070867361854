@import '../../../css/index';

#PulseSetsList {
  padding: 20px 100px 0 100px;
  width: calc(100vw - 210) !important;
  background-color: #f1f2f6;
  height: calc(100vh - #{$topNavHeight});
  overflow-x: hidden;
  overflow-y: visible;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .rows-container {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow-y: visible;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-bottom: 100px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .spinner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  #EmptyState {
    .empty-state-container {
      .empty-state-header {
        font-weight: bold;
        color: #6f8096 !important;
      }
      #EmptyStateText {
        .empty-state-text {
          color: #6f8096 !important;
        }
      }
    }
  }
}
