@import '../../css/index';

.reset {
  text-align: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(#5dcecd, #5bc5d1 90%, #5cc2d7);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;

  .margin-top{
    margin-top: 5px;
  }

  .offline {
    color: $indianRed;
  }

  .center-body {
    flex: 0 0 30%;
  }

  .card {
    background: $white;
    display: flex;
    flex-direction: column;
    padding: 8%;
    border-radius: 5px;
    font-size: small;
    box-shadow: 1px;
    min-width: 650px;
    box-shadow: 1px 2px 12px 5px #52aebc;
    font-size: inherit !important;
  }

  .button-container {
    width: 100%;
  }

  .sign-in-button {
    background-color: $teal;
    color: $white;
    padding: 8px 16px;
    border-radius: 3px;
    font-size: $fs_secondary;
    font-weight: bold;
    letter-spacing: 1px;
    border-color: #36aec0;
    border-style: solid;
    box-shadow: none;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 120px;
    float: center;
  }

  .sign-in-button:hover {
    background-color: #36aec0;
  }

  .request-pw-button {
    background-color: $teal;
    color: $white;
    padding: 8px 16px;
    border-radius: 3px;
    font-size: $fs_secondary;
    font-weight: bold;
    letter-spacing: 1px;
    border-color: #36aec0;
    border-style: solid;
    box-shadow: none;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 270px;
    float: center;
  }

  .request-pw-button:hover {
    background-color: #36aec0;
  }

  .logo {
    max-width: 90%;
    max-height: 90%;
    margin-bottom: 10%;
  }

  h4 {
    font-weight: 600;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #333;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: #7c818e;
  }

  input[type='email'],
  input[type='password'] {
    margin: 10px 0;
    padding: 15px;
    background-color: $platinumGray !important;
    color: #565961;
    border: none;
    border-radius: 6px;
    border-color: #777;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0px 9999px $lightGray;
    color: #7c818e;
  }

  .error-message {
    margin-top: 0.5em;
    color: #ff4646;
  }

  .remember-me {
    align-self: self-start;
    color: #62626daa;
    font-size: 13px;
    font-weight: normal;
    padding-bottom: 10px;
    padding-left: 20px;
    position: relative;
    margin-top: 20px;
  }

  .forgot-password-text {
    font-size: 14px;
    font-family: sans-serif;
    line-height: 1.15;
  }

  .forgot-password {
    padding-top: 15px;
    color: #62626daa;
    font-size: 12px;
    background: transparent;
    border: none;
  }

  .forgot-password:hover span {
    color: #36aec0;
  }

  .remember-me input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
  }

  .remember-me input:checked ~ .checkmark {
    background-color: $teal;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .remember-me input:checked ~ .checkmark:after {
    display: block;
  }

  .remember-me .checkmark:after {
    right: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  button:disabled,
  button:disabled:hover {
    background-color: lightgray;
    border: none;
  }

  .spinner-container {
    min-height: 40px;
  }

  .MuiInputLabel-root {
    text-align: left;
    padding-bottom: 5px;
  }

  .email-username-input{
    margin: 10px 0;
    padding: 15px;
    background-color: #e3e3e8;
    color: #565961;
    border:none;
    border-radius: 6px;
  }

  .welcome{
    margin-bottom: 0px;
  }

  .welcome-sub{
    margin-bottom: 10px;
  }

  .email{
    margin-bottom: 15px;
  }

  @media (max-width:500px) {
    .logo {
      max-width: 75%;
      max-height: 75%;
      margin-bottom: 10%;
    }


  .card {
    background: $white;
    display: flex;
    flex-direction: column;
    padding: 8%;
    border-radius: 5px;
    font-size: small;
    box-shadow: 1px;
    min-width: 350px;
    box-shadow: 1px 2px 12px 5px #52aebc;
    font-size: inherit !important;
  }

 .MuiGrid-container{
  flex-direction: column;
  justify-content: center;
 }

 .checklist{
  flex-direction: column !important;
  flex-wrap: nowrap;
  align-items: flex-start !important;
 }

  }
}
