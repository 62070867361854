@import '../../css/index';


    #PolarityScale{

   

      .polarity-header{
        margin-top:10px;
        color:#68686a;
        font-weight: 800 !important;
        font-size: 10pt ;
      }

      .polarity-text{
        font-weight: 800;
        z-index: 1;
        position: absolute;
        margin-left: 22%;
        line-height: 1;
        margin-top: -.15rem;
       

        .arrow-down {
          width: 0; 
          height: 0; 
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 8px solid #738599;
          z-index: 1;
          position: absolute;
          // margin-left: 4px;
          margin-top: -.10rem;
        }
      }

      .polarity-scale-cont{
        display:flex;
        justify-content: space-between;

        .polarity-scale{
          font-size: 8pt !important;
          opacity:.5;
        }

      
      }

      .no-polarity{
        opacity:.2 !important;
      }

  .MuiLinearProgress-root {
    width: 100%;
    height: 10px;
    border-radius: 20px;
    opacity:.4;
    background-image: linear-gradient(to right, red ,orange, green);
     }

     .hide-scale{
      opacity:.01 !important;
    }
    }
  