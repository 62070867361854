@import '../../../../css/index';

#ManageTrainingPlansWidget {
  overflow-y: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  .MuiAccordionSummary-root {
    cursor: default !important;
  }

  .filter-row {
    border-bottom: 1px solid $platinumGray;
  }
  .filter {
    display: flex;
    align-items: center;
    padding-right: 20px;

    .MuiInput-underline:after {
      border-bottom: 0px !important;
    }
    .MuiFormLabel-root {
      margin-bottom: 0px !important;
      margin-right: 5px;
    }
    .Muifocused {
      &:before {
        border-bottom: 0px;
      }
    }
    .MuiInputBase-root {
      &:before {
        border-bottom: 0px;
      }
    }
  }
  #ManageTrainingContent {
    height: calc(100% - 48px - 34px);
    overflow-y: scroll;
    .MuiAccordion-root.Mui-expanded {
      margin: 0px;
    }
  }
}

.check-icon {
  color: #12c09b;
}
