@import './themes.scss';

@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

@mixin get-color-for-list($i) {
  @if ($i == 1) or ($i == 4) {
    @include themify($themes) {
      background-color: themed('primaryBorderAccent');
    }
  }
  @if ($i == 2) or ($i == 5) {
    @include themify($themes) {
      background-color: themed('secondaryBorderAccent');
    }
  }
  @if ($i == 3) or ($i == 6) {
    @include themify($themes) {
      background-color: themed('tertiaryBorderAccent');
    }
  }
}

// Media Queries Mixins
@mixin phone-only {
  @media (max-width: #{$xs_screen}) {
    @content;
  }
}
@mixin tablet-landscape-down {
  @media (max-width: #{$md_screen - 1}) {
    @content;
  }
}
@mixin tablet-portrait-up {
  @media (min-width: #{$sm_screen}) {
    @content;
  }
}
@mixin tablet-landscape-up {
  @media (min-width: #{$md_screen}) {
    @content;
  }
}
@mixin desktop-up {
  @media (min-width: #{$lg_screen}) {
    @content;
  }
}
@mixin xl-desktop-up {
  @media (min-width: #{$xl_screen}) {
    @content;
  }
}
