@import '../../../../css/index';

#CompletionTabPanel {
  overflow-y: auto;
  height: calc(100vh - 162px);
  background: #fff;
  align-content: flex-start;
  position: relative;

  #Builder {
    #FlowDiagram {
      padding-top: 120px;
      padding-left: 35px;
    }
  }
}

#CompletionToolbar {
  position: absolute;
  padding-top: 20px;
  padding-left: 20px;
  z-index: 1;
  background-color: white;

  .flex-align {
    display: flex;
    align-items: center;
  }

  .input-label {
    display: flex;
    align-items: center;
    svg {
      margin-left: 5px;
      color: $lavenderGray;
    }
  }

  .MuiFormControlLabel-root {
    margin-bottom: 0px;
  }

  .check-error {
    color: red;
  }
}
