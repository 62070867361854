#WelcomeBanner {
  .banner-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .header-text {
      font-size: 19px;
      font-weight: bold;
    }

    .edit-btn {
        margin-left: 10px;
        padding: 0px !important;
        margin-left: 5px;
        background-color: rgba(0, 159, 158, 0.6) !important;
        color: #ffffff !important;    
    }
  }

  .swiper-body {
    display: flex;
    height: 100%;
    width: 100%;
    margin: 5px;

    .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
      bottom: 50px !important;
    }

    .swiper-swipe {
      min-height: 400px;
      max-height: 100%;
      width: 100%;
    }

    .swiper-slide {
      display: flex;
      width: 100%;
      height: 100%;
    }

    .swiper-lazy-preloader-white {
      --swiper-preloader-color: transparent;
    }

    .swiper-slide {

      img {
        width: auto;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
        object-fit: contain;
        object-position: center;
      }

      video {
        width: auto;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
        object-fit: contain;
        object-position: center;
      }
    }

    .swiper-slide-container {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;

      .mux-player-container {
        display: flex;
        width: 100%;
        justify-content: center;
        max-height: 400px;
      }
  
      .mux-player {
        width: 100%;
        min-height: 400px;
        max-height: 400px;
      }
    }
  }

  .media-preview {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    object-fit: contain;
    object-position: center;
    
    justify-content: center;
    align-items: center;
    z-index: 999999;
    pointer-events: none;
    display: flex;
    align-items: flex-end;

    iframe {
      max-width: 560px;
      min-width: 560px;
      height: 355px;
      pointer-events: all;
    }

    &.iframewrapper::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
    
    &.iframewrapper iframe {
      vertical-align: top;
    }
  
    &.youtube {
      iframe {
        max-width: 560px;
        width: '100%';
      }
    }

    &.vimeo {
      width: '100%';
    }
  
  }
}