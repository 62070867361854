#QuestionForm {
  margin: 1.5rem !important;

  .MuiFormGroup-root {
    flex-direction: row;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: rgba(0, 0, 0, 0.54);
  }

  .type-select {
    width: 100%;
    .MuiSelect-root {
      display: flex;
      align-items: center;
    }

    .fa-smile {
      background-color: #ad6f3c;
    }
    .fa-check-square,
    .fa-info-circle {
      background-color: #5a87db !important;
    }
    .fa-dot-circle {
      background-color: #75b975;
    }
    .fa-caret-square-down {
      background-color: #75b975;
    }
    .fa-list-ol {
      background-color: orange;
    }
    .fa-star {
      background-color: #f0e054;
    }
    .fa-font {
      background-color: #da4949;
    }
    .fa-thumbs-up {
      background-color: #c557cf;
    }
  }

  .question-category-picker-row {
    margin: 15px 0px;
  }

  .dropdown-toggle,
  .dropdown-label {
    margin: 10px 0 14px 0;
  }

  .response-field {
    width: 15%;
    margin-right: 10px;
  }

  .keyword-check {
    margin-left: 0px !important;
  }

  .response-fields {
    display: flex;
    align-items: center;
  }

  .info-text-textarea {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    max-height: 400px;
    min-height: 200px;
  }

  .tox-statusbar__branding {
    display: none;
  }
  .icon {
    margin-right: 5px;
  }
}
