#InitiatedStep {
  padding: 2rem;
  background: #fff;
  height: calc(100vh - 64px - 50px - 46px);
  max-height: calc(100vh - 64px - 50px - 46px);
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  flex-basis: auto;
  justify-content: center;
  align-items: center;

  .success-text {
    font-size: 18px !important;
    font-weight: 500 !important;
  }

  .custom-time-picker {
    margin-top: 0px !important;
    width: 12em !important;
  }

  .toggle-text {
    margin-top: 2rem;
  }

  .text-space {
    margin-top: 0.75rem;
  }
}
