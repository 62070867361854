@import '../../../css/colors';

#UserImport{
  height:70%;
  width:100%;

.spinner-container{
  z-index: 9;
  position: absolute;
  top: -10%;
  background-color: rgba(244, 244, 246, 0.584)
  
}

.user-import-list {
  .MuiListItem-root:last-child {
    border-bottom: 0px !important;
  }
}

.dialog-content {
  .dropzone {
    background-color: #f4f4f7;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #d7d7e2;
    border-radius: 4px;
    padding: 1em;
    cursor: pointer;

    .iconContainer {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      .progressCircle {
        font-size: 5em;
        color: #05d1cf;
        margin-bottom: 15px;
      }

      .noteAddIcon {
        font-size: 5em;
        color: #05d1cf;
        margin-bottom: 15px;
      }

      .thumbUpIcon {
        font-size: 5em;
        color: #6aca9a;
        margin-bottom: 15px;
      }

      .thumbDownIcon {
        font-size: 5em;
        color: #e85c5c;
        margin-bottom: 15px;
      }
    }

    .dropzoneText {
      color: #91919a;
    }

    .dropLink {
      color: #05d1cf;
      text-decoration: underline #05d1cf;
    }
  }

  .dialogText {
    margin-top: 7px;
  }
}

.dialog-empty {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 10px;
  justify-content: flex-start;
}

.dialog-body {
  display: flex;
  height: 72vh !important;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
}

.user-import-list {
  .MuiListItem-root:last-child {
    border-bottom: 0px !important;
  }
}