#CampaignRowActions {

  .MuiIconButton-label {
    border: 1px solid #64BAB9;
    border-radius: 5px;
  }

  .action-icon {
    color: #9695A5;
    height: 15px;
    width: 15px;
    margin: 5px;
  }
}