@import '../../../../css/index';

#MyTrainingPlansWidget {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  .cursor {
    cursor: pointer;
  }

  .MuiCardContent-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .filter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    .MuiInput-underline:after {
      border-bottom: 0px !important;
    }
    .MuiFormLabel-root {
      margin-bottom: 0px !important;
      margin-right: 5px;
    }
    .Muifocused {
      &:before {
        border-bottom: 0px;
      }
    }
    .MuiInputBase-root {
      &:before {
        border-bottom: 0px;
      }
    }
  }

  #Content {
    overflow-y: scroll;
    padding-left: 5px;
    padding-right: 12px;

    .no-rows {
      width: 100%;
    }

    .plan-row-assignee {
      display: flex;
      align-items: center;
      justify-content: space-between !important;
      padding-top: 35px;
      height: fit-content;
      span {
        display: flex;
        align-items: center;
      }
    }

    .title-row {
      position: relative;

      .icons {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0px;
        z-index: 1;
        svg {
          margin-left: 5px;
        }
        .trainer {
          color: $rhythmPurple;
        }
        .skills {
          color: $jordyBlue;
        }
      }
    }

    #PlanRow {
      border-bottom: 2px solid $whiteSmoke;
      padding: 10px 18px;
      height: 140px;
      background-color: white !important;
      position: relative;

      .unread-indicator {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        background-color: $blue;
        z-index: 1;
        left: 5px;
        top: 63px;
      }

      .plan-title {
        font-size: $fs_default;
        font-weight: 700;
      }

      .edit-icon {
        cursor: pointer;
        color: $medAquamarine;
        width: 24px;
        height: 24px;
      }

      .readonly-status {
        display: flex;
      }
    
      .lock {
        margin-left: 5px;
        height: 20px;
        width: 20px;
      }
    
      .lock-active {
        @include themify($themes) {
          color: themed('activeTabBgColor') !important;
        }
      }
  
      .lock-due {
        color: #e85c5c;
      }

      .past-due {
        color: red;
      }

      .break {
        color: gray;
      }
      .progress-container {
        position: relative;
        .progress-label {
          font-size: 16px;
          font-weight: 700;
          position: absolute;
          right: 10px;
        }
      }

      .MuiLinearProgress-root {
        width: 100%;
        height: 20px;
        border-radius: 20px;
        background-color: rgba(208, 208, 208, 0.5) !important;
        position: relative;
      }

      .owned .MuiLinearProgress-bar1Determinate {
        background-color: $tuscanYellow;
        border-radius: 20px;
      }

      .not-owned .MuiLinearProgress-bar1Determinate {
        background-color: $plusIconTeal;
        border-radius: 20px;
      }

      .user-name {
        margin-left: 5px;
      }

      .MuiGrid-item {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
      }

      .active {
        margin-right: 5px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: $confirmGreen;
      }
      .inactive {
        margin-right: 5px;

        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: $errorRed;
      }
    }
  }

  .filter-row {
    justify-content: space-between;
    align-items: center;
    min-height: 34px;
  }

  .unread-plan {
    font-weight: 600 !important;
  }
}

.trainer-menu {
  .MuiPaper-root {
    padding: 5px 10px;
    .mail-link {
      z-index: 1;
      .mail {
        color: $darkTurquoise;
        cursor: pointer;
      }
    }

    .trainer-label {
      color: $spanishGray;
    }
    .trainer-container {
      display: flex;
      align-items: center;
      .MuiAvatar-root {
        height: 30px !important;
        width: 30px !important;
        margin-right: 5px;
      }
    }
  }
  .MuiGrid-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
