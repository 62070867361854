@import '../../css/index';

#ContentLayoutGrid {
  height: calc(100vh - 3 * (64px));
  overflow-x: hidden;
  overflow-y: hidden;

  .MuiCardContent-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
