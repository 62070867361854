@import '../../../../css/index';

#ParticipantsTabPanel {
  height: calc(100vh - 64px - 50px - 76px);
  max-height: calc(100vh - 64px - 50px - 76px);
  background: $white;
  align-content: flex-start;
  padding-bottom: 10px;
  .disabled-panel {
    pointer-events: none;
  }
  .left-panel {
    height: 100%;
    background-color: $white;
  }

  .right-panel {
    padding: 30px 35px;
    height: 100%;
    background-color: $white;

    .active-date-and-time-picker-div {
      display: flex;
    }

    .active-date-time-picker {
      width: auto !important;
      margin-top: 0 !important;
      margin-left: 10px;
    }

    .date-input {
      margin-left: 10px;

      .MuiInputBase-root {
        padding-right: 0px;
      }
    }

    .training-item-number {
      width: 40px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: $jordyBlue20;
      color: $gunPowder;
      border-radius: 50%;
      margin: 0 10px;
    }

    .input-label {
      display: flex;
      align-items: center;
      color: $spanishGray;
      font-size: 18px !important;
      margin-bottom: 10px;

      svg {
        margin-left: 5px;
        color: $lavenderGray;
      }

      &.due-date {
        margin-top: 28px;
        margin-bottom: 0;
      }
    }

    .participant-due-date {
      display: flex;
      align-items: center;
      margin: 5px 0;

      .participant-avatar {
        margin: 0 7.5px;
      }
    }

    .due-date-radios {
      display: inline;

      .Mui-checked {
        color: $darkTurquoise80;
      }

      .MuiFormControlLabel-root {
        margin: 0 20px 0 0;
      }
    }

    .details-item {
      margin-bottom: 20px;
    }

    .MuiInputBase-root,
    .MuiOutlinedInput-root,
    .MuiInputBase-fullWidth,
    .category-picker-container {
      background-color: white;
    }
  }
}
