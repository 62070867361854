#NameCell{
  text-decoration: underline;
  font-weight: 500;
  span:hover{
    color:#43a1a0;
    text-decoration: underline;
    font-weight: 500;
  }

  span{
    display:flex;
  }

  .archived-style{
    font-style: italic;
  }

}