@import '../../css/index';

#VisualizationsGrid {
  height: calc(100vh - 64px - 64px);
  overflow-x: hidden;
  overflow-y: auto;

  .MuiCardContent-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
