@import '../../../css/index';

$bgColor: #f1f2f6;

#IntroductionScreenDesignerDialog {
  .intro-text {
    color: #6c6c7f;
    font-weight: 900;
  }

  .pulse-campaign-header-title {
    display: flex;
    justify-content: center;
  }

  .pulse-campaign-header-close {
    display: flex;
    justify-content: flex-end;
  }

  .intro-input {
    background-color: white;
    border-radius: 10px;
    padding-left: 10px;
    font-weight: 700;
    color: #6c6c7f;
  }

  .body {
    display: flex;
    flex-direction: row;
  }

  .editor-section {
    .body-invalid {
      padding: 1px;
      border: 1px solid #f44336;
    }
  }

  .intro-attach-image {
    height: 306px;
    width: 306px;
    border-radius: 10%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    .add-photo-icon {
      height: 100px !important;
      width: 100px !important;
      color: #6c6c7f;
    }
  }

  .intro-attached-image {
    height: 306px;
    width: 306px;
    border-radius: 10%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .intro-image {
    overflow: hidden;
    border-radius: 1%;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    object-fit: contain;
    object-position: center;
  }

  .intro-attach-image:hover {
    background-color: rgb(219, 219, 219);
  }

  .MuiDialog-paperWidthSm {
    min-width: 775px;
    max-width: 80vw;
    min-height: 94vh;
    max-height: 94vh;
    overflow: hidden;
    background-color: $bgColor;
  }

  .MuiDialogContent-root {
    overflow-y: hidden;
  }

  .intro-buttons {
    margin: 10px;
    display: flex;
    justify-content: flex-end;

    .MuiButton-contained.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.26) !important;
    }
  }

  .intro-editor-label {
    margin: 10px 0;
  }

  .intro-section {
    margin-bottom: 15px;
  }

  .intro-left {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }

  .intro-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 185px) !important;
    max-height: calc(100vh - 185px) !important;
  }

  .tox {
    height: calc(100vh - 425px) !important;
    max-height: calc(100vh - 425px) !important;
  }
}

#IntroductionTakePreview {
  display: flex;
  height: 100%;
  width: 100%;
  overflow-y: auto !important;

  #UserSurveyTakeWidget {
    width: 100%;
    overflow-y: auto;
  }

  &.preview-desktop {
      padding: 0;
      margin: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
      border: 0;
      width: 100%;
      height: 100%;
      min-height: 528px;
      max-height: 100%;
  }

  &.preview-mobile {
      border-style: solid;
      border-width: 40px 10px;
      border-radius: 15px;
      border-color: hsla(0, 0%, 62%, .22);
      margin: 10px 60px;
      overflow-y: scroll;
      width: 348px;
      height: 100%;
      min-height: 528px;
      max-height: 578px;
      word-break: break-word;
  }
}

.intro-preview-root {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}