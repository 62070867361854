@import '../../css/index';

#SurveyTemplatesImport {
  height: calc(100vh - #{$topNavHeight});
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #e1e4ef;

  .page-wrapper {
    display: flex;
    flex-wrap: nowrap;
  }

  .page-left {
    width: 25%;
  }

  .page-right {
    width: 60%;
  }

  .survey-templates-content {
    padding: 20px;
  }

  .import-field-wrapper {
    padding: 20px;
  }

  .import-field-wrapper.right {
    padding-left: 0;
  }

  .survey-ids-field {
    background-color: white;
    border-radius: 4px;
  }

  .import-btn {
    margin-left: 0 !important;
    margin-top: 15px;
  }

  .primary-text {
    font-size: $fs_default;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .ui-card {
    height: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    background-color: #ffffff;

    .header-text {
      .MuiCardHeader-title {
        color: #6b6985;
        font-weight: 700 !important;
        &.MuiTypography-h5 {
          font-size: 16px !important;
        }
      }
    }
  }

  .import-list {
    margin-top: 10px;
  }

  .import-text {
    display: flex;
    gap: 6px;
  }

  .em {
    font-style: italic;
  }

  .error-text {
    color: #ff4646;
  }

  #DashboardToolbar {
    height: $topNavHeight;

    .MuiLink-underlineAlways {
      cursor: pointer;
    }

    .MuiTypography-colorPrimary {
      color: white;
    }

    .dashboard-title.spaced {
      padding-right: 8px;
    }
  }
}
