@import '../../../css/index';

$bgColor: white;
$fgColor: #6F8096;

#PulseCampaignTemplateSelector {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 225px);
  margin-top: 15px;
  background: $bgColor;
  padding: 15px 15px 0 15px;
  border-radius: 10px;
  overflow-y: auto;

  .templates-grid {
    margin: 0;
    height: calc(100vh - 285px);
  }

  .templates-grid-header {
    width: 100%;
    margin: 5px 0 5px 0;
  }

  .templates-grid-header-title {
    margin-bottom: 10px;

    @include themify($themes) {
      color: themed('calEventTextColor') !important;
    }

    font-size: $fs_default;
    font-weight: bold;
  }

  .templates-grid-header-grp {
    margin: 0;
    height: 100%;
    width: 100%;
  }

  .templates-grid {
    margin: 5px 0 0 0;
    width: 100%;
    flex: 1;
  }

  .templates-grid-list {
    display: flex;
    margin: 0;
    width: 100%;
  }

  .spinner-container {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .template-grid-item {
    width: 100%;
    height: 80px;
    max-height: 100px;
    padding: 5px 5px 5px 10px;
  }

  .template-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: space-between;
  }

  .template-card-left {
    text-align: left;
    justify-content: center;
    align-items: center;
    height: 100%;

    .template-card-text {
      color: $fgColor  !important;
      font-weight: bold !important;
      font-size: 14px  !important;
      font-size: clamp(12px, 1.0vw, 14px) !important;
    }

    .template-card-subtext {
      color: $fgColor  !important;
      font-size: 12px  !important;
      font-size: clamp(10px, 1.0vw, 12px) !important;
    }
  }

  .template-card-right {
    text-align: right;
  }

  .MuiPaper-root {
    margin: 0 !important;
    background-color: transparent !important;
    padding: 0 !important;
  }

  .tab-btn-selected {
    height: 100% !important;
    background-color: rgb(188, 216, 244);
    border: 1px solid #e8e8e8;
    border-radius: 10px;

    &:hover {
      background-color: rgba(188, 216, 244, 0.8) !important;
    }

    .check-icon {
      margin-right: 5px;
      color: #0097fb;
      font-weight: 600;
      height: 100%;
    }
  }

  .tab-btn-unselected {
    height: 100% !important;
    background-color: $bgColor;
    border: 1px solid #e8e8e8;
    border-radius: 10px;

    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);

    .MuiButton-root {
      margin-left: none !important;
      margin-right: 10px !important;
    }
  }

  .template-sets-filter {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 10px 10px 0 10px;
  }

  .template-sets-filter-label-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  .template-sets-filter-label-item {
    display: flex;
    flex-direction: row;
  }

  .template-sets-filter-label-text {
    color: $fgColor  !important;
    font-size: 12px  !important;
    font-size: clamp(10px, 0.9vw, 12px) !important;
    margin-right: 3px;
  }

  .create-btn {
    margin-top: 6px;
    margin-bottom: 16px;
  }
}