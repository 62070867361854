#ByEmailTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 40px;
  padding-left: 30px;

  .MuiFormControl-root {
    width: 240px;
  }

  button {
    margin: 6px !important;
    margin-top: -4px !important;
  }

  .client-select {
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-endAdornment {
      right: 5px !important;
    }

    margin-bottom: 12px;
    button {
      margin: 0px;
    }
    div {
      padding: 0 0 0 6px;
    }
  }
  .client-autocomplete {
    .MuiAutocomplete-popupIndicator {
      margin: 0px !important;
    }
  }
}
