@import '../../css/index';

.announcement-menu-delete-icon {
  color: $errorRed;
}

#AnnouncementEditTabPanel {
  height: 100%;
  width: 100%;
  background-color: $white !important;

  .spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    position: absolute;
    z-index: 1002;
  }

  .add-new-title {
    height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: $antiflashWhite;

    h4 {
      font-weight: 700;
    }
  }

  .input-label {
    color: $gunPowder;
    margin: 5px 0px;
  }

  .owner-label {
    color: $gunPowder;
    margin-left: 8px;
  }

  .participant-error {
    .MuiButton-label {
      color: $errorRed;
    }
  }

  .menu-button {
    margin-top: 7px;
    cursor: pointer;
  }

  .participants-title {
    display: flex;
    align-items: center;
  }

  .owner-title {
    display: flex;
    align-items: center;

    .button-container{
      margin-left: 15px;
    }
  }

  .attachments-title {
    display: flex;
    align-items: center;
  }

  .participants-select {
    height: 100%;
    overflow-y: hidden;
  }

  .participant-card {
    cursor: default;
    min-height: 85px;

    .participant-card-paper {
      padding: 1em;
      width: 100%
    }

    .MuiPaper-root {
      background-color: $white;
    }

    .add-participant {
      margin-left: 8px;
      font-weight: 600 !important;
    }
  }

  .add-selected-button {
    background-color: $ghostWhite !important;
    padding: 8px 20px;
    display: flex;
    justify-content: flex-end;
  }

  .body-invalid {
    padding: 1px;
    border: 1px solid #f44336
  }

  .required {
    color: $errorRed;
  }

  .body {
    top: 0;
    height: calc(100vh - 150px);
    min-height: calc(100vh - 175px);
    max-height: calc(100vh - 150px);
    padding: 14px 30px 0 30px;
    overflow-y: auto;

    .participants-section{
      margin-top: 5px;
      margin-bottom: 18px;
    }
    .attachments-section{
      margin-bottom: 4px;
    }

    .owner-section{
      margin-bottom: 4px;
      .owner-label{
        margin-left: 8px;
        color: '#91919a !important';
      }
    }

  
  }

  .announce-button {
    background-color: $ghostWhite !important;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .documents-section-header-row {
    h6 {
      margin-bottom: 0px;
    }
  }

  #attachmentsSection,
  #commentInputSection {
    .attachment-file:hover {
      color: $darkTurquoise;
      cursor: pointer;
    }

    .attachments-list {
      .MuiGrid-grid-xs-6 {
        flex-basis: 48% !important;
      }
    }

    .attachment-container {
      margin: 2.5px 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
      border: 1px solid $lavenderGray;

      .attachment-content {
        height: 100%;
        display: flex;
        align-items: center;
        height: 48px;
        width: 80%;
  
        span {
          text-overflow: ellipsis;
          max-width: 80%;
          overflow: hidden;
        }
      }

      .file-container {
        border-top-left-radius: 10px;
        background-color: $whiteSmoke;
        border-bottom-left-radius: 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 40px;
        height: 100%;
        max-height: 48px;
        border-right: 1px solid $lavenderGray;
        margin-right: 5px;
        svg {
          color: $lavenderGray;
        }
      }

      .thumbnail-container {
        width: 40px;
        max-height: 48px;
        border-right: 1px solid $lavenderGray;
        margin-right: 5px;
        justify-content: center;
        align-items: center;
        display: flex;
        img,
        video {
          height: 48px !important;
          width: 40px;
          position: relative;
          border-top-left-radius: 9px;
          border-bottom-left-radius: 9px;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .attachment-row,
    .input-attachment-row {
      margin: 5px 0;
      height: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 5px;

      .delete {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        // align-items: center;
      }
    }

    .input-attachment-row {
      margin-top: 10px;
      margin-left: 50px;
      margin-right: auto;
      background-color: $whiteSmoke;
      border-radius: 5px;
      padding: 2.5px;
      padding-left: 0px !important;

      .img-container {
        height: 100%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 20px;
        margin-right: 2.5px;
      }

      .attachment-body {
        display: flex;
        align-items: center;
        height: 20px;
        svg {
          color: $spanishGray;
        }
      }
    }
  }

  .deliver-on-specific-date-picker {
    width: 180px;
  }

  .publish-at-specific-date-time-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;

    > div {
      margin-right: 15px;
    }

    .custom-time-picker {
      margin-top: 0 !important;
    }

    .time-zone-autocomplete {
      width: 325px;

      .MuiFormControl-root {
        width: 100%;
      }

      .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
        padding: 0;
      }

      .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
        padding: 0px;
        padding-right: 65px;
        padding-left: 10px;

        .MuiAutocomplete-input {
          padding-top: 0px !important;
        }
      }
    }
  }

  .select-template-button{
    margin-left: 0px !important;
    margin-top: 5px;
    margin-bottom: 10px;
  }


  .textfield-label {
    font-weight: 600;
    margin-bottom: 5px;
  }
}
