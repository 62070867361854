@import '../../../css/index';
@import '../../../css/colors';

#SurveyTemplatesManage {
  height: calc(100vh - #{$topNavHeight});
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #e1e4ef;

  .no-templates-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .ag-theme-alpine {
    height: calc(100vh - 220px) !important;
  }

  #DashboardToolbar {
    height: $topNavHeight;
  }

  .actions-button-cell {
    overflow: visible;
  }

  .ag-row {
    z-index: 0;
  }

  .ag-row.ag-row-focus {
    z-index: 1;
  }

  .header-text {
    padding-bottom: 0;
    .MuiCardHeader-title {
      color: #6b6985;
      font-weight: 700 !important;
      &.MuiTypography-h5 {
        font-size: 16px !important;
      }
    }
  }

  .dashboard-title.spaced {
    padding-right: 8px;
  }

  .MuiLink-underlineAlways {
    cursor: pointer;
  }

  .MuiTypography-colorPrimary {
    color: white;
  }
}
