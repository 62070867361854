@import '../../../../css/index';

$drawerHeight: calc(100vh - 64px);

#QuestionsCatalogDrawer {
  .MuiDrawer-paperAnchorRight {
    width: 45%;
    min-width: 580px;
    overflow-y: hidden;
  }

  .ag-theme-alpine {
    height: calc(100% - 147px);

    .ag-body-horizontal-scroll-viewport {
      overflow-x: hidden;
    }
    .ag-root-wrapper {
      border: none;
    }

    .ag-body-viewport,
    .ag-body-horizontal-scroll-viewport,
    .ag-body-horizontal-scroll {
      background-color: #fff;
    }
    .ag-row {
      background-color: #fff;
      border-bottom: none;
      border-top: none;
      font-size: inherit;

      .ag-cell {
        &[aria-colindex='1'] {
          border-left: none;
        }
      }
    }
    .ag-react-container {
      width: 100%;
    }
    .ag-row:not(.ag-row-first) {
      border-top: none;
    }
    .ag-header {
      border-bottom: none;
    }
    .ag-cell {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .question-catalog-header {
    width: 100%;
    height: 46px;
    max-height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $antiflashWhite;
    padding: 0 15px;
  }

  .question-catalog-toolbar {
    width: 100%;
    height: 46px;
    background-color: $ghostWhite;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .question-catalog-toolbar-left {
      display: flex;
      align-items: center;
    }

    .question-catalog-searchbar {
      margin-left: 10px;
    }
  }
}

// NOTE: This h5 style is kinda a problem... Was used a long time ago for input field labels and other stuff like that. Now these h5 elements are all throughout the app.
h5 {
  font-size: $fs_default !important;
  font-weight: 700 !important;
}

.question-catalog-type-filter-menu {
  background: $ghostWhite;
  padding: 10px 15px 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.question-catalog-category-filter-menu {
  $menuHeight: calc(#{$drawerHeight} / 3);

  background: $ghostWhite;
  padding: 10px;
  height: 360px;
  min-height: 300px;
  overflow-y: hidden;
  width: 400px;

  .react-dropdown-tree-select {
    height: 34px;

    .dropdown {
      height: 34px;
      display: block;

      .dropdown-trigger {
        box-sizing: border-box;
        height: 34px;
        width: 100%;
        border-radius: 4px;
        padding: 0;
        overflow: hidden;

        ul.tag-list {
          width: 100%;
          height: 100%;

          li.tag-item {
            display: none;
          }

          li.tag-item:nth-last-child(1) {
            display: block;
            margin: 0;
            height: 100%;
          }

          li.tag-item > input {
            width: 100%;
            height: 100%;
            border-bottom: none;
            font-size: 13px !important;
            padding: 0 0.75rem;
          }
        }
      }

      .dropdown-trigger.arrow.top::after,
      .dropdown-trigger.arrow.bottom::after {
        display: none;
      }

      .dropdown-content {
        max-height: 300px;
        overflow-y: auto;
        padding: 0;
        padding-top: 0.5rem;
        min-width: 100%;
        background: none;
        border-top: none;
        box-shadow: none;

        .infinite-scroll-component {
          height: auto;
          min-height: calc(300px - 64px);
          padding-bottom: 20px;
        }

        .node {
          height: 34px;
          padding: 0;
          display: flex;
          align-items: center;
          border-radius: 4px;

          label {
            z-index: 0;
            position: relative;

            input.checkbox-item {
              display: none;
            }

            span.node-label {
              font-weight: 400;
            }

            // Box
            span.node-label::before {
              content: '';
              display: inline-block;
              box-sizing: border-box;
              border-radius: 2px;
              width: 14px;
              height: 14px;
              margin: 3px 10px 3px 4px;
              vertical-align: top;
              border: 1.5px solid rgba(0, 0, 0, 0.4);
              background-color: transparent;
              transition: border 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            }

            // Box checked
            input.checkbox-item:checked + span::before,
            input.checkbox-item:indeterminate + span::before {
              @include themify($themes) {
                border: 1.5px solid themed('buttonBgColor');
                background-color: themed('buttonBgColor');
              }
            }

            // Check
            span.node-label::after {
              content: '';
              display: block;
              position: absolute;
              top: 3px;
              left: 1px;
              width: 10px;
              height: 5px;
              border: solid 1.25px transparent;
              border-right: none;
              border-top: none;
              transform: translate(5px, 3px) rotate(-45deg);
            }

            // Check checked
            input:checked + span::after,
            input:indeterminate + span::after {
              border-color: rgb(255, 255, 255);
            }
          }

          input.radio-item {
            display: none;
          }
        }

        .toggle {
          // Material Icons font default styles
          font-family: 'Material Icons';
          font-weight: normal;
          font-style: normal;
          font-size: 18px; // Icon size
          display: inline-block;
          line-height: 1;
          text-transform: none;
          letter-spacing: normal;
          word-wrap: normal;
          white-space: nowrap;
          direction: ltr;
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
          -moz-osx-font-smoothing: grayscale;
          font-feature-settings: 'liga';

          // Other styles...
          margin: 0;
        }

        .toggle.collapsed::after {
          cursor: pointer;
          content: '\e313';
        }

        .toggle.expanded::after {
          cursor: pointer;
          content: '\e316';
        }
      }
    }
  }
}
