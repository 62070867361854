@import '../../../css/index';

$bgColor: #F1F2F6;
$bgDarkColor: rgb(213, 213, 222);
$bgDisabled: #EBEBEB;

#PulseCampaignSurveyIntroductionCard {
  width: 100%;

  .MuiCardHeader-root {
    padding: 0;
    cursor: pointer;
  }

  .MuiCardHeader-action {
    height: 100%;
    margin-top: 0 !important;
    margin-right: 0 !important;
    align-self: center;
  }

  .MuiCardContent-root {
    padding: 0 0 5px 0 !important;
  }

  .MuiPaper-rounded {
    width: 100%;
    background-color: white !important;
    border: 1px solid #e8e8e8 !important;
    border-radius: 10px;
  }

  .question-card-header-attachment {
    display: flex;
    flex-direction: row;
    width: fit-content;
  }

  .question-card-header-attachment img {
    object-fit: contain;
    max-width: 40px;
    max-height: 40px;
    margin-left: 10px;
  }

  .question-card-header-actions {
    display: flex;
    flex-direction: row;
  }

  .question-card-content-body {
    margin: 0;
  }

  .question-card-header-title {
    @include themify($themes) {
      color: themed('calEventTextColor') !important;
    }
    font-size: $fs_default;
    font-weight: bold;
    text-align: left;
    padding: 15px;
  }

  .question-card-edit {
    position: absolute;
    display: flex;
    left: 45%;
    align-items: center;
    justify-content: center;
    transform: translate(0%, -50%);
  }

  .question-card-btn {
    padding: 5px !important;
  }

  .question-card-btn-avatar {
    max-height: 32px;
    max-width: 32px;
    color: rgba(0, 0, 0, 0.58);
    background-color: $bgDarkColor !important;

    &.disabled {
      color: rgba(0, 0, 0, 0.18) !important;
      background-color: $bgDisabled !important;
    }
  }
}
