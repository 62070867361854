@import '../../../css/index';

.avatar-container {
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.open {
    background-color: $black10;
  }

  .spinner-container {
    height: 40px !important;
    width: 40px !important;

    .spinner {
      height: 32px !important;
      width: 32px !important;
    }
  }
}

#avatar-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: $white;
  margin-right: -5px;
  @include themify($themes) {
    background-color: themed('primaryBgColor');
  }

  .menu-list {
    width: 200px;
  }

  .avatar-menu-org-list {
    max-height: calc(90vh - 64px - 35px * 3);
    overflow-y: auto;
  }

  .avatar-menu-item {
    font-size: $fs_subheader;
    height: 44px;

    .current-org-check {
      margin-left: auto;
      @include themify($themes) {
        color: themed('activeTabColor');
      }
    }
  }

  .select-org-btn {
    @include themify($themes) {
      background-color: themed('secondaryBgColor');
      color: themed('dashboardHeaderText');
    }
    padding: 8px 12px;
  }

}
