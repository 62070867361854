@import '../../../css/index';

#MyAvailableSurveysWidget {
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  .MuiCardContent-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  #Header {
    color: #000;
    border-bottom: 1px solid #d7d7e2;
    padding: 9px 12px 9px 16px !important;
  
    .MuiCardHeader-content {
      display: flex;
      align-items: center;
    }
  
    .MuiCardHeader-action {
      align-items: center;
      vertical-align: middle;
      margin-top: 0;
      margin-right: 0;
  
      .MuiTypography-root {
        font-size: 1.2rem;
        color: #888;
      }
  
      div:not(:last-child) {
        margin-right: 6px;
      }
    }
  }

  #take-survey {
    color: $medAquamarine;
  }

  .ag-cell {
    &[aria-colindex='1'] {
      border-left: 3px solid $medAquamarine;
    }
  }
}



