@import '../../../css/index';

$bgColor: #dce1ed;
$fgColor: #6f8096;
#UserInfoTab {
  .button-space{
    margin-right: 10px;
  }

  .welcome-status {
    margin-top: 10px;

    .welcome-status-text {
      padding: 0 9px;
      text-transform: uppercase;
    }
  }

  .email-input {
    .MuiFormHelperText-root {
      color: red;
      margin: 8px 4px;
    }
  }

  .birthday-input {
    #birthdate-helper-text {
      display: none !important;
    }

    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.23) !important;
    }
  }
  .MuiGrid-container {
    padding: 0 10px !important;
  }

  #userBasicInfo,
  #userContactInfo {
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      padding: 0 9px;
    }

    margin-bottom: 16px !important;
  }

  #userPhoto {
    .no-avatar {
      font-size: 12rem !important;
      color: lightgray !important;
    }

    .user-avatar {
      height: 12rem !important;
      width: 12rem !important;
    }

    .spinner-container {
      height: 12rem !important;
      width: 12rem !important;
  
      .spinner {
        height: 5rem !important;
        width: 5rem !important;
      }
    }
  }

    .notification-buttons {
    .MuiToggleButton-root {
        background-color: rgba(248, 248, 251, 0.7) !important;
    
        @include themify($themes) {
          color: rgba(0, 0, 0, 0.38) !important;
        }
      }
    
      .MuiToggleButton-root.Mui-selected {
        @include themify($themes) {
          background-color: themed('buttonOtlBgColor') !important;
          color: themed('buttonOtlTextColorAlt') !important;
        }
      }
    
      .tab-btn-selected {
        max-height: 34px;
        max-width: 240px;
        text-transform: uppercase;
        color:#0064e9 !important;
        font-weight: 700 !important;
        font-size: $fs_tertiary !important;
        background-color: #add2f1 ;
        margin-right: 5px;
        padding: 18px 9px 18px 9px !important;
        box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1), inset 0 6px 8px 0 rgba(0, 0, 0, 0.1);
    
        .check-icon {
          margin-left: 5px;
          color: #b0b7c4;
          font-weight: 600;
        }
      }
    
      .tab-btn-unselected {
        max-height: 34px;
        max-width: 240px;
        font-weight: 700 !important;
        color: #6c6c7f !important;
        font-size: $fs_tertiary !important;
        background-color: $bgColor;
        padding: 18px 9px 18px 9px !important;
        border-bottom: 0px !important;
      }
    
      .MuiButton-root {
        height: 34px;
        max-height: 34px;
        margin-left: none !important;
        margin-right: 10px !important;
      }
    }
  
    .notification-icon {
      margin-right: 10px;
    }
  
    .notification-check {
      margin-left: 10px;
    }

    #userNotificationInfo{
      .user-note-tool{
        margin-left: -10px;
      }
    }
  }

  #AvatarDiscard {
    color: $indianRed !important;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -12px;
    top: -182px;
    position: relative;
    z-index: 99;

    .trash-icon{
      background-color: $indianRed !important;
      border-radius: 50%;
      height: 1.75rem;
      width:1.75rem;
      padding: 3px;
      color: white !important;
    }
  }
    
