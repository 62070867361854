#PulseQuestionEditCategories {

  .category {
    .category-header {
      margin-bottom: 8px;
      font-weight: bold;
    }
    .category-select {
      width: 300px;
      margin-bottom: 20px;
    }
  }
  .categories-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
}