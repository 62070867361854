@import '../../../../css/index';

#TimelineSurvey {
  width: 300px;

  .survey-delivery-time {
    display: flex;
    flex-direction: row;

    .line-left {
      height: 25px;
      width: 7%;

      &.sent {
        border-right: 3px solid #8d94bd;
        border-top: 3px solid #8d94bd;
      }

      &.scheduled {
        border-right: 3px solid #dce1ed;
        border-top: 3px solid #dce1ed;
      }
    }

    .line-right {
      height: 20px;
      width: 93%;
      position: relative;

      &.sent {
        border-top: 3px solid #8d94bd;
      }

      &.scheduled {
        border-top: 3px solid #dce1ed;
      }

      .delivery-time {
        display: flex;
        flex-direction: row;
        position: absolute;
        left: -15px;
        top: -12px;
        border-radius: 5px;
        text-transform: uppercase;
        background-color: #f1f2f6;

        &.sent {
          border: 2px solid #8d94bd;
        }

        &.scheduled {
          border: 2px solid #b8c2d9;
        }

        .survey-index {
          color: #ffffff;
          padding: 0px 5px;
          font-size: $fs_tertiary;
          font-weight: 700;

          &.sent {
            background-color: #8d94bd;
          }

          &.scheduled {
            background-color: #b8c2d9;
          }
        }

        .survey-time {
          padding: 0px 5px;
          font-size: $fs_tertiary;
          font-weight: 700;

           &.sent {
            color: #8d94bd;
          }

          &.scheduled {
            color: #b8c2d9;
          }
        }
      }
    }
  }

  .survey-card-details {
    height: 100px;
    margin-bottom: 20px;
    margin-right: 20px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    background-color: #ffffff;
    padding: 5px;
    cursor:pointer;

    &.active {
      box-shadow: inset rgba(2, 2, 2, 0.4) 0px 2px 10px 0px !important;
    }

    .survey-card-details-complete {
      height:100%;
      display:flex;
      align-content: flex-end;
      padding-bottom: 14px;
    }

    .survey-status {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 0px 0px 5px;

      .survey-status-sent {
        background-color: #FDE180;
        width: 15px;
        height: 8px;
        border-radius: 10px;
      }

      .survey-status-scheduled {
        background-color: #b8c2d9;
        width: 15px;
        height: 8px;
        border-radius: 10px;
      }

      .survey-status-text {
        margin-left: 5px;
        font-size: $fs_tertiary;
      }
    }

    .survey-name {
      color: #6b6985;
      font-weight: 700 !important;
      padding: 0px 0px 10px 5px;
      font-size: $fs_secondary;
      font-weight: 700;
    }

    .survey-questions-count {
      padding: 0px 0px 0px 5px;
      .questions-count-text {
        font-size: $fs_tertiary;
      }
    }

    .survey-completed {
      display:flex;
      align-items: center;

      .survey-completed-icon {
        color:green;
        height:13px;
      }
      .survey-completed-text {
        font-size: $fs_tertiary;
      }
    }
  }
}
