@import '../../../css/index';

#QuestionCard {
  display: flex;
  flex-direction: row;
  border-left: solid rgba($lavenderGray, 0.5) 2px;
  padding-left: 40px;
  margin-left: 18px;

  .question-card {
    border: 1px solid #d7d7e2;
    border-radius: 12px;
  }

  .question-text-box {
    background-color: #f2f6ff;
  }

  .image-slider {
    display: flex;
    height: 100%;
    align-items: center !important;
    padding-left: 10px;
  }

  .video-slider {
    padding-left: 20px;
  }
  textarea {
    height: initial;
  }

  .MuiFormControlLabel-root {
    margin-left: -1px !important;
  }

  .MuiGrid-container {
    display: block;
  }

  .prefer-not-to-answer {
    margin-top: 25px;
    margin-left: 0 !important;
  }

  .prefer-not-to-answer-active {
    margin-top: 10px;
    margin-left: 0 !important;
    @include themify($themes) {
      border-color: themed('accentColor20') !important;
    }
  }

  .video-area {
    position: relative;
  }
  .video-controls {
    justify-content: center;
    button {
      color: white;
    }
    position: absolute;
    bottom: 6%;
  }

  .volume {
    margin-left: 110px;
  }

  .media-image {
    cursor: pointer;
    float: left;
    max-width: 240px;
    max-height: 240px;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
    border-radius: 6px;
    border: solid #efefef 1px;
  }

  .media-video {
    // float: left;
    // max-width: 240px;
    // max-height: 240px;
    max-width: 700px;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
    border-radius: 6px;
    border: solid #efefef 1px;
    background-color: #000;
    margin-left: 0px !important;
    padding: 10px !important;
    border-radius: 10px !important;
    border: 5px solid $spanishGray !important;
  }

  &.blurred {
    opacity: 0.5;
  }

  .indicator {
    color: #fff;
    font-weight: bold;
    margin-left: -62px;

    &.active {
      @include themify($themes) {
        background-color: themed('accentColor') !important;
      }
    }
  }

  .hr {
    height: 2px;
    width: 20px;
    background: rgba($lavenderGray, 0.5);
    margin-top: 21px;
  }

  .MuiCard-root {
    background-color: $surface;
    flex: 1;

    .question-row-title {
      font-weight: 700;
      font-size: calc(12px + 0.45vw);
      color: grey;

      .required-indicator {
        color: $red;
        padding-left: 5px;
        font-size: 1.6rem;
      }

      video,
      iframe {
        min-width: 200px;
        max-width: 700px;
        min-height: 112px;
        max-height: 400px;
        background-color: black;
      }
    }
  }

  .MuiSlider-root {
    display: block;
    left: 70px;
    width: calc(100% - 140px);

    &.is-mobile {
      margin-top: 15px;

      .MuiSlider-track {
        padding-left: 6px;
      }

      .MuiSlider-thumb {
        left: -46%;
      }
    }

    .MuiSlider-track {
      left: -20px !important;
      @include themify($themes) {
        color: themed('buttonHoverColor') !important;
      }
    }

    .MuiSlider-thumb {
      @include themify($themes) {
        border: 2px solid themed('primaryBgColor') !important;
      }
      color: $white !important;
    }

    &.has-answer {
      .MuiSlider-track {
        left: -20px !important;
        padding-left: 20px;
        @include themify($themes) {
          color: themed('activeTabBgColor') !important;
        }
      }

      .MuiSlider-thumb {
        @include themify($themes) {
          color: themed('activeTabBgColor') !important;
          border: 2px solid themed('activeTabBgColor') !important;
        }
      }
    }

    .MuiSlider-rail {
      height: 6px;
      left: -20px;
      width: calc(100% + 40px);
      border-radius: 6px;
    }

    .MuiSlider-track {
      height: 6px;
      border-radius: 6px;
    }

    .MuiSlider-thumb {
      width: 18px;
      height: 18px;
    }

    .MuiSlider-mark {
      height: 14px;
      margin-top: 20px;
      background-color: #dcdcdc;
    }

    .MuiSlider-markLabel {
      margin-top: 28px;
      white-space: pre;
      text-align: center;
    }
  }

  .ReactModal__Overlay--after-open {
    div {
      z-index: 10000 !important;
    }
  }

  .MuiSlider-vertical {
    display: block;
    left: 20px;
    width: 6px;

    &.has-answer {
      .MuiSlider-track {
        left: -21px !important;
        padding-left: 6px;
      }
    }

    .MuiSlider-rail {
      width: 6px;
      left: -20px;
      height: 100%;
      border-radius: 6px;
    }

    .MuiSlider-track {
      border-radius: 6px;
    }

    .MuiSlider-thumb {
      width: 18px;
      height: 18px;
      left: -65%;
    }

    .MuiSlider-mark {
      width: 14px;
      height: 1px !important;
      left: -20%;
      float: left;
      background-color: #dcdcdc;
      white-space: normal;
      text-align: left;
    }

    .MuiSlider-markLabel {
      margin: 0px;
      transform: translateY(41%) !important;
      white-space: nowrap;
      text-align: left;
    }
  }

  .slider-container {
    margin-top: 30px;
    padding-bottom: 20px;

    &.mui-slider-container-vertical {
      margin-left: 30px;

      .MuiSlider-markLabel {
        width: calc(100vw - 265px);
        min-width: 600px;
        white-space: pre-wrap;
        max-height: 2 * 19px;
        overflow: hidden;
      }
    }

    &.mui-slider-container-mobile {
      margin-bottom: 25px;

      .MuiSlider-markLabel {
        width: calc(100vw - 260px);
      }
    }
  }

  .flex-row {
    width: fit-content;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 20px;

    .star-icon {
      width: calc(40px + 0.45vw);
      height: calc(40px + 0.45vw);

      @media (min-width: 200px) {
        height: calc(100% + 3 * (100vw - 600px) / 400);
        max-height: calc(40px + 0.45vw);
        width: calc(100% + 3 * (100vw - 600px) / 400);
        max-width: calc(40px + 0.45vw);
      }
    }

    .star-text {
      height: 100%;
      width: 100%;
      display: flex;
      font-size: calc(12px + 0.45vw);
      justify-content: center;
      align-self: center;
      margin-bottom: 5px;
    }
  }
}
