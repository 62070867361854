@import '../../../../css/index';
@import '../../../../css/colors';

#CampaignSurveyTemplateSelect {
  width: 100%; 
  height: calc(100vh - 280px) !important;
  overflow-x: hidden;
  overflow-y: auto;

  #SurveyTemplatesSelect {
    min-height: calc(100vh - 280px) !important;
  }

  .no-templates-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .ag-theme-alpine {
    height: calc(100vh - 380px) !important;
  }

  .folders-card {
    height: calc(100vh - 380px) !important;
    overflow-y: auto;
    margin-right: 10px;
  }
}