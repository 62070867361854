@import '../../../css/index';

#PulseQuestionTypeRequired {
  .question-type {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;

    .question-type-left {
      margin-right: 200px;

      .type-select {
        width: 250px;

        .MuiSelect-root {
          display: flex;
          align-items: center;
        }

        .fa-smile {
          background-color: #ad6f3c;
        }
        .fa-check-square,
        .fa-info-circle {
          background-color: #5a87db !important;
        }
        .fa-dot-circle {
          background-color: #75b975;
        }
        .fa-caret-square-down {
          background-color: #75b975;
        }
        .fa-list-ol {
          background-color: orange;
        }
        .fa-star {
          background-color: #f0e054;
        }
        .fa-font {
          background-color: #da4949;
        }
        .fa-thumbs-up {
          background-color: #c557cf;
        }
        .fa-calculator {
          background-color: orange;
        }
        .fa-sort-amount-up {
          background-color: pink;
        }
      }
    }

    .question-type-header {
      margin-bottom: 8px;
      font-weight: bold;
    }

    .question-type-required-header {
      font-weight: bold;
    }
  }

  .rating-display {
    margin-bottom: 10px;

    .rating-display-header {
      font-weight: bold;
    }

    .MuiFormGroup-root {
      flex-direction: row;
    }

    .MuiRadio-colorSecondary.Mui-checked {
      color: rgba(0, 0, 0, 0.54);
    }
  }

}
