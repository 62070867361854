@import '../../../../css/colors';
@import '../../../../css/index';

#WelcomeBannerEditorToggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  span {
    display: block;
    min-width: fit-content;
  }

  .tab-btns {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 15px;

    .tab-button-label {
      height: 100%;
      text-align: center;
      font-weight: 400 !important;
      font-size: $fs_secondary !important;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }

    .tab-button {
      padding: 6px;
      border-radius: 4px;
      display: flex;
      flex-direction: row;

      cursor: pointer;
      // display: inline-block;
      font-weight: 400 !important;
      font-size: $fs_secondary !important;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      margin-right: 16px;

      @include themify($themes) {
        border: 1px solid themed('primarySurfaceColor') !important;
      }

      &.active {
        font-weight: 700 !important;
        border-bottom: 2px solid !important;

        @include themify($themes) {
          color: themed('buttonOtlTextColor') !important;
          border-color: themed('activeTabBgColor') !important;
        }
      }

      @include xl-desktop-up {
        font-size: 12.5px !important;
      }
    }
  }

}