@import '../../../css/index';

$stepCircleSize: 35px;
$bgColor: #f1f2f6;

#FocusStep {
  height: 100%;
  display: flex;
  padding: 0 15px 5px;
  background-color: $bgColor !important;
  overflow-y: auto;

  .pulse-campaign-focus {
    height: 100%;
  }

  .pulse-campaign-focus-builder {
    flex-grow: 1 !important;
    max-width: 100% !important;
    flex-basis: 60% !important;
  }

  .pulse-campaign-focus-summary {
    flex-grow: 0 !important;
    flex-shrink: 1 !important;
    max-width: 40% !important;
    flex-basis: auto !important;
  }

  #PulseCampaignTopToolbar {
    .dashboard-appbar {
      .dashboard-toolbar {
        .dashboard-text-wrapper {
          width: 65%;
        }
      }
    }
  }
}

#ToggleFilter .MuiToggleButton-root.Mui-selected {
  @include themify($themes) {
    background-color: themed('buttonOtlBgColor') !important;
    color: themed('buttonOtlTextColorAlt') !important;
  }
}
