@import '../../../css/index';
@import '../../../css/ag-grid-tables.scss';

#MyAudienceWidget {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-basis: auto;

  .MuiTableHead-root {
    th {
      font-weight: 700 !important;
      @include themify($themes) {
        background-color: themed('secondarySurfaceColor') !important;
      }
    }
  }

  .no-rows {
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }

  .search {
    height: 30px;
  }

  .audience-action {
    overflow: hidden;

    .MuiIconButton-sizeSmall {
      padding: 0 5px;
    }
  }

  .action-link {
    &.mail {
      height: 22px !important;
      color: $jordyBlue;
    }

    &.event {
      color: $topazYellow;
    }

    &.survey {
      color: $medAquamarine;
    }
  }

  // .MuiCardHeader-root {
  //   height: 52px !important;
  //   display: flex;
  //   flex-direction: row;
  //   flex: 1 0 auto;
  // }

  .type {
    text-transform: capitalize;
    // max-width: 30px;
  }

  .MuiCardContent-root {
    padding: 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    justify-content: flex-start;
  }

  .audience-roles,
  .audience-groups {
    min-width: 70px;
    max-width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .audience-contact {
    svg {
      cursor: pointer;
    }
  }

  // .MuiTableCell-root {
  //   white-space: nowrap;
  //   padding-top: 3px;
  //   padding-bottom: 3px;
  // }

  .MuiTableRow-head th {
    cursor: pointer;
  }

  .user-name-audience {
    white-space: nowrap;
    padding-left: 10px;

    div {
      display: flex;
      align-items: center;
      height: 100%;

      span.name {
        margin-left: 10px;
      }
    }
  }

  .paging-row {
    button {
      padding: 8px !important;
    }
  }

  .audience-members-widget-aggrid-name-cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .MuiAvatar-root {
      margin-right: 10px;
    }
  }

  .ag-row:hover {
    background-color: unset;
    cursor: auto;
  }
}
