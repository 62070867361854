@import './index';

.ag-theme-alpine {
  height: calc(100% - 34px);

  &.ag-grid-no-rows {
    height: 40px !important;
  }

  .ag-header {
    border-top: none;
  }

  // This hides that white bar (scrollbar background) that shows for no reason...
  // Horizontal scrolling still works when cols are expanded beyond width of widget
  .ag-center-cols-viewport {
    overflow-x: hidden;
  }

  .ag-root-wrapper-body {
    height: 100%;
  }

  .ag-root-wrapper {
    border: none;
    font-size: $fs_default;
  }

  @include themify($themes) {
    border-bottom: 1px solid themed('secondarySurfaceColor');
  }
}

.ag-header,
.ag-header-container,
.ag-header-viewport,
.ag-header-cell {
  @include themify($themes) {
    background-color: themed('secondarySurfaceColor');
  }
}

.ag-body-viewport,
.ag-body-horizontal-scroll-viewport,
.ag-body-horizontal-scroll {
  @include themify($themes) {
    background-color: themed('primarySurfaceColor');
  }
}

.ag-cell {
  display: flex;
  align-items: center;
  border-top: none;
  border-bottom: none;
}

.ag-row {
  @include themify($themes) {
    background-color: themed('primarySurfaceColor');
    border-bottom: 1px solid #e0e0e0;
  }
  // border-top: none;
  font-size: inherit !important;

  .ag-cell {
    &[aria-colindex='1'] {
      border-left: 3px solid $topazYellow;
    }
  }
}

.ag-row:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.ag-sort-order {
  display: none;
}

.ag-overlay-no-rows-wrapper {
  align-items: flex-start;
  padding-top: 40px;
}

.ag-paging-panel {
  border: none;
  @include themify($themes) {
    background-color: themed('primarySurfaceColor');
  }
}

.ag-paging-button-wrapper {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0;
}

.ag-paging-button-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.04);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.ag-paging-button-wrapper.ag-disabled:hover {
  background-color: transparent;
  transition: none;
}

.ag-overlay-no-rows-wrapper {
  align-items: flex-start;
  padding-top: 40px;

  .ag-overlay-no-rows-center {
    padding-top: 10px;
  }

  .ag-paging-page-summary-panel {
    margin: 0;
    margin-right: 6px;

    > :first-child,
    > :last-child,
    > .ag-paging-description {
      display: none;
    }
  }

  .ag-paging-row-summary-panel {
    margin-right: 0px;
  }

  .ag-paging-row-summary-panel-number {
    font-weight: 400;
  }
}

.user-cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .avatar {
    height: 20px;
    width: 20px;
  }

  .name {
    margin-left: 5px;
  }
}

.owner-cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: center;

  .name {
    height: 22px;
    margin-left: 5px;
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.ag-root-wrapper {
  border: none;
}

.custom-pagination-for-aggrid {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 6px;
  padding-bottom: 5px;

  @include themify($themes) {
    background-color: themed('primarySurfaceColor');
  }

  button {
    padding: 10px;
  }
}
