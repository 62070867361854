@import '../../../css/index';

#MixedAndTemplatePanel {
  @import '../../../css/ag-grid-tables.scss';
  width: 100%;

  .mixed-toggle-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }

  .mixed-toggle-btn {
    padding: 5px 18px !important;
    color: black;
  }

  .template-container {
    overflow-y: auto;
  }

  .template-header {
    width: 100%;
    margin: 5px 0 5px 0;
  }

  .template-field-wrapper {
    margin: 0;
    height: 100%;
  }

  .template-header-title {
    margin-bottom: 10px;

    @include themify($themes) {
      color: themed('calEventTextColor') !important;
    }
    font-size: $fs_default;
    font-weight: bold;
  }

  .template-wrapper {
    height: 100%;
    width: 100%;
  }

  .template-title {
    margin-bottom: 24px;
  }

  .template-body-item {
    margin-bottom: 2px;
  }

  .template-reset {
    margin-left: 0 !important;
  }

  .template-selected-actions {
    margin: 22px 0 12px;
  }

  .MuiIconButton-root {
    width: 26px;
    height: 26px;
  }

  .MuiSwitch-root .MuiIconButton-root {
    width: 38px;
    height: 38px;
  }

  .MuiSvgIcon-root {
    font-size: 18px !important;
  }

  .selected-template-screen {
    text-align: center;
    margin: 0 auto;
  }

  .spinner-container {
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 100px;

    .spinner {
      height: 32px !important;
      width: 32px !important;
    }
  }

  .questions-panels {
    margin-bottom: 100px;
  }

  .pulse-campaign-questions-grid-header-grp {
    margin: 0;
    height: 100%;
  }

  .owner-input {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  .input-div {
    margin-left: 10px;
    margin-bottom: 10px;
  }

  #SurveyTemplatesSelectorGrid {
    width: 100%;

    .ag-center-cols-container {
      width: 100% !important;
    }

    .MuiCardHeader-root {
      height: 54px;
      border-bottom: 1px solid #e3e3e8;
    }

    .MuiCardContent-root {
      padding: 0;
    }

    .ag-root,
    .ag-body-viewport,
    .ag-body-viewport-wrapper {
      overflow: visible !important;
    }

    .actions-button-cell {
      overflow: visible;
    }

    .ag-row {
      z-index: 0;
    }

    .ag-row.ag-row-focus {
      z-index: 1;
    }

    .survey-templates-grid-card {
      height: 100%;

      .header-text {
        .MuiCardHeader-title {
          color: #6b6985;
          font-weight: 700 !important;
          &.MuiTypography-h5 {
            font-size: 16px !important;
          }
        }
      }
    }
  }
}
