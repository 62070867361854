@import '../../../css/colors';

#OrgUsersListTable {
  @import '../../../css/ag-grid-tables.scss';
  margin: 15px;

  .row-highlighted {
    background-color: rgba(0, 159, 158, 0.6) !important;
  }

  .ag-center-cols-viewport {
    overflow-x: auto !important;
  }

  .ag-root-wrapper {
    border: solid 1px;
    border-color: var(--ag-border-color, #babfc7) !important;
}

  .ag-cell-focus{
    border: 1px solid #2196f3 !important;
}

  .hidden {
    color: #474852 !important;
    background-color: #C1C1D3 !important;
  }

  .ag-body-viewport{
    height: calc(100vh - 174px);
  }
  
  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

.ag-theme-alpine {
  height: 100% !important;
}

#WidgetTable {
  flex: 1;

  th {
    width: 25%;
    font-weight: 700;
    background-color: $whisper;
  }

  tr {
    cursor: pointer;
  }

  .MuiTableCell-root {
    font-size: inherit;
  }
}
}


