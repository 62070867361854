#ChartWidget {
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  .MuiCardContent-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
}

