@import '../../../css/index';

#SettingsPane {
  padding: 8px;

  #Content {
    width: 100%;

    .MuiInputLabel-root:not(:first-child) {
      margin-top: 8px !important;
    }

    .MuiListItem-root {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .right-buttons {
      position: absolute;
      right: 10px;
    }

    button.Mui-disabled {
      .MuiButton-label {
        color: #dcdcdc;
      }
    }

    form {
      margin: 0 !important;
      height: fit-content;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .MuiFormControlLabel-root {
        margin: 0;
        margin-left: -0.75rem;
        margin-right: 1rem;
      }

      button#edit {
        margin-left: auto !important;
        margin-right: 0 !important;
      }

      .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
        padding: 0;
        padding-left: 10px;
        padding-right: 40px;
        min-width: 200px;
      }

      .MuiInputBase-root {
        margin-right: 1rem;
        padding-right: 20px;
        min-width: 200px;
        max-width: 240px;
      }
    }
  }

  .MuiInputBase-root.error {
    border: solid $red 1px !important;
  }
}
