@import '../../css/colors';

#QuestionType {
  width: 100%;
  display: flex;
  align-items: center;

  .fa-smile {
    background-color: #ad6f3c;
  }
  .fa-check-square,
  .fa-info-circle {
    background-color: #5a87db !important;
  }
  .fa-dot-circle {
    background-color: #75b975;
  }
  .fa-caret-square-down {
    background-color: #75b975;
  }
  .fa-list-ol {
    background-color: orange;
  }
  .fa-star {
    background-color: #f0e054;
  }
  .fa-font {
    background-color: #da4949;
  }
  .fa-thumbs-up {
    background-color: #c557cf;
  }
  .fa-calculator {
    background-color: orange;
  }
  .fa-sort-amount-up {
    background-color: pink;
  }
}

.MuiOutlinedInput-root {
  width: 100%;
}

.MuiSelect-iconOutlined {
  right: 14px !important;
}

.MuiIcon-root {
  height: 26px !important;
  width: 26px !important;
  align-self: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
