#UserSurveyTakeWidget #DoneContent {
  text-align: center;
  padding: 50px;
  background-color: white;
  height: 100%;
  width: 100%;

  .MuiTypography-root {
    font-family: Nunito !important;
  }

  .success-msg {
    font-size: 36px;
    font-weight: 700;
  }

  .success-caption {
    font-size: 18px !important;
    font-weight: 500 !important;

    &.mtb {
      margin: 8px 0 30px;
    }
  }

  .view-results {
    color: #8694f0 !important;
  }

  .back-to-surveys {
    background-color: #8694f0 !important;
  }
}
