@import '../../../css/index';

$bgColor: white;
$bgDarkColor: rgba(128, 128, 128, 0.3);
$fgColor: #6f8096;

#PulseSetRowDetails {
  padding-left: 40px;

  .spinner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  .pulse-set-details-card {
    margin: 5px 0px 5px 0px;
    background-color: $bgColor;
    padding: 10px 20px 10px 20px;
    border-radius: 4px;

    .details-header {
      color: $fgColor;
      font-size: $fs_default;
      font-weight: bolder;
      margin-bottom: 10px;
    }

    .orgs-container {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      flex-wrap: wrap;

      .org-container {
        padding: 5px 10px;
        margin-right: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        background-color: #ecedf2;

        .org-name {
          color: #6f8096;
          font-size: $fs_secondary;
        }
      }
    }

    .add-icon-container {
      padding: 0px !important;
      background-color: rgba(0, 159, 158, 0.6) !important;
      color: #ffffff !important;
    }

    .pulse-set-templates {
      .templates-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .templates-title {
          color: $fgColor;
          font-size: $fs_default;
          font-weight: bolder;
        }
      }

      .template-container {
        padding: 5px 10px;
        border-radius: 4px;
        background-color: #ecedf2;
        margin-bottom: 5px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .template-name {
          font-weight: bold;
          color: #6f8096;
          font-size: $fs_secondary;
        }

        .surveys-container {
          display: flex;
          flex-direction: row;

          .survey-name {
            color: #6f8096;
            font-size: $fs_secondary;
          }
        }
      }
    }
  }
}
