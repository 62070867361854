@import '../../css/index';

#numberInputs {
  margin-top: 10px;
  .numeric-inputs {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
  }
}
