@import '../../../css/colors';
@import '../../../css/index';

#CalendarEventDrawer {
  .MuiCardHeader-action {
    margin-top: 0px !important;
    .MuiButtonBase-root {
      margin-top: 0px !important;
    }
  }
  > .MuiDrawer-paper {
    > .drawer-card {
      height: calc(100vh - 64px);

      .calendar-event-drawer-body-card-content {
        overflow-x: hidden;
      }

      .calendar-event-drawer-participants-select-container {
        height: calc(100% - 50px - 64px);

        > div {
          height: 100%;
        }
      }
    }
  }

  .send-notifications {
    margin-top: 20px;
  }

  .MuiToggleButton-label {
    .MuiIcon-root {
      width: 29px !important;
    }
  }

  .toggle-button {
    padding: 0 4px !important;

    .material-icons {
      display: flex;
      align-items: center;
      justify-content: space-around;
      overflow: visible !important;
      margin: 0px;
    }

    .weekday-icon {
      color: black;
    }
  }

  .participant-card-name {
    h6 {
      margin-bottom: 0px;
    }
  }

  ::-ms-clear {
    display: none;
  }

  .attachments-section {
    margin: 14px 0px;
    margin-left: 6px;

    .attachments-header {
      display: flex;
      align-items: center;

      .MuiInputLabel-root {
        margin-bottom: 0px !important;
      }
    }

    .attachments-list {
      .MuiGrid-grid-xs-6 {
        flex-basis: 48% !important;
      }
    }
    
    .attachments-list {
      margin-top: 10px;

      .attachment-container {
        margin: 2.5px 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        border: 1px solid $lavenderGray;
        
        .attachment-content {
          height: 100%;
          display: flex;
          align-items: center;
          height: 48px;
          width: 80%;
    
          span {
            text-overflow: ellipsis;
            max-width: 80%;
            overflow: hidden;
          }
        }

        .file-container {
          border-top-left-radius: 10px;
          background-color: $whiteSmoke;
          border-bottom-left-radius: 10px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 40px;
          height: 100%;
          max-height: 48px;
          border-right: 1px solid $lavenderGray;
          margin-right: 5px;
          svg {
            color: $lavenderGray;
          }
        }

        .thumbnail-container {
          width: 40px;
          max-height: 48px;
          border-right: 1px solid $lavenderGray;
          margin-right: 5px;
          justify-content: center;
          align-items: center;
          display: flex;
          img,
          video {
            height: 48px !important;
            width: 40px;
            position: relative;
            border-top-left-radius: 9px;
            border-bottom-left-radius: 9px;
            height: 100%;
            object-fit: cover;
          }
        }

      }
    }
  }

  .invitees-section {
    margin-left: 3px;
  }

  .calendar-event-drawer-resources-grid,
  .calendar-event-drawer-notes-grid {
    margin-top: 10px;
  }

  .calendar-event-drawer-title-grid {
    margin-bottom: 10px !important;
  }

  .event-edit-drawer-add-alarm-button-grid {
    margin-top: 5px;
  }
}

//fix for date pickers
.MuiPickersBasePicker-container {
  .MuiToolbar-root {
    .MuiButtonBase-root {
      padding: 6px 0 !important;

      .MuiButton-label {
        color: green;
      }
    }

    .MuiGrid-grid-xs-5 {
      .MuiButtonBase-root {
        height: 24px !important;
        padding: 0px !important;
      }
    }

    .MuiGrid-grid-xs-6 {
      align-items: center;

      .MuiButton-root:not(:first-child):not(.MuiButtonGroup-grouped) {
        margin-left: 0 !important;
      }

      .MuiButtonBase-root {
        padding: 0 !important;

        .MuiButton-label {
          color: green;
        }
      }
    }

    .MuiGrid-grid-xs-1 {
      .MuiButton-root:not(:first-child):not(.MuiButtonGroup-grouped) {
        margin-left: 0 !important;
      }
    }
  }

  .MuiSvgIcon-root {
    @include themify($themes) {
      color: themed('accentColor') !important;
    }
  }
}

.location-save-cancel {
  text-align: end;
  margin-top: 10px !important;
}

.hiddenLabel {
  visibility: hidden;
}

.add-participant-card,
.participant-card {
  cursor: pointer;
  padding: 8px 12px;
  height: 62px;
  display: flex;
  justify-content: flex-start;
  align-self: center;
}

.tab-error {
  .MuiTab-wrapper {
    color: $indianRed;
  }
}

.error {
  border: 1px solid $indianRed;
}

.add-participant {
  margin-bottom: 0px;
}

.add-participant-error {
  margin-bottom: 0px;
  color: red;
}

.alarm-text {
  font-weight: 600 !important;
}

.event-type-input {
  margin: 10px 0 !important;
}

.event-type2-input {
  margin-bottom: 8px !important;
}

.location-input {
  width: 100%;
}

.eventTypesSearchBar > div > div,
.location-input > div > div {
  padding: 0 9px !important;
}

.resources-input {
  .MuiChip-deletable {
    margin-top: 5px;
    height: 24px;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 0 !important;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0 55px 0 9px !important;
    height: fit-content !important;
  }
}

.event-participants-toolbar {
  justify-content: space-between !important;
}

#save-resource-button {
  text-align: end;
}

.location-disabled {
  color: gray;
}

#save-type-button {
  text-align: end;
}
